<template>
  <div class="flex flex-col shadow bg-bluegray">
    <div
      class="flex items-center justify-between py-4 border-b px-7 border-bluegray-darker"
    >
      <div class="font-bold">Manuelle Zuordnung der Krankenversicherung</div>
      <e-button variant="text" @click="$emit('close')">
        <e-icon icon="mdi-close-box-outline" />
      </e-button>
    </div>
    <div class="p-7">
      <div class="inline-flex items-center pt-2 pb-4 text-sm text-info gap-x-2">
        <e-icon icon="mdi-alert-outline" />
        <div>Diese Zuordnung kann nicht rückgängig gemacht werden.</div>
      </div>
      <RichTable
        :is-loading="loading"
        :header-items="headers"
        :data="healthInsuranceData"
        :filter-data="filterData"
        :pagination="pagination"
        @update-filter-data="(newFilterData) => updateFilterData(newFilterData)"
        @pagination="(newPagination) => setPagination(newPagination)"
      >
        <template #row="{ item }">
          <tr>
            <td class="p-2">
              {{ formatDate(item.dateOfRecord) }}
            </td>
            <td class="p-2">{{ item.policyId }}</td>
            <td class="flex p-2 gap-x-1">
              <span>{{ item.person.firstName }}</span>
              <span>{{ item.person.lastName }} </span>
            </td>
            <td class="p-2">
              {{ formatDate(item.person.dateOfBirth) }}
            </td>
            <td class="p-2">
              <health-insurance-insured-status :value="item.insuranceStatus" />
            </td>
            <td class="p-2">
              <e-button small @click="assign(item)">Zuordnen</e-button>
            </td>
          </tr>
        </template>
        <template #no-results>
          <tr>
            <td
              :colspan="headers.length"
              class="flex items-center justify-center"
            >
              Es wurden keine Daten gefunden.
            </td>
          </tr>
        </template>
      </RichTable>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Getters } from '@/store/modules/profile/types';
import RichTable from '@/components/RichTable';
import { EButton, EIcon } from '@careerpartner/nitro';
import HealthInsuranceInsuredStatus from './InsuredStatus.vue';
import { formatDate } from '@/common/format';
import { ApiServiceWrapper } from '@/utils/apiServiceWrapper';
import { stringify } from 'qs';
import { Actions } from '@/store/modules/app/types';
import { API_ENDPOINTS } from '@/common/constants';

export default {
  name: 'HealthInsuranceManualAssignment',

  components: {
    RichTable,
    EButton,
    HealthInsuranceInsuredStatus,
    EIcon,
  },

  props: {},

  data() {
    return {
      healthInsuranceData: [],
      loading: false,
      error: null,
      filterData: {
        firstName: '',
        lastName: '',
      },
      formatDate,
      pagination: {
        offset: 0,
        limit: 20,
      },
    };
  },

  computed: {
    ...mapGetters('profile', { profile: Getters.PROFILE }),
    headers() {
      return [
        { label: 'Datum' },
        { label: 'Versicherungsnr.' },
        { label: 'Name' },
        { label: 'Geburtsdatum' },
        { label: 'Status' },
        { label: 'Aktion' },
      ];
    },
  },

  async mounted() {
    this.filterData = {
      firstName: this.profile.person.givenName,
      lastName: this.profile.person.familyName,
    };
    await this.loadData();
  },

  methods: {
    ...mapActions('app', {
      setError: Actions.SET_ERROR,
      updateSnackbar: Actions.UPDATE_SNACKBAR,
    }),

    async loadData() {
      try {
        this.error = null;
        this.loading = true;

        const url = API_ENDPOINTS.HEALTH_INSURANCE_PROFILES;

        const params = {
          filter: {
            lastName: this.filterData.lastName,
            firstName: this.filterData.firstName,
          },
          offset: this.pagination.offset,
          limit: this.pagination.limit,
        };

        const { data } = await ApiServiceWrapper.get(url, {
          paramsSerializer: stringify,
          params,
        });

        // align new health insurance to old one for quick switch
        data.results = data.results.map((insuranceProfile) => {
          return {
            ...insuranceProfile,
            insuranceStatus: insuranceProfile.state,
            dateOfRecord: insuranceProfile.lastEntryDate,
            person: {
              firstName: insuranceProfile.firstName,
              lastName: insuranceProfile.lastName,
              dateOfBirth: insuranceProfile.dateOfBirth,
            },
          };
        });

        this.healthInsuranceData = data.results ?? [];
        this.pagination = data.pagination ?? {};
      } catch (error) {
        this.error = error.message;
        this.setError(error);
      } finally {
        this.loading = false;
      }
    },

    async assign(item) {
      const confimation = confirm(
        'Soll diese Meldung zugeordnet werden? Diese Zuordnung kann nicht rückgängig gemacht werden.',
      );
      if (!confimation) {
        return;
      }

      try {
        this.loading = true;
        this.error = null;

        const url = API_ENDPOINTS.HEALTH_INSURANCE_PROFILE_LINK;
        const payload = {
          insuranceProfileId: item.id,
          iamUserId: this.profile.id,
        };

        await ApiServiceWrapper.post(url, payload);
        this.updateSnackbar({ message: 'Zuordnung erfolgreich' });
        await this.loadData();
        this.$emit('assigned');
      } catch (error) {
        this.error = error.message;
        this.setError(error);
      } finally {
        this.loading = false;
      }
    },

    async updateFilterData(filterData) {
      this.filterData = filterData;
      await this.loadData();
    },

    async setPagination(pagination) {
      this.pagination = pagination;
      await this.loadData();
    },
  },
};
</script>
