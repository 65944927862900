<template>
  <div>
    <div>
      <e-button
        variant="text"
        class="my-1"
        small
        data-test="toggle-extended-search"
        @click="toggleListVisibility"
      >
        Mehr anzeigen
        <e-icon
          :icon="isListCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up'"
        />
      </e-button>
    </div>
    <transition name="collapsible-list-animation">
      <div v-show="!isListCollapsed">
        <div v-if="showHealthInsuranceStatus" class="flex items-center">
          <span class="mr-4">Meldung an Krankenkasse</span>
          <health-insurance-status :value="healthInsuranceStatus" />
        </div>

        <e-simple-loader v-if="isLoading" class="block mx-auto" />
        <div v-else>
          <div class="flex justify-between mt-5 items-center">
            <e-button
              action-type="button"
              variant="text"
              class="n-button pointer-events-auto"
              @click="createInsuranceMessage"
            >
              <e-icon icon="mdi-plus" />
              Neue Meldung hinzufügen
            </e-button>
            <e-pagination
              hide-single-page
              :value="pagination"
              @input="handlePaginationChange"
            />
          </div>
          <ul class="flex gap-4 flex-col">
            <li
              v-for="message in messages"
              :key="message.messageId"
              class="shadow-sm p-2 rounded bg-white"
            >
              <health-insurance-list-item
                :message="message"
                @cancel-message="handleMessageCancellation"
                @correct-message="handleMessageCorrection"
              />
            </li>
          </ul>
        </div>

        <div class="flex my-4 transition-all transition">
          <e-button @click="unlinkHealthInsurance">
            <span>Zuweisung aufheben</span>
          </e-button>
        </div>
      </div>
    </transition>
    <cancel-insurance-dialog
      :is-open="isCancelationDialogVisible"
      :message="editableMessage"
      @close="isCancelationDialogVisible = false"
    />
    <correct-insurance-details-dialog
      :is-open="isCorrectionDialogVisible"
      :message="editableMessage"
      @close="isCorrectionDialogVisible = false"
    />
  </div>
</template>
<script>
import {
  EButton,
  EIcon,
  ESimpleLoader,
  EPagination,
} from '@careerpartner/nitro';
import { Actions } from '@/store/modules/profile/types';
import { mapActions, mapState } from 'vuex';
import HealthInsuranceListItem from './HealthInsuranceListItem.vue';
import HealthInsuranceStatus from './Status.vue';
import CancelInsuranceDialog from './CancelInsuranceDialog.vue';
import CorrectInsuranceDetailsDialog from './CorrectInsuranceDetailsDialog.vue';

export default {
  name: 'HealthInsuranceCollapsibleList',
  components: {
    EButton,
    EIcon,
    ESimpleLoader,
    EPagination,
    HealthInsuranceStatus,
    HealthInsuranceListItem,
    CancelInsuranceDialog,
    CorrectInsuranceDetailsDialog,
  },
  props: {
    showHealthInsuranceStatus: { type: Boolean, default: false },
    healthInsuranceStatus: { type: String, default: undefined },
  },
  data() {
    return {
      isListCollapsed: true,
      isLoading: true,
      isCorrectionDialogVisible: false,
      isCancelationDialogVisible: false,
      editableMessage: undefined,
    };
  },
  computed: mapState({
    // showing the newest message first is better for the user
    messages: (state) =>
      state.profile.healthInsuranceMessages.results.reverse(),
    pagination: (state) => state.profile.healthInsuranceMessages.pagination,
  }),
  methods: {
    ...mapActions('profile', {
      loadHealthInsuranceMessages: Actions.LOAD_HEALTH_INSURANCE_MESSAGES,
      unlinkHealthInsuranceAction: Actions.UNLINK_HEALTH_INSURANCE_PROFILE,
    }),
    async unlinkHealthInsurance() {
      const confirmationMessage =
        'Möchten Sie die Zuweisung der Krankenversicherungshistorie zum aktuellen Profil wirklich auflösen?';

      if (confirm(confirmationMessage) === false) {
        return;
      }

      await this.unlinkHealthInsuranceAction();
    },
    async toggleListVisibility() {
      this.isListCollapsed = !this.isListCollapsed;

      if (this.isListCollapsed) {
        return;
      }

      await this.fetchHealthInsuranceMessages();
    },
    async fetchHealthInsuranceMessages() {
      this.isLoading = true;
      try {
        await this.loadHealthInsuranceMessages();
      } finally {
        this.isLoading = false;
      }
    },
    handleMessageCancellation(message) {
      this.editableMessage = message;
      this.isCancelationDialogVisible = true;
    },
    handleMessageCorrection(message) {
      this.editableMessage = message;
      this.isCorrectionDialogVisible = true;
    },
    async handlePaginationChange({ limit, offset }) {
      this.isLoading = true;
      try {
        await this.loadHealthInsuranceMessages({ limit, offset });
      } finally {
        this.isLoading = false;
      }
    },
    createInsuranceMessage() {
      this.editableMessage = undefined;
      this.isCorrectionDialogVisible = true;
    },
  },
};
</script>
<style scoped>
.collapsible-list-animation-enter-active,
.collapsible-list-animation-leave-active {
  transition: all 0.2s;
  max-height: 230px;
}

.collapsible-list-animation-enter,
.collapsible-list-animation-leave-to {
  opacity: 0;
  max-height: 0;
}
</style>
