<template>
  <section-structure>
    <template #headline> Allgemeine Informationen</template>
    <div class="col-span-3 inline-flex">
      <div class="flex-grow">
        <e-text-field
          id="academy-id"
          :value="profileBooking.academyId"
          data-test="profile__academy-id"
          label="Academy ID"
          disabled
        ></e-text-field>
      </div>
      <div class="justify-center flex pl-2">
        <e-button variant="text" @click="linkToCare()">
          <e-icon icon="mdi-open-in-new" />
        </e-button>
      </div>
    </div>
    <div class="col-span-3">
      <e-text-field
        id="matriculation-number"
        :value="profileBooking.matriculationNumber"
        data-test="profile__matriculation-number"
        label="Matrikelnummer"
        disabled
      ></e-text-field>
    </div>
    <div class="col-span-6">
      <e-multiselect
        id="campus"
        v-model="profileBooking.campus"
        data-test="profile__campus"
        multiple
        disabled
        label="Campus (Mehrfachauswahl)"
        :options="selectLists.campuses"
        option-label="name"
        mapped
        track-by="externalId"
      ></e-multiselect>
    </div>
  </section-structure>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { Getters as ProfileGetters } from '@/store/modules/profile/types';
import { mapGetters } from 'vuex';
import { ETextField, EMultiselect, EButton, EIcon } from '@careerpartner/nitro';
import SectionStructure from '@/components/SectionStructure';
import selectLists from '@/mixins/selectLists';

export default {
  name: 'GeneralInfo',
  components: {
    SectionStructure,
    EMultiselect,
    ETextField,
    EButton,
    EIcon,
  },
  mixins: [selectLists],
  computed: {
    ...mapFields('profile', ['booking.campus', 'careLink']),
    ...mapGetters('profile', {
      profileBooking: ProfileGetters.PROFILE_BOOKING,
    }),
  },
  methods: {
    linkToCare() {
      window.open(this.careLink, '_blank');
    },
  },
};
</script>
