<template>
  <section-structure has-image :image-src="previousStudiesImageURL">
    <template #headline>Studienzeiten an Hochschulen in Deutschland </template>

    <div class="col-span-full">
      <p>
        Gesamtanzahl aller Hochschulsemester:
        <strong class="text-cta" data-test="statistics__totalTerms">{{
          totalTerms || '0'
        }}</strong>
        <br />
        <span class="text-gray-700 text-italic"
          >ohne Unterbrechungssemester/Studienkolleg</span
        >
      </p>
    </div>
    <div class="col-span-full flex space-x-2">
      <validation-provider
        v-slot="{ errors }"
        name="Term Statistic | Anzahl reguläre Hochschulsemester"
        :rules="provideValidationRules('@regularTerms')"
        slim
      >
        <e-text-field
          id="previous-terms"
          v-model="regularTerms"
          data-test="statistics__regularTerms"
          label="Anzahl reguläre Hochschulsemester"
          :errors="errors"
          wide
        />
      </validation-provider>
      <e-tooltip
        content="Zu regulären Hochschulsemester gehören alle Semester außer Urlaubs-, Praxis-, Unterbrechungs-semester und Semester am Studienkolleg."
        class="items-center justify-center text-cta"
      >
        <e-icon icon="mdi-information-outline" size="2xl" />
      </e-tooltip>
    </div>

    <div class="col-span-full flex space-x-2">
      <validation-provider
        v-slot="{ errors }"
        name="Term Statistic | Anzahl Urlaubssemester"
        :rules="provideValidationRules('@holidayTerms')"
        slim
      >
        <e-text-field
          id="previous-holiday-terms"
          v-model="holidayTerms"
          data-test="statistics__holidayTerms"
          label="Anzahl Urlaubssemester"
          :errors="errors"
          wide
        />
      </validation-provider>
      <e-tooltip
        content="Bei einem Urlaubssemester handelt es sich um die offizielle, Unterbrechung des Studiums für ein Semester.  Der Studierendenstatus bleibt dabei erhalten."
        class="items-center justify-center text-cta"
      >
        <e-icon icon="mdi-information-outline" size="2xl" />
      </e-tooltip>
    </div>

    <div class="col-span-full flex space-x-2">
      <validation-provider
        v-slot="{ errors }"
        name="Term Statistic | Anzahl Praxissemester"
        :rules="provideValidationRules('@practicalTerms')"
        slim
      >
        <e-text-field
          id="previous-practical-terms"
          v-model="practicalTerms"
          data-test="statistics__practicalTerms"
          label="Anzahl Praxissemester"
          :errors="errors"
          wide
        />
      </validation-provider>
      <e-tooltip
        content="Fachpraktische Studiensemester an deutschen Hochschulen bei fortbestehender Einschreibung an der Hochschule."
        class="items-center justify-center text-cta"
      >
        <e-icon icon="mdi-information-outline" size="2xl" />
      </e-tooltip>
    </div>

    <div class="col-span-full flex space-x-2">
      <validation-provider
        v-slot="{ errors }"
        name="Term Statistic | Semester am Studienkolleg"
        :rules="provideValidationRules('@preparatoryTerms')"
        slim
      >
        <e-text-field
          id="previous-preparatory-terms"
          v-model="preparatoryTerms"
          data-test="statistics__preparatoryTerms"
          label="Semester am Studienkolleg"
          :errors="errors"
          wide
        />
      </validation-provider>
      <e-tooltip
        content="Getrennte Semesterzählung. (Die am Studienkolleg verbrachten Semester zählen nicht als Fach- oder Hochschulsemester). Auf Studienkollegs werden Studienbewerber mit einer ausländischen HZB, die nicht als dem deutschen Abitur gleichwertig anerkannt ist, auf ein Studium an einer deutschen Hochschule vorbereitet."
        class="items-center justify-center text-cta"
      >
        <e-icon icon="mdi-information-outline" size="2xl" />
      </e-tooltip>
    </div>

    <div class="col-span-full flex space-x-2">
      <validation-provider
        v-slot="{ errors }"
        name="Term Statistic | Anzahl Unterbrechungssemester"
        :rules="provideValidationRules('@interruptionTerms')"
        slim
      >
        <e-text-field
          id="previous-interruption-terms"
          v-model="interruptionTerms"
          data-test="statistics__interruptionTerms"
          label="Anzahl Unterbrechungssemester"
          :errors="errors"
          wide
        />
      </validation-provider>
      <e-tooltip
        content="Eine Studienunterbrechung liegt vor, wenn nach Aufnahme des Studiums in Deutschland für ein oder mehrere Semester keine Einschreibung im 1. Studiengang des Berichtssemesters erfolgt ist."
        class="items-center justify-center text-cta"
      >
        <e-icon icon="mdi-information-outline" size="2xl" />
      </e-tooltip>
    </div>

    <div v-if="hasInterruptionTerms" class="col-span-full">
      <validation-provider
        v-slot="{ errors }"
        name="Term Statistic | Arten der Studienunterbrechung"
        rules="required"
        immediate
        :skip-if-empty="false"
        slim
      >
        <e-multiselect
          id="previous-interruption-type"
          v-model="interruptionType"
          data-test="statistics__interruptionType"
          :options="selectLists.interruptions"
          track-by="statisticKey"
          option-label="label"
          :errors="errors"
          label="Arten der Studienunterbrechung (Mehrfachauswahl)"
          multiple
          :disabled="hasNoInterruptionTerms"
          mapped
        />
      </validation-provider>
    </div>
  </section-structure>
</template>

<script>
import { imagePaths } from '@/common/content';
import { ValidationProvider } from 'vee-validate';
import { mapFields } from 'vuex-map-fields';
import SectionStructure from '@/components/SectionStructure';
import {
  EIcon,
  EMultiselect,
  ETextField,
  ETooltip,
} from '@careerpartner/nitro';
import selectLists from '@/mixins/selectLists';
import '@/common/validationExtends';

export default {
  name: 'TermStatistic',
  components: {
    SectionStructure,
    EMultiselect,
    ETextField,
    EIcon,
    ETooltip,
    ValidationProvider,
  },
  mixins: [selectLists],
  data() {
    return {
      rules: {
        number: (value) => {
          const pattern = /^[0-9]*$/g;
          return pattern.test(value) || 'Invalid number.';
        },
      },
    };
  },
  computed: {
    ...mapFields('academicHistory', [
      'termStatistic.totalTerms',
      'termStatistic.regularTerms',
      'termStatistic.holidayTerms',
      'termStatistic.practicalTerms',
      'termStatistic.preparatoryTerms',
      'termStatistic.interruptionTerms',
      'termStatistic.interruptionType',
    ]),
    hasNoInterruptionTerms() {
      return !this.interruptionTerms || this.interruptionTerms === '';
    },
    previousStudiesImageURL() {
      return imagePaths.STATISTIC_PREVIOUS_STUDIES;
    },
    hasInterruptionTerms() {
      return this.interruptionTerms && this.interruptionTerms > 0;
    },
  },
  methods: {
    provideValidationRules(fieldName) {
      return {
        numeric: true,
        min_value: {
          value: fieldName,
          min: 0,
        },
        max_value: {
          value: fieldName,
          max: 99,
        },
      };
    },
  },
};
</script>
