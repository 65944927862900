export const studyCourses = [
  { statisticKey: '001', label: 'Ägyptologie' },
  { statisticKey: '002', label: 'Afrikanistik' },
  { statisticKey: '003', label: 'Agrarwissenschaft/Landwirtschaft' },
  {
    statisticKey: '004',
    label: 'Interdisziplinäre Studien (Schwerpunkt Geisteswissenschaften)',
  },
  { statisticKey: '005', label: 'Klassische Philologie' },
  { statisticKey: '006', label: 'Amerikanistik/Amerikakunde' },
  { statisticKey: '007', label: 'Angewandte Kunst' },
  { statisticKey: '008', label: 'Anglistik/Englisch' },
  { statisticKey: '009', label: 'Anthropologie (Humanbiologie)' },
  { statisticKey: '010', label: 'Arabisch/Arabistik' },
  { statisticKey: '011', label: 'Arbeitslehre/Wirtschaftslehre' },
  { statisticKey: '012', label: 'Archäologie' },
  { statisticKey: '013', label: 'Architektur' },
  { statisticKey: '014', label: 'Astrophysik und Astronomie' },
  {
    statisticKey: '015',
    label: 'Außereuropäische Sprachen und Kulturen in Ozeanien und Amerika',
  },
  { statisticKey: '016', label: 'Baltistik' },
  { statisticKey: '017', label: 'Bauingenieurwesen/Ingenieurbau' },
  { statisticKey: '018', label: 'Berufsbezogene Fremdsprachenausbildung' },
  {
    statisticKey: '019',
    label:
      'Studienfach Orientierungsstudium MINT im Studienbereich Mathematik, Naturwissenschaften allgemein',
  },
  { statisticKey: '020', label: 'Bergbau/Bergtechnik' },
  { statisticKey: '021', label: 'Betriebswirtschaftslehre' },
  { statisticKey: '022', label: 'Informations- und Bibliothekswissenschaften' },
  { statisticKey: '023', label: 'Bildende Kunst/Graphik' },
  {
    statisticKey: '024',
    label: 'Europäische Ethnologie und Kulturwissenschaft',
  },
  { statisticKey: '025', label: 'Biochemie' },
  { statisticKey: '026', label: 'Biologie' },
  { statisticKey: '028', label: 'Brauwesen/Getränketechnologie' },
  { statisticKey: '029', label: 'Sportwissenschaft' },
  {
    statisticKey: '030',
    label:
      'Interdisziplinäre Studien (Schwerpunkt Rechts-, Wirtschafts- und Sozialwissenschaften)',
  },
  { statisticKey: '031', label: 'Byzantinistik' },
  { statisticKey: '032', label: 'Chemie' },
  {
    statisticKey: '033',
    label: 'Chemie-Ingenieurwesen/Chemieverfahrenstechnik',
  },
  { statisticKey: '034', label: 'Dänisch' },
  { statisticKey: '035', label: 'Darstellende Kunst/Bühnenkunst/Regie' },
  { statisticKey: '036', label: 'Sonstige Regionalwissenschaften2)' },
  { statisticKey: '037', label: 'Archiv- und Dokumentationswissenschaft' },
  { statisticKey: '038', label: 'Lateinamerika-Studien' },
  { statisticKey: '039', label: 'Geowissenschaften allgemein' },
  {
    statisticKey: '040',
    label: 'Interdisziplinäre Studien (Schwerpunkt Kunst, Kunstwissenschaft)',
  },
  {
    statisticKey: '041',
    label:
      'Sonstiges Orientierungsstudium im Studienbereich Außerhalb der Studienbereichsgliederung',
  },
  { statisticKey: '042', label: 'Wirtschaftsrecht' },
  { statisticKey: '043', label: 'Neugriechisch' },
  { statisticKey: '044', label: 'Ost- und Südosteuropa-Studien' },
  { statisticKey: '048', label: 'Elektrotechnik/Elektronik' },
  {
    statisticKey: '049',
    label: 'Interdisziplinäre Studien (Schwerpunkt Naturwissenschaften)1)',
  },
  { statisticKey: '050', label: 'Geographie/Erdkunde' },
  { statisticKey: '052', label: 'Erziehungswissenschaft (Pädagogik)' },
  { statisticKey: '053', label: 'Evang. Theologie, - Religionslehre' },
  { statisticKey: '054', label: 'Film und Fernsehen' },
  {
    statisticKey: '055',
    label:
      'Orientierungsstudium Gesellschaftswissenschaften im Studienbereich Rechts-,  Wirtschafts- und Sozialwissenschaften allgemein',
  },
  { statisticKey: '056', label: 'Finno-Ugristik' },
  { statisticKey: '057', label: 'Luft- und Raumfahrttechnik' },
  { statisticKey: '058', label: 'Forstwissenschaft, -wirtschaft' },
  { statisticKey: '059', label: 'Französisch' },
  { statisticKey: '060', label: 'Gartenbau' },
  { statisticKey: '061', label: 'Meliorationswesen' },
  { statisticKey: '064', label: 'Naturschutz' },
  { statisticKey: '065', label: 'Geologie/Paläontologie' },
  { statisticKey: '066', label: 'Geophysik' },
  { statisticKey: '067', label: 'Germanistik/Deutsch' },
  { statisticKey: '068', label: 'Geschichte' },
  { statisticKey: '069', label: 'Graphikdesign/Kommunikationsgestaltung' },
  { statisticKey: '070', label: 'Griechisch' },
  { statisticKey: '071', label: 'Haushalts- und Ernährungswissenschaft' },
  {
    statisticKey: '072',
    label: 'Interdisziplinäre Studien (Schwerpunkt Ingenieurwissenschaften)1)',
  },
  { statisticKey: '073', label: 'Judaistik/Hebräisch' },
  { statisticKey: '074', label: 'Transport-/Fördertechnik' },
  { statisticKey: '075', label: 'Holzwirtschaft' },
  { statisticKey: '076', label: 'Hütten- und Gießereiwesen' },
  { statisticKey: '077', label: 'Wasserwirtschaft' },
  { statisticKey: '078', label: 'Indologie' },
  { statisticKey: '079', label: 'Informatik' },
  { statisticKey: '080', label: 'Instrumentalmusik' },
  { statisticKey: '081', label: 'Iranistik' },
  { statisticKey: '082', label: 'Holz-/Fasertechnik' },
  { statisticKey: '083', label: 'Islamwissenschaft' },
  { statisticKey: '084', label: 'Italienisch' },
  { statisticKey: '085', label: 'Japanologie' },
  { statisticKey: '086', label: 'Kath. Theologie, - Religionslehre' },
  { statisticKey: '088', label: 'Optoelektronik' },
  { statisticKey: '089', label: 'Verkehrsingenieurwesen' },
  { statisticKey: '090', label: 'Lernbereich Geisteswissenschaften' },
  { statisticKey: '091', label: 'Kunsterziehung' },
  { statisticKey: '092', label: 'Kunstgeschichte, Kunstwissenschaft' },
  { statisticKey: '093', label: 'Landespflege/Landschaftsgestaltung' },
  { statisticKey: '094', label: 'Wasserbau' },
  { statisticKey: '095', label: 'Latein' },
  { statisticKey: '096', label: 'Lebensmittelchemie' },
  { statisticKey: '097', label: 'Lebensmitteltechnologie' },
  { statisticKey: '098', label: 'Sportpädagogik/Sportpsychologie' },
  { statisticKey: '101', label: 'Restaurierungskunde' },
  { statisticKey: '102', label: 'Schauspiel' },
  { statisticKey: '103', label: 'Markscheidewesen' },
  { statisticKey: '104', label: 'Maschinenbau/-wesen' },
  { statisticKey: '105', label: 'Mathematik' },
  { statisticKey: '106', label: 'Tanzpädagogik' },
  { statisticKey: '107', label: 'Medizin (Allgemein-Medizin)' },
  { statisticKey: '108', label: 'Metalltechnik' },
  { statisticKey: '110', label: 'Meteorologie' },
  { statisticKey: '111', label: 'Mineralogie' },
  { statisticKey: '113', label: 'Musikerziehung' },
  { statisticKey: '114', label: 'Musikwissenschaft/-geschichte' },
  { statisticKey: '115', label: 'Grundschul-/Primarstufenpädagogik' },
  { statisticKey: '116', label: 'Textilgestaltung' },
  { statisticKey: '117', label: 'Ausländerpädagogik' },
  { statisticKey: '118', label: 'Technomathematik' },
  { statisticKey: '119', label: 'Niederländisch' },
  {
    statisticKey: '120',
    label:
      'Nordistik/Skandinavistik (Nordische Philologie, Einzelsprachen a.n.g.)',
  },
  { statisticKey: '121', label: 'Medieninformatik' },
  { statisticKey: '122', label: 'Orientalistik, Altorientalistik' },
  { statisticKey: '123', label: 'Ingenieurinformatik/Technische Informatik' },
  { statisticKey: '124', label: 'Ozeanographie' },
  { statisticKey: '125', label: 'Agrarökonomie' },
  { statisticKey: '126', label: 'Pharmazie' },
  { statisticKey: '127', label: 'Philosophie' },
  { statisticKey: '128', label: 'Physik' },
  { statisticKey: '129', label: 'Politikwissenschaften/Politologie' },
  { statisticKey: '130', label: 'Westslawisch (allgemein und a.n.g.)' },
  { statisticKey: '131', label: 'Portugiesisch' },
  { statisticKey: '132', label: 'Psychologie' },
  { statisticKey: '134', label: 'Raumplanung' },
  { statisticKey: '135', label: 'Rechtswissenschaft' },
  { statisticKey: '136', label: 'Religionswissenschaft' },
  {
    statisticKey: '137',
    label: 'Romanistik (Roman. Philologie, Einzelsprachen a.n.g.)',
  },
  { statisticKey: '138', label: 'Agrarbiologie' },
  { statisticKey: '139', label: 'Russisch' },
  { statisticKey: '140', label: 'Angewandte Systemwissenschaften' },
  { statisticKey: '141', label: 'Abfallwirtschaft' },
  { statisticKey: '142', label: 'Schiffbau/Schiffstechnik' },
  { statisticKey: '143', label: 'Augenoptik' },
  { statisticKey: '144', label: 'Technische Kybernetik' },
  { statisticKey: '145', label: 'Sinologie/Koreanistik' },
  { statisticKey: '146', label: 'Slawistik (Slaw. Philologie)' },
  { statisticKey: '147', label: 'Sozialkunde' },
  { statisticKey: '148', label: 'Sozialwissenschaften' },
  { statisticKey: '149', label: 'Soziologie' },
  { statisticKey: '150', label: 'Spanisch' },
  { statisticKey: '151', label: 'Sprachheilpädagogik/Logopädie' },
  {
    statisticKey: '152',
    label: 'Allgemeine Sprachwissenschaft/Indogermanistik',
  },
  {
    statisticKey: '153',
    label: 'Südslawisch (Bulgarisch, Serbokroatisch, Slowenisch usw.)',
  },
  { statisticKey: '154', label: 'Lernbereich Gesellschaftslehre1)' },
  { statisticKey: '155', label: 'Theaterwissenschaft' },
  { statisticKey: '156', label: 'Tiermedizin/Veterinärmedizin' },
  { statisticKey: '157', label: 'Mikroelektronik' },
  { statisticKey: '158', label: 'Turkologie' },
  { statisticKey: '159', label: 'Edelstein- und Schmuckdesign' },
  { statisticKey: '160', label: 'Computerlinguistik' },
  { statisticKey: '161', label: 'Diakoniewissenschaft' },
  { statisticKey: '162', label: 'Caritaswissenschaft' },
  { statisticKey: '163', label: 'Rhythmik' },
  { statisticKey: '164', label: 'Jazz und Popularmusik' },
  { statisticKey: '165', label: 'Orchestermusik' },
  { statisticKey: '166', label: 'Sportmanagement/Sportökonomie' },
  { statisticKey: '167', label: 'Europäische Wirtschaft' },
  { statisticKey: '168', label: 'Justizvollzug' },
  { statisticKey: '169', label: 'Ethik' },
  { statisticKey: '171', label: 'Vermessungswesen (Geodäsie)' },
  { statisticKey: '172', label: 'Verwaltungswissenschaft/-wesen' },
  { statisticKey: '173', label: 'Ethnologie' },
  { statisticKey: '174', label: 'Volkskunde' },
  { statisticKey: '175', label: 'Volkswirtschaftslehre' },
  { statisticKey: '176', label: 'Werkerziehung' },
  { statisticKey: '177', label: 'Werkstofftechnik' },
  { statisticKey: '178', label: 'Wirtschafts-/Sozialgeographie' },
  {
    statisticKey: '179',
    label:
      'Wirtschaftsingenieurwesen mit wirtschaftswissenschaftlichem Schwerpunkt',
  },
  { statisticKey: '180', label: 'Kaukasistik' },
  { statisticKey: '181', label: 'Wirtschaftspädagogik' },
  {
    statisticKey: '182',
    label: 'Internationale Betriebswirtschaft/Management',
  },
  { statisticKey: '183', label: 'Wirtschafts-/Sozialgeschichte' },
  { statisticKey: '184', label: 'Wirtschaftswissenschaften' },
  { statisticKey: '185', label: 'Zahnmedizin' },
  {
    statisticKey: '186',
    label: 'Lernbereich Naturwissenschaften/Sachunterricht1)',
  },
  {
    statisticKey: '187',
    label: 'Asiatische Sprachen und Kulturen/Asienwissenschaften',
  },
  { statisticKey: '188', label: 'Allgemeine Literaturwissenschaft' },
  { statisticKey: '189', label: 'Niederdeutsch' },
  { statisticKey: '190', label: 'Sonderpädagogik' },
  { statisticKey: '191', label: 'Komposition' },
  { statisticKey: '192', label: 'Dirigieren' },
  { statisticKey: '193', label: 'Kirchenmusik' },
  { statisticKey: '194', label: 'Tonmeister' },
  { statisticKey: '195', label: 'Gesundheitspädagogik' },
  { statisticKey: '196', label: 'Studienkolleg' },
  { statisticKey: '197', label: 'Holzbau' },
  { statisticKey: '199', label: 'Lernbereich Technik1)' },
  { statisticKey: '200', label: 'Computer- und Kommunikationstechniken' },
  { statisticKey: '201', label: 'Werken (technisch)/Technologie' },
  { statisticKey: '202', label: 'Fertigungs-/Produktionstechnik' },
  { statisticKey: '203', label: 'Industriedesign/Produktgestaltung' },
  { statisticKey: '204', label: 'Malerei' },
  { statisticKey: '205', label: 'Bildhauerei/Plastik' },
  { statisticKey: '206', label: 'Polnisch' },
  { statisticKey: '207', label: 'Sorabistik' },
  { statisticKey: '208', label: 'Soziale Arbeit' },
  { statisticKey: '209', label: 'Tschechisch' },
  { statisticKey: '210', label: 'Verkehrswirtschaft' },
  { statisticKey: '211', label: ' Energieverfahrenstechnik' },
  { statisticKey: '212', label: 'Feinwerktechnik' },
  { statisticKey: '213', label: 'Versorgungstechnik' },
  { statisticKey: '215', label: 'Gesundheitstechnik' },
  { statisticKey: '216', label: 'Glastechnik/Keramik' },
  { statisticKey: '219', label: 'Kunststofftechnik' },
  { statisticKey: '220', label: 'Milch- und Molkereiwirtschaft' },
  { statisticKey: '221', label: 'Bioinformatik' },
  { statisticKey: '222', label: 'Kommunikations- und Informationstechnik' },
  { statisticKey: '223', label: 'Nautik/Seefahrt' },
  {
    statisticKey: '224',
    label: 'Physikalische Technik/Mechanische Verfahrenstechnik',
  },
  { statisticKey: '225', label: 'Textil- und Bekleidungstechnik/-gewerbe' },
  { statisticKey: '226', label: 'Verfahrenstechnik' },
  { statisticKey: '227', label: 'Weinbau und Kellerwirtschaft' },
  { statisticKey: '230', label: 'Gesang' },
  { statisticKey: '231', label: 'Druck- und Reproduktionstechnik' },
  { statisticKey: '232', label: 'Gesundheitswissenschaft/-management' },
  { statisticKey: '233', label: 'Nichtärztliche Heilberufe/Therapien' },
  { statisticKey: '234', label: 'Pflegewissenschaft/-management' },
  { statisticKey: '235', label: 'Fahrzeugtechnik' },
  {
    statisticKey: '237',
    label: 'Mathematische Statistik/Wahrscheinlichkeitsrechnung',
  },
  { statisticKey: '242', label: 'Innenarchitektur' },
  { statisticKey: '245', label: 'Sozialpädagogik' },
  { statisticKey: '247', label: 'Medizinische Informatik' },
  { statisticKey: '253', label: 'Sozialwesen' },
  { statisticKey: '254', label: 'Sachunterricht (einschl. Schulgarten)' },
  { statisticKey: '255', label: 'Luftverkehrsmanagement' },
  { statisticKey: '256', label: 'Rechtspflege' },
  { statisticKey: '257', label: 'Arbeits- und Berufsberatung' },
  { statisticKey: '258', label: 'Arbeitsverwaltung' },
  { statisticKey: '259', label: 'Auswärtige Angelegenheiten' },
  { statisticKey: '260', label: 'Bundeswehrverwaltung' },
  { statisticKey: '261', label: 'Innere Verwaltung' },
  { statisticKey: '262', label: 'Bibliothekswesen' },
  { statisticKey: '263', label: 'Polizei/Verfassungsschutz' },
  { statisticKey: '264', label: 'Sozialversicherung' },
  { statisticKey: '265', label: 'Bankwesen' },
  { statisticKey: '266', label: 'Finanzverwaltung' },
  { statisticKey: '268', label: 'Verkehrswesen' },
  { statisticKey: '269', label: 'Zoll- und Steuerverwaltung' },
  { statisticKey: '270', label: 'Berufs- und Wirtschaftspädagogik' },
  {
    statisticKey: '271',
    label: 'Deutsch als Fremdsprache oder als Zweitsprache',
  },
  { statisticKey: '272', label: 'Alte Geschichte' },
  { statisticKey: '273', label: 'Mittlere und neuere Geschichte' },
  { statisticKey: '274', label: 'Tourismuswirtschaft' },
  { statisticKey: '275', label: 'Wissenschaftsgeschichte/Technikgeschichte ' },
  { statisticKey: '276', label: 'Wirtschaftsmathematik' },
  { statisticKey: '277', label: 'Wirtschaftsinformatik' },
  { statisticKey: '280', label: 'Kartographie' },
  { statisticKey: '282', label: 'Biotechnologie' },
  { statisticKey: '283', label: 'Landschaftsökologie/Biogeographie' },
  { statisticKey: '284', label: 'Angewandte Sprachwissenschaft' },
  { statisticKey: '286', label: 'Mikrosystemtechnik' },
  { statisticKey: '287', label: 'Neue Medien' },
  { statisticKey: '290', label: 'ohne Angabe/ungeklärt' },
  { statisticKey: '292', label: 'Islamische Studien/Islamische Theologie' },
  { statisticKey: '300', label: 'Biomedizin' },
  {
    statisticKey: '302',
    label: 'Medienwissenschaft im Studienbereich Medienwissenschaft',
  },
  {
    statisticKey: '303',
    label:
      'Kommunikationswissenschaft/Publizistik im Studienbereich Kommunikationswissenschaft/Publizistik',
  },
  { statisticKey: '304', label: 'Medienwirtschaft/Medienmanagement' },
  { statisticKey: '305', label: 'Medientechnik' },
  { statisticKey: '310', label: 'Regenerative Energien' },
  {
    statisticKey: '312',
    label:
      'Statistik im Studienbereich Mathematik, Naturwissenschaften allgemein',
  },
  { statisticKey: '316', label: 'Elektrische Energietechnik' },
  { statisticKey: '320', label: 'Ernährungswissenschaft' },
  {
    statisticKey: '321',
    label: 'Erwachsenenbildung und außerschulische Jugendbildung',
  },
  { statisticKey: '333', label: 'Haushaltswissenschaft' },
  { statisticKey: '353', label: 'Pflanzenproduktion' },
  { statisticKey: '356', label: 'Sozialmanagement' },
  { statisticKey: '358', label: 'Wirtschaftsrecht\r\n' },
  { statisticKey: '361', label: 'Schulpädagogik' },
  { statisticKey: '365', label: 'Pädagogik der frühen Kindheit' },
  {
    statisticKey: '370',
    label:
      'Wirtschaftsingenieurwesen mit ingenieurwissenschaftlichem Schwerpunkt',
  },
  { statisticKey: '371', label: 'Tierproduktion' },
  { statisticKey: '380', label: 'Mechatronik' },
  { statisticKey: '385', label: 'Geoökologie' },
  { statisticKey: '390', label: 'Archäometrie (Ingenieurarchäologie)' },
  { statisticKey: '429', label: 'Stahlbau' },
  { statisticKey: '440', label: 'Internationale Betriebswirtschaft' },
  { statisticKey: '457', label: 'Umwelttechnik einschl. Recycling' },
  { statisticKey: '458', label: 'Umweltschutz' },
  { statisticKey: '464', label: 'Facility Management' },
  {
    statisticKey: '544',
    label: 'Evang. Religionspädagogik, kirchliche Bildungsarbeit',
  },
  {
    statisticKey: '545',
    label: 'Kath. Religionspädagogik, kirchliche Bildungsarbeit',
  },
  { statisticKey: '546', label: 'Heilpädagogik' },
  { statisticKey: '548', label: 'Ur- und Frühgeschichte' },
  { statisticKey: '560', label: 'Internationales Management' },
  { statisticKey: '584', label: 'Internationales Tourismusmanagement' },
  { statisticKey: '585', label: 'Hotelmanagement' },
  { statisticKey: '670', label: 'Kommunikationsdesign' },
  { statisticKey: '703', label: 'Bautechnik\r\n' },
  { statisticKey: '721', label: 'Wirtschaftsinformatik' },
  { statisticKey: '742', label: 'Wirtschaft' },
  { statisticKey: '743', label: 'Wirtschaftsingenieurwesen' },
  { statisticKey: '811', label: 'Finanzmanagement' },
  { statisticKey: '851', label: 'Sportmanagement/Sportökonomie' },
  { statisticKey: '947', label: 'Logistik-Management\r\n' },
  { statisticKey: '976', label: 'Pflegemanagement' },
  { statisticKey: 'A29', label: 'Digital Business' },
  { statisticKey: 'A75', label: 'Medizin-Okonomie\r\n' },
  { statisticKey: 'A95', label: 'Wirtschaftspsychologie' },
  { statisticKey: 'B45', label: 'Datenwissenschaft / Data Science' },
  { statisticKey: 'B95', label: 'Medien-Design' },
  { statisticKey: 'C28', label: 'Eventmanagement\r\n' },
  {
    statisticKey: 'C29',
    label: 'Internationales Hotel- & Tourismusmanagement',
  },
  { statisticKey: 'C40', label: 'Soziale Arbeit' },
  { statisticKey: 'C84', label: 'Gesundeitsmanagement' },
  { statisticKey: 'C89', label: 'General Management' },
  { statisticKey: 'D23', label: 'Health Care Management' },
  { statisticKey: 'D28', label: 'Steuern und Wirtschaftsprüfung' },
  { statisticKey: 'D30', label: 'IT-Management and Information Systems\r\n' },
  { statisticKey: 'D38', label: 'Management' },
  { statisticKey: 'D70', label: 'Sport & Event Management' },
  { statisticKey: 'D72', label: 'International Management with Engineering' },
  { statisticKey: 'F03', label: 'Pädagogik der frühen Kindheit' },
  { statisticKey: 'F06', label: 'International Business Management' },
  { statisticKey: 'F38', label: 'Business Administration' },
  { statisticKey: 'F45', label: 'Marketingmanagement\r\n' },
  { statisticKey: 'F62', label: 'Personalmanagement\r\n' },
  { statisticKey: 'F71', label: 'Transportmanagement' },
  { statisticKey: 'F75', label: 'Immobilienmanagement' },
  { statisticKey: 'F91', label: 'Internationales Hotelmanagement' },
  { statisticKey: 'G65', label: 'Marketing und Vertrieb' },
  { statisticKey: 'G66', label: 'Internationales Luftverkehrsmanagement' },
  { statisticKey: 'G67', label: 'International Marketing Management' },
  { statisticKey: 'G68', label: 'International Human Resource Management' },
  { statisticKey: 'I16', label: 'Internationales Eventmanagement' },
  { statisticKey: 'I17', label: 'Projektmanagement' },
  { statisticKey: 'I18', label: 'Controlling' },
];
