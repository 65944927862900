<template>
  <div class="grid grid-cols-1">
    <section-structure class="col-span-1">
      <template #headline>Krankenversicherung</template>
      <div class="col-span-full">
        <div class="flex">
          <span class="mr-2">Nachweis der Krankenkasse</span>
          <health-insurance-insured-status :value="state" />
          <span v-if="lastInsuranceStatusDate" class="ml-4"
            >Letzte Meldung vom:
            {{ lastInsuranceStatusDate | formatDate }}</span
          >
        </div>
      </div>
      <template v-if="showHealthInput">
        <div class="col-span-full">
          <e-multiselect
            id="insurance-name"
            v-model="companyId"
            data-test="profile__health-company"
            :options="selectLists.healthInsurances"
            label="Name der Krankenkasse*"
            option-label="name"
            track-by="bn"
            disabled
            mapped
          ></e-multiselect>
        </div>
        <div class="col-span-3">
          <e-text-field
            id="operating-number"
            v-model="companyId"
            data-test="profile__health-companyId"
            label="Betriebsnummer"
            disabled
          />
        </div>
        <div class="col-span-3">
          <e-text-field
            id="insurance-number"
            v-model="policyId"
            data-test="profile__health-policyId"
            label="Versichertennummer*"
            disabled
          />
        </div>
      </template>
    </section-structure>
    <div v-if="showHealthInsuranceStatus" class="col-span-2 mt-5">
      <div
        v-if="showManualAssignmentOption"
        class="flex my-4 transition-all transition"
      >
        <e-button
          v-if="!showManualAssignment"
          @click="showManualAssignment = true"
        >
          <span>manuelle Zuordnung</span>
        </e-button>
        <manual-assignment
          v-else
          @assigned="fetchHealthInsuranceAndCloseAssignment()"
          @close="showManualAssignment = false"
        />
      </div>
    </div>
    <health-insurance-collapsible-list
      v-if="showHealthInsuranceCollapsibleList"
      :show-health-insurance-status="showHealthInsuranceStatus"
      :health-insurance-status="reported"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { formatShortDateToLocale } from '@/utils/formatters';
import { mapFields } from 'vuex-map-fields';
import { ETextField, EMultiselect, EButton } from '@careerpartner/nitro';
import SectionStructure from '@/components/SectionStructure';
import selectLists from '@/mixins/selectLists';
import { FEATURES, HEALTHINSURANCE_INSURED_STATUS } from '@/common/constants';
import { Actions as ProfileActions } from '@/store/modules/profile/types';
import ManualAssignment from './ManualAssignment';
import HealthInsuranceInsuredStatus from './InsuredStatus.vue';
import HealthInsuranceCollapsibleList from './HealthInsuranceCollapsibleList.vue';
import { isActive } from '@/utils/feature-config';
import { isInsuranceProfileLinkedToIamProfile } from '@/utils/health-insurance';

export default {
  name: 'HealthInsurance',
  components: {
    SectionStructure,
    EMultiselect,
    ETextField,
    EButton,
    ManualAssignment,
    HealthInsuranceInsuredStatus,
    HealthInsuranceCollapsibleList,
  },
  filters: {
    formatDate: formatShortDateToLocale,
  },
  mixins: [selectLists],

  data() {
    return {
      showManualAssignment: false,
    };
  },

  computed: {
    ...mapFields('profile', [
      'healthInsurance.id',
      'healthInsurance.iamUserId',
      'healthInsurance.companyId',
      'healthInsurance.policyId',
      'healthInsurance.state',
      'healthInsurance.manualInsuranceLinkPossible',
      'healthInsurance.reported',
      'healthInsurance.lastInsuranceStatusDate',
    ]),
    showManualAssignmentOption() {
      if (!this.state) {
        return true;
      }

      return (
        [HEALTHINSURANCE_INSURED_STATUS.open].includes(this.state) &&
        this.manualInsuranceLinkPossible
      );
    },

    showHealthInput() {
      // might need `excluded` as well
      return !['notInsured', 'exempt', 'recorded'].includes(this.state);
    },

    showHealthInsuranceStatus() {
      // might need `excluded` as well
      return !['notInsured', 'exempt'].includes(this.state);
    },

    showHealthInsuranceCollapsibleList() {
      const isInsuranceMessagesFeatureActive = isActive(
        FEATURES.INSURANCE_MESSAGE_UI,
      );
      const isInsuranceProfileLinked = isInsuranceProfileLinkedToIamProfile({
        id: this.id,
        iamUserId: this.iamUserId,
      });

      return isInsuranceMessagesFeatureActive && isInsuranceProfileLinked;
    },
  },
  methods: {
    ...mapActions('profile', {
      fetchHealthInsurance: ProfileActions.LOAD_HEALTH_INSURANCE,
    }),
    async fetchHealthInsuranceAndCloseAssignment() {
      this.showManualAssignment = false;
      await this.fetchHealthInsurance();
    },
  },
};
</script>
