<template>
  <div class="grid grid-cols-12 auto-rows-auto gap-4">
    <!-- Searchbox -->
    <div class="col-span-8 col-start-3 mt-12">
      <slot name="searchbox">
        <search-box
          :search-fields="searchFields"
          :extended-search-fields="extendedSearchFields"
          data-test="search-box"
          @submit="submitSearch"
        />
      </slot>
    </div>
    <!-- Image -->
    <div
      v-show="!isLoading && !hasSearched"
      class="col-span-4 col-start-5 mt-20"
    >
      <img :src="imageSrc" alt="" class="w-100 m-auto" />
    </div>
    <!-- Loader -->
    <div v-if="isLoading" class="col-span-12 flex-col space-y-8 mt-8">
      <e-card
        v-for="(_item, i) in ['1', '2', '3', '4']"
        :key="i"
        sheet
        rounded
        elevation="lg"
      >
        <template #sheet><ESkeletonLoaderItem /></template>
      </e-card>
    </div>
    <!-- no results -->
    <div
      v-else-if="hasSearched && results.length === 0"
      class="col-span-4 col-start-5 mt-12 justify-self-center"
    >
      <e-no-items data-test="search-no-results" />
    </div>
    <!-- Search Results -->
    <div
      v-else-if="results.length"
      class="col-span-full grid grid-cols-12 mt-16"
    >
      <div class="col-span-full flex">
        <e-checkbox
          v-show="!hideSelectAll"
          id="search-selectall"
          :disabled="disableSelection"
          class="pl-4"
          label="Alle auswählen"
          check-value="select"
          :options="allSelected"
          data-test="search-select-all"
          @input="toggleAll"
        />
        <p class="text-gray-700 ml-auto">
          Es {{ results.length > 1 ? 'wurden' : 'wurde' }}
          <span class="text-cta font-bold" data-test="search-total-count">
            {{ pagination.totalCount }}
          </span>
          {{ results.length > 1 ? 'Suchergebnisse' : 'Suchergebnis' }}
          gefunden.
        </p>
      </div>
      <ul
        class="col-span-12 flex-col space-y-8 mt-8"
        data-test="search-results"
      >
        <li v-for="(result, index) in results" :key="index">
          <slot name="search-result" :result="result" />
        </li>
      </ul>
      <div v-if="!hidePaginationLimit" class="col-span-full flex mt-8">
        <p class="text-gray-700 ml-auto">Zeilen pro Seite:&nbsp;</p>
        <e-menu
          id="rows-per-page"
          :actions="actions"
          align-right
          class="ml-4"
          @clicked="paginationLimitClicked"
        >
          <template #activator="{ interact }">
            <span
              class="cursor-pointer flex text-cta font-bold"
              @click="interact"
            >
              {{ pagination.limit }}
              <e-icon icon="chevron-down" size="l" />
            </span>
          </template>
        </e-menu>
      </div>
      <!-- Pagination -->
      <div class="col-span-4 col-start-9 justify-self-end mt-8">
        <e-pagination
          v-model="page"
          :length="pagesLength"
          data-test="search-pagination"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ECard,
  ECheckbox,
  EIcon,
  EMenu,
  ENoItems,
  EPagination,
  ESkeletonLoaderItem,
  ESearchableListDefaultPagination,
} from '@careerpartner/nitro';

import SearchBox from './SearchBox';

export default {
  name: 'ESearchableList',
  components: {
    ECard,
    ECheckbox,
    EIcon,
    EMenu,
    ENoItems,
    EPagination,
    SearchBox,
    ESkeletonLoaderItem,
  },
  props: {
    disableSelection: {
      type: Boolean,
      default: false,
    },
    extendedSearchFields: {
      type: Array,
      default: () => [],
      validator: (value) =>
        value.every((item) => !!item.queryParam && !!item.label),
    },
    hasSearched: {
      type: Boolean,
      default: false,
    },
    hideSelectAll: {
      type: Boolean,
      default: false,
    },
    imageSrc: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Object,
      default: () => ESearchableListDefaultPagination,
      validator: (value) =>
        Object.keys(value).includes('limit') &&
        Object.keys(value).includes('offset') &&
        value.limit > 0 &&
        value.offset >= 0,
    },
    results: {
      type: Array,
      default: () => [],
    },
    hidePaginationLimit: {
      type: Boolean,
      default: false,
    },
    searchFields: {
      type: Array,
      required: true,
      validator: (value) =>
        value.every((item) => !!item.queryParam && !!item.label),
    },
    allSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // component uses id to emit and text is displayed as item
      actions: [
        {
          id: '5',
          text: '5',
          event: 'clicked',
        },
        {
          id: '10',
          text: '10',
          event: 'clicked',
        },
        {
          id: '20',
          text: '20',
          event: 'clicked',
        },
        {
          id: '50',
          text: '50',
          event: 'clicked',
        },
      ],
    };
  },
  computed: {
    page: {
      get() {
        if (
          this.pagination.totalCount <
          this.pagination.offset + this.pagination.limit
        ) {
          return this.pagesLength;
        }
        return Math.ceil(
          (parseInt(this.pagination.offset) + parseInt(this.pagination.limit)) /
            parseInt(this.pagination.limit),
        );
      },
      set(value) {
        if (value === 1)
          return this.$emit('search', {
            query: null,
            limit: this.pagination.limit,
            offset: 0,
            emitter: 'pagination',
          });

        const isModOffset = this.modOffsetLimit ? 1 : 0;
        const offset =
          (value - 1 - isModOffset) * this.pagination.limit +
          this.modOffsetLimit;

        this.$emit('search', {
          query: null,
          limit: this.pagination.limit,
          offset,
          emitter: 'pagination',
        });
      },
    },
    pagesLength() {
      // this is done because of the result fit of the last page
      const isModPagelengthGreaterModOffset =
        this.pagination.totalCount % this.pagination.limit >
        this.modOffsetLimit;
      const isModOffsetAndModPagelengthGreaterModOffset =
        this.modOffsetLimit && isModPagelengthGreaterModOffset ? 1 : 0;

      return (
        Math.ceil(this.pagination.totalCount / this.pagination.limit) +
        isModOffsetAndModPagelengthGreaterModOffset
      );
    },
    // We need to keep the offset while changing pages
    modOffsetLimit() {
      return this.pagination.offset % this.pagination.limit;
    },
  },
  methods: {
    submitSearch(query) {
      // show the last few newly created profiles if no search params are given
      if (Object.keys(query || {}).length <= 0) {
        query = { orderBy: 'created', orderDirection: 'DESC' };
      }

      this.$emit('search', {
        query,
        ...this.pagination,
        emitter: 'search-button',
      });
    },
    toggleAll(val) {
      this.$emit('toggle-all', val);
    },
    paginationLimitClicked(value) {
      return this.$emit('clicked-pagination-limit', value);
    },
  },
};
</script>
