export const federalStates = [
  { statisticKey: '01', label: 'Schleswig-Holstein' },
  { statisticKey: '02', label: 'Hamburg' },
  { statisticKey: '03', label: 'Niedersachsen' },
  { statisticKey: '04', label: 'Bremen' },
  { statisticKey: '05', label: 'Nordrhein-Westfalen' },
  { statisticKey: '06', label: 'Hessen' },
  { statisticKey: '07', label: 'Rheinland-Pfalz' },
  { statisticKey: '08', label: 'Baden-Württemberg' },
  { statisticKey: '09', label: 'Bayern' },
  { statisticKey: '10', label: 'Saarland' },
  { statisticKey: '11', label: 'Berlin' },
  { statisticKey: '12', label: 'Brandenburg' },
  { statisticKey: '13', label: 'Mecklenburg-Vorpommern' },
  { statisticKey: '14', label: 'Sachsen' },
  { statisticKey: '15', label: 'Sachsen-Anhalt' },
  { statisticKey: '16', label: 'Thüringen' },
];
