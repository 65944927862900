export const OVERLAY_EVENTS = {
  CLOSE: 'close',
  SUBMIT: 'submit',
  SUBMIT_NEW: 'submitNew',
};

export const PAGE_TITLE_TEMPLATE = '| Kundenverwaltung | EPOS | IU';

export const USAGE_TYPES = {
  primary: 'Korrespondenz',
  shipping: 'Lieferung',
  billing: 'Rechnung',
  permanent: 'Meldeadresse',
};

export const API_ENDPOINTS = {
  FEATURE_FLAGS: `/student-enrolment/app/feature-flags`,
  PICKLISTS: '/cms/v1/lists',
  CUSTOMER_PROFILES: '/student-enrolment/v1/profiles',
  HEALTH_INSURANCE_PROFILES:
    '/student-enrolment/v1/health-insurance/insurance-profile',
  HEALTH_INSURANCE_PROFILE_LINK:
    '/student-enrolment/v1/health-insurance/profile-link',
  HEALTH_INSURANCE_OF_PROFILE:
    '/student-enrolment/v1/health-insurance/insurance-iam-profile/:profileId',
  HEALTH_INSURANCE_GET_MESSAGES:
    '/student-enrolment/v1/health-insurance/insurance-profile/:insuranceProfileId/message?offset=:offset&limit=:limit',
  HEALTH_INSURANCE_CORRECT_MESSAGE:
    '/student-enrolment/v1/health-insurance/message/:messageId/correction',
  HEALTH_INSURANCE_CANCEL_MESSAGE:
    '/student-enrolment/v1/health-insurance/message/:messageId/cancellation',
  CREATE_HEALTH_INSURANCE_MESSAGE:
    '/student-enrolment/v1/health-insurance/message',
};

export const IS_NON_GERMAN_UNIVERSITY = (universityCode) => {
  return universityCode === '9990';
};

export const VALID_IU_ADDRESSES = [
  '@iu.org',
  '@iu-study.org',
  '@iubh.de',
  '@iu-akademie.de',
  '@iubh-dualesstudium.de',
  '@iubh-fernstudium.de',
];

export const CONTACT_POINTS = {
  ADDRESS: 'address',
  EMAIL: 'email',
  PHONE: 'phone',
};

const CONTACTTYPES_AS_ARRAY = (types) => {
  return Object.entries(types).map(([key, value]) => {
    return { key, label: value };
  });
};

export const BASE_TYPES = {
  default: 'Standard',
  private: 'Privat',
  business: 'Geschäftlich',
  vacation: 'Urlaub',
  emergency: 'Notfall',
};

export const PHONE_TYPES = { ...BASE_TYPES };
export const PHONE_TYPES_AS_ARRAY = CONTACTTYPES_AS_ARRAY(PHONE_TYPES);

export const EMAIL_TYPES = {
  iu: 'IU-Adresse',
  ...BASE_TYPES,
};
export const EMAIL_TYPES_AS_ARRAY = CONTACTTYPES_AS_ARRAY(EMAIL_TYPES);

export const ADDRESS_TYPES = { ...BASE_TYPES };
export const ADDRESS_TYPES_AS_ARRAY = CONTACTTYPES_AS_ARRAY(ADDRESS_TYPES);

export const FEATURES = {
  CLICK_AND_STUDY: 'EP-7019',
  INSURANCE_MESSAGE_UI: 'EP-10896',
};

export const HEALTHINSURANCE_INSURED_STATUS = {
  open: 'open',
  insured: 'insured',
  notInsured: 'notInsured',
  exempt: 'exempt',
  excluded: 'excluded',
  paymentDelayed: 'paymentDelayed',
};

// https://gitlab.com/iu-group/orgtech/epos/epos.student-enrolment/-/blob/master/src/modules/health-insurance/dtos/messages.dto.ts?ref_type=heads#L7-20
export const HEALTH_INSURANCE_MESSAGE_TYPE = Object.freeze({
  INSURANCE_STATUS_REPORTED: 'Meldegrund_10_Versicherungsstatus',
  INSURANCE_STATUS_REPORTED_CANCELLATION:
    'Meldegrund_10_Versicherungsstatus_Storno',
  INSURANCE_CHANGED: 'Meldegrund_11_Meldung_Krankenkassenwechsel',
  INSURANCE_CHANGED_CANCELLATION:
    'Meldegrund_11_Meldung_Krankenkassenwechsel_Storno',
  PAYMENT_DELAYED: 'Meldegrund_12_Zahlungsverzug_liegt_vor',
  PAYMENT_DELAYED_CANCELLATION: 'Meldegrund_12_Zahlungsverzug_liegt_vor_Storno',
  PAYMENT_SETTLED: 'Meldegrund_13_Zahlungsverzug_liegt_nicht_vor',
  PAYMENT_SETTLED_CANCELLATION:
    'Meldegrund_13_Zahlungsverzug_liegt_nicht_vor_Storno',
  STUDIES_STARTED: 'Meldegrund_20_Beginn_des_Studiums',
  STUDIES_STARTED_CANCELLATION: 'Meldegrund_20_Beginn_des_Studiums_Storno',
  STUDIES_ENDED: 'Meldegrund_30_Ende_des_Studiums',
  STUDIES_ENDED_CANCELLATION: 'Meldegrund_30_Ende_des_Studiums_Storno',
});

export const HEALTH_INSURANCE_MESSAGE_LABELS = Object.freeze({
  [HEALTH_INSURANCE_MESSAGE_TYPE.INSURANCE_STATUS_REPORTED]:
    'Versicherungsstatus',
  [HEALTH_INSURANCE_MESSAGE_TYPE.INSURANCE_STATUS_REPORTED_CANCELLATION]:
    'Storno: Versicherungsstatus',
  [HEALTH_INSURANCE_MESSAGE_TYPE.INSURANCE_CHANGED]: 'Krankenkassenwechsel',
  [HEALTH_INSURANCE_MESSAGE_TYPE.INSURANCE_CHANGED_CANCELLATION]:
    'Storno: Krankenkassenwechsel',
  [HEALTH_INSURANCE_MESSAGE_TYPE.PAYMENT_DELAYED]: 'Zahlungsverzug liegt vor',
  [HEALTH_INSURANCE_MESSAGE_TYPE.PAYMENT_DELAYED_CANCELLATION]:
    'Storno: Zahlungsverzug liegt vor',
  [HEALTH_INSURANCE_MESSAGE_TYPE.PAYMENT_SETTLED]:
    'Zahlungsverzug liegt nicht vor',
  [HEALTH_INSURANCE_MESSAGE_TYPE.PAYMENT_SETTLED_CANCELLATION]:
    'Storno: Zahlungsverzug liegt nicht vor',
  [HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_STARTED]: 'Beginn des Studiums',
  [HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_STARTED_CANCELLATION]:
    'Storno: Beginn des Studiums',
  [HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_ENDED]: 'Ende des Studiums',
  [HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_ENDED_CANCELLATION]:
    'Storno: Ende des Studiums',
});
