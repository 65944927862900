<template>
  <div class="grid grid-cols-12 items-center py-1 gap-x-4">
    <div class="col-span-4 pl-2">
      <p class="text-xs mb-0 text-gray-700">Typ</p>
      <div class="flex space-x-4">
        <p class="font-bold mb-0">{{ messageType }}</p>
        <e-chip
          v-show="message.cancelled"
          text-classes="font-bold uppercase text-xs"
          default-style
          class="bg-error"
        >
          Storniert
        </e-chip>
      </div>
    </div>

    <div class="col-span-3 pl-2">
      <p class="text-xs mb-0 text-gray-700">Versicherung</p>
      <p class="font-bold mb-0">
        {{ getInsuranceName || message.insuranceId }}
      </p>
    </div>

    <div class="col-span-1 pl-2">
      <p class="text-xs mb-0 text-gray-700">Verarbeitet</p>
      <p v-if="handledOnDateValue" class="font-bold mb-0">
        {{ handledOnDateValue | formatDate }}
      </p>
      <p v-else class="font-bold mb-0">-</p>
    </div>
    <div class="col-span-1 pl-2">
      <p class="text-xs mb-0 text-gray-700">{{ dateColumnLabel }}</p>
      <p class="font-bold mb-0">{{ dateColumnValue | formatDate }}</p>
    </div>
    <div class="col-span-2 pl-2">
      <p class="text-xs mb-0 text-gray-700">{{ matriculationDateLabel }}</p>
      <p class="font-bold mb-0">{{ matriculationDateValue | formatDate }}</p>
    </div>
    <div v-if="message.editable" class="col-span-1 space-x-5">
      <e-button
        type="button"
        variant="blank"
        @click="$emit('correct-message', message)"
      >
        <e-tooltip content="Bearbeiten" class="text-cta">
          <e-icon icon="mdi-pencil" size="2xl" />
        </e-tooltip>
      </e-button>
      <e-button
        type="button"
        variant="blank"
        @click="$emit('cancel-message', message)"
      >
        <e-tooltip content="Stornieren" class="text-cta">
          <e-icon icon="mdi-cancel" size="2xl" />
        </e-tooltip>
      </e-button>
    </div>
  </div>
</template>
<script>
import { EButton, EIcon, ETooltip, EChip } from '@careerpartner/nitro';
import { HEALTH_INSURANCE_MESSAGE_LABELS } from '@/common/constants';
import selectLists from '@/mixins/selectLists';
import { formatShortDateToLocale } from '@/utils/formatters';
export default {
  name: 'HealthInsuranceListItem',
  components: {
    EButton,
    EIcon,
    ETooltip,
    EChip,
  },
  filters: {
    formatDate: formatShortDateToLocale,
  },
  mixins: [selectLists],
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    messageType() {
      return HEALTH_INSURANCE_MESSAGE_LABELS[this.message.messageType];
    },
    dateColumnLabel() {
      if ('studyEnd' in this.message) {
        return 'Studiumende';
      }
      if ('studyStart' in this.message) {
        return 'Studiumstart';
      }
      return '';
    },
    dateColumnValue() {
      if ('studyEnd' in this.message) {
        return this.message.studyEnd;
      }
      if ('studyStart' in this.message) {
        return this.message.studyStart;
      }
      return '';
    },
    matriculationDateLabel() {
      if ('exmatriculationDate' in this.message) {
        return 'Exmatrikuliert';
      }
      if ('matriculationDate' in this.message) {
        return 'Immartrikuliert';
      }
      return '';
    },
    matriculationDateValue() {
      if ('exmatriculationDate' in this.message) {
        return this.message.exmatriculationDate;
      }
      if ('matriculationDate' in this.message) {
        return this.message.matriculationDate;
      }

      return '';
    },
    handledOnDateValue() {
      return this.message.handledOn;
    },
    getInsuranceName() {
      const insurance = this.selectLists.healthInsurances.find(
        (insurance) => insurance.bn === this.message.insuranceId,
      );
      return insurance ? insurance.name : undefined;
    },
  },
};
</script>
