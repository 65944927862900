<template>
  <e-modal
    ref="modal"
    size="lg"
    :display-close-button="false"
    auto-height
    allow-overflow
    v-bind="$props"
  >
    <form @submit.prevent="confirmCorrection">
      <div class="flex p-6 flex-col">
        <div class="col-span-full self-center">
          <img src="@/assets/correct-insurance-message-icon.svg" />
        </div>
        <div class="col-span-full self-center">
          <h3
            v-if="isEditMode"
            class="block text-center font-bold text-black mt-6 px-12"
          >
            Bist Du sicher, dass Du die Meldung korrigieren möchtest?
          </h3>
          <h3 v-else class="block text-center font-bold text-black mt-6 px-12">
            Eine neuen Krankenkassenmeldung erzeugen
          </h3>
        </div>
        <div v-if="!isEditMode" class="col-span-full flex mt-6">
          <div class="flex-grow">
            <e-multiselect
              id="correctedMessageType"
              v-model="selectedMessageType"
              label="Meldegrund"
              :options="insuranceMessageTypeOptions"
              mapped
              option-label="label"
              track-by="messageType"
              required
            />
          </div>
        </div>
        <div
          v-show="isStudyStarted"
          class="col-span-full self-center mt-6 w-full"
        >
          <div class="col-span-6 grid grid-cols-6 gap-x-6 gap-y-6">
            <div class="col-span-3 inline-flex">
              <div class="flex-grow">
                <e-text-field
                  id="studyStartId"
                  v-model="studyStart"
                  label="Studienstart"
                  type="date"
                />
              </div>
            </div>
            <div class="col-span-3 inline-flex">
              <div class="flex-grow">
                <e-text-field
                  id="matriculationId"
                  v-model="matriculationDate"
                  label="Immatrikulationsdatum"
                  type="date"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="isStudyEnded"
          class="col-span-full self-center mt-6 w-full"
        >
          <div class="col-span-6 grid grid-cols-6 gap-x-6 gap-y-6">
            <div class="col-span-3 inline-flex">
              <div class="flex-grow">
                <e-text-field
                  id="studyEndId"
                  v-model="studyEnd"
                  label="Studienende"
                  type="date"
                />
              </div>
            </div>
            <div class="col-span-3 inline-flex">
              <div class="flex-grow">
                <e-text-field
                  id="exmatriculationId"
                  v-model="exmatriculationDate"
                  label="Exmatrikulationsdatum"
                  type="date"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-full self-center mt-6 px-12">
          <e-checkbox
            id="correction-checkbox"
            v-model="isCorrectionConfirmed"
            check-value="correction"
            :label="
              isEditMode
                ? 'Bestätige die Korrektur der Krankenkassenmeldung'
                : 'Bestätige das Hinzufügen der Krankenkassenmeldung'
            "
          />
        </div>
      </div>
      <footer class="flex items-center justify-end gap-x-2 p-6">
        <div data-v-d8ff3f77="" class="flex w-full justify-between">
          <e-button
            class="n-button pointer-events-auto secondary"
            variant="secondary"
            autofocus
            tabindex="1"
            @click="closeModal"
          >
            Abbrechen </e-button
          ><e-button
            class="pointer-events-auto primary mt-18 ml-auto"
            action-type="submit"
            :disabled="!(isCorrectionConfirmed && isSubmitValid)"
          >
            Ausführen
          </e-button>
        </div>
      </footer>
    </form>
  </e-modal>
</template>
<script>
import {
  EModal,
  EModalEvents,
  EButton,
  ECheckbox,
  EMultiselect,
  ETextField,
} from '@careerpartner/nitro';
import { mapActions } from 'vuex';
import { Actions } from '@/store/modules/profile/types';
import { HEALTH_INSURANCE_MESSAGE_TYPE } from '@/common/constants';

// FOR VALIDATION:
// https://gitlab.com/iu-group/orgtech/epos/epos.student-enrolment/-/blob/master/src/modules/health-insurance/dtos/request/insurance-message.dto.ts?ref_type=heads

export default {
  name: 'CorrectInsuranceDetailsDialog',
  components: {
    ETextField,
    EModal,
    EButton,
    ECheckbox,
    EMultiselect,
  },
  props: {
    isOpen: { type: Boolean, default: false },
    message: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      isCorrectionConfirmed: false,
      studyStart: undefined,
      studyEnd: undefined,
      matriculationDate: undefined,
      exmatriculationDate: undefined,
      selectedMessageType: HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_STARTED,
    };
  },
  computed: {
    insuranceMessageTypeOptions: () => [
      {
        label: 'Beginn des Studiums',
        messageType: HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_STARTED,
      },
      {
        label: 'Ende des Studiums',
        messageType: HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_ENDED,
      },
    ],
    isStudyStarted() {
      return (
        this.selectedMessageType ===
        HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_STARTED
      );
    },
    isStudyEnded() {
      return (
        this.selectedMessageType === HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_ENDED
      );
    },
    isSubmitValid() {
      if (this.isStudyStarted) {
        return !!this.studyStart && !!this.matriculationDate;
      }
      if (this.isStudyEnded) {
        return !!this.studyEnd && !!this.exmatriculationDate;
      }
      return false;
    },
    isEditMode() {
      return Boolean(Object.keys(this.message).length);
    },
  },
  watch: {
    message() {
      if (!this.message.messageType) {
        return;
      }

      this.selectedMessageType = this.message.messageType;
    },
  },
  methods: {
    ...mapActions('profile', {
      correctEditableInsuranceMessages: Actions.CORRECT_OUTGOING_MESSAGE,
      createInsuranceMessage: Actions.SAVE_HEALTH_INSURANCE_MESSAGE,
    }),
    closeModal() {
      this.isCorrectionConfirmed = false;
      this.studyEnd = undefined;
      this.exmatriculationDate = undefined;
      this.studyStart = undefined;
      this.matriculationDate = undefined;
      this.$emit(EModalEvents.CLOSE);
    },
    getInsuranceMessagePayload() {
      if (this.isStudyStarted) {
        this.studyEnd = undefined;
        this.exmatriculationDate = undefined;
      }
      if (this.isStudyEnded) {
        this.studyStart = undefined;
        this.matriculationDate = undefined;
      }

      return {
        messageType: this.selectedMessageType,
        studyStart: this.studyStart,
        matriculationDate: this.matriculationDate,
        studyEnd: this.studyEnd,
        exmatriculationDate: this.exmatriculationDate,
      };
    },
    async confirmCorrection() {
      const insuranceMessagePaylod = this.getInsuranceMessagePayload();
      if (this.isEditMode) {
        await this.correctEditableInsuranceMessages({
          message: this.message,
          payload: insuranceMessagePaylod,
        });
      } else {
        await this.createInsuranceMessage(insuranceMessagePaylod);
      }

      this.closeModal();
    },
  },
};
</script>
