<template>
  <div class="flex flex-col p-6 items-center">
    <img :src="confirmCertificationImage" alt="" class="h-20" />
    <h5 class="font-bold mt-6 text-center max-w-sm">
      Bist Du sicher, dass Du die Hochschulzugangs-berechtigung beglaubigen
      möchtest?
    </h5>
    <div class="flex justify-center mt-6 space-between">
      <e-chip
        text-classes="text-white uppercase text-xs font-bold"
        class="bg-orange-600 ml-2"
        >Ausstehend</e-chip
      >
      <p class="ml-2 text-lg">&xrarr;</p>
      <e-chip
        text-classes="text-white uppercase text-xs font-bold"
        class="bg-green-600 ml-2"
        >Beglaubigt</e-chip
      >
    </div>
    <p class="mt-8 text-gray-700 text-sm">
      Aktion kann NICHT rückgängig gemacht werden.
    </p>
    <div class="mt-4 flex flex-col">
      <e-checkbox
        id="certify-hzb"
        v-model="hasCertification"
        check-value="true"
        label="Bestätige die Beglaubigung der Hochschulzugangsberechtigung "
      />
      <div class="flex justify-between mt-16 w-full">
        <e-button variant="secondary" @click.stop="$emit(OVERLAY_EVENTS.CLOSE)"
          >Abbrechen</e-button
        >
        <e-button
          :disabled="!hasCertification"
          data-test="certify-hzb-save"
          @click.stop="submitCertification"
        >
          <e-icon
            v-show="waitingForResponse"
            class="mr-2 animate-spin"
            icon="mdi-loading"
          />Ausführen</e-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { imagePaths } from '@/common/content';
import { EButton, EChip, ECheckbox, EIcon } from '@careerpartner/nitro';
import { Actions as ProfileActions } from '@/store/modules/profile/types';
import dayjs from 'dayjs';
import { OVERLAY_EVENTS } from '@/common/constants';

export default {
  name: 'CertifyUniversityQualification',
  components: {
    EButton,
    EChip,
    ECheckbox,
    EIcon,
  },
  data() {
    return {
      waitingForResponse: false,
      lazyHasCertification: null,
    };
  },
  computed: {
    OVERLAY_EVENTS() {
      return OVERLAY_EVENTS;
    },
    hasCertification: {
      get() {
        return !!this.lazyHasCertification;
      },
      set(val) {
        let payload = null;

        if (val !== false) {
          payload = dayjs().format('YYYY-MM-DD');
        }

        this.lazyHasCertification = payload;
      },
    },
    confirmCertificationImage() {
      return imagePaths.CONFIRM_CERTIFICATION_OF_HZB;
    },
  },
  methods: {
    async submitCertification() {
      if (!this.lazyHasCertification) return;
      this.waitingForResponse = true;

      await this.$store.dispatch(
        `profile/${ProfileActions.CERTIFY_HZB}`,
        this.lazyHasCertification,
      );

      try {
        await this.$store.dispatch(
          `profile/${ProfileActions.SAVE_UNIVERSITY_ENTRANCE_QUALIFICATION}`,
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.waitingForResponse = false;
        this.$emit(OVERLAY_EVENTS.SUBMIT);
      }
    },
  },
};
</script>
