export const districts = [
  { statisticKey: '01001', label: 'Flensburg, Stadt' },
  { statisticKey: '01002', label: 'Kiel, Landeshauptstadt' },
  { statisticKey: '01003', label: 'Lübeck, Stadt' },
  { statisticKey: '01004', label: 'Neumünster, Stadt' },
  { statisticKey: '01051', label: 'Dithmarschen' },
  { statisticKey: '01053', label: 'Herzogtum Lauenburg' },
  { statisticKey: '01054', label: 'Nordfriesland' },
  { statisticKey: '01055', label: 'Ostholstein' },
  { statisticKey: '01056', label: 'Pinneberg' },
  { statisticKey: '01057', label: 'Plön' },
  { statisticKey: '01058', label: 'Rendsburg-Eckernförde' },
  { statisticKey: '01059', label: 'Schleswig-Flensburg' },
  { statisticKey: '01060', label: 'Segeberg' },
  { statisticKey: '01061', label: 'Steinburg' },
  { statisticKey: '01062', label: 'Stormarn' },
  { statisticKey: '02000', label: 'Hamburg' },
  { statisticKey: '03101', label: 'Braunschweig, Stadt' },
  { statisticKey: '03102', label: 'Salzgitter, Stadt' },
  { statisticKey: '03103', label: 'Wolfsburg, Stadt' },
  { statisticKey: '03151', label: 'Gifhorn' },
  { statisticKey: '03153', label: 'Goslar' },
  { statisticKey: '03154', label: 'Helmstedt' },
  { statisticKey: '03155', label: 'Northeim' },
  { statisticKey: '03157', label: 'Peine' },
  { statisticKey: '03158', label: 'Wolfenbüttel' },
  { statisticKey: '03159', label: 'Göttingen' },
  { statisticKey: '03241', label: 'Hannover, Region' },
  { statisticKey: '03251', label: 'Diepholz' },
  { statisticKey: '03252', label: 'Hameln-Pyrmont' },
  { statisticKey: '03254', label: 'Hildesheim' },
  { statisticKey: '03255', label: 'Holzminden' },
  { statisticKey: '03256', label: 'Nienburg (Weser)' },
  { statisticKey: '03257', label: 'Schaumburg' },
  { statisticKey: '03351', label: 'Celle' },
  { statisticKey: '03352', label: 'Cuxhaven' },
  { statisticKey: '03353', label: 'Harburg' },
  { statisticKey: '03354', label: 'Lüchow-Dannenberg' },
  { statisticKey: '03355', label: 'Lüneburg' },
  { statisticKey: '03356', label: 'Osterholz' },
  { statisticKey: '03357', label: 'Rotenburg (Wümme)' },
  { statisticKey: '03358', label: 'Heidekreis' },
  { statisticKey: '03359', label: 'Stade' },
  { statisticKey: '03360', label: 'Uelzen' },
  { statisticKey: '03361', label: 'Verden' },
  { statisticKey: '03401', label: 'Delmenhorst, Stadt' },
  { statisticKey: '03402', label: 'Emden, Stadt' },
  { statisticKey: '03403', label: 'Oldenburg (Oldenburg), Stadt' },
  { statisticKey: '03404', label: 'Osnabrück, Stadt' },
  { statisticKey: '03405', label: 'Wilhelmshaven, Stadt' },
  { statisticKey: '03451', label: 'Ammerland' },
  { statisticKey: '03452', label: 'Aurich' },
  { statisticKey: '03453', label: 'Cloppenburg' },
  { statisticKey: '03454', label: 'Emsland' },
  { statisticKey: '03455', label: 'Friesland' },
  { statisticKey: '03456', label: 'Grafschaft Bentheim' },
  { statisticKey: '03457', label: 'Leer' },
  { statisticKey: '03458', label: 'Oldenburg (Oldenburg)' },
  { statisticKey: '03459', label: 'Osnabrück' },
  { statisticKey: '03460', label: 'Vechta' },
  { statisticKey: '03461', label: 'Wesermarsch' },
  { statisticKey: '03462', label: 'Wittmund' },
  { statisticKey: '04011', label: 'Bremen, Stadt' },
  { statisticKey: '04012', label: 'Bremerhaven, Stadt' },
  { statisticKey: '05111', label: 'Düsseldorf, Landeshauptstadt' },
  { statisticKey: '05112', label: 'Duisburg, Stadt' },
  { statisticKey: '05113', label: 'Essen, Stadt' },
  { statisticKey: '05114', label: 'Krefeld, Stadt' },
  { statisticKey: '05116', label: 'Mönchengladbach, Stadt' },
  { statisticKey: '05117', label: 'Mülheim a.d. Ruhr, Stadt' },
  { statisticKey: '05119', label: 'Oberhausen, Stadt' },
  { statisticKey: '05120', label: 'Remscheid, Stadt' },
  { statisticKey: '05122', label: 'Solingen, Stadt' },
  { statisticKey: '05124', label: 'Wuppertal, Stadt' },
  { statisticKey: '05154', label: 'Kleve' },
  { statisticKey: '05158', label: 'Mettmann' },
  { statisticKey: '05162', label: 'Neuss' },
  { statisticKey: '05166', label: 'Viersen' },
  { statisticKey: '05170', label: 'Wesel' },
  { statisticKey: '05314', label: 'Bonn, Stadt' },
  { statisticKey: '05315', label: 'Köln, Stadt' },
  { statisticKey: '05316', label: 'Leverkusen, Stadt' },
  { statisticKey: '05334', label: 'Aachen, Städteregion' },
  { statisticKey: '05358', label: 'Düren' },
  { statisticKey: '05362', label: 'Erftkreis' },
  { statisticKey: '05366', label: 'Euskirchen' },
  { statisticKey: '05370', label: 'Heinsberg' },
  { statisticKey: '05374', label: 'Oberbergischer Kreis' },
  { statisticKey: '05378', label: 'Rheinisch-Bergischer Kreis' },
  { statisticKey: '05382', label: 'Rhein-Sieg-Kreis' },
  { statisticKey: '05512', label: 'Bottrop, Stadt' },
  { statisticKey: '05513', label: 'Gelsenkirchen, Stadt' },
  { statisticKey: '05515', label: 'Münster, Stadt' },
  { statisticKey: '05554', label: 'Borken' },
  { statisticKey: '05558', label: 'Coesfeld' },
  { statisticKey: '05562', label: 'Recklinghausen' },
  { statisticKey: '05566', label: 'Steinfurt' },
  { statisticKey: '05570', label: 'Warendorf' },
  { statisticKey: '05711', label: 'Bielefeld, Stadt' },
  { statisticKey: '05754', label: 'Gütersloh' },
  { statisticKey: '05758', label: 'Herford' },
  { statisticKey: '05762', label: 'Höxter' },
  { statisticKey: '05766', label: 'Lippe' },
  { statisticKey: '05770', label: 'Minden-Lübbecke' },
  { statisticKey: '05774', label: 'Paderborn' },
  { statisticKey: '05911', label: 'Bochum, Stadt' },
  { statisticKey: '05913', label: 'Dortmund, Stadt' },
  { statisticKey: '05914', label: 'Hagen, Stadt' },
  { statisticKey: '05915', label: 'Hamm, Stadt' },
  { statisticKey: '05916', label: 'Herne, Stadt' },
  { statisticKey: '05954', label: 'Ennepe-Ruhr-Kreis' },
  { statisticKey: '05958', label: 'Hochsauerlandkreis' },
  { statisticKey: '05962', label: 'Märkischer Kreis' },
  { statisticKey: '05966', label: 'Olpe' },
  { statisticKey: '05970', label: 'Siegen-Wittgenstein' },
  { statisticKey: '05974', label: 'Soest' },
  { statisticKey: '05978', label: 'Unna' },
  { statisticKey: '06411', label: 'Darmstadt, Stadt' },
  { statisticKey: '06412', label: 'Frankfurt am Main, Stadt' },
  { statisticKey: '06413', label: 'Offenbach am Main, Stadt' },
  { statisticKey: '06414', label: 'Wiesbaden, Landeshauptstadt' },
  { statisticKey: '06431', label: 'Bergstraße' },
  { statisticKey: '06432', label: 'Darmstadt-Dieburg' },
  { statisticKey: '06433', label: 'Groß-Gerau' },
  { statisticKey: '06434', label: 'Hochtaunuskreis' },
  { statisticKey: '06435', label: 'Main-Kinzig-Kreis' },
  { statisticKey: '06436', label: 'Main-Taunus-Kreis' },
  { statisticKey: '06437', label: 'Odenwaldkreis' },
  { statisticKey: '06438', label: 'Offenbach' },
  { statisticKey: '06439', label: 'Rheingau-Taunus-Kreis' },
  { statisticKey: '06440', label: 'Wetteraukreis' },
  { statisticKey: '06531', label: 'Gießen' },
  { statisticKey: '06532', label: 'Lahn-Dill-Kreis' },
  { statisticKey: '06533', label: 'Limburg-Weilburg' },
  { statisticKey: '06534', label: 'Marburg-Biedenkopf' },
  { statisticKey: '06535', label: 'Vogelsbergkreis' },
  { statisticKey: '06611', label: 'Kassel, Stadt' },
  { statisticKey: '06631', label: 'Fulda' },
  { statisticKey: '06632', label: 'Hersfeld-Rotenburg' },
  { statisticKey: '06633', label: 'Kassel' },
  { statisticKey: '06634', label: 'Schwalm-Eder-Kreis' },
  { statisticKey: '06635', label: 'Waldeck-Frankenberg' },
  { statisticKey: '06636', label: 'Werra-Meißner-Kreis' },
  { statisticKey: '07111', label: 'Koblenz, Stadt' },
  { statisticKey: '07131', label: 'Ahrweiler' },
  { statisticKey: '07132', label: 'Altenkirchen (Westerwald)' },
  { statisticKey: '07133', label: 'Bad Kreuznach' },
  { statisticKey: '07134', label: 'Birkenfeld' },
  { statisticKey: '07135', label: 'Cochem-Zell' },
  { statisticKey: '07137', label: 'Mayen-Koblenz' },
  { statisticKey: '07138', label: 'Neuwied' },
  { statisticKey: '07140', label: 'Rhein-Hunsrück-Kreis' },
  { statisticKey: '07141', label: 'Rhein-Lahn-Kreis' },
  { statisticKey: '07143', label: 'Westerwaldkreis' },
  { statisticKey: '07211', label: 'Trier, Stadt' },
  { statisticKey: '07231', label: 'Bernkastel-Wittlich' },
  { statisticKey: '07232', label: 'Bitburg-Prüm' },
  { statisticKey: '07233', label: 'Vulkaneifel' },
  { statisticKey: '07235', label: 'Trier-Saarburg' },
  { statisticKey: '07311', label: 'Frankenthal (Pfalz), Stadt' },
  { statisticKey: '07312', label: 'Kaiserslautern, Stadt' },
  { statisticKey: '07313', label: 'Landau i.d. Pfalz, Stadt' },
  { statisticKey: '07314', label: 'Ludwigshafen a. Rhein, Stadt' },
  { statisticKey: '07315', label: 'Mainz, Landeshauptstadt' },
  { statisticKey: '07316', label: 'Neustadt a.d. Weinstraße, Stadt' },
  { statisticKey: '07317', label: 'Pirmasens, Stadt' },
  { statisticKey: '07318', label: 'Speyer, Stadt' },
  { statisticKey: '07319', label: 'Worms, Stadt' },
  { statisticKey: '07320', label: 'Zweibrücken, Stadt' },
  { statisticKey: '07331', label: 'Alzey-Worms' },
  { statisticKey: '07332', label: 'Bad Dürkheim' },
  { statisticKey: '07333', label: 'Donnersbergkreis' },
  { statisticKey: '07334', label: 'Germersheim' },
  { statisticKey: '07335', label: 'Kaiserslautern' },
  { statisticKey: '07336', label: 'Kusel' },
  { statisticKey: '07337', label: 'Südliche Weinstraße' },
  { statisticKey: '07338', label: 'Rhein-Pfalz-Kreis' },
  { statisticKey: '07339', label: 'Mainz-Bingen' },
  { statisticKey: '07340', label: 'Südwestpfalz' },
  { statisticKey: '08111', label: 'Stuttgart, Landeshauptstadt' },
  { statisticKey: '08115', label: 'Böblingen' },
  { statisticKey: '08116', label: 'Esslingen' },
  { statisticKey: '08117', label: 'Göppingen' },
  { statisticKey: '08118', label: 'Ludwigsburg' },
  { statisticKey: '08119', label: 'Rems-Murr-Kreis' },
  { statisticKey: '08121', label: 'Heilbronn, Stadt' },
  { statisticKey: '08125', label: 'Heilbronn' },
  { statisticKey: '08126', label: 'Hohenlohekreis' },
  { statisticKey: '08127', label: 'Schwäbisch Hall' },
  { statisticKey: '08128', label: 'Main-Tauber-Kreis' },
  { statisticKey: '08135', label: 'Heidenheim' },
  { statisticKey: '08136', label: 'Ostalbkreis' },
  { statisticKey: '08211', label: 'Baden-Baden, Stadt' },
  { statisticKey: '08212', label: 'Karlsruhe, Stadt' },
  { statisticKey: '08215', label: 'Karlsruhe' },
  { statisticKey: '08216', label: 'Rastatt' },
  { statisticKey: '08221', label: 'Heidelberg, Stadt' },
  { statisticKey: '08222', label: 'Mannheim, Stadt' },
  { statisticKey: '08225', label: 'Neckar-Odenwald-Kreis' },
  { statisticKey: '08226', label: 'Rhein-Neckar-Kreis' },
  { statisticKey: '08231', label: 'Pforzheim, Stadt' },
  { statisticKey: '08235', label: 'Calw' },
  { statisticKey: '08236', label: 'Enzkreis' },
  { statisticKey: '08237', label: 'Freudenstadt' },
  { statisticKey: '08311', label: 'Freiburg im Breisgau, Stadt' },
  { statisticKey: '08315', label: 'Breisgau-Hochschwarzwald' },
  { statisticKey: '08316', label: 'Emmendingen' },
  { statisticKey: '08317', label: 'Ortenaukreis' },
  { statisticKey: '08325', label: 'Rottweil' },
  { statisticKey: '08326', label: 'Schwarzwald-Baar-Kreis' },
  { statisticKey: '08327', label: 'Tuttlingen' },
  { statisticKey: '08335', label: 'Konstanz' },
  { statisticKey: '08336', label: 'Lörrach' },
  { statisticKey: '08337', label: 'Waldshut' },
  { statisticKey: '08415', label: 'Reutlingen' },
  { statisticKey: '08416', label: 'Tübingen' },
  { statisticKey: '08417', label: 'Zollernalbkreis' },
  { statisticKey: '08421', label: 'Ulm, Stadt' },
  { statisticKey: '08425', label: 'Alb-Donau-Kreis' },
  { statisticKey: '08426', label: 'Biberach' },
  { statisticKey: '08435', label: 'Bodenseekreis' },
  { statisticKey: '08436', label: 'Ravensburg' },
  { statisticKey: '08437', label: 'Sigmaringen' },
  { statisticKey: '09161', label: 'Ingolstadt, Stadt' },
  { statisticKey: '09162', label: 'München, Landeshauptstadt' },
  { statisticKey: '09163', label: 'Rosenheim, Stadt' },
  { statisticKey: '09171', label: 'Altötting' },
  { statisticKey: '09172', label: 'Berchtesgadener Land' },
  { statisticKey: '09173', label: 'Bad Tölz-Wolfratshausen' },
  { statisticKey: '09174', label: 'Dachau' },
  { statisticKey: '09175', label: 'Ebersberg' },
  { statisticKey: '09176', label: 'Eichstätt' },
  { statisticKey: '09177', label: 'Erding' },
  { statisticKey: '09178', label: 'Freising' },
  { statisticKey: '09179', label: 'Fürstenfeldbruck' },
  { statisticKey: '09180', label: 'Garmisch-Partenkirchen' },
  { statisticKey: '09181', label: 'Landsberg am Lech' },
  { statisticKey: '09182', label: 'Miesbach' },
  { statisticKey: '09183', label: 'Mühldorf am Inn' },
  { statisticKey: '09184', label: 'München' },
  { statisticKey: '09185', label: 'Neuburg-Schrobenhausen' },
  { statisticKey: '09186', label: 'Pfaffenhofen a. d. Ilm' },
  { statisticKey: '09187', label: 'Rosenheim' },
  { statisticKey: '09188', label: 'Starnberg' },
  { statisticKey: '09189', label: 'Traunstein' },
  { statisticKey: '09190', label: 'Weilheim-Schongau' },
  { statisticKey: '09261', label: 'Landshut, Stadt' },
  { statisticKey: '09262', label: 'Passau, Stadt' },
  { statisticKey: '09263', label: 'Straubing, Stadt' },
  { statisticKey: '09271', label: 'Deggendorf' },
  { statisticKey: '09272', label: 'Freyung-Grafenau' },
  { statisticKey: '09273', label: 'Kelheim' },
  { statisticKey: '09274', label: 'Landshut' },
  { statisticKey: '09275', label: 'Passau' },
  { statisticKey: '09276', label: 'Regen' },
  { statisticKey: '09277', label: 'Rottal-Inn' },
  { statisticKey: '09278', label: 'Straubing-Bogen' },
  { statisticKey: '09279', label: 'Dingolfing-Landau' },
  { statisticKey: '09361', label: 'Amberg, Stadt' },
  { statisticKey: '09362', label: 'Regensburg, Stadt' },
  { statisticKey: '09363', label: 'Weiden i.d.Opf., Stadt' },
  { statisticKey: '09371', label: 'Amberg-Sulzbach' },
  { statisticKey: '09372', label: 'Cham' },
  { statisticKey: '09373', label: 'Neumarkt i.d.Opf.' },
  { statisticKey: '09374', label: 'Neustadt a.d. Waldnaab' },
  { statisticKey: '09375', label: 'Regensburg' },
  { statisticKey: '09376', label: 'Schwandorf' },
  { statisticKey: '09377', label: 'Tirschenreuth' },
  { statisticKey: '09461', label: 'Bamberg, Stadt' },
  { statisticKey: '09462', label: 'Bayreuth, Stadt' },
  { statisticKey: '09463', label: 'Coburg, Stadt' },
  { statisticKey: '09464', label: 'Hof, Stadt' },
  { statisticKey: '09471', label: 'Bamberg' },
  { statisticKey: '09472', label: 'Bayreuth' },
  { statisticKey: '09473', label: 'Coburg' },
  { statisticKey: '09474', label: 'Forchheim' },
  { statisticKey: '09475', label: 'Hof' },
  { statisticKey: '09476', label: 'Kronach' },
  { statisticKey: '09477', label: 'Kulmbach' },
  { statisticKey: '09478', label: 'Lichtenfels' },
  { statisticKey: '09479', label: 'Wunsiedel i. Fichtelgebirge' },
  { statisticKey: '09561', label: 'Ansbach, Stadt' },
  { statisticKey: '09562', label: 'Erlangen, Stadt' },
  { statisticKey: '09563', label: 'Fürth, Stadt' },
  { statisticKey: '09564', label: 'Nürnberg, Stadt' },
  { statisticKey: '09565', label: 'Schwabach, Stadt' },
  { statisticKey: '09571', label: 'Ansbach' },
  { statisticKey: '09572', label: 'Erlangen-Höchstadt' },
  { statisticKey: '09573', label: 'Fürth' },
  { statisticKey: '09574', label: 'Nürnberger Land' },
  { statisticKey: '09575', label: 'Neustadt a.d. Aisch - Bad Windsheim' },
  { statisticKey: '09576', label: 'Roth' },
  { statisticKey: '09577', label: 'Weißenburg-Gunzenhausen' },
  { statisticKey: '09661', label: 'Aschaffenburg, Stadt' },
  { statisticKey: '09662', label: 'Schweinfurt, Stadt' },
  { statisticKey: '09663', label: 'Würzburg, Stadt' },
  { statisticKey: '09671', label: 'Aschaffenburg' },
  { statisticKey: '09672', label: 'Bad Kissingen' },
  { statisticKey: '09673', label: 'Rhön-Grabfeld' },
  { statisticKey: '09674', label: 'Haßberge' },
  { statisticKey: '09675', label: 'Kitzingen' },
  { statisticKey: '09676', label: 'Miltenberg' },
  { statisticKey: '09677', label: 'Main-Spessart' },
  { statisticKey: '09678', label: 'Schweinfurt' },
  { statisticKey: '09679', label: 'Würzburg' },
  { statisticKey: '09761', label: 'Augsburg, Stadt' },
  { statisticKey: '09762', label: 'Kaufbeuren, Stadt' },
  { statisticKey: '09763', label: 'Kempten (Allgäu), Stadt' },
  { statisticKey: '09764', label: 'Memmingen, Stadt' },
  { statisticKey: '09771', label: 'Aichach-Friedberg' },
  { statisticKey: '09772', label: 'Augsburg' },
  { statisticKey: '09773', label: 'Dillingen a.d. Donau' },
  { statisticKey: '09774', label: 'Günzburg' },
  { statisticKey: '09775', label: 'Neu-Ulm' },
  { statisticKey: '09776', label: 'Lindau-Bodensee' },
  { statisticKey: '09777', label: 'Ostallgäu' },
  { statisticKey: '09778', label: 'Unterallgäu' },
  { statisticKey: '09779', label: 'Donau-Ries' },
  { statisticKey: '09780', label: 'Oberallgäu' },
  { statisticKey: '10041', label: 'Regionalverband Saarbrücken' },
  { statisticKey: '10042', label: 'Merzig-Wadern' },
  { statisticKey: '10043', label: 'Neunkirchen' },
  { statisticKey: '10044', label: 'Saarlouis' },
  { statisticKey: '10045', label: 'Saar-Pfalz-Kreis' },
  { statisticKey: '10046', label: 'Sankt Wendel' },
  { statisticKey: '11000', label: 'Berlin' },
  { statisticKey: '12051', label: 'Brandenburg an der Havel, Stadt' },
  { statisticKey: '12052', label: 'Cottbus/Chosebuz, Stadt' },
  { statisticKey: '12053', label: 'Frankfurt (Oder), Stadt' },
  { statisticKey: '12054', label: 'Potsdam, Landeshauptstadt' },
  { statisticKey: '12060', label: 'Barnim' },
  { statisticKey: '12061', label: 'Dahme-Spreewald' },
  { statisticKey: '12062', label: 'Elbe-Elster' },
  { statisticKey: '12063', label: 'Havelland' },
  { statisticKey: '12064', label: 'Märkisch-Oderland' },
  { statisticKey: '12065', label: 'Oberhavel' },
  { statisticKey: '12066', label: 'Oberspreewald-Lausitz' },
  { statisticKey: '12067', label: 'Oder-Spree' },
  { statisticKey: '12068', label: 'Ostprignitz-Ruppin' },
  { statisticKey: '12069', label: 'Potsdam-Mittelmark' },
  { statisticKey: '12070', label: 'Prignitz' },
  { statisticKey: '12071', label: 'Spree-Neiße' },
  { statisticKey: '12072', label: 'Teltow-Fläming' },
  { statisticKey: '12073', label: 'Uckermark' },
  { statisticKey: '13003', label: 'Rostock, Hansestadt' },
  { statisticKey: '13004', label: 'Schwerin, Landeshauptstadt' },
  { statisticKey: '13071', label: 'Mecklenburgische Seenplatte' },
  { statisticKey: '13072', label: 'Rostock' },
  { statisticKey: '13073', label: 'Vorpommern-Rügen' },
  { statisticKey: '13074', label: 'Nordwestmecklenburg' },
  { statisticKey: '13075', label: 'Vorpommern-Greifswald' },
  { statisticKey: '13076', label: 'Ludwigslust-Parchim' },
  { statisticKey: '14511', label: 'Chemnitz, Stadt' },
  { statisticKey: '14521', label: 'Erzgebirgkreis' },
  { statisticKey: '14522', label: 'Mittelsachsen' },
  { statisticKey: '14523', label: 'Vogtlandkreis' },
  { statisticKey: '14524', label: 'Zwickau' },
  { statisticKey: '14612', label: 'Dresden, Stadt' },
  { statisticKey: '14625', label: 'Bautzen' },
  { statisticKey: '14626', label: 'Görlitz' },
  { statisticKey: '14627', label: 'Meißen' },
  { statisticKey: '14628', label: 'Sächsische Schweiz-Ostererzgebirge' },
  { statisticKey: '14713', label: 'Leipzig, Stadt' },
  { statisticKey: '14729', label: 'Leipzig' },
  { statisticKey: '14730', label: 'Nordsachsen' },
  { statisticKey: '15001', label: 'Dessau-Roßlau, Stadt' },
  { statisticKey: '15002', label: 'Halle (Saale), Stadt' },
  { statisticKey: '15003', label: 'Magdeburg, Landeshauptstadt' },
  { statisticKey: '15081', label: 'Altmarkkreis Salzwedel' },
  { statisticKey: '15082', label: 'Anhalt-Bitterfeld' },
  { statisticKey: '15083', label: 'Börde' },
  { statisticKey: '15084', label: 'Burgenlandkreis' },
  { statisticKey: '15085', label: 'Harz' },
  { statisticKey: '15086', label: 'Jerichower Land' },
  { statisticKey: '15087', label: 'Mansfeld-Südharz' },
  { statisticKey: '15088', label: 'Saalekreis' },
  { statisticKey: '15089', label: 'Salzlandkreis' },
  { statisticKey: '15090', label: 'Stendal' },
  { statisticKey: '15091', label: 'Wittenberg' },
  { statisticKey: '16051', label: 'Erfurt, Stadt' },
  { statisticKey: '16052', label: 'Gera, Stadt' },
  { statisticKey: '16053', label: 'Jena, Stadt' },
  { statisticKey: '16054', label: 'Suhl, Stadt' },
  { statisticKey: '16055', label: 'Weimar, Stadt' },
  { statisticKey: '16056', label: 'Eisenach, Stadt' },
  { statisticKey: '16061', label: 'Eichsfeld' },
  { statisticKey: '16062', label: 'Nordhausen' },
  { statisticKey: '16063', label: 'Wartburgkreis' },
  { statisticKey: '16064', label: 'Unstrut-Hainich-Kreis' },
  { statisticKey: '16065', label: 'Kyffhäuserkreis' },
  { statisticKey: '16066', label: 'Schmalkalden-Meiningen' },
  { statisticKey: '16067', label: 'Gotha' },
  { statisticKey: '16068', label: 'Sömmerda' },
  { statisticKey: '16069', label: 'Hildburghausen' },
  { statisticKey: '16070', label: 'Ilm-Kreis' },
  { statisticKey: '16071', label: 'Weimarer Land' },
  { statisticKey: '16072', label: 'Sonneberg' },
  { statisticKey: '16073', label: 'Saalfeld-Rudolstadt' },
  { statisticKey: '16074', label: 'Saale-Holzland-Kreis' },
  { statisticKey: '16075', label: 'Saale-Orla-Kreis' },
  { statisticKey: '16076', label: 'Greiz' },
  { statisticKey: '16077', label: 'Altenburger Land' },
];
