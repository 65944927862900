import { setPublicPath } from 'systemjs-webpack-interop';
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import router from './router';
import store from './store';
import { loadEnabledFeatures } from './utils/feature-config';
import sentryInit, { Sentry } from './plugins/sentry';
import '@/styles/main.css';
import '@careerpartner/nitro/dist/styles.css';
import '@/assets/tailwind.css';

setPublicPath(process.env.APPLET_NAME);

Vue.config.productionTip = false;
Vue.config.errorHandler = (error) => {
  Sentry.captureException(error);
  throw error;
};
Vue.use(VueMeta);

const CONTAINER_SELECTOR = '#app-placeholder'

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App);
    },
    router,
    store,
    el: CONTAINER_SELECTOR,
  },
});


export const bootstrap = vueLifecycles.bootstrap;

export const mount = async (props) => {
  sentryInit();

  await loadEnabledFeatures();
  return vueLifecycles.mount(props);
};

export const unmount = vueLifecycles.unmount;

export const devtools = {
  overlays: {
    selectors: [CONTAINER_SELECTOR],
  },
};
