import { Mutations } from './types';
import { updateField } from 'vuex-map-fields';
import { CONTACT_POINTS } from '@/common/constants';

export default {
  updateField,
  [Mutations.SET_CERTIFY_HZB](state, payload) {
    state.universityEntranceQualification.certifiedOn = payload;
  },
  [Mutations.SET_PROFILE](state, payload) {
    Object.keys(payload).forEach((key) => {
      // profile and contact_point share the same keys, so do not allow profile to overwrite contact points on init
      if (key === CONTACT_POINTS.EMAIL) {
        return;
      }
      state[key] = payload[key];
    });
  },
  [Mutations.SET_CONTACT_POINT](state, { target, payload }) {
    state[target] = payload;
  },
  [Mutations.SET_IDENTITY_MANAGEMENT](state, payload) {
    state.identityDocument = payload.identityDocument;
    state.residencePermit = payload.residencePermit;
  },
  [Mutations.SET_HEALTH_INSURANCE](state, payload) {
    state.healthInsurance = payload;
  },
  [Mutations.SET_HEALTH_INSURANCE_MESSAGES](state, payload) {
    state.healthInsuranceMessages = payload;
  },
  [Mutations.SET_UNIVERSITY_ENTRANCE_QUALIFICATION](state, payload) {
    state.universityEntranceQualification = payload;
  },
  [Mutations.SET_HAS_FETCHED_PROFILE](state, payload) {
    state.hasFetched = payload;
  },
};
