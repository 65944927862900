<template>
  <section class="pt-12">
    <div :class="hasImage ? 'flex flex-col xl:grid xl:grid-cols-2' : ''">
      <!-- has image-->
      <div :class="hasImage ? 'col-span-1' : ''">
        <h3 class="uppercase text-blue-600 font-bold">
          <slot name="headline"></slot>
        </h3>
        <form
          autocomplete="off"
          class="col-span-6 grid grid-cols-6 gap-x-6 gap-y-7 mt-4"
          @submit.prevent
        >
          <slot></slot>
        </form>
      </div>

      <div v-show="hasImage" class="col-span-1 grid">
        <img
          alt=""
          :src="imageSrc"
          width="250px"
          class="hidden xl:block justify-self-center self-center"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionStructure',
  props: {
    hasImage: {
      type: Boolean,
      default: false,
    },
    imageSrc: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
};
</script>
