import { Mutations } from './types';
import { updateField } from 'vuex-map-fields';

export default {
  updateField,
  [Mutations.SET_ACADEMIC_HISTORY](state, payload) {
    Object.keys(payload).forEach((key) => {
      state[key] = payload[key];
    });
  },
};
