import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { version } from '../../package.json';

export default function sentryInit() {
  Sentry.init({
    Vue,
    dsn: process.env.SENTRY_DSN,
    environment: process.env.DEPLOYMENT_ENV,
    logErrors: true,
    release: version,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.DEPLOYMENT_ENV === 'dev' ? 1.0 : 0.25,
  });
}

export { Sentry };
