import { extend } from 'vee-validate';
import { validationErrorMessages } from '@/common/validationErrorMessages';
import { validationRegex } from '@/common/validationRules';
import {
  email as EmailValidator,
  numeric,
  required,
} from 'vee-validate/dist/rules';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { countries } from '@/common/selectLists';
import { VALID_IU_ADDRESSES } from '@/common/constants';
import dayjs from 'dayjs';

extend('max', {
  validate(value, args) {
    return (
      value &&
      (value.length <= args.length ||
        validationErrorMessages.length(args.length))
    );
  },
  params: ['length'],
});

extend('ruleForName', (value) => {
  return (
    !!value.match(validationRegex.alphaNumSpaceDash) ||
    validationErrorMessages.nameFormatting
  );
});

extend('noDigits', (value) => {
  return (
    !!value.match(validationRegex.noDigits) ||
    validationErrorMessages.noDigitsName
  );
});

extend('postalCode', (value) => {
  return (
    value &&
    (!!value.match(validationRegex.simplePostalCode) ||
      validationErrorMessages.postalCodeFormatting)
  );
});

extend('required', {
  ...required,
  message: validationErrorMessages.required,
});

extend('numeric', {
  ...numeric,
  message: validationErrorMessages.numeric,
});

extend('max_value', {
  params: ['max'],
  validate: (value, { max }) => {
    return value <= max;
  },
  message: validationErrorMessages.maxTerms,
});

extend('min_value', {
  params: ['min'],
  validate: (value, { min }) => {
    return value >= min;
  },
  message: validationErrorMessages.minTerms,
});

extend('date_format', (value) => {
  const date = dayjs(value);
  return date.isValid();
});

extend('phoneValidator', {
  params: ['prefix'],
  validate: (value, { prefix }) => {
    return isValidPhoneNumber(`${prefix}${value}`);
  },
  message: validationErrorMessages.phoneFormatting,
});

extend('customEmail', {
  params: ['contactType'],
  validate: (value, { contactType }) => {
    let valid = EmailValidator.validate(value);

    if (contactType === 'iu') {
      valid =
        VALID_IU_ADDRESSES.some((iumail) => value.includes(iumail)) &&
        EmailValidator.validate(value);
    }

    return valid;
  },
  message: validationErrorMessages.emailFormatting,
});

extend('phonePrefixWithPlus', {
  validate: (value) => {
    return value.charAt(0) === '+';
  },
  message: validationErrorMessages.phonePrefixWithPlus,
});

const permittedValues = countries.map((country) => country.countryCallingCode);

extend('phonePrefixAllowedEntry', {
  validate: (value) => {
    return permittedValues.includes(value);
  },
  message: validationErrorMessages.phonePrefixAllowedEntry,
});

extend('identityDocumentId', {
  validate: (value) => value.length === 9,
  message: validationErrorMessages.identityDocumentId,
});

extend('before', {
  params: ['date'],
  validate: (value, { date }) => {
    const compareDate = dayjs(date);
    if (!compareDate.isValid()) {
      throw new Error("compare date is not valid for rule 'before'" + date);
    }

    if (!value) {
      return true;
    }

    const valueDate = dayjs(value);
    const validValue = valueDate.isValid();
    const isBefore = valueDate.isBefore(compareDate, 'date');
    if (validValue && isBefore) {
      return true;
    }
    return validationErrorMessages.before(date);
  },
});
extend('after', {
  params: ['date'],
  validate: (value, { date }) => {
    const compareDate = dayjs(date);
    if (!compareDate.isValid()) {
      throw new Error("compare date is not valid for rule 'after'" + date);
    }

    if (!value) {
      return true;
    }

    const valueDate = dayjs(value);
    const validValue = valueDate.isValid();
    const isAfter = valueDate.isAfter(compareDate, 'date');
    if (validValue && isAfter) {
      return true;
    }
    return validationErrorMessages.after(date);
  },
});
