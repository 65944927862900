export const Mutations = {
  SET_ACADEMIC_HISTORY: 'setAcademicHistory',
};

export const Actions = {
  LOAD_ACADEMIC_HISTORY: 'loadAcademicHistory',
  UPDATE_ACADEMIC_HISTORY: 'updateAcademicHistory',
};

export const Getters = {
  ACADEMIC_HISTORY: 'academicHistory',
};
