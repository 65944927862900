export const hzbCountries = [
  { statisticKey: '000', label: 'Deutschland' },
  { statisticKey: '112', label: 'Gibraltar (Britisches Überseegebiet)' },
  { statisticKey: '113', label: 'Guernsey (Britisches Überseegebiet)' },
  { statisticKey: '114', label: 'Jersey (Britisches Überseegebiet)' },
  { statisticKey: '115', label: 'Insel Man (Britisches Überseegebiet)' },
  {
    statisticKey: '116',
    label:
      'Svalbard und Jan Mayen (u. a. Bäreninsel, Spitzbergen) (Norwegisches Überseegebiet)',
  },
  { statisticKey: '121', label: 'Albanien' },
  { statisticKey: '122', label: 'Bosnien und Herzegowina' },
  { statisticKey: '123', label: 'Andorra' },
  { statisticKey: '124', label: 'Belgien' },
  { statisticKey: '125', label: 'Bulgarien' },
  { statisticKey: '126', label: 'Dänemark' },
  { statisticKey: '127', label: 'Estland' },
  { statisticKey: '128', label: 'Finnland' },
  { statisticKey: '129', label: 'Frankreich, einschl. Korsika' },
  { statisticKey: '130', label: 'Kroatien' },
  { statisticKey: '131', label: 'Slowenien' },
  { statisticKey: '134', label: 'Griechenland' },
  { statisticKey: '135', label: 'Irland' },
  { statisticKey: '136', label: 'Island' },
  { statisticKey: '137', label: 'Italien' },
  { statisticKey: '139', label: 'Lettland' },
  { statisticKey: '140', label: 'Montenegro' },
  { statisticKey: '141', label: 'Liechtenstein' },
  { statisticKey: '142', label: 'Litauen' },
  { statisticKey: '143', label: 'Luxemburg' },
  { statisticKey: '144', label: 'Nordmazedonien' },
  { statisticKey: '145', label: 'Malta' },
  { statisticKey: '146', label: 'Republik Moldau (Moldawien)' },
  { statisticKey: '147', label: 'Monaco' },
  { statisticKey: '148', label: 'Niederlande' },
  { statisticKey: '149', label: 'Norwegen' },
  { statisticKey: '150', label: 'Kosovo' },
  { statisticKey: '151', label: 'Österreich' },
  { statisticKey: '152', label: 'Polen' },
  { statisticKey: '153', label: 'Portugal' },
  { statisticKey: '154', label: 'Rumänien' },
  { statisticKey: '155', label: 'Slowakei' },
  { statisticKey: '156', label: 'San Marino' },
  { statisticKey: '157', label: 'Schweden' },
  { statisticKey: '158', label: 'Schweiz' },
  { statisticKey: '160', label: 'Russische Föderation' },
  { statisticKey: '161', label: 'Spanien' },
  { statisticKey: '163', label: 'Türkei' },
  { statisticKey: '164', label: 'Tschechien' },
  { statisticKey: '165', label: 'Ungarn' },
  { statisticKey: '166', label: 'Ukraine' },
  { statisticKey: '167', label: 'Vatikanstadt' },
  {
    statisticKey: '168',
    label: 'Vereinigtes Königreich (Großbritannien und Nordirland)',
  },
  { statisticKey: '169', label: 'Belarus' },
  { statisticKey: '170', label: 'Serbien' },
  { statisticKey: '181', label: 'Zypern' },
  { statisticKey: '182', label: 'Färöer (Dänisches Überseegebiet)' },
  { statisticKey: '185', label: 'Britisches Überseegebiet ausserhalb Europas' },
  { statisticKey: '211', label: 'Mayotte (Französisches Überseegebiet)' },
  { statisticKey: '214', label: 'Réunion (Französisches Überseegebiet)' },
  {
    statisticKey: '216',
    label: 'Spanische Hoheitsplätze in Nordafrika (Spanisches Überseegebiet)',
  },
  { statisticKey: '221', label: 'Algerien' },
  { statisticKey: '223', label: 'Angola' },
  { statisticKey: '224', label: 'Eritrea' },
  { statisticKey: '225', label: 'Äthiopien' },
  { statisticKey: '226', label: 'Lesotho' },
  { statisticKey: '227', label: 'Botsuana' },
  { statisticKey: '229', label: 'Benin' },
  { statisticKey: '230', label: 'Dschibuti' },
  { statisticKey: '231', label: 'Côte d`Ivoire' },
  { statisticKey: '232', label: 'Nigeria' },
  { statisticKey: '233', label: 'Simbabwe' },
  { statisticKey: '236', label: 'Gabun' },
  { statisticKey: '237', label: 'Gambia' },
  { statisticKey: '238', label: 'Ghana' },
  { statisticKey: '239', label: 'Mauretanien' },
  { statisticKey: '242', label: 'Cabo Verde' },
  { statisticKey: '243', label: 'Kenia' },
  { statisticKey: '244', label: 'Komoren' },
  { statisticKey: '245', label: 'Kongo, Republik' },
  { statisticKey: '246', label: 'Kongo, Dem. Republik' },
  { statisticKey: '247', label: 'Liberia' },
  { statisticKey: '248', label: 'Libyen' },
  { statisticKey: '249', label: 'Madagaskar' },
  { statisticKey: '251', label: 'Mali' },
  { statisticKey: '252', label: 'Marokko' },
  { statisticKey: '253', label: 'Mauritius' },
  { statisticKey: '254', label: 'Mosambik' },
  { statisticKey: '255', label: 'Niger' },
  { statisticKey: '256', label: 'Malawi' },
  { statisticKey: '257', label: 'Sambia' },
  { statisticKey: '258', label: 'Burkina Faso' },
  { statisticKey: '259', label: 'Guinea-Bissau' },
  { statisticKey: '261', label: 'Guinea' },
  { statisticKey: '262', label: 'Kamerun' },
  { statisticKey: '263', label: 'Südafrika' },
  { statisticKey: '265', label: 'Ruanda' },
  { statisticKey: '267', label: 'Namibia' },
  { statisticKey: '268', label: 'São Tomé und Príncipe' },
  { statisticKey: '269', label: 'Senegal' },
  { statisticKey: '271', label: 'Seychellen' },
  { statisticKey: '272', label: 'Sierra Leone' },
  { statisticKey: '273', label: 'Somalia' },
  { statisticKey: '274', label: 'Äquatorialguinea' },
  { statisticKey: '277', label: 'Sudan' },
  { statisticKey: '278', label: 'Südsudan' },
  { statisticKey: '281', label: 'Eswatini' },
  { statisticKey: '282', label: 'Tansania, Vereinigte Republik' },
  { statisticKey: '283', label: 'Togo' },
  { statisticKey: '284', label: 'Tschad' },
  { statisticKey: '285', label: 'Tunesien' },
  { statisticKey: '286', label: 'Uganda' },
  { statisticKey: '287', label: 'Ägypten' },
  { statisticKey: '289', label: 'Zentralafrikanische Republik' },
  { statisticKey: '291', label: 'Burundi' },
  { statisticKey: '311', label: 'Aruba (Niederländisches Überseegebiet)' },
  {
    statisticKey: '315',
    label: 'Französisch-Guayana (Französisches Überseegebiet)',
  },
  {
    statisticKey: '316',
    label: 'Amerikanische Jungferninseln (US-Überseegebiet)',
  },
  { statisticKey: '317', label: 'Guadeloupe (Französisches Überseegebiet)' },
  { statisticKey: '319', label: 'Martinique (Französisches Überseegebiet)' },
  { statisticKey: '320', label: 'Antigua und Barbuda' },
  { statisticKey: '321', label: 'Curaçao (Niederländisches Überseegebiet)' },
  { statisticKey: '322', label: 'Barbados' },
  { statisticKey: '323', label: 'Argentinien' },
  { statisticKey: '324', label: 'Bahamas' },
  { statisticKey: '325', label: 'Puerto Rico (US-Überseegebiet)' },
  { statisticKey: '326', label: 'Bolivien' },
  { statisticKey: '327', label: 'Brasilien' },
  { statisticKey: '328', label: 'Guyana' },
  {
    statisticKey: '329',
    label: 'St. Barthélemy (Französisches Überseegebiet)',
  },
  { statisticKey: '330', label: 'Belize' },
  { statisticKey: '331', label: 'St. Martin (Französisches Überseegebiet)' },
  { statisticKey: '332', label: 'Chile' },
  { statisticKey: '333', label: 'Dominica' },
  { statisticKey: '334', label: 'Costa Rica' },
  { statisticKey: '335', label: 'Dominikanische Republik' },
  { statisticKey: '336', label: 'Ecuador, einschl. Galapagos-Inseln' },
  { statisticKey: '337', label: 'El Salvador' },
  {
    statisticKey: '338',
    label: 'St. Pierre und Miquelon (Französisches Überseegebiet)',
  },
  { statisticKey: '340', label: 'Grenada' },
  {
    statisticKey: '341',
    label:
      'St. Martin (niederländischer Teil) (Niederländisches Überseegebiet)',
  },
  { statisticKey: '342', label: 'Grönland (Dänisches Überseegebiet)' },
  { statisticKey: '343', label: 'Navassa (US-Überseegebiet)' },
  {
    statisticKey: '344',
    label: 'Bonaire, Saba, St. Eustatius (Niederländisches Überseegebiet)',
  },
  { statisticKey: '345', label: 'Guatemala' },
  { statisticKey: '346', label: 'Haiti' },
  { statisticKey: '347', label: 'Honduras' },
  { statisticKey: '348', label: 'Kanada' },
  { statisticKey: '349', label: 'Kolumbien' },
  { statisticKey: '351', label: 'Kuba' },
  { statisticKey: '352', label: 'Clipperton (Französisches Überseegebiet)' },
  { statisticKey: '353', label: 'Mexiko' },
  { statisticKey: '354', label: 'Nicaragua' },
  { statisticKey: '355', label: 'Jamaika' },
  { statisticKey: '357', label: 'Panama' },
  { statisticKey: '359', label: 'Paraguay' },
  { statisticKey: '361', label: 'Peru' },
  { statisticKey: '364', label: 'Suriname' },
  { statisticKey: '365', label: 'Uruguay' },
  { statisticKey: '366', label: 'St. Lucia' },
  { statisticKey: '367', label: 'Venezuela' },
  { statisticKey: '368', label: 'Vereinigte Staaten von Amerika, auch USA' },
  { statisticKey: '369', label: 'St. Vincent und die Grenadinen' },
  { statisticKey: '370', label: 'St. Kitts und Nevis' },
  { statisticKey: '371', label: 'Trinidad und Tobago' },
  { statisticKey: '411', label: 'Hongkong' },
  { statisticKey: '412', label: 'Macau' },
  { statisticKey: '421', label: 'Jemen' },
  { statisticKey: '422', label: 'Armenien' },
  { statisticKey: '423', label: 'Afghanistan' },
  { statisticKey: '424', label: 'Bahrain' },
  { statisticKey: '425', label: 'Aserbaidschan' },
  { statisticKey: '426', label: 'Bhutan' },
  { statisticKey: '427', label: 'Myanmar' },
  { statisticKey: '429', label: 'Brunei Darussalam' },
  { statisticKey: '430', label: 'Georgien' },
  { statisticKey: '431', label: 'Sri Lanka' },
  { statisticKey: '432', label: 'Vietnam' },
  { statisticKey: '434', label: 'Dem. Volksrepublik Korea' },
  { statisticKey: '436', label: 'Indien, einschl. Sikkim und Gôa' },
  { statisticKey: '437', label: 'Indonesien, einschl. Irian Jaya' },
  { statisticKey: '438', label: 'Irak' },
  { statisticKey: '439', label: 'Iran, Islamische Republik' },
  { statisticKey: '441', label: 'Israel' },
  { statisticKey: '442', label: 'Japan' },
  { statisticKey: '444', label: 'Kasachstan' },
  { statisticKey: '445', label: 'Jordanien' },
  { statisticKey: '446', label: 'Kambodscha' },
  { statisticKey: '447', label: 'Katar' },
  { statisticKey: '448', label: 'Kuwait' },
  { statisticKey: '449', label: 'Dem. Volksrepublik Laos' },
  { statisticKey: '450', label: 'Kirgisistan' },
  { statisticKey: '451', label: 'Libanon' },
  { statisticKey: '454', label: 'Malediven' },
  { statisticKey: '456', label: 'Oman' },
  { statisticKey: '457', label: 'Mongolei' },
  { statisticKey: '458', label: 'Nepal' },
  { statisticKey: '459', label: 'Palästinensische Gebiete' },
  { statisticKey: '460', label: 'Bangladesch' },
  { statisticKey: '461', label: 'Pakistan' },
  { statisticKey: '462', label: 'Philippinen' },
  { statisticKey: '465', label: 'Taiwan' },
  { statisticKey: '467', label: 'Republik Korea, auch Süd-Korea' },
  { statisticKey: '469', label: 'Vereinigte Arabische Emirate' },
  { statisticKey: '470', label: 'Tadschikistan' },
  { statisticKey: '471', label: 'Turkmenistan' },
  { statisticKey: '472', label: 'Saudi-Arabien' },
  { statisticKey: '474', label: 'Singapur' },
  { statisticKey: '475', label: 'Arabische Republik Syrien' },
  { statisticKey: '476', label: 'Thailand' },
  { statisticKey: '477', label: 'Usbekistan' },
  { statisticKey: '479', label: 'China' },
  { statisticKey: '482', label: 'Malaysia' },
  { statisticKey: '483', label: 'Timor-Leste' },
  { statisticKey: '499', label: 'Übriges Asien' },
  {
    statisticKey: '510',
    label: 'Heard und McDonaldinseln (Australisches Überseegebiet)',
  },
  {
    statisticKey: '511',
    label: 'Korallenmeerinseln (Australisches Überseegebiet)',
  },
  { statisticKey: '512', label: 'Kokosinseln (Australisches Überseegebiet)' },
  { statisticKey: '513', label: 'Neukaledonien (Französisches Überseegebiet)' },
  { statisticKey: '514', label: 'Nördliche Marianen (US-Überseegebiet)' },
  { statisticKey: '515', label: 'Norfolkinsel (Australisches Überseegebiet)' },
  { statisticKey: '517', label: 'Amerikanisch-Samoa (US-Überseegebiet)' },
  { statisticKey: '519', label: 'Tokelau (Neuseeländisches Überseegebiet)' },
  {
    statisticKey: '520',
    label: 'Wallis und Futuna (Französisches Überseegebiet)',
  },
  {
    statisticKey: '521',
    label: 'Weihnachtsinsel (Australisches Überseegebiet)',
  },
  { statisticKey: '522', label: 'Bouvetinsel (Norwegisches Überseegebiet)' },
  {
    statisticKey: '523',
    label:
      'Australien, einschl. Kokosinseln, Weihnachtsinsel und Norfolk-Inseln',
  },
  { statisticKey: '524', label: 'Salomonen' },
  {
    statisticKey: '525',
    label: 'Ashmore- und Cartierinseln (Australisches Überseegebiet)',
  },
  { statisticKey: '526', label: 'Fidschi' },
  { statisticKey: '527', label: 'Cookinseln' },
  {
    statisticKey: '528',
    label: 'Französisch-Polynesien (Französisches Überseegebiet)',
  },
  { statisticKey: '529', label: 'Guam (US-Überseegebiet)' },
  { statisticKey: '530', label: 'Kiribati' },
  { statisticKey: '531', label: 'Nauru' },
  { statisticKey: '532', label: 'Vánúatú' },
  { statisticKey: '533', label: 'Niue' },
  {
    statisticKey: '534',
    label: 'Kleinere Amerikanische Überseeinseln (US-Überseegebiet)',
  },
  {
    statisticKey: '535',
    label: 'Norwegisches Antarktis-Territorium (Norwegisches Überseegebiet)',
  },
  { statisticKey: '536', label: 'Neuseeland' },
  { statisticKey: '537', label: 'Palau' },
  { statisticKey: '538', label: 'Papua-Neuguinea' },
  { statisticKey: '540', label: 'Tuvalu' },
  { statisticKey: '541', label: 'Tonga' },
  {
    statisticKey: '542',
    label:
      'Französische Süd- und Antarktisgebiete (Französisches Überseegebiet)',
  },
  { statisticKey: '543', label: 'Samoa' },
  { statisticKey: '544', label: 'Marschallinseln' },
  { statisticKey: '545', label: 'Föderierte Staaten von Mikronesien' },
  {
    statisticKey: '546',
    label: 'Chilenische Antarktis (Chilenisches Überseegebiet)',
  },
  {
    statisticKey: '547',
    label: 'Australisches Antarktis-Territorium (Australisches Überseegebiet)',
  },
  {
    statisticKey: '548',
    label: 'Argentinische Antarktis (Argentinisches Überseegebiet)',
  },
  {
    statisticKey: '549',
    label:
      'Neuseeländische Antarktis: Ross-Nebengebiet (Neuseeländisches Überseegebiet)',
  },
  { statisticKey: '997', label: 'Staatenlos' },
  { statisticKey: '998', label: 'Ungeklärt' },
  { statisticKey: '999', label: 'Ohne Angabe' },
];
