export const universityEntranceCertificates = [
  {
    statisticKey: '03',
    label: 'Erwerb der HZB in Deutschland - Gymnasium (aHR)',
  },
  {
    statisticKey: '06',
    label: 'Erwerb der HZB in Deutschland - Gesamtschule (aHR)',
  },
  {
    statisticKey: '17',
    label:
      'Erwerb der HZB im Ausland - Erwerb an einer deutschen Schule im Ausland (aHR)',
  },
  {
    statisticKey: '18',
    label: 'Erwerb der HZB in Deutschland - Fachgymnasium (aHR)',
  },
  {
    statisticKey: '21',
    label: 'Erwerb der HZB in Deutschland - Berufsoberschule (aHR)',
  },
  {
    statisticKey: '27',
    label: 'Erwerb der HZB in Deutschland - Abendgymnasium/Kolleg (aHR)',
  },
  {
    statisticKey: '28',
    label: 'Erwerb der HZB in Deutschland - Fachoberschule (aHR)',
  },
  {
    statisticKey: '31',
    label: 'Erwerb der HZB in Deutschland - Studienkolleg (aHR)',
  },
  {
    statisticKey: '33',
    label:
      'Erwerb der HZB in Deutschland - Begabten-/Eignungsprüfung/Externenprüfung (aHR)',
  },
  {
    statisticKey: '34',
    label: 'Erwerb der HZB in Deutschland - Beruflich Qualifizierte (aHR)',
  },
  {
    statisticKey: '37',
    label: 'Erwerb der HZB in Deutschland - Sonstige Studienberechtigung (aHR)',
  },
  {
    statisticKey: '39',
    label: 'Erwerb der HZB im Ausland - Allgemeine Hochschulreife',
  },
  {
    statisticKey: '43',
    label: 'Erwerb der HZB in Deutschland - Fachgymnasium (fgHR)',
  },
  {
    statisticKey: '44',
    label:
      'Erwerb der HZB in Deutschland - Berufsoberschule/Fachakademie/Fach- und Ingenieurschule (fgHR)',
  },
  {
    statisticKey: '47',
    label:
      'Erwerb der HZB im Ausland - Erwerb an einer deutschen Schule im Ausland (fgHR)',
  },
  {
    statisticKey: '48',
    label: 'Erwerb der HZB in Deutschland - Fachoberschule (fgHR)',
  },
  {
    statisticKey: '51',
    label: 'Erwerb der HZB in Deutschland - Studienkolleg (fgHR)',
  },
  {
    statisticKey: '52',
    label: 'Erwerb der HZB in Deutschland - Begabten-/Eignungsprüfung (fgHR)',
  },
  {
    statisticKey: '53',
    label: 'Erwerb der HZB in Deutschland - Beruflich Qualifizierte (fgHR)',
  },
  {
    statisticKey: '55',
    label:
      'Erwerb der HZB in Deutschland - Sonstige Studienberechtigung (fgHR)',
  },
  {
    statisticKey: '59',
    label: 'Erwerb der HZB im Ausland - Fachgebundene Hochschulreife',
  },
  {
    statisticKey: '60',
    label: 'Erwerb der HZB in Deutschland - Gymnasium (FHR)',
  },
  {
    statisticKey: '62',
    label: 'Erwerb der HZB in Deutschland - Gesamtschule (FHR)',
  },
  {
    statisticKey: '64',
    label: 'Erwerb der HZB in Deutschland - Fachgymnasium (FHR)',
  },
  {
    statisticKey: '65',
    label:
      'Erwerb der HZB in Deutschland - Berufsoberschule, Fachakademie (FHR)',
  },
  {
    statisticKey: '66',
    label: 'Erwerb der HZB in Deutschland - Fachoberschule (FHR)',
  },
  {
    statisticKey: '67',
    label:
      'Erwerb der HZB im Ausland - Erwerb an einer deutschen Schule im Ausland (FHR)',
  },
  {
    statisticKey: '70',
    label: 'Erwerb der HZB in Deutschland - Abendgymnasium, Kolleg (FHR)',
  },
  {
    statisticKey: '71',
    label: 'Erwerb der HZB in Deutschland - Beruflich Qualifizierte (FHR)',
  },
  {
    statisticKey: '72',
    label: 'Erwerb der HZB in Deutschland - Berufsfachschule (FHR)',
  },
  {
    statisticKey: '73',
    label: 'Erwerb der HZB in Deutschland - Fachschule (FHR)',
  },
  {
    statisticKey: '76',
    label: 'Erwerb der HZB in Deutschland - Studienkolleg (FHR)',
  },
  {
    statisticKey: '77',
    label: 'Erwerb der HZB in Deutschland - Begabten-/Eignungsprüfung (FHR)',
  },
  {
    statisticKey: '78',
    label: 'Erwerb der HZB in Deutschland - Sonstige Studienberechtigung (FHR)',
  },
  {
    statisticKey: '79',
    label: 'Erwerb der HZB im Ausland - Fachhochschulreife',
  },
];
