import { ApiService } from '@epos/core-applets';

function replaceEmptyStringWithNull(obj) {
  for (const k in obj) {
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      replaceEmptyStringWithNull(obj[k]);
    } else {
      if (obj[k] === '') {
        obj[k] = null;
      }
    }
  }

  return obj;
}

export const ApiServiceWrapper = {
  post: async (url, payload) => {
    payload = replaceEmptyStringWithNull(payload);
    return ApiService.post(url, payload);
  },
  put: async (url, payload) => {
    payload = replaceEmptyStringWithNull(payload);
    return ApiService.put(url, payload);
  },
  get: async (url, config) => {
    return ApiService.get(url, config);
  },
  delete: async (url, config) => {
    return ApiService.delete(url, config);
  },
};
