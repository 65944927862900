export const healthInsurances = [
  {
    name: 'AOK - Die Gesundheitskasse für Niedersachsen',
    bn: '29720865',
  },
  {
    name: 'AOK - Die Gesundheitskasse in Hessen',
    bn: '45118687',
  },
  {
    name: 'AOK Baden-Württemberg',
    bn: '67450665',
  },
  {
    name: 'AOK Bayern - Die Gesundheitskasse',
    bn: '87880235',
  },
  {
    name: 'AOK Bremen/Bremerhaven',
    bn: '20012084',
  },
  {
    name: 'AOK Nordost - Die Gesundheitskasse',
    bn: '90235319',
  },
  {
    name: 'AOK NordWest - Die Gesundheitskasse',
    bn: '33526082',
  },
  {
    name: 'AOK PLUS - Die Gesundheitskasse für Sachsen und Thüringen',
    bn: '05174740',
  },
  {
    name: 'AOK Rheinland-Pfalz/Saarland-Die Gesundheitskasse',
    bn: '51605725',
  },
  {
    name: 'AOK Rheinland/Hamburg - Die Gesundheitskasse',
    bn: '34364249',
  },
  {
    name: 'AOK Sachsen-Anhalt - Die Gesundheitskasse',
    bn: '01029141',
  },
  {
    name: 'Audi BKK',
    bn: '82889062',
  },
  {
    name: 'BAHN-BKK',
    bn: '49003443',
  },
  {
    name: 'BARMER',
    bn: '42938966',
  },
  {
    name: 'Bertelsmann BKK',
    bn: '31323584',
  },
  {
    name: 'Betriebskrankenkasse Mobil',
    bn: '15517302',
  },
  {
    name: 'Betriebskrankenkasse PricewaterhouseCoopers',
    bn: '47307817',
  },
  {
    name: 'BIG direkt gesund',
    bn: '97141402',
  },
  {
    name: 'BKK Achenbach Buschhütten',
    bn: '41378433',
  },
  {
    name: 'BKK Akzo Nobel Bayern',
    bn: '71579930',
  },
  {
    name: 'BKK B. Braun Aesculap',
    bn: '47034975',
  },
  {
    name: 'BKK BPW Bergische Achsen KG',
    bn: '42039708',
  },
  {
    name: 'BKK Deutsche Bank AG',
    bn: '34401277',
  },
  {
    name: 'BKK Diakonie',
    bn: '31323686',
  },
  {
    name: 'BKK EUREGIO',
    bn: '30168049',
  },
  {
    name: 'BKK EVM',
    bn: '51980490',
  },
  {
    name: 'BKK EWE',
    bn: '26515319',
  },
  {
    name: 'BKK exklusiv',
    bn: '22178373',
  },
  {
    name: 'BKK Faber-Castell & Partner',
    bn: '86772584',
  },
  {
    name: 'BKK firmus',
    bn: '20156168',
  },
  {
    name: 'BKK Freudenberg',
    bn: '63922962',
  },
  {
    name: 'BKK GILDEMEISTER SEIDENSTICKER',
    bn: '31323802',
  },
  {
    name: 'BKK GRILLO-WERKE AG',
    bn: '35087667',
  },
  {
    name: 'BKK Groz-Beckert',
    bn: '60393261',
  },
  {
    name: 'BKK Herkules',
    bn: '47034953',
  },
  {
    name: 'BKK HMR',
    bn: '36916980',
  },
  {
    name: 'BKK KARL MAYER',
    bn: '48063096',
  },
  {
    name: 'BKK Linde',
    bn: '48698889',
  },
  {
    name: 'BKK MAHLE',
    bn: '67572537',
  },
  {
    name: 'BKK Melitta Plus',
    bn: '36916935',
  },
  {
    name: 'BKK Miele',
    bn: '67572537',
  },
  {
    name: 'BKK MTU',
    bn: '65710574',
  },
  {
    name: 'BKK PFAFF',
    bn: '51588416',
  },
  {
    name: 'BKK Pfalz',
    bn: '52598579',
  },
  {
    name: 'BKK ProVita',
    bn: '88571250',
  },
  {
    name: 'BKK Public',
    bn: '21488086',
  },
  {
    name: 'BKK Rieker.RICOSTA.Weisser',
    bn: '66626976',
  },
  {
    name: 'BKK RWE',
    bn: '16665321',
  },
  {
    name: 'BKK Salzgitter',
    bn: '21203214',
  },
  {
    name: 'BKK Scheufelen',
    bn: '61232758',
  },
  {
    name: 'BKK Schwarzwald-Baar-Heuberg',
    bn: '66614249',
  },
  {
    name: 'BKK STADT AUGSBURG',
    bn: '81211334',
  },
  {
    name: 'BKK Technoform',
    bn: '23446040',
  },
  {
    name: 'BKK Textilgruppe Hof',
    bn: '73170269',
  },
  {
    name: 'BKK VDN',
    bn: '37416328',
  },
  {
    name: 'BKK VerbundPlus',
    bn: '69785429',
  },
  {
    name: 'BKK Verkehrsbau Union (BKK VBU)',
    bn: '92644250',
  },
  {
    name: 'BKK Voralb',
    bn: '97352653',
  },
  {
    name: 'BKK Werra-Meissner',
    bn: '44037562',
  },
  {
    name: 'BKK Wirtschaft & Finanzen',
    bn: '46967693',
  },
  {
    name: 'BKK Würth',
    bn: '67161380',
  },
  {
    name: 'BKK ZF & Partner',
    bn: '697 532 66',
  },
  {
    name: 'BKK DürkoppAdler',
    bn: '31323799',
  },
  {
    name: 'BKK24',
    bn: '23709856',
  },
  {
    name: 'BMW BKK',
    bn: '87271125',
  },
  {
    name: 'Bosch BKK',
    bn: '67572593',
  },
  {
    name: 'Continentale Betriebskrankenkasse',
    bn: '33865367',
  },
  {
    name: 'Daimler Betriebskrankenkasse',
    bn: '68216980',
  },
  {
    name: 'DAK-Gesundheit',
    bn: '48698890',
  },
  {
    name: 'Debeka BKK',
    bn: '52156763',
  },
  {
    name: 'DIE BERGISCHE KRANKENKASSE',
    bn: '42039708',
  },
  {
    name: 'energie-Betriebskrankenkasse',
    bn: '29717581',
  },
  {
    name: 'Ernst & Young BKK',
    bn: '46939789',
  },
  {
    name: 'Handelskrankenkasse (hkk)',
    bn: '20013461',
  },
  {
    name: 'Heimat Krankenkasse',
    bn: '31209131',
  },
  {
    name: 'HEK - Hanseatische Krankenkasse',
    bn: '15031806',
  },
  {
    name: 'IKK - Die Innovationskasse',
    bn: '14228571',
  },
  {
    name: 'IKK Brandenburg und Berlin',
    bn: '90397224',
  },
  {
    name: 'IKK classic',
    bn: '01049203',
  },
  {
    name: 'IKK gesund plus',
    bn: '20013109',
  },
  {
    name: 'IKK Südwest',
    bn: '55811201',
  },
  {
    name: 'Kaufmännische Krankenkasse - KKH',
    bn: '29137937',
  },
  {
    name: 'KNAPPSCHAFT',
    bn: '98000006',
  },
  {
    name: 'Koenig & Bauer BKK',
    bn: '75925585',
  },
  {
    name: 'Krones BKK',
    bn: '74157435',
  },
  {
    name: 'Merck BKK',
    bn: '44377882',
  },
  {
    name: 'mhplus Betriebskrankenkasse',
    bn: '63494759',
  },
  {
    name: 'Novitas BKK',
    bn: '351 340 22',
  },
  {
    name: 'pronova BKK',
    bn: '15872672',
  },
  {
    name: 'R+V Betriebskrankenkasse',
    bn: '48944809',
  },
  {
    name: 'Salus BKK',
    bn: '44953697',
  },
  {
    name: 'SECURVITA BKK',
    bn: '15517482',
  },
  {
    name: 'SIEMAG BKK',
    bn: '41378558',
  },
  {
    name: 'Siemens-Betriebskrankenkasse (SBK)',
    bn: '87954699',
  },
  {
    name: 'SKD BKK',
    bn: '74773896',
  },
  {
    name: 'SVLFG, Landw. Krankenkasse, Bayreuth',
    bn: '72360029',
  },
  {
    name: 'SVLFG, Landw. Krankenkasse, Darmstadt',
    bn: '47068420',
  },
  {
    name: 'SVLFG, Landw. Krankenkasse, Hannover',
    bn: '29147110',
  },
  {
    name: 'SVLFG, Landw. Krankenkasse, Kassel',
    bn: '47069693',
  },
  {
    name: 'SVLFG, Landw. Krankenkasse, Kiel',
    bn: '13199426',
  },
  {
    name: 'SVLFG, Landw. Krankenkasse, Landshut',
    bn: '87119868',
  },
  {
    name: 'SVLFG, Landw. Krankenkasse, Münster',
    bn: '39873587',
  },
  {
    name: 'SVLFG, Landw. Krankenkasse, Stuttgart',
    bn: '67575619',
  },
  {
    name: 'BKK Bundesverband',
    bn: '35382142',
  },
  {
    name: 'Südzucker BKK',
    bn: '62332660',
  },
  {
    name: 'Techniker Krankenkasse',
    bn: '15027365',
  },
  {
    name: 'TUI BKK',
    bn: '29074470',
  },
  {
    name: 'VIACTIV Krankenkasse',
    bn: '40180080',
  },
  {
    name: 'vivida bkk',
    bn: '664 584 77',
  },
  {
    name: 'Wieland BKK',
    bn: '68659646',
  },
  {
    name: 'WMF Betriebskrankenkasse',
    bn: '61232769',
  },
];
