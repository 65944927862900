export const sexes = [
  {
    referenceId: 'm',
    label: 'männlich',
  },
  {
    referenceId: 'f',
    label: 'weiblich',
  },
  {
    referenceId: 'x',
    label: 'divers',
  },
];
