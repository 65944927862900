<template>
  <div class="grid grid-cols-12 items-center py-1 gap-x-4">
    <div v-show="false" class="col-span-1 pl-2">
      <e-checkbox
        :id="`select-${result.id}`"
        v-model="isSelected"
        disabled
        check-value="true"
        :aria-label="ariaLabel"
        @change.prevent="toggleSelection"
      >
      </e-checkbox>
    </div>
    <div class="col-span-4 pl-2">
      <p class="text-xs mb-0 text-gray-700">
        {{ getGender }}
      </p>
      <p class="font-bold mb-0">
        {{ result.givenName }} {{ result.familyName }} {{ getBirthName }}
      </p>
    </div>
    <div class="col-span-2">
      <p class="text-xs mb-0 text-gray-700">Matrikelnummer</p>
      <p class="mb-0">
        {{ result.matriculationNumber || '-' }}
      </p>
    </div>
    <div class="col-span-5">
      <p class="mb-0 text-xs text-gray-700">Studiengang</p>
      <p class="mb-0">
        {{ result.studyProgram || '-' }}
      </p>
    </div>
  </div>
</template>

<script>
import { ECheckbox } from '@careerpartner/nitro';
import selectLists from '@/mixins/selectLists';

export default {
  name: 'SearchResult',
  components: {
    ECheckbox,
  },
  mixins: [selectLists],
  props: {
    result: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  // TODO use i18n
  computed: {
    getGender() {
      return (
        this.selectLists.sexes.find(
          (item) => item.referenceId === String(this.result.sex),
        )?.label || ''
      );
    },
    getBirthName() {
      return this.result.birthName ? `(${this.result.birthName})` : '';
    },
    ariaLabel() {
      return this.isSelected ? 'Profil abwählen' : 'Profil auswählen';
    },
  },
  methods: {
    toggleSelection() {
      this.$emit('toggle-selection', this.result.id);
    },
    deleteResult() {
      this.$emit('delete', this.result);
    },
  },
};
</script>
