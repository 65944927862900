<template>
  <div>
    <e-modal
      auto-height
      size="lg"
      :is-open="overlayVisible"
      display-close-button
      :title="currentModalTitle"
      @close="closeOverlay"
    >
      <edit-email-form
        v-model="currentContactPoint"
        @[OVERLAY_EVENTS.CLOSE]="toggleOverlay"
        @[OVERLAY_EVENTS.SUBMIT]="submitEmail"
      ></edit-email-form>
    </e-modal>
    <div>
      <h4 class="font-bold text-sm">E-Mail</h4>
      <e-contact-group>
        <e-contact-detail
          v-for="email in emails"
          :key="email.id"
          :is-primary="isPrimary(email)"
        >
          <template #title>
            <h5 data-test="profile__email-type">
              {{ EMAIL_TYPES[email.contactType] || EMAIL_TYPES.default }}
            </h5>
          </template>
          <template #tags>
            <e-chip
              v-for="tag in tags(email)"
              :key="tag"
              default-style
              data-test="profile__email-tags"
            >
              {{ tag }}
            </e-chip>
          </template>
          <template #data>
            <p class="mt-1" data-test="profile__email-display">
              {{ email.email }}
            </p>
          </template>
          <template #actions>
            <e-menu
              :actions="actions(email)"
              align-right
              @onEdit="editContact(CONTACT_POINTS.EMAIL, email)"
              @setAsPrimary="setAsPrimary(email)"
              @onDelete="deleteContact(CONTACT_POINTS.EMAIL, email)"
            >
              <template #activator="{ interact }">
                <e-button
                  variant="text"
                  small
                  data-test="profile__email-manage"
                  @click="interact"
                  ><e-icon icon="mdi-chevron-down"></e-icon>Verwalten</e-button
                >
              </template>
            </e-menu>
          </template>
        </e-contact-detail>
      </e-contact-group>
      <e-button
        variant="text"
        class="mt-4"
        data-test="profile__add-email"
        @click="add(CONTACT_POINTS.EMAIL)"
      >
        <e-icon icon="mdi-plus" />
        E-Mail hinzufügen
      </e-button>
    </div>
  </div>
</template>

<script>
import EditEmailForm from '@/components/overlays/EditEmailForm';
import ContactPointMixin from '@/components/Profile/Contact/ContactPointMixin';

export default {
  name: 'EmailComponent',
  components: {
    EditEmailForm,
  },
  mixins: [ContactPointMixin],
  methods: {
    actions(email) {
      return [
        {
          id: 'onEdit',
          text: 'bearbeiten',
          disabled: email.contactType === 'iu',
        },
        {
          id: 'setAsPrimary',
          text: 'für Korrespondenz festlegen',
          disabled: this.isPrimary(email),
        },
        {
          id: 'onDelete',
          text: 'löschen',
          disabled: this.isPrimary(email) || email.contactType === 'iu',
        },
      ];
    },
    setAsPrimary(email) {
      const emailDirty = { ...email };
      emailDirty.usageType.push('primary');
      this.submitEmail({ email: emailDirty });
    },
    submitEmail({ email }) {
      if (this.currentIsNew) {
        if (this.emails.length === 0) {
          email.usageType.push('primary');
        }

        this.saveContactPoint({
          contactPoint: email,
          target: this.CONTACT_POINTS.EMAIL,
        });
      } else {
        this.updateContactPoint({
          contactPoint: email,
          target: this.CONTACT_POINTS.EMAIL,
        });
      }
    },
  },
};
</script>
