export const interruptions = [
  {
    statisticKey: '01',
    label: 'Mutterschutz (Schwangerschaft) und Elternzeit',
  },
  { statisticKey: '02', label: 'Kinderbetreuung' },
  { statisticKey: '03', label: 'andere familiäre Gründe' },
  { statisticKey: '04', label: 'Praktikum im Inland' },
  {
    statisticKey: '05',
    label: 'Auslandsaufenthalt (einschl. Praktikum im Ausland)',
  },
  { statisticKey: '06', label: 'Freiwilligendienst' },
  { statisticKey: '07', label: 'Erwerbstätigkeit' },
  { statisticKey: '08', label: 'Krankheit' },
];
