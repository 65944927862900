<template>
  <div>
    <e-modal
      auto-height
      size="lg"
      :is-open="overlayVisible"
      display-close-button
      :title="currentModalTitle"
      @close="closeOverlay"
    >
      <edit-phone-form
        v-model="currentContactPoint"
        @[OVERLAY_EVENTS.CLOSE]="toggleOverlay"
        @[OVERLAY_EVENTS.SUBMIT]="submitPhone"
      ></edit-phone-form>
    </e-modal>
    <div>
      <h4 class="font-bold text-sm">Telefon</h4>
      <e-contact-group>
        <e-contact-detail
          v-for="phone in phones"
          :key="phone.id"
          :is-primary="isPrimary(phone)"
        >
          <template #title>
            <h5 data-test="profile__phone-type">
              {{ PHONE_TYPES[phone.contactType] || PHONE_TYPES.default }}
            </h5>
          </template>
          <template #tags>
            <e-chip
              v-for="tag in tags(phone)"
              :key="tag"
              default-style
              data-test="profile__phone-tags"
            >
              {{ tag }}
            </e-chip>
          </template>
          <template #data>
            <p class="mt-1" data-test="profile__phone-display">
              {{ phone.phone }}
            </p>
          </template>
          <template #actions>
            <e-menu
              :actions="actions(phone)"
              align-right
              @onEdit="editContact(CONTACT_POINTS.PHONE, phone)"
              @setAsPrimary="setAsPrimary(phone)"
              @onDelete="deleteContact(CONTACT_POINTS.PHONE, phone)"
            >
              <template #activator="{ interact }">
                <e-button variant="text" small @click="interact"
                  ><e-icon icon="mdi-chevron-down"></e-icon>Verwalten</e-button
                >
              </template>
            </e-menu>
          </template>
        </e-contact-detail>
      </e-contact-group>
      <e-button
        variant="text"
        class="mt-4"
        data-test="profile__add-phone"
        @click="add(CONTACT_POINTS.PHONE)"
      >
        <e-icon icon="mdi-plus" />
        Telefonnummer hinzufügen
      </e-button>
    </div>
  </div>
</template>

<script>
import EditPhoneForm from '@/components/overlays/EditPhoneForm';
import ContactPointMixin from '@/components/Profile/Contact/ContactPointMixin';

export default {
  name: 'PhoneComponent',
  components: {
    EditPhoneForm,
  },
  mixins: [ContactPointMixin],
  methods: {
    actions(phone) {
      return [
        {
          id: 'onEdit',
          text: 'bearbeiten',
          disabled: false,
        },
        {
          id: 'setAsPrimary',
          text: 'für Korrespondenz festlegen',
          disabled: this.isPrimary(phone),
        },
        {
          id: 'onDelete',
          text: 'löschen',
          disabled: this.isPrimary(phone),
        },
      ];
    },
    setAsPrimary(phone) {
      const phoneDirty = { ...phone };
      phoneDirty.usageType.push('primary');
      this.submitPhone({ phone: phoneDirty });
    },
    submitPhone({ phone }) {
      if (this.currentIsNew) {
        if (this.phones.length === 0) {
          phone.usageType.push('primary');
        }

        this.saveContactPoint({
          contactPoint: phone,
          target: this.CONTACT_POINTS.PHONE,
        });
      } else {
        this.updateContactPoint({
          contactPoint: phone,
          target: this.CONTACT_POINTS.PHONE,
        });
      }
    },
  },
};
</script>
