import Vue from 'vue';
import VueRouter from 'vue-router';
import { Routes } from '@epos/core-applets';
import Profile from '../views/Profile';
import Statistics from '../views/Statistics';
import CustomerSearch from '@/views/CustomerSearch';

Vue.use(VueRouter);

const routes = [
  {
    path: Routes.CUSTOMER_SERVICES.BASE,
    redirect: { name: 'search' },
  },
  {
    path: Routes.CUSTOMER_SERVICES.SEARCH,
    component: CustomerSearch,
    name: 'search',
    meta: {
      title: 'Suche - Kundenverwaltung',
      headline: 'Kundenverwaltung',
      subheadline: 'Suche',
    },
  },
  {
    name: 'profile',
    path: Routes.CUSTOMER_SERVICES.PROFILE,
    component: Profile,
    meta: {
      title: 'Profil - Kundenverwaltung',
      headline: 'Kundenprofil',
    },
  },
  {
    name: 'statistics',
    path: Routes.CUSTOMER_SERVICES.STATISTICS,
    component: Statistics,
    meta: {
      title: 'Statistik - Kundenverwaltung',
      headline: 'Kundenprofil',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
