<template>
  <section-structure>
    <e-modal
      :is-open="certificationModalVisible"
      auto-height
      size="lg"
      @close="closeCertificationModal"
    >
      <certify-university-qualification
        @[OVERLAY_EVENTS.CLOSE]="closeCertificationModal"
        @[OVERLAY_EVENTS.SUBMIT]="reloadHZB"
      />
    </e-modal>
    <template #headline> Hochschulzugangsberechtigung </template>

    <div class="col-span-full">
      <validation-provider name="HZB | Typ" slim>
        <e-radio-group
          v-model="qualificationSource"
          name="qualificationSource"
          label="Typ der Hochschulzugangsberechtigung"
          data-test="profile__hzb-source"
          :options="[
            {
              label: 'Hochschulreife',
              value: 'general',
            },
            { label: 'Berufspraktische Tätigkeit', value: 'work' },
          ]"
        />
      </validation-provider>
    </div>
    <div class="col-span-full">
      <validation-provider name="HZB | Art" slim>
        <e-multiselect
          id="typeOfCertificate"
          v-model="qualificationType"
          data-test="profile__hzb-type"
          label="Art der Hochschulzugangsberechtigung"
          :options="selectLists.universityEntranceCertificates"
          track-by="statisticKey"
          option-label="label"
          mapped
          searchable
        />
      </validation-provider>
    </div>
    <template v-if="!qualificationSource || qualificationSource === 'general'">
      <template v-if="country === '000'">
        <div class="col-span-3">
          <validation-provider name="HZB | Bundesland" slim>
            <e-multiselect
              id="certificateFederalstate"
              v-model="federalState"
              data-test="profile__hzb-federalstate"
              label="Bundesland"
              :options="selectLists.federalStates"
              option-label="label"
              track-by="statisticKey"
              mapped
              searchable
            />
          </validation-provider>
        </div>
        <div class="col-span-3">
          <validation-provider name="HZB | Kreis/Stadt" slim>
            <e-multiselect
              id="certificateLocality"
              v-model="locality"
              data-test="profile__hzb-locality"
              label="Kreis/Stadt"
              :options="districtsFiltered"
              option-label="label"
              track-by="statisticKey"
              mapped
              searchable
            />
          </validation-provider>
        </div>
      </template>
      <template v-else>
        <div class="col-span-full">
          <validation-provider
            name="HZB | Land"
            :rules="country && country !== '000' ? 'required' : ''"
            immediate
            :skip-if-empty="false"
          >
            <e-multiselect
              id="certificateCountry"
              v-model="country"
              data-test="profile__hzb-country"
              label="Land"
              :options="countriesWithoutGermany"
              option-label="label"
              track-by="statisticKey"
              mapped
              searchable
            />
          </validation-provider>
        </div>
      </template>

      <div class="col-span-3">
        <validation-provider name="HZB | Jahr des Erwerbs" slim>
          <e-datepicker
            id="certificateYearIssued"
            v-model="parseYearIssued"
            data-test="profile__hzb-year"
            label="Jahr des Erwerbs"
            picker-type="year"
            format="YYYY"
            :min-date="calculateMinGradDate"
            :max-date="calculateMaxGradDate"
          />
        </validation-provider>
      </div>
    </template>
    <template v-else-if="qualificationSource === 'work'">
      <div class="col-span-full">
        <validation-provider
          name="HZB | Typ der berufspraktischen Tätigkeit"
          slim
        >
          <e-radio-group
            v-model="qualificationWorkType"
            name="qualificationWorkType"
            label="Typ der berufspraktischen Tätigkeit"
            data-test="profile__qualificationType"
            :options="[
              {
                label: 'Berufsausbildung mit Abschluss',
                value: 'vocationWithGraduation',
              },
              { label: 'Praktikum oder Volontariat', value: 'workExperience' },
            ]"
          />
        </validation-provider>
      </div>
      <div class="col-span-full">
        <validation-provider
          v-slot="{ errors }"
          rules="max:200"
          name="HZB | Beschreibung der berufspraktischen Tätigkeit"
          slim
        >
          <e-text-field
            id="qualificationWorkDesc"
            v-model="qualificationWorkDescription"
            data-test="profile__hzb-workdescription"
            :errors="errors"
            label="Beschreibung der berufspraktischen Tätigkeit"
          ></e-text-field>
        </validation-provider>
      </div>
    </template>

    <div
      v-if="isClickAndStudyDisabled"
      class="col-span-full flex flex-col items-start"
    >
      <div class="flex">
        <p>Beglaubigung der Hochschulzugangsberechtigung</p>
        <e-chip
          :class="chipStatusColor"
          text-classes="text-white uppercase text-xs font-bold"
          class="ml-2"
        >
          {{ hasCertification ? 'Beglaubigt' : 'Ausstehend' }}
        </e-chip>
      </div>
      <p v-show="hasCertification" class="text-sm text-gray-700">
        beglaubigt am {{ certificationDate }}
      </p>
      <e-button
        v-show="!hasCertification"
        variant="secondary"
        class="mt-8"
        :disabled="!qualificationType"
        @click="showCertificationModal"
      >
        Beglaubigung liegt vor
      </e-button>
    </div>
  </section-structure>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import SectionStructure from '@/components/SectionStructure';
import {
  EButton,
  EChip,
  EModal,
  ETextField,
  EDatepicker,
  EMultiselect,
  ERadioGroup,
} from '@careerpartner/nitro';
import selectLists from '@/mixins/selectLists';
import { ValidationProvider } from 'vee-validate';
import CertifyUniversityQualification from '@/components/overlays/CertifyUniversityQualification';
import { OVERLAY_EVENTS, FEATURES } from '@/common/constants';
import {
  Actions as ProfileActions,
  Getters as ProfileGetters,
} from '@/store/modules/profile/types';
import { Actions as AppActions } from '@/store/modules/app/types';
import dayjs from 'dayjs';
import { isInactive } from '@/utils/feature-config';

export default {
  name: 'UniversityEntranceQualification',
  components: {
    CertifyUniversityQualification,
    SectionStructure,
    EButton,
    EChip,
    EModal,
    EDatepicker,
    ERadioGroup,
    EMultiselect,
    ETextField,
    ValidationProvider,
  },
  mixins: [selectLists],
  data() {
    return {
      certificationModalVisible: false,
    };
  },
  computed: {
    ...mapFields('profile', [
      'universityEntranceQualification.qualificationSource',
      'universityEntranceQualification.qualificationType',
      'universityEntranceQualification.qualificationWorkType',
      'universityEntranceQualification.qualificationWorkDescription',
      'universityEntranceQualification.federalState',
      'universityEntranceQualification.country',
      'universityEntranceQualification.locality',
      'universityEntranceQualification.yearIssued',
      'universityEntranceQualification.certifiedOn',
      'person.birthDate',
    ]),
    parseYearIssued: {
      get() {
        return this.yearIssued ? String(this.yearIssued) : null;
      },
      set(val) {
        this.yearIssued = Number(val) || null;
      },
    },
    certificationDate() {
      return this.hasCertification
        ? dayjs(this.certifiedOn).format('DD.MM.YYYY')
        : '';
    },
    calculateMinGradDate() {
      if (!this.birthDate) return '1950';

      return dayjs(this.birthDate).add(14, 'years').format('YYYY');
    },
    calculateMaxGradDate() {
      return dayjs().add(1, 'year').format('YYYY');
    },
    countriesWithoutGermany() {
      return this.selectLists.hzbCountries.filter(
        (country) => country.statisticKey !== '000',
      );
    },
    districtsFiltered() {
      // filter the districts list by the federal state they are in
      if (this.federalState) {
        return this.selectLists.districts.filter(
          (district) =>
            district.statisticKey.substr(0, 2) === this.federalState,
        );
      } else {
        return this.selectLists.districts;
      }
    },
    hasCertification() {
      return this.$store.getters[`profile/${ProfileGetters.HAS_CERTIFIED_HZB}`];
    },
    chipStatusColor() {
      if (this.hasCertification) {
        return 'bg-green-600';
      } else {
        return 'bg-orange-600';
      }
    },
    OVERLAY_EVENTS() {
      return OVERLAY_EVENTS;
    },
    isClickAndStudyDisabled() {
      return isInactive(FEATURES.CLICK_AND_STUDY);
    },
  },
  watch: {
    qualificationType(newQualificationType) {
      if (this.isQualificationTypeInGermany(newQualificationType)) {
        this.country = '000';
      } else {
        this.country = null;
      }
    },
    country(newCountry) {
      if (newCountry !== '000') {
        this.federalState = null;
        this.locality = null;
      }
    },
  },
  methods: {
    closeCertificationModal() {
      this.certificationModalVisible = false;
    },
    showCertificationModal() {
      this.certificationModalVisible = true;
    },
    isQualificationTypeInGermany(statisticsKey) {
      if (!statisticsKey) {
        return false;
      }
      const list = this.selectLists.universityEntranceCertificates?.filter(
        (item) => item.statisticKey === statisticsKey,
      );
      if (list?.length) {
        return list[0].label.includes('Deutschland');
      }
      return false;
    },
    async reloadHZB() {
      try {
        const id = this.$store.getters[`profile/${ProfileGetters.PROFILE_ID}`];

        await this.$store.dispatch(
          `profile/${ProfileActions.LOAD_UNIVERSITY_ENTRANCE_QUALIFICATION}`,
          id,
        );

        await this.$store.dispatch(
          `app/${AppActions.UPDATE_SNACKBAR}`,
          { message: 'Beglaubigung erfolgreich bestätigt.' },
          { root: true },
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.closeCertificationModal();
      }
    },
  },
};
</script>
