export const permitTypes = [
  { key: 'Aufenthaltserlaubnis', value: 'Aufenthaltserlaubnis' },
  { key: 'Blaue Karte EU', value: 'Blaue Karte EU' },
  { key: 'ICT Karte', value: 'ICT Karte' },
  { key: 'Mobile ICT Karte', value: 'Mobile ICT Karte' },
  {
    key: 'Erlaubnis zum Daueraufenthalt-EU',
    value: 'Erlaubnis zum Daueraufenthalt-EU',
  },
  { key: 'Visum', value: 'Visum' },
  { key: 'Niederlassungserlaubnis', value: 'Niederlassungserlaubnis' },
];
