<template>
  <div class="flex flex-col gap-y-2">
    <form
      v-if="!hideFilter"
      class="flex"
      @submit.prevent="$emit('update-filter-data', { firstName, lastName })"
    >
      <e-text-field
        id="filter1"
        v-model="firstName"
        label="Vorname"
        class="flex-grow rounded-r-none pr-2"
      />
      <e-text-field
        id="filter2"
        v-model="lastName"
        label="Nachname"
        class="flex-grow rounded-r-none pl-2"
      />
      <e-button action-type="submit" class="round-l-none" variant="primary">
        <e-icon icon="mdi-magnify" class="mx-4" />
      </e-button>
    </form>
    <e-table :data="data" :is-loading="isLoading" :header-items="headerItems">
      <template #row="props">
        <slot name="row" v-bind="props">
          <tr>
            <td v-for="(value, name) in props.item" :key="name">
              {{ value }}
            </td>
          </tr>
        </slot>
      </template>
    </e-table>
    <e-pagination v-model="innerPagination" />
  </div>
</template>

<script>
import {
  ETable,
  ETextField,
  EIcon,
  EButton,
  EPagination,
} from '@careerpartner/nitro';

export default {
  name: 'RichTable',
  components: { ETable, ETextField, EIcon, EButton, EPagination },

  props: {
    data: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: false },
    headerItems: {
      type: Array,
      required: true,
    },
    hideFilter: { type: Boolean, default: false },
    filterData: {
      type: Object,
      default: () => ({ firstName: '', lastName: '' }),
    },
    pagination: {
      type: Object,
      default: () => ({ limit: 20, offset: 0, totalcount: 0 }),
    },
  },

  data() {
    return {
      firstName: '',
      lastName: '',
    };
  },

  computed: {
    innerPagination: {
      get() {
        return this.pagination;
      },
      set(newPagination) {
        this.$emit('pagination', newPagination);
      },
    },
  },
  watch: {
    filterData(newFilterData) {
      this.lastName = newFilterData.lastName;
      this.firstName = newFilterData.firstName;
    },
  },
};
</script>
