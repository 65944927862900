export default () => ({
  loadingCounter: 0,
  hasFetched: false,
  locale: 'de-DE', // TODO get locale from i18n
  snackbar: {
    message: '',
    error: false,
  },
  selectLists: null,
  errors: [],
});
