<template>
  <validation-observer
    ref="phone-form"
    v-slot="{ invalid }"
    vid="phone-form"
    slim
  >
    <form
      class="grid grid-cols-12 gap-x-6 gap-y-7 mt-9 px-4 pb-8"
      data-test="profile__phone-modal"
    >
      <div class="col-span-6">
        <validation-provider
          v-slot="{ errors }"
          name="Art der Telefonnummer"
          rules="required"
          slim
        >
          <e-multiselect
            id="phone-contacttype"
            v-model="phone.contactType"
            data-test="profile__phone-edit-type"
            :options="PHONE_TYPES"
            :errors="errors"
            label="Art der Telefonnummer"
            option-label="label"
            track-by="key"
            required
            mapped
          />
        </validation-provider>
      </div>
      <div class="col-span-6"></div>
      <div class="col-span-4">
        <validation-provider
          v-slot="{ errors }"
          vid="prefix"
          name="Ländervorwahl mit +"
          rules="required|phonePrefixWithPlus|phonePrefixAllowedEntry"
          slim
        >
          <e-text-field
            id="phone-prefix"
            v-model="phone.prefix"
            data-test="profile__phone-edit-prefix"
            :errors="errors"
            required
            label="Ländervorwahl"
            wide
          />
        </validation-provider>
      </div>
      <div class="col-span-8">
        <validation-provider
          v-slot="{ errors }"
          name="Telefonnummer"
          slim
          :rules="{
            required: true,
            numeric: true,
            phoneValidator: {
              value: '@phone',
              prefix: '@prefix',
            },
          }"
        >
          <e-text-field
            id="phone-number"
            v-model="phone.phone"
            data-test="profile__phone-edit-phone"
            label="Telefonnummer"
            wide
            required
            :errors="errors"
          />
        </validation-provider>
      </div>
      <p class="mt-2 text-xs text-gray-700 col-span-full">* Pflichtfelder</p>
      <last-changed-by
        :updated-at="phone.updatedAt"
        :updated-by="phone.updatedBy"
      />

      <div class="col-span-full flex h-9">
        <e-button
          variant="primary"
          :disabled="invalid"
          class="ml-auto mt-18"
          @click="savePhone"
        >
          <e-icon icon="mdi-content-save-outline" class="mr-2"></e-icon>
          Speichern</e-button
        >
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { parsePhoneNumber } from 'libphonenumber-js';
import EditModalMixin from '@/components/overlays/EditModalMixin';
import { OVERLAY_EVENTS } from '@/common/constants';
import LastChangedBy from '@/components/overlays/shared/LastChangedBy';

export default {
  name: 'PhoneOverlay',
  components: { LastChangedBy },
  mixins: [EditModalMixin],
  watch: {
    phone: {
      handler(val) {
        this.parsePhone(val);
      },
      deep: true,
    },
  },
  beforeMount() {
    if (this.value) {
      this.phone = JSON.parse(JSON.stringify(this.value));

      if (!this.value.contactType) {
        this.phone.contactType = 'default';
      }

      if (this.value.phone?.length) {
        this.parsePhone(this.value);
      }
    }
  },
  methods: {
    parsePhone(val) {
      if ([0, '0'].includes(val.phone.charAt(0))) {
        this.phone.phone = this.phone.phone.replace('0', '+49');
      }

      if (val.phone.charAt(0) === '+') {
        const parsedPhone = parsePhoneNumber(this.phone.phone);
        this.phone.prefix = `+${parsedPhone.countryCallingCode}`;
        this.phone.phone = parsedPhone.nationalNumber;
      } else {
        this.phone.phone = val.phone;
      }
    },
    async savePhone() {
      if (this.phone.phone.charAt(0) === '0') {
        this.phone.phone.replace('0', '');
      }

      if (await this.validateForm('phone-form')) {
        const phoneDirty = { ...this.phone };
        // put number back together with prefix and actual number
        phoneDirty.phone = `${this.phone.prefix}${this.phone.phone}`;
        this.$emit(OVERLAY_EVENTS.SUBMIT, {
          phone: phoneDirty,
        });
      }

      this.closeOverlay();
    },
  },
};
</script>
