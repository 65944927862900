import { Actions, Getters, Mutations } from '../types';
import { ApiServiceWrapper } from '@/utils/apiServiceWrapper';
import { API_ENDPOINTS } from '@/common/constants';

export default {
  async [Actions.LOAD_IDENTITY_MANAGEMENT]({ commit }, id) {
    const CUSTOMER_DETAIL_URL = `${API_ENDPOINTS.CUSTOMER_PROFILES}/${id}/identitymanagement`;
    const { data } = await ApiServiceWrapper.get(CUSTOMER_DETAIL_URL);
    commit(Mutations.SET_IDENTITY_MANAGEMENT, data);
  },
  async [Actions.SAVE_IDENTITY_MANAGEMENT]({ getters, commit }) {
    const { identityDocument, residencePermit } = getters[Getters.PROFILE];
    const id = getters[Getters.PROFILE_ID];

    if (!identityDocument.validUntil) {
      identityDocument.validUntil = null;
    }
    if (!identityDocument.arrivalOnCampus) {
      identityDocument.arrivalOnCampus = null;
    }
    if (!residencePermit.validUntil) {
      residencePermit.validUntil = null;
    }

    const payload = {
      identityDocument,
      residencePermit,
    };

    const PUT_URL = `${API_ENDPOINTS.CUSTOMER_PROFILES}/${id}/identitymanagement`;

    const { data } = await ApiServiceWrapper.put(PUT_URL, payload);

    commit(Mutations.SET_IDENTITY_MANAGEMENT, data);
  },
};
