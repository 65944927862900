<template>
  <section-structure>
    <template #headline>Kontaktdaten</template>
    <div class="col-span-full">
      <contact-addresses />
      <contact-emails class="mt-14" />
      <contact-phones class="mt-14" />
    </div>
  </section-structure>
</template>

<script>
import ContactAddresses from '@/components/Profile/Contact/ContactAddresses';
import ContactEmails from '@/components/Profile/Contact/ContactEmails';
import ContactPhones from '@/components/Profile/Contact/ContactPhones';
import SectionStructure from '@/components/SectionStructure';

export default {
  name: 'ContactInfo',
  components: {
    SectionStructure,
    ContactPhones,
    ContactEmails,
    ContactAddresses,
  },
};
</script>
