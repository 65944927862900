import { Actions, Getters, Mutations } from '../types';
import { Actions as AppActions } from '@/store/modules/app/types';
import { ApiServiceWrapper } from '@/utils/apiServiceWrapper';
import { API_ENDPOINTS, CONTACT_POINTS } from '@/common/constants';

export default {
  async [Actions.LOAD_CONTACT_POINTS]({ dispatch }, id) {
    await Promise.all([
      dispatch(Actions.LOAD_SINGLE_CONTACT_POINT, {
        id,
        target: CONTACT_POINTS.ADDRESS,
      }),
      dispatch(Actions.LOAD_SINGLE_CONTACT_POINT, {
        id,
        target: CONTACT_POINTS.EMAIL,
      }),
      dispatch(Actions.LOAD_SINGLE_CONTACT_POINT, {
        id,
        target: CONTACT_POINTS.PHONE,
      }),
    ]);
  },
  async [Actions.LOAD_SINGLE_CONTACT_POINT]({ commit }, { id, target }) {
    const CONTACT_POINTS_TARGET = `${API_ENDPOINTS.CUSTOMER_PROFILES}/${id}/${target}`;
    const { data } = await ApiServiceWrapper.get(CONTACT_POINTS_TARGET);

    commit(Mutations.SET_CONTACT_POINT, { target, payload: data });
  },
  async [Actions.DELETE_CONTACT_POINT](
    { getters, dispatch },
    { target, contactPoint },
  ) {
    const id = getters[Getters.PROFILE_ID];

    if (!contactPoint.id) return;

    try {
      const DELETE_URL = `${API_ENDPOINTS.CUSTOMER_PROFILES}/${id}/${target}/${contactPoint.id}`;
      await ApiServiceWrapper.delete(DELETE_URL);
      dispatch(Actions.LOAD_SINGLE_CONTACT_POINT, { id, target });
      dispatch(
        `app/${AppActions.UPDATE_SNACKBAR}`,
        { message: 'Kontakt gelöscht.' },
        { root: true },
      );
    } catch (error) {
      dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
    }
  },
  async [Actions.UPDATE_CONTACT_POINT](
    { getters, dispatch },
    { target, contactPoint },
  ) {
    const id = getters[Getters.PROFILE_ID];

    if (!contactPoint.id) return;

    try {
      const PUT_URL = `${API_ENDPOINTS.CUSTOMER_PROFILES}/${id}/${target}/${contactPoint.id}`;
      await ApiServiceWrapper.put(PUT_URL, contactPoint);
      dispatch(Actions.LOAD_SINGLE_CONTACT_POINT, { id, target });
      dispatch(
        `app/${AppActions.UPDATE_SNACKBAR}`,
        { message: 'Kontakt aktualisiert.' },
        { root: true },
      );
    } catch (error) {
      dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
    }
  },
  async [Actions.SAVE_NEW_CONTACT_POINT](
    { getters, dispatch },
    { target, contactPoint },
  ) {
    const id = getters[Getters.PROFILE_ID];

    try {
      const POST_URL = `${API_ENDPOINTS.CUSTOMER_PROFILES}/${id}/${target}`;
      await ApiServiceWrapper.post(POST_URL, { id: null, ...contactPoint });
      dispatch(Actions.LOAD_SINGLE_CONTACT_POINT, { id, target });
      dispatch(
        `app/${AppActions.UPDATE_SNACKBAR}`,
        { message: 'Kontakt gespeichert.' },
        { root: true },
      );
    } catch (error) {
      dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
    }
  },
};
