<template>
  <e-modal
    ref="modal"
    size="lg"
    :display-close-button="false"
    :auto-height="true"
    v-bind="$props"
  >
    <form @submit.prevent="confirmCancellation">
      <div class="flex p-6 flex-col">
        <div class="col-span-full self-center">
          <img src="@/assets/cancel-insurance-message-icon.svg" />
        </div>
        <div class="col-span-full self-center">
          <h3 class="block text-center font-bold text-black mt-6 px-12">
            Bist Du sicher, dass Du die Meldung stornieren möchtest?
          </h3>
        </div>
        <div class="col-span-full flex self-center justify-center gap-x-4 mt-6">
          <status :value="transitionStatusFrom" />
          <span class="m-auto">
            <img src="@/assets/arrow-icon.svg" />
          </span>
          <status :value="transitionStatusTo" />
        </div>
        <div class="col-span-full self-center mt-6 px-12">
          <e-checkbox
            id="cancellation-checkbox"
            v-model="isCancellationConfirmed"
            check-value="cancellation"
            label="Bestätige das Zurücksetzen der Krankenkassenmeldung"
          />
        </div>
      </div>
      <footer class="flex items-center justify-end gap-x-2 p-6">
        <div data-v-d8ff3f77="" class="flex w-full justify-between">
          <e-button
            class="n-button pointer-events-auto secondary"
            variant="secondary"
            @click="closeModal"
          >
            Abbrechen </e-button
          ><e-button
            class="pointer-events-auto primary mt-18 ml-auto"
            action-type="submit"
            :disabled="!isCancellationConfirmed"
          >
            Bestätigen
          </e-button>
        </div>
      </footer>
    </form>
  </e-modal>
</template>
<script>
import { EModal, EButton, EModalEvents, ECheckbox } from '@careerpartner/nitro';
import { mapActions } from 'vuex';
import Status, { HealthinsuranceStatus } from './Status.vue';
import { Actions } from '@/store/modules/profile/types';
import { HEALTH_INSURANCE_MESSAGE_TYPE } from '@/common/constants';

const TRANSITION_STATUS_FROM_MAPPING = Object.freeze({
  [HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_STARTED]:
    HealthinsuranceStatus.REPORTED,
  [HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_STARTED_CANCELLATION]:
    HealthinsuranceStatus.CANCELLED,
  [HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_ENDED]:
    HealthinsuranceStatus.CANCELLED,
  [HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_ENDED_CANCELLATION]:
    HealthinsuranceStatus.CANCELLED,
});

const TRANSITION_STATUS_TO_MAPPING = Object.freeze({
  [HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_STARTED]:
    HealthinsuranceStatus.PENDING,
  [HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_STARTED_CANCELLATION]:
    HealthinsuranceStatus.PENDING,
  [HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_ENDED]: HealthinsuranceStatus.REPORTED,
  [HEALTH_INSURANCE_MESSAGE_TYPE.STUDIES_ENDED_CANCELLATION]:
    HealthinsuranceStatus.REPORTED,
});

export default {
  name: 'CancelInsuranceDialog',
  components: { EModal, EButton, Status, ECheckbox },
  props: {
    isOpen: { type: Boolean, default: false },
    message: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      isCancellationConfirmed: false,
    };
  },
  computed: {
    transitionStatusFrom() {
      return TRANSITION_STATUS_FROM_MAPPING[this.message.messageType];
    },
    transitionStatusTo() {
      return TRANSITION_STATUS_TO_MAPPING[this.message.messageType];
    },
  },
  methods: {
    ...mapActions('profile', {
      cancelEditableInsuranceMessages: Actions.CANCEL_OUTGOING_MESSAGE,
    }),
    closeModal() {
      this.isCancellationConfirmed = false;
      this.$emit(EModalEvents.CLOSE);
    },
    async confirmCancellation() {
      await this.cancelEditableInsuranceMessages(this.message);
      this.closeModal();
    },
  },
};
</script>
