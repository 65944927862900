<template>
  <section-structure has-image :image-src="firstEnrollmentImageURL">
    <template #headline
      >Ersteinschreibung an einer Hochschule weltweit
    </template>
    <div class="col-span-full flex space-x-2">
      <validation-provider
        name="First Enrolment | Hochschule bei Ersteinschreibung"
        slim
      >
        <e-multiselect
          id="first-university"
          v-model="universityCode"
          data-test="statistics__initial-university"
          :options="selectLists.universities"
          track-by="statisticKey"
          option-label="label"
          label="Hochschule bei Ersteinschreibung"
          searchable
          mapped
        />
      </validation-provider>
      <e-tooltip
        content="Nur die Hochschule der Ersteinschreibung in Deutschland oder im Ausland aufführen."
        class="items-center justify-center text-cta"
      >
        <e-icon icon="mdi-information-outline" size="2xl" />
      </e-tooltip>
    </div>

    <div v-if="isNonGermanUniversity" class="col-span-full">
      <validation-provider
        v-slot="{ errors }"
        name="First Enrolment | Land"
        rules="required"
        immediate
        :skip-if-empty="false"
        slim
      >
        <e-multiselect
          id="first-country"
          v-model="countryCode"
          data-test="statistics__initial-country"
          :errors="errors"
          :options="countriesWithoutGermany"
          track-by="statisticKey"
          option-label="label"
          label="Land"
          searchable
          mapped
        />
      </validation-provider>
    </div>

    <div class="col-span-3">
      <validation-provider
        name="First Enrolment | Jahr der Immatrikulation"
        slim
      >
        <e-datepicker
          id="first-enrolment"
          v-model="parseMatriculationYear"
          data-test="statistics__initial-matriculationYear"
          label="Jahr der Immatrikulation"
          picker-type="year"
          format="YYYY"
          min-date="1950-01-01"
          :max-date="new Date().toISOString()"
        />
      </validation-provider>
    </div>

    <div class="col-span-full">
      <validation-provider name="First Enrolment | Immatrikulationssemester">
        <e-radio-group
          v-model="matriculationTerm"
          label="Immatrikulationssemester"
          name="matriculation-term"
          data-test="statistics__initial-semester"
          :options="[
            { value: '1', label: 'Wintersemester' },
            { value: '2', label: 'Sommersemester' },
          ]"
        />
      </validation-provider>
    </div>
  </section-structure>
</template>

<script>
import { imagePaths } from '@/common/content';
import { mapFields } from 'vuex-map-fields';
import SectionStructure from '@/components/SectionStructure';
import {
  EDatepicker,
  EIcon,
  EMultiselect,
  ERadioGroup,
  ETooltip,
} from '@careerpartner/nitro';
import selectLists from '@/mixins/selectLists';
import { IS_NON_GERMAN_UNIVERSITY } from '@/common/constants';
import { ValidationProvider } from 'vee-validate';
import '@/common/validationExtends';

const COUNTRY_CODE_GERMANY = '000';

export default {
  name: 'InitialAcademicMatriculation',
  components: {
    SectionStructure,
    EDatepicker,
    EMultiselect,
    ERadioGroup,
    EIcon,
    ETooltip,
    ValidationProvider,
  },
  mixins: [selectLists],
  computed: {
    ...mapFields('academicHistory', [
      'initialAcademicMatriculation.universityCode',
      'initialAcademicMatriculation.countryCode',
      'initialAcademicMatriculation.matriculationTerm',
      'initialAcademicMatriculation.matriculationYear',
    ]),
    countriesWithoutGermany() {
      return this.selectLists.hzbCountries.filter(
        (country) => country.statisticKey !== COUNTRY_CODE_GERMANY,
      );
    },
    parseMatriculationYear: {
      get() {
        return this.matriculationYear ? String(this.matriculationYear) : null;
      },
      set(val) {
        this.matriculationYear = Number(val) || null;
      },
    },
    firstEnrollmentImageURL() {
      return imagePaths.STATISTIC_FIRST_ENROLLMENT;
    },
    isNonGermanUniversity() {
      return IS_NON_GERMAN_UNIVERSITY(this.universityCode);
    },
  },
  watch: {
    universityCode() {
      if (
        this.isNonGermanUniversity &&
        this.countryCode === COUNTRY_CODE_GERMANY
      ) {
        this.countryCode = null;
      } else if (!this.isNonGermanUniversity) {
        this.countryCode = COUNTRY_CODE_GERMANY;
      }
    },
  },
};
</script>
