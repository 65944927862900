export default () => ({
  latestAcademicFinals: {
    universityCode: '',
    countryCode: '',
    examinationType: '',
    studyCourse: '',
    month: '',
    year: '',
    pass: '',
    grade: '',
  },
  initialAcademicMatriculation: {
    universityCode: '',
    countryCode: '',
    matriculationYear: '',
    matriculationTerm: '',
  },
  parallelAcademicStudies: {
    universityCode: '',
    countryCode: '',
    examinationType: '',
    studies: [
      { studyCourse: null },
      { studyCourse: null },
      { studyCourse: null },
    ],
  },
  termStatistic: {
    totalTerms: '',
    regularTerms: '',
    practicalTerms: '',
    holidayTerms: '',
    preparatoryTerms: '',
    interruptionTerms: '',
    interruptionType: [],
  },
});
