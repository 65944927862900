<template>
  <e-card sheet rounded elevation="lg" class="px-8 py-6 z-1 overflow-visible">
    <template #sheet>
      <div class="flex items-start space-x-8 z-10">
        <div class="grid grid-cols-6 gap-x-8 gap-y-4 w-full">
          <slot v-for="field in searchFields" :name="field.queryParam">
            <div :key="field.queryParam" class="col-span-3">
              <e-text-field
                v-if="field.type !== 'select'"
                :id="field.label"
                :ref="field.label"
                v-model="query[field.queryParam]"
                :type="field.type || 'text'"
                :label="field.label"
                :disabled="field.disabled"
                :data-test="field.dataTest"
                @keyup.enter.native="_submitSearch"
              />
              <e-multiselect
                v-else-if="field.type === 'select'"
                :id="field.label"
                :ref="field.label"
                v-model="query[field.queryParam]"
                v-bind="field"
                :options="field.options"
                :track-by="field.trackBy"
                :option-label="field.optionLabel"
                :label="field.label"
                :data-test="field.dataTest"
                :mapped="!!field.multiple && !!field.trackBy"
                open-direction="bottom"
              />
            </div>
          </slot>
        </div>
        <slot name="submit">
          <e-button
            id="epos-search"
            :disabled="disabled"
            variant="primary"
            class="min-w-20"
            aria-label="Suche"
            data-test="submit-search"
            @click="_submitSearch"
          >
            <e-icon icon="mdi-magnify"></e-icon>
          </e-button>
        </slot>
      </div>
      <div v-if="extendedSearchFields.length">
        <e-button
          variant="text"
          class="my-1"
          small
          data-test="toggle-extended-search"
          @click="_toggleExtendedQuery"
        >
          Erweiterte Suche
          <e-icon
            :icon="displayExtendedQuery ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          />
        </e-button>
        <transition name="extendedQuery-animation">
          <div v-show="displayExtendedQuery" class="flex flex-col">
            <div class="grid pt-4 grid-cols-12 gap-4 border-t border-gray-300">
              <slot
                v-for="field in extendedSearchFields"
                :name="field.queryParam"
              >
                <div
                  :key="field.queryParam"
                  :class="field.containerClass || 'col-span-6'"
                >
                  <e-text-field
                    v-if="field.type !== 'select'"
                    :id="field.label"
                    :ref="field.label"
                    v-model="query[field.queryParam]"
                    :type="field.type || 'text'"
                    :tabindex="displayExtendedQuery ? '1' : '-1'"
                    :label="field.label"
                    :disabled="field.disabled"
                    :data-test="field.dataTest"
                    wide
                    @keyup.enter.native="_submitSearch"
                  />
                  <e-multiselect
                    v-else-if="field.type === 'select'"
                    :id="field.label"
                    :ref="field.label"
                    v-model="query[field.queryParam]"
                    :tabindex="displayExtendedQuery ? '1' : '-1'"
                    :options="field.options"
                    :track-by="field.trackBy"
                    :label="field.label"
                    :option-label="field.optionLabel"
                    :mapped="!!field.multiple && !!field.trackBy"
                    :data-test="field.dataTest"
                    v-bind="field"
                    wide
                  />
                </div>
              </slot>
            </div>
            <div class="flex">
              <e-button
                :tabindex="displayExtendedQuery ? '0' : '-1'"
                variant="text"
                small
                class="mt-6"
                data-test="reset-search"
                @click="_resetQuery"
                >Suchkriterien zurücksetzen</e-button
              >
            </div>
          </div>
        </transition>
      </div>
    </template>
  </e-card>
</template>

<script>
import {
  EButton,
  ECard,
  EIcon,
  EMultiselect,
  ETextField,
} from '@careerpartner/nitro';

import _ from 'lodash';

export default {
  name: 'SearchBox',
  components: { EMultiselect, ECard, EButton, EIcon, ETextField },
  props: {
    searchFields: {
      type: Array,
      required: true,
      validator: (value) =>
        value.every((item) => !!item.queryParam && !!item.label),
    },
    extendedSearchFields: {
      type: Array,
      default: () => [],
      validator: (value) =>
        value.every((item) => !!item.queryParam && !!item.label),
    },
  },
  data() {
    return {
      displayExtendedQuery: true,
      isReady: false,
      query: {},
      // allow search with no filters (to get last created profiles see SearchableList)
      disabled: false,
    };
  },
  computed: {
    fields() {
      return [...this.searchFields, ...this.extendedSearchFields];
    },
  },
  watch: {
    fields: {
      handler() {
        this._setQuery();
      },
      immediate: true,
      deep: true,
    },
    extendedSearchFields: {
      handler(val) {
        if (val.length && val.some((field) => !_.isEmpty(field.value))) {
          this.displayExtendedQuery = true;
          this._focusFirstInput();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.fields.length && this.fields[0].type !== 'select') {
        this.$refs[this.fields[0].label][0].$el.querySelector('input').focus();
      }
    });
  },
  methods: {
    _setQuery() {
      const query = {};

      this.fields.forEach(({ queryParam, value }) => {
        query[queryParam] = value;
      });

      this.query = query;
      this.isReady = true;
    },
    _submitSearch() {
      if (this.disabled) return;

      const query = {};

      Object.entries(this.query).forEach(([key, value]) => {
        if (value) {
          query[key] = value;
        }
      });

      this.$emit('submit', query);
    },
    _toggleExtendedQuery() {
      this.displayExtendedQuery = !this.displayExtendedQuery;
      this._focusFirstInput();
    },
    _focusFirstInput() {
      if (this.displayExtendedQuery) {
        this.$nextTick(() => {
          if (this.extendedSearchFields[0].type !== 'select') {
            this.$refs[this.extendedSearchFields[0].label][0].$el
              .querySelector('input')
              .focus();
          }
        });
      }
    },
    _resetQuery() {
      const extendedSearchFieldKeys = this.extendedSearchFields.map(
        (field) => field.queryParam,
      );
      extendedSearchFieldKeys.forEach((extendedSearchFieldKey) => {
        this.query[extendedSearchFieldKey] = '';
      });

      const searchFieldKeys = this.searchFields.map(
        (field) => field.queryParam,
      );
      searchFieldKeys.forEach((searchFieldKey) => {
        this.query[searchFieldKey] = '';
      });
    },
  },
};
</script>

<style scoped>
.extendedQuery-animation-enter-active,
.extendedQuery-animation-leave-active {
  transition: all 0.2s;
  max-height: 230px;
}

.extendedQuery-animation-enter,
.extendedQuery-animation-leave-to {
  opacity: 0;
  max-height: 0;
}
</style>
