<template>
  <validation-observer
    ref="address-form"
    v-slot="{ invalid }"
    vid="address-form"
    slim
  >
    <form
      class="grid grid-cols-12 gap-x-6 gap-y-7 mt-9 px-4 pb-8"
      data-test="profile__address-modal"
    >
      <div class="col-span-6">
        <validation-provider
          v-slot="{ errors }"
          name="Art der Adresse"
          rules="required"
          slim
          immediate
          :skip-if-empty="false"
        >
          <e-multiselect
            id="address-contacttype"
            ref="address-contacttype"
            v-model="address.contactType"
            data-test="profile__address-edit-type"
            :options="ADDRESS_TYPES"
            label="Adresstyp"
            option-label="label"
            track-by="key"
            :errors="errors"
            required
            mapped
          />
        </validation-provider>
      </div>
      <div class="col-span-full flex space-x-2">
        <validation-provider
          v-slot="{ errors }"
          name="Adresszusatz"
          rules="max:200"
          slim
          :skip-if-empty="true"
        >
          <e-text-field
            id="address-additional"
            v-model="address.addressAdditional"
            data-test="profile__address-edit-additional"
            label="Adresszusatz (optional)"
            wide
            :errors="errors"
          />
        </validation-provider>
        <e-tooltip
          content="Stockwerk, Gebäudeteil, oder ähnliches"
          class="items-center justify-center text-cta"
          placement="left-center"
        >
          <e-icon icon="mdi-information-outline" size="2xl" />
        </e-tooltip>
      </div>
      <div class="col-span-full">
        <validation-provider
          v-slot="{ errors }"
          name="Straße, Hausnummer"
          rules="required|max:200"
          slim
          :skip-if-empty="false"
        >
          <e-text-field
            id="address-street"
            v-model="address.streetAddress"
            data-test="profile__address-edit-street"
            label="Straße, Hausnummer"
            wide
            required
            :errors="errors"
          />
        </validation-provider>
      </div>
      <div class="col-span-4">
        <validation-provider
          v-slot="{ errors }"
          name="Postleitzahl"
          rules="required|postalCode|max:15"
          slim
          :skip-if-empty="false"
        >
          <e-text-field
            id="address-postalcode"
            v-model="address.postalCode"
            data-test="profile__address-edit-postalcode"
            label="Postleitzahl"
            wide
            required
            :errors="errors"
          />
        </validation-provider>
      </div>
      <div class="col-span-8">
        <validation-provider
          v-slot="{ errors }"
          name="Ort"
          rules="required|max:50"
          slim
          :skip-if-empty="false"
        >
          <e-text-field
            id="address-locality"
            v-model="address.addressLocality"
            data-test="profile__address-edit-locality"
            label="Ort"
            wide
            required
            :errors="errors"
          />
        </validation-provider>
      </div>
      <div class="col-span-full">
        <validation-provider
          v-slot="{ errors }"
          name="Land"
          rules="required"
          slim
          :skip-if-empty="false"
        >
          <e-multiselect
            id="country"
            v-model="address.addressCountry"
            data-test="profile__address-edit-country"
            searchable
            label="Land"
            :options="selectLists.countries"
            open-direction="top"
            track-by="countryIso"
            option-label="name"
            :errors="errors"
            required
            mapped
          />
        </validation-provider>
      </div>
      <p class="mt-2 text-xs text-gray-700 col-span-full">* Pflichtfelder</p>

      <last-changed-by
        :updated-at="address.updatedAt"
        :updated-by="address.updatedBy"
      />
      <div class="col-span-full flex h-9">
        <e-button
          variant="primary"
          :disabled="invalid"
          data-test="profile__address-edit-save"
          class="ml-auto mt-18"
          @click="saveAddress"
        >
          <e-icon icon="mdi-content-save-outline" class="mr-2"></e-icon>
          Speichern</e-button
        >
      </div>
    </form>
  </validation-observer>
</template>

<script>
import selectLists from '@/mixins/selectLists';
import EditModalMixin from '@/components/overlays/EditModalMixin';
import { OVERLAY_EVENTS } from '@/common/constants';
import LastChangedBy from '@/components/overlays/shared/LastChangedBy';

export default {
  name: 'AddressOverlay',
  components: { LastChangedBy },
  mixins: [EditModalMixin, selectLists],
  beforeMount() {
    if (this.value) {
      this.address = JSON.parse(JSON.stringify(this.value));
    }

    if (!this.value.contactType) {
      this.address.contactType = 'default';
    }
  },
  methods: {
    async saveAddress() {
      if (await this.validateForm('address-form')) {
        this.$emit(OVERLAY_EVENTS.SUBMIT, {
          address: this.address,
        });
      }

      this.closeOverlay();
    },
  },
};
</script>
