<template>
  <section-structure is-extendable mount-closed>
    <template #headline>
      Ausweisdokumente (Nur erforderlich für CS Studierende)
    </template>

    <div class="col-span-full">
      <validation-provider name="IdentityDocument | Typ">
        <e-radio-group
          v-model="documentType"
          name="documentType"
          label="Art des Ausweisdokuments"
          data-test="profile__id-type"
          :options="[
            {
              label: 'Reisepass',
              value: 'passport',
            },
            {
              label: 'Personalausweis (bei deutschen Studierenden)',
              value: 'idCard',
            },
          ]"
        />
      </validation-provider>
    </div>

    <div class="col-span-full">
      <validation-provider
        v-slot="{ errors }"
        name="IdentityDocument | Name"
        rules="noDigits|ruleForName|max:100"
        immediate
        slim
      >
        <e-text-field
          id="documentHolder"
          v-model="documentHolder"
          data-test="profile__id-documentHolder"
          :errors="errors"
          label="Vollständiger Name auf Ausweisdokument"
        ></e-text-field>
      </validation-provider>
    </div>

    <div class="col-span-3 flex space-x-2">
      <validation-provider
        v-slot="{ errors }"
        name="IdentityDocument | Id"
        rules="identityDocumentId"
        :skip-if-empty="true"
        slim
      >
        <e-text-field
          id="documentId"
          v-model="documentId"
          data-test="profile__id-documentId"
          :errors="errors"
          label="Ausweisnummer - 9 stellig"
          wide
        ></e-text-field>
      </validation-provider>
    </div>

    <div class="col-span-3">
      <validation-provider
        v-slot="{ errors }"
        name="IdentityDocument | Ablaufdatum"
        :rules="dateValidator"
        slim
      >
        <e-text-field
          id="documentValid"
          v-model="validUntil"
          type="date"
          data-test="profile__id-validUntil"
          label="Gültig bis"
          :errors="errors"
        >
        </e-text-field>
      </validation-provider>
    </div>

    <div class="col-span-3 flex space-x-2">
      <validation-provider
        v-slot="{ errors }"
        name="IdentityDocument | Ankunftsdatum"
        :rules="{ date_format: true, before: { date: tomorrowDate } }"
        slim
      >
        <e-text-field
          id="arrival-campus"
          v-model="arrivalOnCampus"
          type="date"
          :errors="errors"
          data-test="profile__id-arrivalOnCampus"
          label="Angereist am Campus"
          wide
          :max-date="todayDayDate"
        >
        </e-text-field>
      </validation-provider>
    </div>
  </section-structure>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import SectionStructure from '@/components/SectionStructure';
import { ERadioGroup, ETextField } from '@careerpartner/nitro';
import '@/common/validationExtends';
import { ValidationProvider } from 'vee-validate';
import dayjs from 'dayjs';

export default {
  name: 'IdentityDocument',
  components: {
    SectionStructure,
    ERadioGroup,
    ETextField,
    ValidationProvider,
  },
  computed: {
    ...mapFields('profile', [
      'identityDocument.documentType',
      'identityDocument.documentHolder',
      'identityDocument.documentId',
      'identityDocument.validUntil',
      'identityDocument.arrivalOnCampus',
    ]),
    nextDayDate() {
      return dayjs().add(1, 'day').format('YYYY-MM-DD');
    },
    todayDayDate() {
      return dayjs().format('YYYY-MM-DD');
    },
    tomorrowDate() {
      return dayjs().add(1, 'day').format('YYYY-MM-DD');
    },
  },
  methods: {
    dateValidator(value) {
      if (!value) {
        return true;
      }
      return {
        date_format: true,
        before: {
          date: this.nextDayDate,
        },
      };
    },
  },
};
</script>
