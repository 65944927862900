import contactPoints from './actions/contactPoints';
import profile from './actions/profile';
import identityManagement from './actions/identityManagement';
import healthInsurance from './actions/healthInsurance';
import universityEntranceQualification from './actions/universityEntranceQualification';
import { Actions } from './types';
import { Actions as AppActions } from '../app/types';

export default {
  ...profile,
  ...contactPoints,
  ...identityManagement,
  ...healthInsurance,
  ...universityEntranceQualification,
  async [Actions.SAVE_ALL_CHANGES]({ dispatch }) {
    // TODO: Find out if profile is dirty, only save the parts that changed
    try {
      await Promise.all([
        dispatch(Actions.SAVE_PROFILE),
        dispatch(Actions.SAVE_UNIVERSITY_ENTRANCE_QUALIFICATION),
        dispatch(Actions.SAVE_IDENTITY_MANAGEMENT),
      ]);

      dispatch(
        `app/${AppActions.UPDATE_SNACKBAR}`,
        { message: 'Profil aktualisiert.' },
        { root: true },
      );
    } catch (error) {
      dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
    }
  },
};
