<template>
  <div
    v-show="updatedBy.length !== 0"
    class="col-span-full text-gray-800 font-light text-xs"
  >
    Zuletzt geändert am {{ formatShortDateToLocale(updatedAt) }} von
    {{ updatedBy }}
  </div>
</template>

<script>
import { formatShortDateToLocale } from '@/utils/formatters';

export default {
  name: 'LastChangedBy',
  props: {
    updatedAt: {
      type: String,
      default: '',
    },
    updatedBy: {
      type: String,
      default: '',
    },
  },
  methods: {
    formatShortDateToLocale,
  },
};
</script>
