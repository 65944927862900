export default () => ({
  academyId: '',
  address: [],
  booking: {
    campus: [],
  },
  campus: [''],
  damFolderUrl: null,
  email: [],
  careLink: '',
  healthInsurance: {
    id: '',
    iamUserId: '',
    companyId: '',
    policyId: '',
    state: '',
    reported: '',
  },
  healthInsuranceMessages: {
    pagination: {
      limit: 10,
      offset: 0,
      totalCount: 0,
    },
    results: [],
  },
  id: '',
  identityDocument: {
    documentType: '',
    documentHolder: '',
    documentId: '',
    validUntil: '',
    arrivalOnCampus: '',
  },
  imageUrl: '',
  matriculationNumber: '',
  person: {
    sex: '',
    honorificPrefix: '',
    givenName: '',
    familyName: '',
    birthDate: '',
    birthName: '',
    nationality: [''],
  },
  phone: [],
  residencePermit: {
    permitType: '',
    validUntil: '',
  },
  universityEntranceQualification: {
    qualificationSource: '',
    qualificationType: '',
    country: '',
    federalState: '',
    locality: '',
    yearIssued: null,
    qualificationWorkType: '',
    qualificationWorkDescription: '',
    certifiedOn: null,
  },
});
