import Store from '@/store';
import { Actions as AppActions } from '@/store/modules/app/types';

export default {
  watch: {
    async '$route.params.id'() {
      await this.fetchProfile();
    },
    '$route.name': {
      // TODO: call only when visiting from another repo (maybe travel history)
      handler: async function (path) {
        // this will always update on profile load
        if (path === 'profile') {
          await this.fetchProfile();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async beforeMount() {
    if (this.$route.params.id !== Store.state.profile.id) {
      await this.fetchProfile();
    }
  },
  methods: {
    async fetchProfile() {
      const id = this.$route.params.id;
      await Store.dispatch(`app/${AppActions.INITIALIZE}`, id);
    },
  },
};
