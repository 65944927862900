import { Actions, Mutations, Getters } from '../types';
import { Actions as AppActions } from '../../app/types';
import { ApiServiceWrapper } from '@/utils/apiServiceWrapper';
import {
  API_ENDPOINTS,
  HEALTHINSURANCE_INSURED_STATUS,
} from '@/common/constants';

export default {
  async [Actions.LOAD_HEALTH_INSURANCE]({ commit, getters }, id) {
    const profileId = id ?? getters[Getters.PROFILE_ID];

    const CUSTOMER_DETAIL_URL =
      API_ENDPOINTS.HEALTH_INSURANCE_OF_PROFILE.replace(
        ':profileId',
        profileId,
      );

    const { data } = await ApiServiceWrapper.get(CUSTOMER_DETAIL_URL).catch(
      () => [],
    );

    if (!data || Object.keys(data).length === 0) {
      commit(Mutations.SET_HEALTH_INSURANCE, {
        state: HEALTHINSURANCE_INSURED_STATUS.open, // open is the default / empty state
        manualInsuranceLinkPossible: true,
      });
      return;
    }

    // map new values to old ones for a quick switch
    // policyId, state is already passed by new endpoint with same name, does not need to be mapped manually
    data.companyId = data.insuranceId;
    data.reported = data.reportedState;

    data.manualInsuranceLinkPossible = false;
    if (!data.id && data.state === HEALTHINSURANCE_INSURED_STATUS.open) {
      data.manualInsuranceLinkPossible = true;
    }

    commit(Mutations.SET_HEALTH_INSURANCE, data);
  },

  async [Actions.UNLINK_HEALTH_INSURANCE_PROFILE]({ getters, dispatch }) {
    const insuranceProfileId = getters[Getters.INSURANCE_PROFILE_ID];

    try {
      await ApiServiceWrapper.delete(
        API_ENDPOINTS.HEALTH_INSURANCE_PROFILE_LINK,
        { data: { insuranceProfileId: insuranceProfileId } },
      );

      await dispatch(Actions.LOAD_HEALTH_INSURANCE);
    } catch (error) {
      dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
    }
  },

  async [Actions.CANCEL_OUTGOING_MESSAGE]({ getters, dispatch }, payload) {
    const insuranceCancellationApiUrl =
      API_ENDPOINTS.HEALTH_INSURANCE_CANCEL_MESSAGE.replace(
        ':messageId',
        payload.messageId,
      );

    try {
      await ApiServiceWrapper.post(insuranceCancellationApiUrl);
    } catch (error) {
      dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
    }

    await dispatch(Actions.LOAD_HEALTH_INSURANCE, getters[Getters.PROFILE_ID]);
    await dispatch(Actions.LOAD_HEALTH_INSURANCE_MESSAGES);
  },

  async [Actions.CORRECT_OUTGOING_MESSAGE](
    { getters, dispatch },
    { message, payload },
  ) {
    const healthInsuranceCorrectionUrl =
      API_ENDPOINTS.HEALTH_INSURANCE_CORRECT_MESSAGE.replace(
        ':messageId',
        message.messageId,
      );

    try {
      await ApiServiceWrapper.post(healthInsuranceCorrectionUrl, payload);
    } catch (error) {
      dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
    }
    await dispatch(Actions.LOAD_HEALTH_INSURANCE, getters[Getters.PROFILE_ID]);
    await dispatch(Actions.LOAD_HEALTH_INSURANCE_MESSAGES);
  },

  async [Actions.LOAD_HEALTH_INSURANCE_MESSAGES](
    { getters, commit, dispatch },
    payload,
  ) {
    const pagination = getters[Getters.INSURANCE_MESSAGES_PAGINATION];

    const options = Object.assign(
      {},
      { limit: 10, offset: 0 },
      {
        limit: pagination.limit,
        offset: pagination.offset,
      },
      payload,
    );

    const insuranceProfileId = getters[Getters.INSURANCE_PROFILE_ID];
    const insuranceMessagesApiUrl =
      API_ENDPOINTS.HEALTH_INSURANCE_GET_MESSAGES.replace(
        ':insuranceProfileId',
        insuranceProfileId,
      )
        .replace(':offset', options.offset)
        .replace(':limit', options.limit);
    try {
      const { data } = await ApiServiceWrapper.get(insuranceMessagesApiUrl);

      if (!data) {
        return;
      }

      commit(Mutations.SET_HEALTH_INSURANCE_MESSAGES, data);
    } catch (error) {
      dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
    }
  },

  async [Actions.SAVE_HEALTH_INSURANCE_MESSAGE](
    { getters, dispatch },
    payload,
  ) {
    const insuranceProfileId = getters[Getters.INSURANCE_PROFILE_ID];
    try {
      await ApiServiceWrapper.post(
        API_ENDPOINTS.CREATE_HEALTH_INSURANCE_MESSAGE,
        { ...payload, insuranceProfileId },
      );
    } catch (error) {
      dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
    }
    await dispatch(Actions.LOAD_HEALTH_INSURANCE, getters[Getters.PROFILE_ID]);
    await dispatch(Actions.LOAD_HEALTH_INSURANCE_MESSAGES);
  },
};
