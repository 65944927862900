<template>
  <section-structure has-image :image-src="parallelEnrollmentImageURL">
    <template #headline
      >Parallele Einschreibung an einer Hochschule weltweit
    </template>

    <div class="col-span-full flex space-x-2">
      <e-multiselect
        id="parallel-university"
        v-model="universityCode"
        data-test="statistics__parallel-university"
        :options="selectLists.universities"
        track-by="statisticKey"
        option-label="label"
        label="Hochschule bei paralleler Einschreibung"
        searchable
        mapped
      />
      <e-tooltip
        content="Nur die Hochschule aufführen, an der zum parallel zum derzeitigen Studiengang noch studiert wird."
        class="items-center justify-center text-cta"
      >
        <e-icon icon="mdi-information-outline" size="2xl" />
      </e-tooltip>
    </div>

    <div v-if="isNonGermanUniversity" class="col-span-full">
      <validation-provider
        v-slot="{ errors }"
        name="Parallel Academic Studies | Land"
        slim
        rules="required"
        immediate
      >
        <e-multiselect
          id="parallel-country"
          v-model="countryCode"
          data-test="statistics__parallel-country"
          :errors="errors"
          :options="countriesWithoutGermany"
          track-by="statisticKey"
          option-label="label"
          label="Land"
          searchable
          mapped
        />
      </validation-provider>
    </div>

    <div class="col-span-full">
      <e-multiselect
        id="parallel-examination"
        v-model="examinationType"
        data-test="statistics__parallel-type"
        :options="selectLists.graduationTypes"
        track-by="statisticKey"
        option-label="label"
        label="Angestrebter Abschluss"
        mapped
        searchable
      />
    </div>

    <div class="col-span-full flex space-x-2">
      <validation-provider
        v-slot="{ errors }"
        name="Parallel Academic Studies | 1. Studienfach"
        :rules="universityCode ? 'required' : null"
        slim
        immediate
      >
        <e-multiselect
          id="parallel-course-1"
          v-model="studies[0].studyCourse"
          data-test="statistics__parallel-course-1"
          :errors="errors"
          :options="selectLists.studyCourses"
          track-by="statisticKey"
          option-label="label"
          label="1. Studienfach (Haupthörer)"
          mapped
          searchable
        />
      </validation-provider>
      <e-tooltip
        content="Die Reihenfolge der Studiengänge gibt die Immatrikulationsbescheinigung der jeweiligen Hochschule vor. Es gilt: <p> 1.Studiengang: Haupthörer </p><p> 2.Studiengang: Nebenhörer </p><p> 3.Studiengang: Zweiter Nebenhörer</p>"
        class="items-center justify-center text-cta"
        html
      >
        <e-icon icon="mdi-information-outline" size="2xl" />
      </e-tooltip>
    </div>

    <div class="col-span-full">
      <div class="w-full flex space-x-2 items-center">
        <e-multiselect
          id="parallel-course-2"
          v-model="studies[1].studyCourse"
          data-test="statistics__parallel-course-2"
          :options="selectLists.studyCourses"
          track-by="statisticKey"
          option-label="label"
          label="2. Studienfach (Nebenhörer)"
          mapped
          searchable
        />
      </div>
    </div>

    <div class="col-span-full">
      <div class="w-full flex space-x-2 items-center">
        <e-multiselect
          id="parallel-course-2"
          v-model="studies[2].studyCourse"
          data-test="statistics__parallel-course-3"
          :options="selectLists.studyCourses"
          track-by="statisticKey"
          option-label="label"
          label="3. Studienfach (zweiter Nebenhörer)"
          mapped
          searchable
        />
      </div>
    </div>
  </section-structure>
</template>

<script>
import { imagePaths } from '@/common/content';
import { mapFields, mapMultiRowFields } from 'vuex-map-fields';
import { ValidationProvider } from 'vee-validate';
import SectionStructure from '@/components/SectionStructure';
import { EIcon, EMultiselect, ETooltip } from '@careerpartner/nitro';
import { IS_NON_GERMAN_UNIVERSITY } from '@/common/constants';
import selectLists from '@/mixins/selectLists';
import '@/common/validationExtends';

export default {
  name: 'ParallelAcademicStudies',
  components: {
    SectionStructure,
    ValidationProvider,
    EMultiselect,
    EIcon,
    ETooltip,
  },
  mixins: [selectLists],
  computed: {
    ...mapFields('academicHistory', [
      'parallelAcademicStudies.universityCode',
      'parallelAcademicStudies.countryCode',
      'parallelAcademicStudies.examinationType',
    ]),
    ...mapMultiRowFields('academicHistory', [
      'parallelAcademicStudies.studies',
    ]),
    parallelEnrollmentImageURL() {
      return imagePaths.STATISTIC_PARALLEL_ENROLLMENT;
    },
    isNonGermanUniversity() {
      return IS_NON_GERMAN_UNIVERSITY(this.universityCode);
    },
    countriesWithoutGermany() {
      return this.selectLists.hzbCountries.filter(
        (country) => country.statisticKey !== '000',
      );
    },
  },
};
</script>
