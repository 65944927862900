export const universities = [
  {
    statisticKey: '0011',
    label: 'U Kassel, Abt. Kassel (ohne Kunsthochschule)',
  },
  { statisticKey: '0012', label: 'U Kassel, Witzenhausen' },
  { statisticKey: '0013', label: 'U Kassel, Abt. Kassel (Kunsthochschule)' },
  {
    statisticKey: '0014',
    label: 'U Kassel, Abt. Kassel (International Management School)',
  },
  {
    statisticKey: '0020',
    label: 'APOLLON Hochschule der Gesundheitswissenschaft, Bremen (Priv. FH)',
  },
  {
    statisticKey: '0030',
    label:
      'Hochschule Macromedia für angewandte Wissenschaften Stuttgart, Campus München (Priv. FH)',
  },
  {
    statisticKey: '0032',
    label:
      'Hochschule Macromedia für angewandte Wissenschaften Stuttgart, Campus Berlin (Priv. FH)',
  },
  {
    statisticKey: '0033',
    label:
      'Hochschule Macromedia für angewandte Wissenschaften Stuttgart, Campus Hamburg (Priv. FH)',
  },
  {
    statisticKey: '0034',
    label:
      'Hochschule Macromedia für angewandte Wissenschaften Stuttgart, Campus Köln (Priv. FH)',
  },
  {
    statisticKey: '0035',
    label:
      'Hochschule Macromedia für angewandte Wissenschaften Stuttgart, Campus Stuttgart (Priv. FH)',
  },
  {
    statisticKey: '0036',
    label:
      'Hochschule Macromedia für angewandte Wissenschaften Stuttgart, Campurs Freiburg i. Br. (Priv. FH)',
  },
  {
    statisticKey: '0037',
    label:
      'Hochschule Macromedia für angewandte Wissenschaften Stuttgart, Campus Leipzig (Priv. FH)',
  },
  {
    statisticKey: '0038',
    label:
      'Hochschule Macromedia für angewandte Wissenschaften Stuttgart, Campus Frankfurt a. M. (Priv. FH)',
  },
  {
    statisticKey: '0040',
    label:
      'FH der Diakonie - Diaconia - University of Applied Science Bielefeld-Bethel',
  },
  {
    statisticKey: '0050',
    label: 'German Graduate School of Management & Law, Heilbronn (Priv. FH)',
  },
  {
    statisticKey: '0060',
    label: 'Hochschule der Bundesagentur für Arbeit, Mannheim (FH)',
  },
  {
    statisticKey: '0061',
    label: 'Hochschule der Bundesagentur für Arbeit in Schwerin',
  },
  { statisticKey: '0080', label: 'U Duisburg-Essen in Essen' },
  { statisticKey: '0082', label: 'U Duisburg-Essen in Duisburg' },
  { statisticKey: '0121', label: 'U Paderborn' },
  { statisticKey: '0130', label: 'U Siegen' },
  { statisticKey: '0140', label: 'U Wuppertal' },
  { statisticKey: '0150', label: 'Fernuniversität Hagen' },
  {
    statisticKey: '0160',
    label: 'EBC Euro Business College Hamburg Campus Berlin (Priv. FH) ',
  },
  { statisticKey: '0170', label: 'Touro College Berlin (Priv. FH)' },
  { statisticKey: '0180', label: 'Charité Universitätsmedizin Berlin' },
  { statisticKey: '0190', label: 'Europa-U Viadrina Frankfurt/Oder' },
  { statisticKey: '0200', label: 'Humboldt-U Berlin' },
  { statisticKey: '0260', label: 'U Rostock' },
  { statisticKey: '0270', label: 'U Greifswald' },
  { statisticKey: '0301', label: 'U Halle, Abt. Halle' },
  { statisticKey: '0302', label: 'U Halle, Abt. Merseburg' },
  { statisticKey: '0310', label: 'U Magdeburg' },
  { statisticKey: '0360', label: 'U Leipzig ' },
  { statisticKey: '0370', label: 'TU Dresden' },
  { statisticKey: '0372', label: 'TU Dresden in Tharandt' },
  { statisticKey: '0373', label: 'TU Dresden in Zittau (IHI)' },
  { statisticKey: '0380', label: 'TU Chemnitz' },
  { statisticKey: '0390', label: 'TU Bergakademie Freiberg' },
  { statisticKey: '0490', label: 'U Jena' },
  { statisticKey: '0500', label: 'U Bamberg' },
  { statisticKey: '0510', label: 'U Bayreuth' },
  { statisticKey: '0511', label: 'Universität Bayreuth in Kulmbach' },
  { statisticKey: '0520', label: 'U Oldenburg' },
  { statisticKey: '0530', label: 'U Osnabrück' },
  { statisticKey: '0540', label: 'U Passau' },
  {
    statisticKey: '0551',
    label: 'Kath. U Eichstätt-Ingolstadt, Abt. Eichstätt',
  },
  {
    statisticKey: '0552',
    label: 'Kath. U Eichstätt-Ingolstadt, Abt. Ingolstadt',
  },
  {
    statisticKey: '0560',
    label: 'Augustana-H (ev) Neuendettelsau (ehem. GH Neuendettelsau)',
  },
  { statisticKey: '0580', label: 'Bauhaus-Universität Weimar' },
  { statisticKey: '0590', label: 'TU Ilmenau' },
  { statisticKey: '0620', label: 'U Erfurt' },
  {
    statisticKey: '0630',
    label: 'HHL Leipzig Graduate School of Management (Priv. H)',
  },
  { statisticKey: '0660', label: 'Jacobs University Bremen (Priv. wiss. H)' },
  {
    statisticKey: '0670',
    label: 'European School of Managment and Technology, Berlin (Priv. H)',
  },
  {
    statisticKey: '0680',
    label: 'Hertie School of Governance (Priv. wiss. H) Berlin',
  },
  { statisticKey: '0690', label: 'Hafencity Universität, Hamburg' },
  { statisticKey: '0700', label: 'bbw Hochschule Berlin (Priv. FH)' },
  {
    statisticKey: '0710',
    label: 'H für Kirchenmusik Herford-Witten (Kirchl.-Theol. H)',
  },
  {
    statisticKey: '0720',
    label:
      'Internationale Hochschule SDI München - Hochschule für angewandte Wissenschaften (Priv. FH)',
  },
  {
    statisticKey: '0730',
    label: 'SRH Fachhochschule für Gesundheit Gera (Priv. FH)',
  },
  {
    statisticKey: '0740',
    label: 'Norddeutsche Hochschule für Rechtspflege, Hildesheim (FH)',
  },
  {
    statisticKey: '0750',
    label:
      'Hannover, Kommunale H für Verwaltung in Niedersachsen (Priv. Verw-FH)',
  },
  {
    statisticKey: '0760',
    label:
      'IB Hochschule für Gesundheit und Soziales Berlin in Berlin (Priv. FH)',
  },
  {
    statisticKey: '0761',
    label:
      'IB Hochschule für Gesundheit und Soziales Berlin in Stuttgart (Priv. FH)',
  },
  {
    statisticKey: '0762',
    label:
      'IB Hochschule für Gesundheit und Soziales Berlin in München (Priv. FH)',
  },
  {
    statisticKey: '0763',
    label:
      'IB Hochschule für Gesundheit und Soziales Berlin in Hamburg (Priv. FH)',
  },
  {
    statisticKey: '0764',
    label:
      'IB Hochschule für Gesundheit und Soziales Berlin in Köln (Priv. FH)',
  },
  { statisticKey: '0790', label: 'Akkon HS Berlin (Priv. FH)' },
  { statisticKey: '0800', label: 'Helmut-Schmidt-Universität Hamburg' },
  {
    statisticKey: '0811',
    label: 'U der Bundeswehr München (FB Universitätsstudiengänge)',
  },
  {
    statisticKey: '0812',
    label: 'U der Bundeswehr München (FB Fachhochschulstudiengänge)',
  },
  {
    statisticKey: '0821',
    label:
      'Deutsche Hochschule für Gesundheit und Sport Berlin in Berlin (Priv. FH)',
  },
  {
    statisticKey: '0822',
    label:
      'Deutsche Hochschule für Gesundheit und Sport Berlin in Ismaning (Priv. FH)',
  },
  {
    statisticKey: '0823',
    label:
      'Deutsche Hochschule für Gesundheit und Sport Berlin in Unna (Priv. FH)',
  },
  {
    statisticKey: '0830',
    label:
      'Design Akademie Berlin, SRH H für Kommunikation und Design (Priv. FH)',
  },
  {
    statisticKey: '0860',
    label:
      'Deutsche H für Prävention und Gesundheitsmanagement Saarbrücken (Priv. FH)',
  },
  {
    statisticKey: '0880',
    label: 'EBC Euro Business College Hamburg Campus Hamburg (Priv. FH) ',
  },
  {
    statisticKey: '0910',
    label: 'Deutsche Hochschule der Polizei, Münster (U)',
  },
  { statisticKey: '0920', label: 'FH Kunst Arnstadt (Priv.)' },
  { statisticKey: '0930', label: 'EBZ  Business School, Bochum (Priv. FH) ' },
  { statisticKey: '0940', label: 'Freie Theologische H (FTH) Gießen (Priv.)' },
  { statisticKey: '0960', label: 'Universität Vechta' },
  { statisticKey: '0980', label: 'U Hildesheim' },
  { statisticKey: '0990', label: 'U Lüneburg' },
  { statisticKey: '1000', label: 'U Kiel' },
  { statisticKey: '1010', label: 'U Lübeck' },
  { statisticKey: '1020', label: 'U Hamburg ' },
  { statisticKey: '1030', label: 'U Göttingen' },
  { statisticKey: '1040', label: 'TU Hamburg' },
  { statisticKey: '1050', label: 'U Bremen' },
  { statisticKey: '1060', label: 'Bucerius Law School, Hamburg' },
  { statisticKey: '1080', label: 'U Bochum' },
  { statisticKey: '1090', label: 'U Bonn' },
  { statisticKey: '1100', label: 'U Düsseldorf' },
  { statisticKey: '1110', label: 'U Köln ' },
  { statisticKey: '1120', label: 'U Münster' },
  { statisticKey: '1130', label: 'Technische Universität Dortmund' },
  { statisticKey: '1140', label: 'U Bielefeld' },
  { statisticKey: '1150', label: 'Deutsche Sporthochschule Köln' },
  { statisticKey: '1160', label: 'U Frankfurt a.M.' },
  { statisticKey: '1170', label: 'U Gießen' },
  { statisticKey: '1180', label: 'U Marburg' },
  { statisticKey: '1190', label: 'Priv. wiss. H für Unternehmensführung' },
  { statisticKey: '1200', label: 'U Trier' },
  { statisticKey: '1210', label: 'Technische U Kaiserslautern' },
  { statisticKey: '1221', label: 'U Mainz, Abt. Mainz' },
  { statisticKey: '1222', label: 'U Mainz, Abt. Germersheim' },
  {
    statisticKey: '1230',
    label: 'Deutsche Universität für Verwaltungswissenschaften Speyer',
  },
  { statisticKey: '1240', label: 'U Freiburg i.Br.' },
  { statisticKey: '1250', label: 'U Heidelberg' },
  { statisticKey: '1252', label: 'U Heidelberg in Mannheim' },
  { statisticKey: '1260', label: 'U Konstanz' },
  { statisticKey: '1270', label: 'U Tübingen' },
  { statisticKey: '1280', label: 'Priv. wiss. H Bierbronnen' },
  { statisticKey: '1291', label: 'U Koblenz-Landau, Abt. Koblenz' },
  { statisticKey: '1292', label: 'U Koblenz-Landau, Abt. Landau' },
  { statisticKey: '1311', label: 'U Erlangen-Nürnberg, Abt. Erlangen' },
  { statisticKey: '1312', label: 'U Erlangen-Nürnberg, Abt. Nürnberg' },
  { statisticKey: '1322', label: 'U München in München' },
  { statisticKey: '1323', label: 'U München im Lankreis München' },
  { statisticKey: '1330', label: 'U Würzburg' },
  { statisticKey: '1340', label: 'U Regensburg' },
  { statisticKey: '1350', label: 'U Augsburg' },
  { statisticKey: '1360', label: 'U des Saarlandes Saarbrücken' },
  { statisticKey: '1380', label: 'FU Berlin' },
  { statisticKey: '1410', label: 'Allensbach Hochschule Konstanz (Priv. FH)' },
  { statisticKey: '1430', label: 'TU Braunschweig' },
  { statisticKey: '1440', label: 'TU Clausthal' },
  { statisticKey: '1450', label: 'U Hannover' },
  {
    statisticKey: '1460',
    label: 'Zeppelin Universität Friedrichshafen (Priv. H)',
  },
  {
    statisticKey: '1470',
    label: 'DIU Dresden International University (Priv. H)',
  },
  { statisticKey: '1480', label: 'TH Aachen ' },
  { statisticKey: '1490', label: 'Universität Witten/Herdecke (Priv. H)' },
  {
    statisticKey: '1500',
    label: 'Hochschule für Finanzwirtschaft & Management Bonn (Priv. FH)',
  },
  { statisticKey: '1510', label: 'CVJM-Hochschule Kassel (FH)' },
  {
    statisticKey: '1520',
    label: 'International Psychoanalytic University Berlin (Priv.)',
  },
  { statisticKey: '1530', label: 'TU Darmstadt' },
  {
    statisticKey: '1541',
    label: 'EBS U für Wirtschaft und Recht in Oestrich-Winkel (Priv. H)',
  },
  {
    statisticKey: '1542',
    label: 'EBS U für Wirtschaft und Recht in Wiesbaden (Priv. H)',
  },
  { statisticKey: '1550', label: 'Evangelische Hochschule Tabor in Marburg' },
  {
    statisticKey: '1561',
    label:
      'H für Medien, Kommunikation und Wirtschaft Berlin Abt. Berlin (Priv. FH)',
  },
  {
    statisticKey: '1562',
    label:
      'H für Medien, Kommunikation und Wirtschaft Berlin Abt. Köln (Priv. FH)',
  },
  {
    statisticKey: '1563',
    label:
      'HS für Medien, Kommunikation und Wirtschaft Berlin in Frankfurt (Priv. FH) ',
  },
  {
    statisticKey: '1570',
    label: 'FH für Sport und Management, Potsdam (Priv. FH)',
  },
  {
    statisticKey: '1580',
    label: 'Karlsruher Institut für Technologie (KIT) - Bereich Hochschule',
  },
  { statisticKey: '1590', label: 'U Stuttgart' },
  { statisticKey: '1600', label: 'Quadriga Hochschule Berlin (Priv. FH)' },
  {
    statisticKey: '1610',
    label: 'DEKRA Hochschule für Medien Berlin (Priv. FH)',
  },
  { statisticKey: '1631', label: 'TU München in München' },
  { statisticKey: '1632', label: 'TU München in Weihenstephan' },
  { statisticKey: '1633', label: 'TU München in Garching' },
  { statisticKey: '1634', label: 'TU München in Straubing' },
  { statisticKey: '1635', label: 'TU München in Heilbronn' },
  {
    statisticKey: '1660',
    label: 'EBC Euro Business College Hamburg Campus Düsseldorf (Priv. FH) ',
  },
  {
    statisticKey: '1680',
    label: 'CBS International Business School in Köln (Priv. FH)',
  },
  {
    statisticKey: '1681',
    label: 'CBS International Business School in Mainz (Priv. FH)',
  },
  { statisticKey: '1690', label: 'TU Berlin' },
  {
    statisticKey: '1700',
    label: 'ESCP Europe Wirtschaftshochschule Berlin (Priv. H)',
  },
  { statisticKey: '1710', label: 'H für Politik München' },
  { statisticKey: '1730', label: 'Medizinische H Hannover' },
  { statisticKey: '1740', label: 'Tierärztliche H Hannover' },
  {
    statisticKey: '1760',
    label:
      'BSP Business and Law School - Hochschule für Management und Recht Berlin in Berlin (Priv. FH)',
  },
  {
    statisticKey: '1761',
    label:
      'BSP Business and Law School - Hochschule für Management und Recht Berlin in Hamburg (Priv. FH)',
  },
  { statisticKey: '1770', label: 'Hochschule für Gesundheit (FH) Bochum' },
  {
    statisticKey: '1780',
    label: 'Hochschule Weserbergland (HSW), Hameln (Priv. FH)',
  },
  {
    statisticKey: '1790',
    label: 'Internationale Hochschule Bad Honnef-Bonn in Bremen (Priv. FH)',
  },
  { statisticKey: '1800', label: 'U Hohenheim' },
  { statisticKey: '1810', label: 'U Mannheim' },
  { statisticKey: '1820', label: 'U Ulm' },
  {
    statisticKey: '1830',
    label: 'Freie Hochschule Stuttgart, Seminar für Waldorfpädagogik',
  },
  { statisticKey: '1840', label: 'Psychologische Hochschule Berlin (Priv. U)' },
  { statisticKey: '1850', label: 'Fachhochschule Dresden (Priv. FH)' },
  { statisticKey: '1870', label: 'Brand University Hamburg (Priv. FH)' },
  { statisticKey: '1880', label: 'MSH Medical School Hamburg (Priv. FH)' },
  {
    statisticKey: '1890',
    label: 'Bard College Berlin, A Liberal Arts University (Priv. H)',
  },
  {
    statisticKey: '1910',
    label: 'Leibniz - Fachhochschule Hannover (Priv. FH)',
  },
  {
    statisticKey: '1921',
    label: 'VICTORIA - Internationale Hochschule Berlin in Berlin (Priv. FH)',
  },
  {
    statisticKey: '1922',
    label:
      'VICTORIA - Internationale Hochschule Berlin in Baden-Baden (Priv. FH)',
  },
  {
    statisticKey: '1940',
    label: 'Internationale Hochschule Liebenzell (Evang. FH)',
  },
  {
    statisticKey: '1950',
    label: 'Fliedner Fachhochschule Düsseldorf (Priv. FH)',
  },
  {
    statisticKey: '1960',
    label: 'Kühne Logistics University - The KLU, Hamburg (Priv. H)',
  },
  {
    statisticKey: '1970',
    label:
      'Hochschule der Wirtschaft für Management (HdWM) Mannheim (Priv. FH)',
  },
  {
    statisticKey: '1990',
    label: 'Medical School Berlin H für Gesundheit und Medizin (Priv. FH)',
  },
  { statisticKey: '2000', label: 'Theol. Fakultät (rk) Fulda' },
  { statisticKey: '2020', label: 'Phil.-Theol. H (rk) Frankfurt a.M.' },
  { statisticKey: '2030', label: 'Theol. Fakultät (rk) Paderborn' },
  { statisticKey: '2040', label: 'Theol. Fakultät Trier' },
  {
    statisticKey: '2050',
    label: 'Vinzenz Pallotti University (VPU) Vallendar (Kirchl.-T heol. H)',
  },
  {
    statisticKey: '2060',
    label: 'Kölner H für Katholische Theologie (Kirchl.-Theol. H)',
  },
  { statisticKey: '2080', label: 'Phil.-Theol. H (rk) Münster' },
  {
    statisticKey: '2100',
    label: 'SRH Wilhelm Löhe Hochschule Fürth (Priv. FH)',
  },
  { statisticKey: '2150', label: 'Hochschule Fresenius Heidelberg (Priv. FH)' },
  { statisticKey: '2170', label: 'H für Philosophie (rk) München' },
  { statisticKey: '2200', label: 'Hochschule Geisenheim University (FH)' },
  {
    statisticKey: '2211',
    label:
      'HDBW Hochschule der Bayerischen Wirtschaft für angewandte Wissenschaften in München (Priv. FH)',
  },
  { statisticKey: '2220', label: 'Theologische H Ewersbach in Dietzhölztal' },
  {
    statisticKey: '2240',
    label: 'Hochschule für angewandte Pädagogik, Berlin (Priv. FH)',
  },
  {
    statisticKey: '2271',
    label: 'Kirchliche Hochschule Wuppertal/Bethel in Bethel (ev.)',
  },
  {
    statisticKey: '2272',
    label:
      'Kirchliche H Wuppertal/Bethel - H für Diakonie und Kirche in Wuppertal (ev)',
  },
  {
    statisticKey: '2280',
    label: 'IST-Hochschule für Management Düsseldorf (Priv. FH)',
  },
  {
    statisticKey: '2290',
    label: 'Berlin International University of Applied Sciences (Priv. FH)',
  },
  { statisticKey: '2300', label: 'H für jüdische Studien Heidelberg' },
  { statisticKey: '2320', label: 'Luth.-Theol. H (ev) Oberursel' },
  { statisticKey: '2350', label: 'Theologische H Friedensau' },
  {
    statisticKey: '2360',
    label: 'FH für Interkulturelle Theologie Hermannsburg (Priv.)',
  },
  {
    statisticKey: '2381',
    label:
      'VWA - Hochschule für berufsbegleitendes Studium Stuttgart in Stuttgart (Priv. FH)',
  },
  {
    statisticKey: '2382',
    label:
      'VWA - Hochschule für berufsbegleitendes Studium Stuttgart in Freiburg (Priv. FH)',
  },
  {
    statisticKey: '2383',
    label:
      'VWA - Hochschule für berufsbegleitendes Studium Stuttgart in Karlsruhe (Priv. FH)',
  },
  {
    statisticKey: '2384',
    label:
      'VWA - Hochschule für berufsbegleitendes Studium Stuttgart in Ulm (Priv. FH)',
  },
  {
    statisticKey: '2410',
    label: 'NBS Northern Business School Hamburg (Priv. FH)',
  },
  {
    statisticKey: '2420',
    label: 'Medizinische Hochschule Brandenburg in Neuruppin (Priv. U)',
  },
  { statisticKey: '2430', label: 'Alanus Hochschule, Alfter' },
  {
    statisticKey: '2450',
    label: 'EU European University of Applied Sciences in Mannheim (Pri v. FH)',
  },
  { statisticKey: '2490', label: 'Palucca Hochschule für Tanz Dresden' },
  { statisticKey: '2500', label: 'H für Musik und Theater Hamburg' },
  { statisticKey: '2510', label: 'Weißensee Kunsthochschule Berlin' },
  { statisticKey: '2520', label: 'H für Musik Berlin' },
  {
    statisticKey: '2530',
    label: 'Hochschule für Musik, Theater und Medien, Hannover',
  },
  { statisticKey: '2540', label: 'H für Schauspielkunst Berlin' },
  { statisticKey: '2550', label: 'Filmuniversität Babelsberg' },
  { statisticKey: '2561', label: 'H für Musik Detmold, Abt. Detmold' },
  {
    statisticKey: '2571',
    label: 'Folkwang U der Künste Essen in Essen (Kunst-H)',
  },
  {
    statisticKey: '2572',
    label: 'Folkwang U der Künste Essen in Duisburg (Kunst-H)',
  },
  {
    statisticKey: '2573',
    label: 'Folkwang U der Künste Essen in Bochum (Kunst-H)',
  },
  {
    statisticKey: '2574',
    label: 'Folkwang U der Künste Essen in Dortmund (Kunst-H)',
  },
  { statisticKey: '2581', label: 'H für Musik und Tanz Köln in Köln' },
  { statisticKey: '2582', label: 'H für Musik und Tanz Köln in Aachen' },
  { statisticKey: '2583', label: 'H für Musik und Tanz Köln in Wuppertal' },
  { statisticKey: '2590', label: 'Musikhochschule Lübeck' },
  { statisticKey: '2600', label: 'Burg Giebichenstein Kunsthochschule Halle' },
  { statisticKey: '2610', label: 'H für Künste Bremen' },
  {
    statisticKey: '2620',
    label: 'H für Musik und Darstellende Kunst Frankfurt a.M.',
  },
  { statisticKey: '2630', label: 'H für Bildende Künste Dresden' },
  { statisticKey: '2640', label: 'H für Graphik und Buchkunst Leipzig ' },
  { statisticKey: '2650', label: 'Staatl. H für Musik Freiburg i.Br.' },
  {
    statisticKey: '2660',
    label: 'Staatl. H für Musik und Darstellende Kunst Mannheim ',
  },
  { statisticKey: '2670', label: 'Staatl. H für Musik Karlsruhe' },
  {
    statisticKey: '2690',
    label: 'Staatl. H für Musik und Darstellende Kunst Stuttgart',
  },
  { statisticKey: '2700', label: 'Staatl. H für  Musik Trossingen' },
  { statisticKey: '2710', label: 'H für Musik Würzburg' },
  { statisticKey: '2720', label: 'H für Musik und Theater München' },
  { statisticKey: '2730', label: 'H für Fernsehen und Film München' },
  { statisticKey: '2740', label: 'H für Musik und Theater Leipzig' },
  { statisticKey: '2760', label: 'Hochschule für Musik Saarbrücken' },
  { statisticKey: '2770', label: 'H der Bildenden Künste Saarbrücken' },
  { statisticKey: '2790', label: 'Universität der Künste Berlin' },
  { statisticKey: '2800', label: 'H für Musik Dresden' },
  { statisticKey: '2810', label: 'H für Bildende Künste Hamburg' },
  { statisticKey: '2830', label: 'H für Bildende Künste Braunschweig' },
  { statisticKey: '2850', label: 'H für Musik Weimar' },
  { statisticKey: '2860', label: 'Kunstakademie Düsseldorf' },
  { statisticKey: '2870', label: 'Robert-Schumann-H Düsseldorf' },
  { statisticKey: '2880', label: 'KH für Medien Köln' },
  { statisticKey: '2890', label: 'Kunstakademie Münster' },
  {
    statisticKey: '2900',
    label: 'H für Bildende Künste (Städelschule) Frankfurt a.M.',
  },
  { statisticKey: '2920', label: 'H für Gestaltung Offenbach' },
  {
    statisticKey: '2930',
    label:
      'H für Kirchenmusik der Evangelisch-Lutherischen Landeskriche Sachsens, Dresden',
  },
  { statisticKey: '2940', label: 'Staatl. H für Gestaltung Karlsruhe' },
  {
    statisticKey: '2950',
    label: 'Staatl. Akademie der Bildenden Künste Karlsruhe',
  },
  {
    statisticKey: '2960',
    label: 'Staatl. Akademie der Bildenden Künste Stuttgart',
  },
  { statisticKey: '2980', label: 'Akademie der Bildenden Künste München' },
  { statisticKey: '2990', label: 'Akademie der Bildenden Künste Nürnberg' },
  { statisticKey: '3000', label: 'Evang. H für Kirchenmusik Halle' },
  { statisticKey: '3011', label: 'Hochschule für Musik Nürnberg' },
  { statisticKey: '3020', label: 'H für evang. Kirchenmusik, Bayreuth' },
  { statisticKey: '3030', label: 'H für Musik und Theater Rostock' },
  {
    statisticKey: '3040',
    label: 'Hochschule für kath. Kirchenmusik und Musikpädagogik Regensburg',
  },
  { statisticKey: '3051', label: 'H Ruhr-West in Bottrop (FH)' },
  { statisticKey: '3052', label: 'H Ruhr-West in Mülheim (FH)' },
  { statisticKey: '3061', label: 'H Rhein-Waal in Kleve (FH)' },
  { statisticKey: '3062', label: 'H Rhein-Waal in Kamp-Lintfort (FH)' },
  { statisticKey: '3071', label: 'H Hamm-Lippstadt in Hamm (FH)' },
  { statisticKey: '3072', label: 'H Hamm-Lippstadt in Lippstadt (FH)' },
  {
    statisticKey: '3080',
    label: 'Hochschule der bildenden Künste (HBK) Essen (Priv. Kunst-H)',
  },
  { statisticKey: '3090', label: 'HSD Hochschule Döpfer (Priv. FH), Köln' },
  {
    statisticKey: '3091',
    label: 'HSD Hochschule Döpfer Köln in Regensburg (Priv. FH)',
  },
  { statisticKey: '3100', label: 'EUF Europa-Universität Flensburg' },
  {
    statisticKey: '3121',
    label:
      'Hochschule für Kommunikation und Gestaltung in Stuttgart, FB Gestaltung (Priv. FH)',
  },
  {
    statisticKey: '3122',
    label:
      'Hochschule für Kommunikation und Gestaltung in Ulm, FB Kommunikation (Priv. FH)',
  },
  { statisticKey: '3130', label: 'Barenboim-Said Akademie Berlin (Priv. KH)' },
  {
    statisticKey: '3140',
    label: 'CODE University of Applied Sciences Berlin (Priv. FH)',
  },
  { statisticKey: '3230', label: 'Cusanus H Koblenz (Priv. FH)' },
  {
    statisticKey: '3241',
    label: 'Duale Hochschule Gera-Eisenach in Eisenach (FH)',
  },
  {
    statisticKey: '3242',
    label: 'Duale Hochschule Gera-Eisenach in Gera (FH)',
  },
  {
    statisticKey: '3250',
    label: 'Fachhochschule Clara Hoffbauer Potsdam (Priv. FH)',
  },
  {
    statisticKey: '3260',
    label: 'DHSH - Duale Hochschule Schleswig-Holstein in Kiel (Priv. FH)',
  },
  {
    statisticKey: '3270',
    label: 'German International University Berlin (Priv. U)',
  },
  {
    statisticKey: '3290',
    label: 'HMU Health and Medical University Potsdam (Priv. U)',
  },
  {
    statisticKey: '3360',
    label: 'Digital Business University of Applied Sciences Berlin (Priv. FH)',
  },
  { statisticKey: '3370', label: 'Technische Universität Nürnberg' },
  { statisticKey: '3430', label: 'Berufliche Hochschule Hamburg (FH)' },
  {
    statisticKey: '3490',
    label: 'XU Exponential University of Applied Sciences Potsdam (Priv. FH)',
  },
  { statisticKey: '3500', label: 'U Potsdam (ehem. PH Potsdam)' },
  { statisticKey: '3640', label: 'GISMA Business School Potsdam (Priv. FH)' },
  { statisticKey: '3660', label: 'PH Freiburg i.Br.' },
  { statisticKey: '3670', label: 'PH Heidelberg' },
  { statisticKey: '3680', label: 'PH Karlsruhe' },
  { statisticKey: '3700', label: 'PH Schwäbisch Gmünd' },
  { statisticKey: '3711', label: 'PH Ludwigsburg in Ludwigsburg' },
  {
    statisticKey: '3720',
    label: 'UoL-University of Labour Frankfurt a. M. (Priv. FH)',
  },
  { statisticKey: '3780', label: 'PH Weingarten' },
  { statisticKey: '3790', label: 'Kolping H, Köln (Priv. FH)' },
  {
    statisticKey: '3971',
    label: 'Brandenburgische TU Cottbus-Senftenberg in Cottbus',
  },
  {
    statisticKey: '3972',
    label: 'Brandenburgische TU Cottbus-Senftenberg in Senftenberg',
  },
  {
    statisticKey: '3980',
    label: 'Promotionszentrum Soziale Arbeit in Wiesbaden',
  },
  {
    statisticKey: '3990',
    label: 'Promotionszentrum Angewandte Informatik in Darmstadt',
  },
  {
    statisticKey: '4960',
    label: 'media Akademie - Hochschule Stuttgart (Priv. FH)  ',
  },
  {
    statisticKey: '4981',
    label: 'Hochschule Wilhelmshaven/Oldenburg/Elsfleth in Wilhelmshaven (FH)',
  },
  {
    statisticKey: '4982',
    label: 'Hochschule Wilhelmshaven/Oldenburg/Elsfleth in Oldenburg (FH)',
  },
  {
    statisticKey: '4983',
    label: 'Hochschule Wilhelmshaven/Oldenburg/Elsfleth in Elsfleth (FH)',
  },
  { statisticKey: '4991', label: 'Hochschule Emden/Leer in Emden (FH)' },
  { statisticKey: '4992', label: 'Hochschule Emden/Leer in Leer (FH)' },
  { statisticKey: '5010', label: 'Hochschule Flensburg (FH)' },
  {
    statisticKey: '5020',
    label:
      'FH Kiel die Abteilungen 5021, 5024 ? 5028 werden unter FH Kiel 5020 nachgewiesen',
  },
  { statisticKey: '5030', label: 'Technische Hochschule Lübeck (FH)' },
  { statisticKey: '5050', label: 'Priv. FH Wedel' },
  {
    statisticKey: '5061',
    label: 'FH für Verwaltung und Dienstleistung, Abt. Altenholz',
  },
  {
    statisticKey: '5062',
    label: 'FH für Verwaltung und Dienstleistung, Abt. Reinfeld',
  },
  { statisticKey: '5080', label: 'Muthesius Kunsthochschule Kiel' },
  { statisticKey: '5090', label: 'FH Westküste in Heide' },
  {
    statisticKey: '5100',
    label: 'Hochschule für Telekommunikation Leipzig (Priv. FH)',
  },
  {
    statisticKey: '5110',
    label: 'Evangelische Hochschule Dresden in Dresden (Kirchl. FH)',
  },
  {
    statisticKey: '5111',
    label: 'Evangelische Hochschule Dresden in Moritzburg (Kirchl. FH)',
  },
  {
    statisticKey: '5120',
    label:
      'H für Technik und Wirtschaft Dresden, Hochschule für angewandte Wissenschaften (FH)',
  },
  {
    statisticKey: '5130',
    label:
      'H für Technik, Wirtschaft und Kultur Leipzig, Hochschule für angewandte Wissenschaften (FH)',
  },
  {
    statisticKey: '5140',
    label:
      'H Mittweida (University of Applied Sciences), Hochschule für angewandte Wissenschaften (FH)',
  },
  {
    statisticKey: '5151',
    label:
      'H Zittau/Görlitz, Hochschule für angewandte Wissenschaften in Zittau (FH)',
  },
  {
    statisticKey: '5152',
    label:
      'H Zittau/Görlitz, Hochschule für angewandte Wissenschaften in Görlitz (FH)',
  },
  {
    statisticKey: '5161',
    label:
      'Westsächsische H Zwickau, Hochschule für angewandte Wissenschaften in Zwickau (FH)',
  },
  {
    statisticKey: '5162',
    label:
      'Westsächsische H Zwickau, Hochschule für angewandte Wissenschaften in Schneeberg (FH)',
  },
  {
    statisticKey: '5163',
    label:
      'Westsächsische H Zwickau, Hochschule für angewandte Wissenschaften in Reichenbach (FH)',
  },
  { statisticKey: '5170', label: 'Evangelische Hochschule Moritzburg (FH)' },
  {
    statisticKey: '5190',
    label: 'H der Sächsischen Polizei in Rothenburg/OL (FH)',
  },
  {
    statisticKey: '5191',
    label: 'H der Sächsischen Polizei in Bautzen (Verw-FH)',
  },
  {
    statisticKey: '5210',
    label: 'Hochschule für Angewandte Wissenschaften  Hamburg',
  },
  { statisticKey: '5220', label: 'HFH Hamburger Fern-Hochschule' },
  {
    statisticKey: '5230',
    label: 'Evangelische Hochschule für Soziale Arbeit Hamburg (FH)',
  },
  { statisticKey: '5240', label: 'Akademie der Polizei Hamburg (Verw-FH)' },
  { statisticKey: '5251', label: 'FH Südwestfalen, Abteilung Hagen' },
  { statisticKey: '5252', label: 'FH Südwestfalen, Abteilung Iserlohn' },
  { statisticKey: '5253', label: 'FH Südwestfalen, Abteilung Meschede' },
  { statisticKey: '5254', label: 'FH Südwestfalen, Abteilung Soest' },
  { statisticKey: '5260', label: 'Europäische Fernhochschule Hamburg' },
  { statisticKey: '5270', label: 'Mediadesign Hochschule, Berlin (Priv. FH)' },
  {
    statisticKey: '5271',
    label: 'Mediadesign Hochschule Berlin in München (Priv. FH)',
  },
  {
    statisticKey: '5272',
    label: 'Mediadesign Hochschule Berlin in Düsseldorf (Priv. FH)',
  },
  { statisticKey: '5280', label: 'Priv. FH Elmshorn (Nordakademie)' },
  {
    statisticKey: '5312',
    label: 'Hochschule Braunschweig-Wolfenbüttel in Wolfenbüttel (FH)',
  },
  {
    statisticKey: '5313',
    label: 'Hochschule Braunschweig-Wolfenbüttel in Wolfsburg (FH)',
  },
  {
    statisticKey: '5314',
    label: 'Hochschule Braunschweig-Wolfenbüttel in Salzgitter (FH)',
  },
  {
    statisticKey: '5315',
    label: 'Hochschule Braunschweig-Wolfenbüttel in Suderburg (FH)',
  },
  { statisticKey: '5330', label: 'Hochschule Hannover' },
  {
    statisticKey: '5381',
    label: 'Hochschule Hildesheim/Holzminden/Göttingen in Göttingen (FH)',
  },
  {
    statisticKey: '5382',
    label: 'Hochschule Hildesheim/Holzminden/Göttingen in Hildesheim (FH)',
  },
  {
    statisticKey: '5385',
    label: 'Hochschule Hildesheim/Holzminden/Göttingen in Holzminden (FH)',
  },
  {
    statisticKey: '5391',
    label: 'Fachhochschule für die Wirtschaft Hannover (FHDW) (Priv. FH)',
  },
  { statisticKey: '5400', label: 'Priv. FH Göttingen' },
  {
    statisticKey: '5411',
    label:
      'Priv. H für Wirtschaft und Technik Vechta/Diepholz in Diepholz (Priv. FH)',
  },
  {
    statisticKey: '5412',
    label:
      'Priv. H für Wirtschaft und Technik Vechta/Diepholz in Vechta (Priv. FH)',
  },
  { statisticKey: '5430', label: 'FH Ottersberg (Priv. FH)' },
  { statisticKey: '5501', label: 'Hochschule Osnabrück in Osnabrück (FH)' },
  { statisticKey: '5503', label: 'Hochschule Osnabrück in Lingen (FH)' },
  { statisticKey: '5660', label: 'H Bremerhaven' },
  { statisticKey: '5670', label: 'H für öffentl. Verwaltung Bremen' },
  { statisticKey: '5680', label: 'H Bremen' },
  {
    statisticKey: '5690',
    label: 'International School of Management Dortmund in Dortmund (Priv. FH)',
  },
  {
    statisticKey: '5691',
    label: 'International School of Management Dortmund in München (Priv. FH)',
  },
  {
    statisticKey: '5692',
    label:
      'International School of Management Dortmund in Stuttgart (Priv. FH)',
  },
  {
    statisticKey: '5693',
    label: 'International School of Management Dortmund in Köln (Priv. FH)',
  },
  {
    statisticKey: '5694',
    label: 'International School of Management Dortmund in Hamburg (Priv. FH)',
  },
  {
    statisticKey: '5695',
    label:
      'International School of Management Dortmund in Frankfurt (Priv. FH)',
  },
  {
    statisticKey: '5696',
    label: 'International School of Management Dortmund in Berlin (Priv. FH)',
  },
  { statisticKey: '5701', label: 'Westfälische H in Gelsenkirchen (FH)' },
  { statisticKey: '5702', label: 'Westfälische H in Bocholt (FH)' },
  { statisticKey: '5703', label: 'Westfälische H in Recklinghausen (FH)' },
  { statisticKey: '5711', label: 'FH Aachen, Abt. Aachen' },
  { statisticKey: '5712', label: 'FH Aachen, Abt. Jülich' },
  {
    statisticKey: '5720',
    label: 'IU Internationale Hochschule Erfurt in Bad Honnef (Priv. FH)',
  },
  {
    statisticKey: '5721',
    label: 'IU Internationale Hochschule Erfurt in Erfurt (Priv. FH)',
  },
  {
    statisticKey: '5722',
    label: 'IU Internationale Hochschule Erfurt in Berlin (Priv. FH)',
  },
  {
    statisticKey: '5723',
    label: 'IU Internationale Hochschule Erfurt in Bad Reichenhall (Pri v. FH)',
  },
  {
    statisticKey: '5724',
    label: 'IU Internationale Hochschule Erfurt in München (Priv. FH)',
  },
  {
    statisticKey: '5725',
    label: 'IU Internationale Hochschule Erfurt in Nürnberg (Priv. FH)',
  },
  {
    statisticKey: '5726',
    label: 'IU Internationale Hochschule Erfurt in Dortmund (Priv. FH)',
  },
  {
    statisticKey: '5727',
    label: 'IU Internationale Hochschule Erfurt in Düsseldorf (Priv. FH)',
  },
  {
    statisticKey: '5728',
    label: 'IU Internationale Hochschule Erfurt in Mannheim (Priv. FH)',
  },
  {
    statisticKey: '5729',
    label: 'IU Internationale Hochschule Erfurt in Hamburg (Priv. FH)',
  },
  {
    statisticKey: '572A',
    label: 'IU Internationale Hochschule Erfurt in Frankfurt (Priv. FH)',
  },
  {
    statisticKey: '572C',
    label: 'IU Internationale Hochschule  Erfurt in Augsburg (Priv. FH)',
  },
  {
    statisticKey: '572D',
    label: 'IU Internationale Hochschule Erfurt in Mainz (Priv. FH)',
  },
  {
    statisticKey: '572E',
    label: 'IU Internationale Hochschule Erfurt in Dresden (Priv. FH)',
  },
  {
    statisticKey: '572F',
    label: 'IU Internationale Hochschule Erfurt in Essen (Priv. FH)',
  },
  {
    statisticKey: '572G',
    label: 'IU Internationale Hochschule Erfurt in Köln (Priv. FH)',
  },
  {
    statisticKey: '572H',
    label: 'IU Internationale Hochschule Erfurt in Münster (Priv. FH)',
  },
  {
    statisticKey: '572J',
    label: 'IU Internationale Hochschule Erfurt in Lübeck (Priv. FH)',
  },
  {
    statisticKey: '572K',
    label: 'IU Internationale Hochschule  Erfurt in Hannover (Priv. FH)',
  },
  {
    statisticKey: '572L',
    label: 'IU Internationale Hochschule  Erfurt in Stuttgart (Priv. FH)',
  },
  { statisticKey: '5731', label: 'FH Bielefeld, Abt. Bielefeld' },
  { statisticKey: '5732', label: 'FH Bielefeld, Abt. Minden' },
  { statisticKey: '5733', label: 'FH Bielefeld in Gütersloh' },
  { statisticKey: '5751', label: 'Hochschule Bochum (FH) in Bochum' },
  {
    statisticKey: '5752',
    label: 'Hochschule Bochum (FH) in Velbert/Heiligenhaus',
  },
  { statisticKey: '5770', label: 'FH Dortmund' },
  { statisticKey: '5780', label: 'H Düsseldorf (FH)' },
  {
    statisticKey: '5800',
    label:
      'University of Europe for Applied Sciences Potsdam in Iserlohn (Priv. FH)',
  },
  {
    statisticKey: '5801',
    label:
      'University of Europe for Applied Sciences Potsdam in Potsdam (Priv. FH)',
  },
  {
    statisticKey: '5802',
    label:
      'University of Europe for Applied Sciences Potsdam in Berlin (Priv. FH)',
  },
  {
    statisticKey: '5803',
    label:
      'University of Europe for Applied Sciences Potsdam in Hamburg (Priv. FH)',
  },
  { statisticKey: '5820', label: 'FH des Mittelstandes (FHM) in Bielefeld' },
  { statisticKey: '5821', label: 'FH des Mittelstandes (FHM) in Köln' },
  {
    statisticKey: '5822',
    label: 'FH des Mittelstandes (FHM) in Frechen (Priv. FH)',
  },
  {
    statisticKey: '5823',
    label: 'FH des Mittelstandes (FHM) in Rostock (Priv.)',
  },
  {
    statisticKey: '5824',
    label: 'FH des Mittelstandes (FHM) in Schwerin (Priv.)',
  },
  {
    statisticKey: '5825',
    label: 'FH des Mittelstandes (FHM) in Hannover (Priv.)',
  },
  {
    statisticKey: '5826',
    label: 'FH des Mittelstandes (FHM) in Bamberg (Priv.) ',
  },
  {
    statisticKey: '5827',
    label: 'FH des Mittelstandes (FHM) in Berlin (Priv. FH)',
  },
  { statisticKey: '5831', label: 'Technische Hochschule Köln in Köln (FH)' },
  {
    statisticKey: '5832',
    label: 'Technische Hochschule Köln in Gummersbach (FH)',
  },
  {
    statisticKey: '5833',
    label: 'Technische Hochschule Köln in Leverkusen (FH)',
  },
  { statisticKey: '5841', label: 'H Niederrhein in Krefeld (FH)' },
  { statisticKey: '5842', label: 'H Niederrhein in Mönchengladbach (FH)' },
  { statisticKey: '5861', label: 'TH Ostwestfalen-Lippe in Lemgo (FH)' },
  { statisticKey: '5862', label: 'TH Ostwestfalen-Lippe in Detmold (FH)' },
  { statisticKey: '5863', label: 'TH Ostwestfalen-Lippe in Höxter (FH)' },
  { statisticKey: '5891', label: 'FH Münster, Abt. Münster' },
  { statisticKey: '5892', label: 'FH Münster, Abt. Steinfurt' },
  {
    statisticKey: '5901',
    label: 'Priv. FH der Wirtschaft Paderborn, Abt. Paderborn',
  },
  {
    statisticKey: '5902',
    label: 'Priv. FH der Wirtschaft Paderborn, Abt. Bergisch-Gladbach',
  },
  {
    statisticKey: '5904',
    label: 'Priv. FH der Wirtschaft Paderborn, Abt. Bielefeld',
  },
  {
    statisticKey: '5905',
    label: 'Priv. FH der Wirtschaft Paderborn, Abt. Mettmann',
  },
  { statisticKey: '5980', label: 'TH Georg Agricola Bochum (Priv. FH)' },
  { statisticKey: '5990', label: 'Priv. Rheinische FH Köln in Köln' },
  { statisticKey: '5991', label: 'Priv. Rheinische FH Köln in Neuss' },
  {
    statisticKey: '6000',
    label: 'Evang. FH Rheinland-Westfalen-Lippe, Bochum',
  },
  {
    statisticKey: '6031',
    label: 'Kath. Hochschule Nordrhein-Westfalen in Köln (FH)',
  },
  {
    statisticKey: '6032',
    label: 'Kath. Hochschule Nordrhein-Westfalen in Aachen (FH)',
  },
  {
    statisticKey: '6033',
    label: 'Kath. Hochschule Nordrhein-Westfalen in Münster (FH)',
  },
  {
    statisticKey: '6034',
    label: 'Kath. Hochschule Nordrhein-Westfalen in Paderborn (FH)',
  },
  {
    statisticKey: '6071',
    label:
      'Hochschule für Polizei und öffentliche Verwaltung Nordrhein-Westfalen in Gelsenkirchen (Verw-FH)',
  },
  {
    statisticKey: '6072',
    label:
      'Hochschule für Polizei und öffentliche Verwaltung Nordrhein-Westfalen in Dortmund (Verw-FH)',
  },
  {
    statisticKey: '6074',
    label:
      'Hochschule für Polizei und öffentliche Verwaltung Nordrhein-Westfalen in Duisburg (Verw-FH)',
  },
  {
    statisticKey: '6075',
    label:
      'Hochschule für Polizei und öffentliche Verwaltung Nordrhein-Westfalen in Hagen (Verw-FH)',
  },
  {
    statisticKey: '6076',
    label:
      'Hochschule für Polizei und öffentliche Verwaltung Nordrhein-Westfalen in Köln (Verw-FH)',
  },
  {
    statisticKey: '6077',
    label:
      'Hochschule für Polizei und öffentliche Verwaltung Nordrhein-Westfalen in Münster (Verw-FH)',
  },
  {
    statisticKey: '607A',
    label:
      'Hochschule für Polizei und öffentliche Verwaltung Nordrhein-Westfalen in Bielefeld (Verw-FH)',
  },
  {
    statisticKey: '607B',
    label:
      'Hochschule für Polizei und öffentliche Verwaltung Nordrhein-Westfalen in Mülheim (Verw-FH)',
  },
  {
    statisticKey: '607C',
    label:
      'Hochschule für Polizei und öffentliche Verwaltung Nordrhein-Westfalen in Aachen (Verw-FH)',
  },
  {
    statisticKey: '607D',
    label:
      'Hochschule für Polizei und öffentliche Verwaltung Nordrhein-Westfalen in Herne (Verw-FH)',
  },
  { statisticKey: '6180', label: 'FH für Rechtspflege NW, Bad Münstereifel' },
  {
    statisticKey: '6190',
    label:
      'Hochschule für Finanzen Nordrhein-Westfalen in Nordkirchen (Verw-FH)',
  },
  {
    statisticKey: '6202',
    label:
      'Hochschule des Bundes für öffentliche Verwaltung, FB Sozialversicherung in Berlin',
  },
  {
    statisticKey: '6203',
    label:
      'Hochschule des Bundes für öffentliche Verwaltung, FB Wetterdienst in Langen',
  },
  {
    statisticKey: '6205',
    label:
      'Hochschule des Bundes für öffentliche Verwaltung, FB Auswärtige Angelegenheiten in Berlin',
  },
  {
    statisticKey: '6206',
    label:
      'Hochschule des Bundes für öffentliche Verwaltung, FB Allgemeine innere Verwaltung in Brühl ',
  },
  {
    statisticKey: '6207',
    label:
      'Hochschule des Bundes für öff. Verwaltung, FB Nachrichtendienste Abteilung Verfassungsschutz in Brühl',
  },
  {
    statisticKey: '6208',
    label:
      'Hochschule des Bundes für öffentliche Verwaltung, FB Finanzen in Münster ',
  },
  {
    statisticKey: '620B',
    label:
      'Hochschule des Bundes für öffentliche Verwaltung, FB Bundeswehrverwaltung in Mannheim',
  },
  {
    statisticKey: '620C',
    label:
      'Hochschule des Bundes für öffentliche Verwaltung, FB Kriminalpolizei in Wiesbaden',
  },
  {
    statisticKey: '620D',
    label:
      'Hochschule des Bundes für öffentliche Verwaltung, FB Bundespolizei in Lübeck',
  },
  {
    statisticKey: '620E',
    label:
      'Hochschule des Bundes für öffentl. Verwaltung, FB Landwirtschaftliche Sozialversicherung in Kassel',
  },
  {
    statisticKey: '620F',
    label:
      'Hochschule des Bundes für öffentliche Verwaltung, FB Nachrichtendienste in Berlin',
  },
  { statisticKey: '6211', label: 'h_da - H Darmstadt in Darmstadt (FH)' },
  { statisticKey: '6212', label: 'h_da - H Darmstadt in Dieburg (FH)' },
  {
    statisticKey: '6220',
    label: 'Frankfurt University of Applied Sciences (FH)',
  },
  {
    statisticKey: '6231',
    label: 'Technische Hochschule Mittelhessen (THM) in Gießen',
  },
  {
    statisticKey: '6232',
    label: 'Technische Hochschule Mittelhessen (THM) in Friedberg',
  },
  {
    statisticKey: '6233',
    label: 'Technische Hochschule Mittelhessen (THM) in Wetzlar',
  },
  { statisticKey: '6243', label: 'Hochschule RheinMain, Rüsselsheim' },
  { statisticKey: '6244', label: 'Hochschule RheinMain, Wiesbaden' },
  {
    statisticKey: '6271',
    label: 'Evangelische Hochschule Darmstadt (EHD) in Darmstadt (FH)',
  },
  {
    statisticKey: '6272',
    label: 'Evangelische Hochschule Darmstadt (EHD) in Schwalmstadt (FH)',
  },
  {
    statisticKey: '6281',
    label: 'Hochschule Fresenius Idstein in Idstein (Priv. FH)',
  },
  {
    statisticKey: '6282',
    label: 'Hochschule Fresenius Idstein in Köln (Priv. FH) ',
  },
  {
    statisticKey: '6284',
    label: 'Hochschule Fresenius Idstein in München (Priv. FH)',
  },
  {
    statisticKey: '6285',
    label: 'Hochschule Fresenius Idstein in Hamburg (Priv. FH)',
  },
  {
    statisticKey: '6286',
    label: 'Hochschule Fresenius Idstein in Frankfurt (Priv. FH)',
  },
  {
    statisticKey: '6287',
    label: 'Hochschule Fresenius Idstein in Berlin (Priv. FH)',
  },
  {
    statisticKey: '6288',
    label: 'Hochschule Fresenius Idstein in Düsseldorf (Priv. FH)  ',
  },
  {
    statisticKey: '6289',
    label: 'Hochschule Fresenius Idstein in Wiesbaden (Priv. FH)',
  },
  { statisticKey: '6290', label: 'Hochschule Fulda (FH)' },
  { statisticKey: '6320', label: 'FH für Archivwesen Marburg' },
  {
    statisticKey: '6330',
    label: 'Hessische H für Finanzen und Rechtspflege in Rotenburg (Verw-FH)',
  },
  {
    statisticKey: '6342',
    label:
      'Hessische Hochschule für öffentliches Management und Sicherheit Wiesbaden in Mühlheim (Verw-FH)',
  },
  {
    statisticKey: '6343',
    label:
      'Hessische Hochschule für öffentliches Management und Sicherheit Wiesbaden in Gießen (Verw-FH)',
  },
  {
    statisticKey: '6344',
    label:
      'Hessische Hochschule für öffentliches Management und S icherheit Wiesbaden in Kassel (Verw-FH)',
  },
  {
    statisticKey: '6345',
    label:
      'Hessische Hochschule für öffentliches Management und Sicherheit Wiesbaden in Wiesbaden (Verw-FH)',
  },
  {
    statisticKey: '6360',
    label:
      'H der Deutschen Gesetzl. Unfallversicherung, Bad Hersfeld (Priv. FH)',
  },
  {
    statisticKey: '6370',
    label: 'Frankfurt School of Finance & Management -HfB (Priv. H)',
  },
  {
    statisticKey: '6380',
    label: 'FH für öffentliche Verwaltung und Rechtspflege Meißen',
  },
  {
    statisticKey: '6390',
    label: 'Wilhelm Büchner Hochschule Darmstadt (Priv. Fern-FH)',
  },
  {
    statisticKey: '6401',
    label: 'DIPLOMA - FH Nordhessen in Bad Sooden-Allendorf (Priv. FH)',
  },
  {
    statisticKey: '6402',
    label: 'DIPLOMA - FH Nordhessen in Kassel (Priv. FH)',
  },
  {
    statisticKey: '6411',
    label: 'Europäische Fachhochschule (EUFH) Rhein/Erft in Brühl',
  },
  {
    statisticKey: '6412',
    label: 'Europäische Fachhochschule (EUFH) Rhein/Erft in Neuss',
  },
  {
    statisticKey: '6413',
    label: 'Europäische Fachhochschule (EUFH) Rhein/Erft in Rostock (Priv.)',
  },
  {
    statisticKey: '6414',
    label: 'Europäische Fachhochschule (EUFH) Rhein/Erft in Köln (Priv. FH)',
  },
  {
    statisticKey: '6415',
    label: 'Europäische Fachhochschule (EUFH) Rhein/Erft in Rheine (Priv. FH)',
  },
  {
    statisticKey: '6416',
    label: 'Europäische Fachhochschule (EUFH) Rhein/Erft in Berlin (Pri v. FH)',
  },
  { statisticKey: '6430', label: 'Theologische Hochschule Elstal (FH)' },
  { statisticKey: '6510', label: 'Technische Hochschule Bingen (FH)' },
  { statisticKey: '6521', label: 'FH Kaiserslautern, Abt. Kaiserslautern' },
  { statisticKey: '6522', label: 'FH Kaiserslautern, Abt. Pirmasens' },
  { statisticKey: '6523', label: 'FH Kaiserslautern, Abt. Zweibrücken' },
  { statisticKey: '6531', label: 'Hochschule Koblenz (FH) in Koblenz' },
  {
    statisticKey: '6532',
    label: 'Hochschule Koblenz (FH) in Höhr-Grenzhausen',
  },
  { statisticKey: '6533', label: 'Hochschule Koblenz (FH) in Remagen' },
  { statisticKey: '6581', label: 'Hochschule Trier (FH) in Trier' },
  { statisticKey: '6582', label: 'Hochschule Trier (FH) in Birkenfeld' },
  { statisticKey: '6583', label: 'Hochschule Trier (FH) in Idar-Oberstein' },
  { statisticKey: '6600', label: 'Katholische Hochschule Mainz (FH)' },
  {
    statisticKey: '6620',
    label: 'Hochschule der Deutschen Bundesbank Hachenburg',
  },
  {
    statisticKey: '6631',
    label:
      'Hochschule für öffentliche Verwaltung Rheinland-Pfalz in Mayen (FB Innere Verwaltung) (FH)',
  },
  {
    statisticKey: '6640',
    label: 'Hochschule für Finanzen Rheinland-Pfalz in Edenkoben (FH)',
  },
  {
    statisticKey: '6650',
    label: 'Hochschule für Wirtschaft und Gesellschaft Ludwigshafen (FH)',
  },
  { statisticKey: '6660', label: 'FH Worms' },
  { statisticKey: '6670', label: 'FH Mainz' },
  {
    statisticKey: '6680',
    label: 'Hochschule für angewandtes Management, Ismaning (Priv. FH)',
  },
  {
    statisticKey: '6690',
    label:
      'Provadis School of International Management and Technology Frankfurt (Priv. FH)',
  },
  {
    statisticKey: '6710',
    label: 'Hochschule Aalen - Technik und Wirtschaft in Aalen (FH) ',
  },
  {
    statisticKey: '6711',
    label: 'Hochschule Aalen - Technik und Wirtschaft in Schwäbisch Gmünd (FH)',
  },
  {
    statisticKey: '6720',
    label: 'H für Bauwesen und Wirtschaft Biberach a.d. Riß (FH)',
  },
  { statisticKey: '6731', label: 'Hochschule Esslingen in Esslingen (FH)' },
  { statisticKey: '6732', label: 'Hochschule Esslingen in Göppingen (FH)' },
  {
    statisticKey: '6741',
    label: 'H für Technik und Wirtschaft Furtwangen (FH), Abt. Furtwangen',
  },
  {
    statisticKey: '6742',
    label:
      'H für Technik und Wirtschaft Furtwangen (FH), Abt. Villingen-Schwenningen',
  },
  {
    statisticKey: '6743',
    label: 'H für Technik und Wirtschaft Furtwangen (FH), Abt. Tuttlingen',
  },
  {
    statisticKey: '6751',
    label: 'H für Technik und Wirtschaft Heilbronn (FH), Abt. Heilbronn',
  },
  {
    statisticKey: '6752',
    label: 'H für Technik und Wirtschaft Heilbronn (FH), Abt. Künzelsau',
  },
  {
    statisticKey: '6753',
    label: 'H für Technik und Wirtschaft Heilbronn (FH), Abt. Schwäbisch-Hall',
  },
  { statisticKey: '6760', label: 'H Technik und Wirtschaft Karlsruhe (FH)' },
  {
    statisticKey: '6770',
    label: 'H für Technik, Wirtschaft und Gestaltung Konstanz (FH)',
  },
  { statisticKey: '6780', label: 'Hochschule Mannheim (FH)' },
  {
    statisticKey: '6791',
    label:
      'H für Wirtschaft, Landwirtschaft und Landespflege Nürtingen (FH), Abt. Nürtingen',
  },
  {
    statisticKey: '6792',
    label:
      'H für Wirtschaft, Landwirtschaft und Landespflege Nürtingen (FH), Abt. Geislingen',
  },
  {
    statisticKey: '6800',
    label: 'H für Technik und Wirtschaft Offenburg (FH)',
  },
  {
    statisticKey: '6810',
    label: 'H für Gestaltung, Technik und Wirtschaft Pforzheim (FH)',
  },
  {
    statisticKey: '6820',
    label: 'H für Technik und Sozialwesen Ravensburg-Weingarten (FH)',
  },
  {
    statisticKey: '6830',
    label: 'H für Technik und Wirtschaft Reutlingen (FH)',
  },
  {
    statisticKey: '6841',
    label:
      'H für Technik und Wirtschaft Albstadt-Sigmaringen (FH), Abt. Albstadt',
  },
  {
    statisticKey: '6842',
    label:
      'H für Technik und Wirtschaft Albstadt-Sigmaringen (FH), Abt. Sigmaringen',
  },
  { statisticKey: '6850', label: 'Hochschule für Technik Stuttgart (FH)' },
  {
    statisticKey: '6860',
    label: 'Fachhochschule Stuttgart - Hochschule der Medien',
  },
  { statisticKey: '6870', label: 'Technische Hochschule Ulm (FH)' },
  {
    statisticKey: '6890',
    label: 'Naturwissenschaftlich-Technische Akademie Isny (Priv. FH)',
  },
  { statisticKey: '6900', label: 'Ev. Hochschule Freiburg (FH)' },
  {
    statisticKey: '6910',
    label: 'Katholische Hochschule Freiburg, Campus Freiburg',
  },
  {
    statisticKey: '6911',
    label: 'Katholische Hochschule Freiburg, Campus Stuttgart',
  },
  {
    statisticKey: '6970',
    label:
      'Merz Akademie Hochschule für Gestaltung, Kunst und Medien, Stuttgart (Priv. FH)',
  },
  { statisticKey: '7020', label: 'AKAD Fachhochschule Stuttgart' },
  { statisticKey: '7050', label: 'H für Gestaltung Schwäbisch Gmünd (FH)' },
  { statisticKey: '7060', label: 'H für öffentl. Verwaltung Kehl (VerwFH)' },
  { statisticKey: '7070', label: 'Hochschule Ludwigsburg (FH)' },
  { statisticKey: '7090', label: 'SRH Hochschule Heidelberg (Priv. FH)' },
  {
    statisticKey: '7110',
    label: 'H für Forstwirtschaft Rottenburg a. Neckar (FH)',
  },
  { statisticKey: '7120', label: 'H für Rechtspflege Schwetzingen (VerwFH)' },
  {
    statisticKey: '7140',
    label: 'H für Polizei Villingen-Schwenningen (VerwFH)',
  },
  { statisticKey: '7180', label: 'SRH Fernhochschule Riedlingen (Priv. FH)' },
  {
    statisticKey: '7190',
    label: 'Evang. Hochschule Ludwigsburg in Ludwigsburg (FH)',
  },
  {
    statisticKey: '7192',
    label: 'Evang. Hochschule Ludwigsburg in Reutlingen (FH)',
  },
  {
    statisticKey: '7210',
    label: 'Hochschule für angewandte Wissenschaften Augsburg (FH)',
  },
  {
    statisticKey: '7220',
    label: 'Hochschule für angewandte Wissenschaften Coburg (FH)',
  },
  {
    statisticKey: '7240',
    label: 'Hochschule für angewandte Wissenschaften München (FH)',
  },
  {
    statisticKey: '7250',
    label: 'Technische Hochschule Nürnberg Georg Simon Ohm (FH)',
  },
  {
    statisticKey: '7260',
    label: 'Ostbayerische Technische Hochschule Regensburg (FH)',
  },
  { statisticKey: '7300', label: 'TH Rosenheim in Rosenheim (FH)' },
  { statisticKey: '7301', label: 'TH Rosenheim in Mühldorf a. Inn (FH)' },
  { statisticKey: '7303', label: 'TH Rosenheim in Burghausen (FH)' },
  {
    statisticKey: '7311',
    label:
      'Hochschule für angewandte Wissenschaften Weihenstephan-Triesdorf in Weihenstephan (FH)',
  },
  {
    statisticKey: '7312',
    label:
      'Hochschule für angewandte Wissenschaften Weihenstephan-Triesdorf in Triesdorf (FH)',
  },
  {
    statisticKey: '7313',
    label:
      'Hochschule für angewandte Wissenschaften Weihenstephan-Triesdorf in Straubing (FH)',
  },
  {
    statisticKey: '7351',
    label:
      'Hochschule für angewandte Wissenschaften Würzburg-Schweinfurt in Würzburg (FH)',
  },
  {
    statisticKey: '7352',
    label:
      'Hochschule für angewandte Wissenschaften Würzburg-Schweinfurt in Schweinfurt (FH)',
  },
  { statisticKey: '7370', label: 'Evang. Hochschule Nürnberg (FH)' },
  {
    statisticKey: '7381',
    label: 'Katholische Stiftungshochschule München in München (Kirchl. FH)',
  },
  {
    statisticKey: '7382',
    label:
      'Katholische Stiftungshochschule München in Benediktbeuern (Kirchl. FH)',
  },
  {
    statisticKey: '7440',
    label: 'Hochschule für angewandte Wissenschaften Kempten (FH)',
  },
  {
    statisticKey: '7450',
    label: 'Hochschule für angewandte Wissenschaften Landshut (FH)',
  },
  {
    statisticKey: '7461',
    label:
      'Hochschule für den öffentlichen Dienst in Bayern Standort Fürstenfeldbruck (Polizei)',
  },
  {
    statisticKey: '7462',
    label:
      'Hochschule für den öffentlichen Dienst in Bayern Standort Herrsching (Finanzwesen)',
  },
  {
    statisticKey: '7463',
    label:
      'Hochschule für den öffentlichen Dienst in Bayern Standort Hof (Allg. Innere Verwaltung)',
  },
  {
    statisticKey: '7464',
    label:
      'Hochschule für den öffentlichen Dienst in Bayern Standort München (Archiv- u. Biblioth.)',
  },
  {
    statisticKey: '7465',
    label:
      'Hochschule für den öffentlichen Dienst in Bayern Standort Starnberg (Rechtspflege)',
  },
  {
    statisticKey: '7466',
    label:
      'Hochschule für den öffentlichen Dienst in Bayern Standort Wasserburg (Sozialverwaltung)',
  },
  {
    statisticKey: '746A',
    label:
      'Hochschule für den öffentlichen Dienst in Bayern Standort Sulzbach-Rosenberg (Polizei) ',
  },
  {
    statisticKey: '746B',
    label:
      'Hochschule für den öffentlichen Dienst in Bayern Standort Kaufbeuren (Finanzwesen) ',
  },
  {
    statisticKey: '746C',
    label:
      'Hochschule für den öffentlichen Dienst in Bayern Standort Dorfen (Sozialverwaltung) (Verw-FH)',
  },
  {
    statisticKey: '7521',
    label: 'Technische Hochschule Deggendorf in Deggendorf (FH)',
  },
  {
    statisticKey: '7522',
    label: 'Technische Hochschule Deggendorf im Landkreis Rottal-Inn (FH)',
  },
  {
    statisticKey: '7523',
    label: 'Technische Hochschule Deggendorf in Cham (FH)',
  },
  {
    statisticKey: '7531',
    label: 'Hochschule für angewandte Wissenschaften Hof in Hof (FH)',
  },
  {
    statisticKey: '7532',
    label: 'Hochschule für angewandte Wissenschaften Hof in Münchberg (FH)',
  },
  {
    statisticKey: '7540',
    label: 'Technische Hochschule Ingolstadt in Ingolstadt (FH)',
  },
  {
    statisticKey: '7541',
    label: 'Technische Hochschule Ingolstadt in Neuburg a. d. Donau (FH)',
  },
  {
    statisticKey: '7551',
    label: 'Ostbayerische Technische Hochschule Amberg-Weiden (FH) in Amberg',
  },
  {
    statisticKey: '7552',
    label: 'Ostbayerische Technische Hochschule Amberg-Weiden (FH) in Weiden',
  },
  {
    statisticKey: '7560',
    label: 'Hochschule für angewandte Wissenschaften Ansbach in Ansbach (FH)',
  },
  {
    statisticKey: '7561',
    label:
      'Hochschule für angewandte Wissenschaften Ansbach in Weißenburg (FH)',
  },
  {
    statisticKey: '7562',
    label:
      'Hochschule für angewandte Wissenschaften Ansbach in Rothenburg (FH)',
  },
  {
    statisticKey: '7570',
    label: 'Hochschule für angewandte Wissenschaften Neu-Ulm (FH)',
  },
  { statisticKey: '7580', label: 'Munich Business School München (Priv. FH)' },
  { statisticKey: '7590', label: 'TH Aschaffenburg (FH)' },
  {
    statisticKey: '7610',
    label: 'H für Technik und Wirtschaft des Saarlandes Saarbrücken',
  },
  { statisticKey: '7630', label: 'FH für Verwaltung Saarbrücken' },
  { statisticKey: '7670', label: 'accadis Hochschule Bad Homburg (Priv. FH)' },
  { statisticKey: '7680', label: 'Theologisches Seminar Reutlingen (ev.)' },
  {
    statisticKey: '7690',
    label: 'Hochschule für Technik und Wirtschaft Berlin (FH)',
  },
  { statisticKey: '7700', label: 'Kath. Hochschule für Sozialwesen Berlin' },
  { statisticKey: '7710', label: 'Berliner Hochschule für Technik (FH)' },
  { statisticKey: '7721', label: 'HWR Berlin, FB Wirtschaft' },
  {
    statisticKey: '7722',
    label: 'HS für Wirtschaft und Recht Berlin, FB Duales Studium (FH)',
  },
  { statisticKey: '7723', label: 'HWR Berlin, FB Verwaltung, Recht, Polizei' },
  { statisticKey: '7730', label: 'Alice Salomon Hochschule Berlin (FH)' },
  { statisticKey: '7750', label: 'Evangelische Hochschule Berlin (FH)' },
  { statisticKey: '7780', label: 'Hochschule Neubrandenburg (FH)' },
  { statisticKey: '7790', label: 'Hochschule Stralsund (FH)' },
  { statisticKey: '7810', label: 'FH Erfurt' },
  { statisticKey: '7820', label: 'Hochschule Jena (FH)' },
  { statisticKey: '7830', label: 'Hochschule Schmalkalden (FH)' },
  { statisticKey: '7840', label: 'Hochschule Wismar (FH)' },
  {
    statisticKey: '7850',
    label: 'FH für Finanzen Brandenburg in Königs Wusterhausen (VerwFH)',
  },
  {
    statisticKey: '7860',
    label: 'Thüringer FH für öffentl. Verwaltung in Gotha',
  },
  { statisticKey: '7880', label: 'Hochschule Nordhausen (FH)' },
  {
    statisticKey: '7890',
    label: 'Hochschule der Polizei Brandenburg (VerwFH) Oranienburg',
  },
  {
    statisticKey: '7900',
    label:
      'FH für öffentliche Verwaltung, Polizei und Rechtspflege in Güstrow (Verw-FH)',
  },
  { statisticKey: '7910', label: 'Technische Hochschule Brandenburg (FH)' },
  {
    statisticKey: '7920',
    label: 'Hochschule für nachhaltige Entwicklung Eberswalde (FH)',
  },
  { statisticKey: '7940', label: 'Technische Hochschule Wildau (FH)' },
  { statisticKey: '7950', label: 'FH Potsdam' },
  { statisticKey: '7960', label: 'Private Universität Steinbeis, Berlin' },
  {
    statisticKey: '7971',
    label: 'SRH Berlin University of Applied Sciences in Berlin (Priv. FH)',
  },
  {
    statisticKey: '7972',
    label: 'SRH Berlin University of Applied Sciences in Dresden (Priv. FH)',
  },
  {
    statisticKey: '7973',
    label: 'SRH Berlin University of Applied Sciences in Hamburg (Priv. FH)',
  },
  {
    statisticKey: '7990',
    label: 'Norddeutsche Akademie für Finanzen und Steuerrecht Hamburg',
  },
  { statisticKey: '8000', label: 'Hochschule Merseburg (FH)' },
  {
    statisticKey: '8011',
    label: 'Hochschule Magdeburg-Stendal (FH), Abt. Magdeburg',
  },
  {
    statisticKey: '8012',
    label: 'Hochschule Magdeburg-Stendal (FH), Abt. Stendal',
  },
  { statisticKey: '8021', label: 'Hochschule Harz (FH), Abt. Wernigerode' },
  { statisticKey: '8022', label: 'Hochschule Harz (FH), Abt. Halberstadt' },
  { statisticKey: '8031', label: 'Hochschule Anhalt (FH), Abt. Bernburg' },
  { statisticKey: '8032', label: 'Hochschule Anhalt (FH), Abt. Dessau' },
  { statisticKey: '8033', label: 'Hochschule Anhalt (FH), Abt. Köthen' },
  {
    statisticKey: '8060',
    label: 'FH Polizei Sachsen-Anhalt, Aschersleben (VerwFH)',
  },
  {
    statisticKey: '8080',
    label: 'SRH Hochschule Nordrhein-Westfalen Hamm in Hamm (Priv. FH)',
  },
  {
    statisticKey: '8081',
    label: 'SRH Hochschule Nordrhein-Westfalen Hamm in Leverkusen (Priv. FH)',
  },
  {
    statisticKey: '8090',
    label: 'HSBA School of Business Administration Hamburg (Priv. FH)',
  },
  {
    statisticKey: '8101',
    label:
      'FH für Ökonomie und Management (Priv. FH für Berufstätige) Essen, Abt. Essen',
  },
  {
    statisticKey: '8102',
    label:
      'FH für Ökonomie und Management (Priv. FH für Berufstätige) Essen, Abt. Duisburg',
  },
  {
    statisticKey: '8103',
    label:
      'FH für Ökonomie und Management (Priv. FH für Berufstätige) Essen, Abt. Neuss',
  },
  {
    statisticKey: '8104',
    label:
      'FH für Ökonomie und Management (Priv. FH für Berufstätige) Essen, Abt. Köln',
  },
  {
    statisticKey: '8105',
    label:
      'FH für Ökonomie und Management (Priv. FH für Berufstätige) Essen, Abt. Gütersloh',
  },
  {
    statisticKey: '8106',
    label:
      'FH für Ökonomie und Management (Priv. FH für Berufstätige) Essen, Abt. Siegen',
  },
  {
    statisticKey: '8107',
    label:
      'FH für Ökonomie und Management (Priv. FH für Berufstätige) Essen, Abt. Marl',
  },
  {
    statisticKey: '8108',
    label:
      'FH für Ökonomie und Management (Priv. FH für Berufstätige) Essen, Abt. Düsseldorf',
  },
  {
    statisticKey: '8109',
    label:
      'FH für Ökonomie und Management (Priv. FH für Berufstätige) Essen, Abt. Dortmund',
  },
  {
    statisticKey: '810A',
    label:
      'Priv. FH für Ökonomie und Management (Priv. FH für Berufstätige) Essen, Abt. Bonn',
  },
  {
    statisticKey: '810B',
    label: 'Priv. FH für Ökonomie und Management Essen in Aachen',
  },
  {
    statisticKey: '810C',
    label: 'Priv. FH für Ökonomie und Management Essen in Wuppertal',
  },
  {
    statisticKey: '810D',
    label:
      'FH für Ökonomie und Management (Priv. FH für Berufstätige) Essen in Bochum',
  },
  {
    statisticKey: '810E',
    label:
      'FH für Ökonomie und Management (Priv. FH für Berufstätige) Essen in Bönen',
  },
  {
    statisticKey: '810F',
    label:
      'FH für Ökonomie und Management (Priv. FH für Berufstätige) Essen in Hagen',
  },
  {
    statisticKey: '810G',
    label:
      'FH für Ökonomie und Management (Priv. FH für Berufstätige) Essen in Münster',
  },
  {
    statisticKey: '810H',
    label:
      'FH für Ökonomie und Management (Priv. FH für Berufstätige) Essen in Wesel',
  },
  {
    statisticKey: '810J',
    label: 'Priv. FH für Ökonomie und Management Essen in Bremen',
  },
  {
    statisticKey: '810K',
    label: 'Priv. FH für Ökonomie und Management Essen in Leipzig',
  },
  {
    statisticKey: '810L',
    label: 'Priv. FH für Ökonomie und Management Essen in Frankfurt ',
  },
  {
    statisticKey: '810M',
    label: 'Priv. FH für Ökonomie und Management Essen in Kassel',
  },
  {
    statisticKey: '810N',
    label: 'Priv. FH für Ökonomie und Management Essen in Augsburg',
  },
  {
    statisticKey: '810P',
    label: 'Priv. FH für Ökonomie und Management Essen in München',
  },
  {
    statisticKey: '810Q',
    label: 'Priv. FH für Ökonomie und Management Essen in Nürnberg',
  },
  {
    statisticKey: '810R',
    label: 'Priv. FH für Ökonomie und Management Essen in Hannover',
  },
  {
    statisticKey: '810S',
    label: 'Priv. FH für Ökonomie und Management Essen in Berlin',
  },
  {
    statisticKey: '810T',
    label: 'Priv. FH für Ökonomie und Management Essen in Mannheim',
  },
  {
    statisticKey: '810U',
    label: 'Priv. FH für Ökonomie und Management Essen in Stuttgart',
  },
  {
    statisticKey: '810V',
    label: 'Priv. FH für Ökonomie und Management Essen in Hamburg',
  },
  {
    statisticKey: '810W',
    label: 'Priv. FH für Ökonomie und Management Essen in Karlsruhe',
  },
  {
    statisticKey: '810X',
    label: 'Priv. FH für Ökonomie und Management Essen in Saarbrücken',
  },
  {
    statisticKey: '810Y',
    label: 'Priv. FH für Ökonomie und Management Essen in Arnsberg',
  },
  { statisticKey: '8111', label: 'H Bonn-Rhein-Sieg in St. Augustin (FH)' },
  { statisticKey: '8112', label: 'H Bonn-Rhein-Sieg in Rheinbach (FH)' },
  { statisticKey: '8113', label: 'H Bonn-Rhein-Sieg in Hennef (FH)' },
  { statisticKey: '8140', label: 'Hochschule21, Buxtehude (Priv. FH)' },
  {
    statisticKey: '8150',
    label: 'Karlshochschule International University, Karlsruhe ',
  },
  {
    statisticKey: '8171',
    label: 'Duale Hochschule Baden-Württemberg Mosbach, Campus Bad Mergentheim',
  },
  {
    statisticKey: '8172',
    label:
      'Duale Hochschule Baden-Württemberg Ravensburg, Campus Friedrichshafen',
  },
  {
    statisticKey: '8173',
    label: 'Duale Hochschule Baden-Württemberg in Heidenheim',
  },
  {
    statisticKey: '8174',
    label: 'Duale Hochschule Baden-Württemberg Stuttgart, Campus Horb',
  },
  {
    statisticKey: '8175',
    label: 'Duale Hochschule Baden-Württemberg in Karlsruhe',
  },
  {
    statisticKey: '8176',
    label: 'Duale Hochschule Baden-Württemberg in Lörrach',
  },
  {
    statisticKey: '8177',
    label: 'Duale Hochschule Baden-Württemberg in Mannheim',
  },
  {
    statisticKey: '8178',
    label: 'Duale Hochschule Baden-Württemberg in Mosbach',
  },
  {
    statisticKey: '8179',
    label: 'Duale Hochschule Baden-Württemberg in Ravensburg',
  },
  {
    statisticKey: '817A',
    label: 'Duale Hochschule Baden-Württemberg in Stuttgart',
  },
  {
    statisticKey: '817C',
    label: 'Duale Hochschule Baden-Württemberg in Villingen-Schwenningen',
  },
  {
    statisticKey: '817D',
    label: 'Duale Hochschule Baden-Württemberg in Heilbronn',
  },
  {
    statisticKey: '817E',
    label: 'Duale Hochschule Baden-Württemberg Präsidium in Stuttgart',
  },
  {
    statisticKey: '817F',
    label: 'Duale Hochschule Baden-Württemberg CAS in Heilbronn',
  },
  {
    statisticKey: '8180',
    label: 'Hochschule der Polizei Rheinland-Pfalz in Büchenbeuren (FH)',
  },
  {
    statisticKey: '8191',
    label: 'Charlotte Fresenius Hochschule Wiesbaden in Wiesbaden (Priv . U)',
  },
  { statisticKey: '9000', label: 'Sonstige deutsche Hochschule' },
  { statisticKey: '9500', label: 'Berufsakademie in Deutschland' },
  { statisticKey: '9990', label: 'Hochschule im Ausland' },
];
