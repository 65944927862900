import { getField } from 'vuex-map-fields';
import { Getters } from './types';

export default {
  getField,
  [Getters.CURRENT_ERROR](state) {
    return state.errors.length > 0 ? state.errors[0] : null;
  },
  [Getters.LOADING](state) {
    return state.loadingCounter !== 0;
  },
  [Getters.SELECT_LISTS](state) {
    return state.selectLists;
  },
  [Getters.SNACKBAR](state) {
    return state.snackbar;
  },
  [Getters.HAS_ERRORS](state) {
    return Boolean(state.errors.length);
  },
};
