<template>
  <e-chip :class="healthInsuranceClass" data-test="profile__health-content">
    {{ healthInsuranceContent }}
  </e-chip>
</template>

<script>
import { EChip } from '@careerpartner/nitro';
import { HEALTHINSURANCE_INSURED_STATUS } from '@/common/constants';
export default {
  name: 'HealthInsuranceInsuredStatus',
  components: {
    EChip,
  },
  props: {
    value: { type: String, default: undefined },
  },
  computed: {
    healthInsuranceClass() {
      const mapping = {
        [undefined]: 'bg-gray-300 text-gray-800',
        [HEALTHINSURANCE_INSURED_STATUS.open]: 'bg-info text-white',
        [HEALTHINSURANCE_INSURED_STATUS.insured]: 'bg-success text-white',
        [HEALTHINSURANCE_INSURED_STATUS.notInsured]: 'bg-error text-white',
        [HEALTHINSURANCE_INSURED_STATUS.exempt]: 'bg-success text-white',
        [HEALTHINSURANCE_INSURED_STATUS.excluded]: 'bg-success text-white',
        [HEALTHINSURANCE_INSURED_STATUS.paymentDelayed]: 'bg-info text-white',
        [HEALTHINSURANCE_INSURED_STATUS.recorded]: 'bg-info text-white',
      };
      return mapping[this.value] ?? mapping[undefined];
    },
    healthInsuranceContent() {
      const mapping = {
        [undefined]: 'unbekannt',
        [HEALTHINSURANCE_INSURED_STATUS.open]: 'offen',
        [HEALTHINSURANCE_INSURED_STATUS.insured]: 'Liegt vor',
        [HEALTHINSURANCE_INSURED_STATUS.notInsured]: 'Ist Befreit',
        [HEALTHINSURANCE_INSURED_STATUS.exempt]: 'Ist Befreit',
        [HEALTHINSURANCE_INSURED_STATUS.excluded]: 'Ist Befreit',
        [HEALTHINSURANCE_INSURED_STATUS.paymentDelayed]: 'Ausstehend',
        [HEALTHINSURANCE_INSURED_STATUS.recorded]: 'Im Zahlungsverzug',
      };
      return mapping[this.value] ?? this.value;
    },
  },
};
</script>
