<template>
  <section-structure has-image :image-src="graduatedStudyImageURL">
    <template #headline>Letzte Abschlussprüfung</template>

    <div class="col-span-full">
      <validation-provider
        v-slot="{ errors }"
        name="Latest Academic Finals | Hochschule der letzten Abschlussprüfung"
        slim
      >
        <e-multiselect
          id="graduated-university"
          v-model="universityCode"
          :errors="errors"
          data-test="statistics__latest-university"
          :options="selectLists.universities"
          track-by="statisticKey"
          option-label="label"
          label="Hochschule der letzten Abschlussprüfung"
          searchable
          mapped
        />
      </validation-provider>
    </div>

    <div v-if="isNonGermanUniversity" class="col-span-full">
      <validation-provider
        v-slot="{ errors }"
        name="Latest Academic Finals | Hochschule der letzten Abschlussprüfung"
        slim
        rules="required"
        :skip-if-empty="false"
        immediate
      >
        <e-multiselect
          id="graduated-country"
          v-model="countryCode"
          data-test="statistics__latest-country"
          :errors="errors"
          :options="countriesWithoutGermany"
          track-by="statisticKey"
          option-label="label"
          label="Land"
          searchable
          mapped
        />
      </validation-provider>
    </div>

    <div class="col-span-full">
      <validation-provider
        v-slot="{ errors }"
        name="Latest Academic Finals | Art der Abschlussprüfung"
        slim
      >
        <e-multiselect
          id="graduated-examination"
          v-model="examinationType"
          :errors="errors"
          data-test="statistics__latest-type"
          :options="selectLists.graduationTypes"
          track-by="statisticKey"
          option-label="label"
          label="Art der Abschlussprüfung"
          searchable
          mapped
        />
      </validation-provider>
    </div>

    <div class="col-span-full flex space-x-2">
      <validation-provider
        v-slot="{ errors }"
        name="Latest Academic Finals | Studienfach"
        slim
      >
        <e-multiselect
          id="graduated-course"
          v-model="studyCourse"
          :errors="errors"
          data-test="statistics__latest-course"
          :options="selectLists.studyCourses"
          track-by="statisticKey"
          option-label="label"
          label="Studienfach"
          searchable
          mapped
        />
      </validation-provider>
      <e-tooltip
        content="Falls das gewünschte Studienfach nicht auffindbar ist, sollte eine möglichst inhaltsnahe Zuordnung erfolgen. So würde man z.B. “Sporttourismus”  zu “Tourismuswirtschaft” zuordnen."
        class="items-center justify-center text-cta"
      >
        <e-icon icon="mdi-information-outline" size="2xl" />
      </e-tooltip>
    </div>
    <div class="col-span-full">
      <validation-provider
        ref="graduated-year"
        name="Monat und Jahr der letzten Prüfung"
      >
        <e-datepicker
          id="graduated-year"
          v-model="parseGraduationDate"
          data-test="statistics__latest-monthyear"
          label="Monat und Jahr der letzten Prüfung"
          picker-type="month"
          format="MM/YYYY"
          :max-date="new Date().toISOString()"
        />
      </validation-provider>
    </div>

    <div class="col-span-full">
      <validation-provider name="Latest Academic Finals | Prüfungsergebnis">
        <e-radio-group
          v-model="pass"
          name="graduated-pass"
          label="Prüfungsergebnis"
          data-test="statistics__latest-pass"
          :options="[
            { value: '01', label: 'bestanden' },
            { value: '02', label: 'nicht bestanden' },
          ]"
        />
      </validation-provider>
    </div>
  </section-structure>
</template>

<script>
import { imagePaths } from '@/common/content';
import { ValidationProvider } from 'vee-validate';
import { mapFields } from 'vuex-map-fields';
import {
  EMultiselect,
  ERadioGroup,
  EDatepicker,
  EIcon,
  ETooltip,
} from '@careerpartner/nitro';
import SectionStructure from '@/components/SectionStructure';
import selectLists from '@/mixins/selectLists';
import { IS_NON_GERMAN_UNIVERSITY } from '@/common/constants';

export default {
  name: 'LatestAcademicFinals',
  components: {
    SectionStructure,
    ValidationProvider,
    EDatepicker,
    EIcon,
    ETooltip,
    EMultiselect,
    ERadioGroup,
  },
  mixins: [selectLists],
  computed: {
    ...mapFields('academicHistory', [
      'latestAcademicFinals.universityCode',
      'latestAcademicFinals.countryCode',
      'latestAcademicFinals.examinationType',
      'latestAcademicFinals.studyCourse',
      'latestAcademicFinals.month',
      'latestAcademicFinals.year',
      'latestAcademicFinals.pass',
    ]),
    graduatedStudyImageURL() {
      return imagePaths.STATISTIC_GRADUATED_STUDY;
    },
    isNonGermanUniversity() {
      return IS_NON_GERMAN_UNIVERSITY(this.universityCode);
    },
    countriesWithoutGermany() {
      return this.selectLists.hzbCountries.filter(
        (country) => country.statisticKey !== '000',
      );
    },
    parseGraduationDate: {
      get() {
        const month = this.month || null;
        const year = this.year || null;

        return year && month ? `${year}-${month}` : null;
      },
      set(val) {
        if (val) {
          this.year = Number(val.substr(0, 4));
          this.month = Number(val.substr(5, 2));
        }
      },
    },
  },
};
</script>
