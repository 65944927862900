<template>
  <validation-observer
    ref="email-form"
    v-slot="{ invalid }"
    vid="email-form"
    slim
  >
    <form
      class="grid grid-cols-12 gap-x-6 gap-y-7 mt-9 px-4 pb-8"
      data-test="profile__email-modal"
    >
      <div class="col-span-6">
        <validation-provider
          v-slot="{ errors }"
          name="Art der E-Mail"
          rules="required"
        >
          <e-multiselect
            id="email-contacttype"
            v-model="email.contactType"
            data-test="profile__email-edit-type"
            :options="EMAIL_TYPES"
            label="Art der E-Mail-Adresse"
            option-label="label"
            track-by="key"
            required
            :errors="errors"
            mapped
          />
        </validation-provider>
      </div>
      <div class="col-span-full">
        <validation-provider
          v-slot="{ errors }"
          name="E-Mail"
          :rules="{
            required: true,
            customEmail: {
              value: '@email',
              contactType: email.contactType,
            },
          }"
        >
          <e-text-field
            id="email"
            v-model="email.email"
            data-test="profile__email-edit-email"
            label="E-Mail"
            wide
            required
            :errors="errors"
          />
        </validation-provider>
      </div>
      <p class="mt-2 text-xs text-gray-700 col-span-full">* Pflichtfelder</p>

      <last-changed-by
        :updated-at="email.updatedAt"
        :updated-by="email.updatedBy"
      />
      <div class="col-span-full flex h-9">
        <e-button
          variant="primary"
          :disabled="invalid"
          class="ml-auto mt-18"
          @click="saveEmail"
        >
          <e-icon icon="mdi-content-save-outline" class="mr-2"></e-icon>
          Speichern</e-button
        >
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { OVERLAY_EVENTS, VALID_IU_ADDRESSES } from '@/common/constants';
import EditModalMixin from '@/components/overlays/EditModalMixin';
import LastChangedBy from '@/components/overlays/shared/LastChangedBy';

export default {
  name: 'EmailOverlay',
  components: { LastChangedBy },
  mixins: [EditModalMixin],
  beforeMount() {
    this.email = JSON.parse(JSON.stringify(this.value));
    if (!this.email.contactType) {
      this.email.contactType = 'default';

      if (
        VALID_IU_ADDRESSES.some((iumail) => this.email.email.includes(iumail))
      ) {
        this.email.contactType = 'iu';
      }
    }
  },
  methods: {
    async saveEmail() {
      if (await this.validateForm('email-form')) {
        this.$emit(OVERLAY_EVENTS.SUBMIT, {
          email: this.email,
        });
      }

      this.closeOverlay();
    },
  },
};
</script>
