import { Actions, Getters, Mutations } from '../types';
import { API_ENDPOINTS } from '@/common/constants';
import { ApiServiceWrapper } from '@/utils/apiServiceWrapper';

export default {
  [Actions.CERTIFY_HZB]({ commit }, payload) {
    commit(Mutations.SET_CERTIFY_HZB, payload);
  },
  async [Actions.LOAD_UNIVERSITY_ENTRANCE_QUALIFICATION]({ commit }, id) {
    const GET_URL = `${API_ENDPOINTS.CUSTOMER_PROFILES}/${id}/university-entrance-qualification`;

    const { data } = await ApiServiceWrapper.get(GET_URL);

    if (!data.certifiedOn) {
      commit(Mutations.SET_UNIVERSITY_ENTRANCE_QUALIFICATION, {
        ...data,
        certifiedOn: null,
      });
    } else {
      commit(Mutations.SET_UNIVERSITY_ENTRANCE_QUALIFICATION, data);
    }
  },
  async [Actions.SAVE_UNIVERSITY_ENTRANCE_QUALIFICATION]({ getters, commit }) {
    const { universityEntranceQualification } = getters[Getters.PROFILE];
    const id = getters[Getters.PROFILE_ID];

    const payload = universityEntranceQualification;

    const PUT_URL = `${API_ENDPOINTS.CUSTOMER_PROFILES}/${id}/university-entrance-qualification`;

    const { data } = await ApiServiceWrapper.put(PUT_URL, payload);
    commit(Mutations.SET_UNIVERSITY_ENTRANCE_QUALIFICATION, data);
  },
};
