<template>
  <div>
    <e-modal
      auto-height
      size="lg"
      :is-open="overlayVisible"
      display-close-button
      :title="currentModalTitle"
      @close="closeOverlay"
    >
      <edit-address-form
        v-model="currentContactPoint"
        @[OVERLAY_EVENTS.CLOSE]="toggleOverlay"
        @[OVERLAY_EVENTS.SUBMIT]="submitAddress"
      />
    </e-modal>
    <div>
      <h4 class="font-bold text-sm">Adresse</h4>
      <e-contact-group>
        <e-contact-detail
          v-for="address in addresses"
          :key="address.id"
          :is-primary="isPrimary(address)"
        >
          <template #title>
            <h5 data-test="profile__address-type">
              {{ ADDRESS_TYPES[address.contactType] || ADDRESS_TYPES.default }}
            </h5>
          </template>
          <template #tags>
            <e-chip
              v-for="tag in addressTags(address)"
              :key="tag"
              data-test="profile__address-tags"
              default-style
            >
              {{ tag }}
            </e-chip>
          </template>

          <template #data>
            <p class="mt-1" data-test="profile__address-display">
              {{ address.streetAddress }} <br />
              <span v-if="address.addressAdditional">
                {{ address.addressAdditional }} <br />
              </span>
              {{ address.postalCode }} {{ address.addressLocality }}<br />
              {{ getCountryName(address.addressCountry) }}
            </p>
          </template>
          <template #actions>
            <e-menu
              :actions="actions(address)"
              align-right
              @onEdit="editContact(CONTACT_POINTS.ADDRESS, address)"
              @setAsPermanentAddress="setAsPermanentAddress(address)"
              @setAsInvoice="setAsInvoice(address)"
              @setAsShipping="setAsShipping(address)"
              @onDelete="deleteContact(CONTACT_POINTS.ADDRESS, address)"
            >
              <template #activator="{ interact }">
                <e-button
                  variant="text"
                  small
                  data-test="profile__address-manage"
                  @click="interact"
                  ><e-icon icon="mdi-chevron-down"></e-icon>Verwalten</e-button
                >
              </template>
            </e-menu>
          </template>
        </e-contact-detail>
      </e-contact-group>
    </div>
    <e-button
      variant="text"
      class="mt-4"
      data-test="profile__add-address"
      @click="add(CONTACT_POINTS.ADDRESS)"
    >
      <e-icon icon="mdi-plus" />
      Adresse hinzufügen
    </e-button>
  </div>
</template>

<script>
import EditAddressForm from '@/components/overlays/EditAddressForm';
import selectLists from '@/mixins/selectLists';
import { CONTACT_POINTS, USAGE_TYPES } from '@/common/constants';
import ContactPointMixin from '@/components/Profile/Contact/ContactPointMixin';

export default {
  name: 'AddressComponent',
  components: {
    EditAddressForm,
  },
  mixins: [selectLists, ContactPointMixin],
  methods: {
    actions(address) {
      return [
        {
          id: 'onEdit',
          text: 'bearbeiten',
          disabled: false,
        },
        {
          id: 'setAsPermanentAddress',
          text: 'als Meldeadresse festlegen',
          disabled: address.usageType.includes('permanent'),
        },
        {
          id: 'setAsShipping',
          text: 'als Lieferadresse festlegen',
          disabled: address.usageType.includes('shipping'),
        },
        {
          id: 'setAsInvoice',
          text: 'als Rechnungsadresse festlegen',
          disabled: address.usageType.includes('billing'),
        },
        {
          id: 'onDelete',
          text: 'löschen',
          disabled: this.isPrimary(address),
        },
      ];
    },
    getCountryName(country) {
      const foundCountry = this.selectLists.countries.find(
        (el) => el.countryIso === country,
      );
      return foundCountry ? foundCountry.name : '';
    },
    addressTags(contact) {
      return (contact.usageType || [])
        .filter((el) => el !== 'primary')
        .map((el) => USAGE_TYPES[el]);
    },
    setAsPermanentAddress(address) {
      const addressDirty = { ...address };
      addressDirty.usageType.push('permanent');
      this.submitAddress({ address: addressDirty });
    },
    setAsShipping(address) {
      const addressDirty = { ...address };
      addressDirty.usageType.push('shipping');
      this.submitAddress({ address: addressDirty });
    },
    setAsInvoice(address) {
      const addressDirty = { ...address };
      addressDirty.usageType.push('billing');
      this.submitAddress({ address: addressDirty });
    },
    submitAddress({ address }) {
      if (this.currentIsNew) {
        if (this.addresses.length === 0) {
          address.usageType.push('shipping');
        }

        this.saveContactPoint({
          contactPoint: address,
          target: this.CONTACT_POINTS.ADDRESS,
        });
      } else {
        this.updateContactPoint({
          contactPoint: address,
          target: CONTACT_POINTS.ADDRESS,
        });
      }
    },
  },
};
</script>
