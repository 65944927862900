import { Actions, Mutations, Getters } from './types';
import { Getters as ProfileGetters } from '../profile/types';
import { Actions as AppActions } from '../app/types';
import { API_ENDPOINTS } from '@/common/constants';
import { ApiServiceWrapper } from '@/utils/apiServiceWrapper';

export default {
  async [Actions.LOAD_ACADEMIC_HISTORY]({ commit }, id) {
    const CUSTOMER_STATISTICS_URL = `${API_ENDPOINTS.CUSTOMER_PROFILES}/${id}/academic-history`;
    const { data } = await ApiServiceWrapper.get(CUSTOMER_STATISTICS_URL);

    if (
      !Object.keys(data?.parallelAcademicStudies).includes('studies') ||
      !data.parallelAcademicStudies.studies.length
    ) {
      data.parallelAcademicStudies.studies = [
        { studyCourse: null },
        { studyCourse: null },
        { studyCourse: null },
      ];
    }

    commit(Mutations.SET_ACADEMIC_HISTORY, data);
  },
  async [Actions.UPDATE_ACADEMIC_HISTORY]({ dispatch, getters, rootGetters }) {
    const id = rootGetters[`profile/${ProfileGetters.PROFILE_ID}`];
    const payload = getters[Getters.ACADEMIC_HISTORY];

    const defaultTo0 = (value) => {
      if (isNaN(value) || !value) {
        return 0;
      }
      return parseInt(value, 10);
    };

    // Map strings to int
    payload.termStatistic = {
      ...payload.termStatistic,
      regularTerms: defaultTo0(payload.termStatistic.regularTerms),
      holidayTerms: defaultTo0(payload.termStatistic.holidayTerms),
      practicalTerms: defaultTo0(payload.termStatistic.practicalTerms),
      preparatoryTerms: defaultTo0(payload.termStatistic.preparatoryTerms),
      interruptionTerms: defaultTo0(payload.termStatistic.interruptionTerms),
    };

    const PUT_URL = `${API_ENDPOINTS.CUSTOMER_PROFILES}/${id}/academic-history`;
    await ApiServiceWrapper.put(PUT_URL, payload);
    dispatch(Actions.LOAD_ACADEMIC_HISTORY, id);

    dispatch(
      `app/${AppActions.UPDATE_SNACKBAR}`,
      { message: 'Statistik aktualisiert.' },
      { root: true },
    );
  },
};
