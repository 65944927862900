import {
  EButton,
  EIcon,
  EMultiselect,
  ETextField,
  ETooltip,
} from '@careerpartner/nitro';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  ADDRESS_TYPES_AS_ARRAY,
  EMAIL_TYPES_AS_ARRAY,
  OVERLAY_EVENTS,
  PHONE_TYPES_AS_ARRAY,
} from '@/common/constants';
import '@/common/validationExtends';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    EButton,
    EMultiselect,
    ETextField,
    EIcon,
    ETooltip,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      address: {},
      email: {},
      phone: {},
    };
  },
  computed: {
    EMAIL_TYPES() {
      return EMAIL_TYPES_AS_ARRAY;
    },
    PHONE_TYPES() {
      return PHONE_TYPES_AS_ARRAY;
    },
    ADDRESS_TYPES() {
      return ADDRESS_TYPES_AS_ARRAY;
    },
  },
  methods: {
    closeOverlay() {
      this.$emit(OVERLAY_EVENTS.CLOSE, null);
    },
    async validateForm(ref) {
      return await this.$refs[ref].validate();
    },
  },
};
