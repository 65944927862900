export const Mutations = {
  SET_CERTIFY_HZB: 'setCertifyHZB',
  RESET_PROFILE: 'resetProfile',
  SET_PROFILE: 'setProfile',
  SET_CONTACT_POINT: 'setContactPoint',
  SET_IDENTITY_MANAGEMENT: 'setIdentityManagement',
  SET_HAS_FETCHED_PROFILE: 'setHasFetchedProfile',
  SET_HEALTH_INSURANCE: 'setHealthInsurance',
  SET_UNIVERSITY_ENTRANCE_QUALIFICATION: 'setUniversityEntranceQualification',
  SET_HEALTH_INSURANCE_MESSAGES: 'setHealthInsuranceMessages',
};

export const Actions = {
  CERTIFY_HZB: 'certifyHZB',
  DELETE_CONTACT_POINT: 'deleteContactPoint',
  LOAD_CONTACT_POINTS: 'loadProfileContactPoints',
  LOAD_HEALTH_INSURANCE: 'loadProfileHealthInsurance',
  CANCEL_OUTGOING_MESSAGE: 'cancelOutgoingMessage',
  CORRECT_OUTGOING_MESSAGE: 'correctOutgoingMessage',
  LOAD_HEALTH_INSURANCE_MESSAGES: 'loadHealthInsuranceMessages',
  SAVE_HEALTH_INSURANCE_MESSAGE: 'saveHealthInsuranceMessage',
  UNLINK_HEALTH_INSURANCE_PROFILE: 'unlinkHealthInsuranceProfile',
  LOAD_IDENTITY_MANAGEMENT: 'loadProfileIdentityManagement',
  RESET_PROFILE: 'resetProfile',
  LOAD_PROFILE: 'loadProfile',
  LOAD_SINGLE_CONTACT_POINT: 'loadProfileContactPoint',
  LOAD_UNIVERSITY_ENTRANCE_QUALIFICATION: 'loadUniversityEntranceQualification',
  SAVE_ALL_CHANGES: 'saveAllChanges',
  SAVE_NEW_CONTACT_POINT: 'saveNewContactPoint',
  SAVE_PROFILE: 'saveProfile',
  SAVE_UNIVERSITY_ENTRANCE_QUALIFICATION: 'saveUniversityEntranceQualification',
  SAVE_IDENTITY_MANAGEMENT: 'saveProfileIdentityManagement',
  UPDATE_CONTACT_POINT: 'updateContactPoint',
};

export const Getters = {
  FULL_NAME: 'fullName',
  HAS_CERTIFIED_HZB: 'hasCertifiedHZB',
  PROFILE: 'profile',
  PROFILE_ADDRESSES: 'profileAddresses',
  PROFILE_BOOKING: 'profileBooking',
  PROFILE_EMAILS: 'profileEmails',
  PROFILE_ID: 'profileId',
  PROFILE_IMAGE_URL: 'profileImageUrl',
  PROFILE_INITIALS: 'profileInitals',
  PROFILE_PHONES: 'profilePhones',
  PROFILE_DAM_FOLDER_URL: 'profileDamUrl',
  INSURANCE_PROFILE_ID: 'insuranceProfileId',
  INSURANCE_MESSAGES_PAGINATION: 'insuranceMessagesPagination',
};
