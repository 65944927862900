export const countries = [
  {
    careCountryId: '43',
    name: 'Deutschland',
    countryIso: 'DE',
    countryCallingCode: '+49',
  },
  {
    careCountryId: '158',
    name: 'Österreich',
    countryIso: 'AT',
    countryCallingCode: '+43',
  },
  {
    careCountryId: '186',
    name: 'Schweiz',
    countryIso: 'CH',
    countryCallingCode: '+41',
  },
  {
    careCountryId: '3',
    name: 'Albanien',
    countryIso: 'AL',
    countryCallingCode: '+355',
  },
  {
    careCountryId: '6',
    name: 'Andorra',
    countryIso: 'AD',
    countryCallingCode: '+376',
  },
  {
    careCountryId: '22',
    name: 'Belgien',
    countryIso: 'BE',
    countryCallingCode: '+32',
  },
  {
    careCountryId: '28',
    name: 'Bosnien und Herzegowina',
    countryIso: 'BA',
    countryCallingCode: '+387',
  },
  {
    careCountryId: '34',
    name: 'Bulgarien',
    countryIso: 'BG',
    countryCallingCode: '+359',
  },
  {
    careCountryId: '42',
    name: 'Dänemark',
    countryIso: 'DK',
    countryCallingCode: '+45',
  },
  {
    careCountryId: '51',
    name: 'Estland',
    countryIso: 'EE',
    countryCallingCode: '+372',
  },
  {
    careCountryId: '55',
    name: 'Finnland',
    countryIso: 'FI',
    countryCallingCode: '+358',
  },
  {
    careCountryId: '56',
    name: 'Frankreich',
    countryIso: 'FR',
    countryCallingCode: '+33',
  },
  {
    careCountryId: '66',
    name: 'Griechenland',
    countryIso: 'GR',
    countryCallingCode: '+30',
  },
  {
    careCountryId: '68',
    name: 'Großbritannien',
    countryIso: 'GB',
    countryCallingCode: '+44',
  },
  {
    careCountryId: '83',
    name: 'Irland',
    countryIso: 'IE',
    countryCallingCode: '+353',
  },
  {
    careCountryId: '84',
    name: 'Island',
    countryIso: 'IS',
    countryCallingCode: '+354',
  },
  {
    careCountryId: '86',
    name: 'Italien',
    countryIso: 'IT',
    countryCallingCode: '+39',
  },
  {
    careCountryId: '108',
    name: 'Kroatien',
    countryIso: 'HR',
    countryCallingCode: '+358',
  },
  {
    careCountryId: '240',
    name: 'Kosovo',
    countryIso: 'XK',
    countryCallingCode: '+383',
  },
  {
    careCountryId: '113',
    name: 'Lettland',
    countryIso: 'LV',
    countryCallingCode: '+371',
  },
  {
    careCountryId: '117',
    name: 'Liechtenstein',
    countryIso: 'LI',
    countryCallingCode: '+423',
  },
  {
    careCountryId: '118',
    name: 'Litauen',
    countryIso: 'LT',
    countryCallingCode: '+370',
  },
  {
    careCountryId: '119',
    name: 'Luxemburg',
    countryIso: 'LU',
    countryCallingCode: '+352',
  },
  {
    careCountryId: '126',
    name: 'Malta',
    countryIso: 'MT',
    countryCallingCode: '+356',
  },
  {
    careCountryId: '136',
    name: 'Moldawien',
    countryIso: 'MD',
    countryCallingCode: '+373',
  },
  {
    careCountryId: '137',
    name: 'Monaco',
    countryIso: 'MC',
    countryCallingCode: '+377',
  },
  {
    careCountryId: '239',
    name: 'Montenegro',
    countryIso: 'ME',
    countryCallingCode: '+382',
  },

  {
    careCountryId: '148',
    name: 'Niederlande',
    countryIso: 'NL',
    countryCallingCode: '+31',
  },
  {
    careCountryId: '156',
    name: 'Norwegen',
    countryIso: 'NO',
    countryCallingCode: '+47',
  },
  {
    careCountryId: '168',
    name: 'Polen',
    countryIso: 'PL',
    countryCallingCode: '+48',
  },
  {
    careCountryId: '169',
    name: 'Portugal',
    countryIso: 'PT',
    countryCallingCode: '+351',
  },
  {
    careCountryId: '173',
    name: 'Rumänien',
    countryIso: 'RO',
    countryCallingCode: '+40',
  },
  {
    careCountryId: '174',
    name: 'Russland',
    countryIso: 'RU',
    countryCallingCode: '+7',
  },
  {
    careCountryId: '182',
    name: 'San Marino',
    countryIso: 'SM',
    countryCallingCode: '+378',
  },
  {
    careCountryId: '185',
    name: 'Schweden',
    countryIso: 'SE',
    countryCallingCode: '+46',
  },
  {
    careCountryId: '188',
    name: 'Serbien',
    countryIso: 'RS',
    countryCallingCode: '+381',
  },
  {
    careCountryId: '192',
    name: 'Slowakei',
    countryIso: 'SK',
    countryCallingCode: '+421',
  },
  {
    careCountryId: '193',
    name: 'Slowenien',
    countryIso: 'SI',
    countryCallingCode: '+386',
  },
  {
    careCountryId: '196',
    name: 'Spanien',
    countryIso: 'ES',
    countryCallingCode: '+34',
  },
  {
    careCountryId: '214',
    name: 'Tschechische Republik',
    countryIso: 'CZ',
    countryCallingCode: '+420',
  },
  {
    careCountryId: '216',
    name: 'Türkei',
    countryIso: 'TR',
    countryCallingCode: '+90',
  },
  {
    careCountryId: '221',
    name: 'Ukraine',
    countryIso: 'UA',
    countryCallingCode: '+380',
  },
  {
    careCountryId: '222',
    name: 'Ungarn',
    countryIso: 'HU',
    countryCallingCode: '+36',
  },
  {
    careCountryId: '234',
    name: 'Belarus ',
    countryIso: 'BY',
    countryCallingCode: '+375',
  },
  {
    careCountryId: '237',
    name: 'Zypern',
    countryIso: 'CY',
    countryCallingCode: '+356',
  },
  {
    careCountryId: '1',
    name: 'Afghanistan',
    countryIso: 'AF',
    countryCallingCode: '+93',
  },
  {
    careCountryId: '2',
    name: 'Ägypten',
    countryIso: 'EG',
    countryCallingCode: '+20',
  },

  {
    careCountryId: '4',
    name: 'Algerien',
    countryIso: 'DZ',
    countryCallingCode: '+213',
  },
  {
    careCountryId: '5',
    name: 'Amerikanisch-Samoa',
    countryIso: 'AS',
    countryCallingCode: '+1-684',
  },

  {
    careCountryId: '7',
    name: 'Angola',
    countryIso: 'AO',
    countryCallingCode: '+244',
  },
  {
    careCountryId: '8',
    name: 'Anguilla',
    countryIso: 'AI',
    countryCallingCode: '+1-264',
  },
  {
    careCountryId: '9',
    name: 'Antarktis',
    countryIso: 'AQ',
    countryCallingCode: '+672',
  },
  {
    careCountryId: '10',
    name: 'Antigua und Barbuda',
    countryIso: 'AG',
    countryCallingCode: '+1-268',
  },
  {
    careCountryId: '11',
    name: 'Äquatorialguinea',
    countryIso: 'GQ',
    countryCallingCode: '+240',
  },
  {
    careCountryId: '12',
    name: 'Argentinien',
    countryIso: 'AR',
    countryCallingCode: '+54',
  },
  {
    careCountryId: '13',
    name: 'Armenien',
    countryIso: 'AM',
    countryCallingCode: '+374',
  },
  {
    careCountryId: '14',
    name: 'Aruba',
    countryIso: 'AW',
    countryCallingCode: '+294',
  },
  {
    careCountryId: '15',
    name: 'Aserbaidschan',
    countryIso: 'AZ',
    countryCallingCode: '+994',
  },
  {
    careCountryId: '16',
    name: 'Äthiopien',
    countryIso: 'ET',
    countryCallingCode: '+251',
  },
  {
    careCountryId: '17',
    name: 'Australien',
    countryIso: 'AU',
    countryCallingCode: '+61',
  },
  {
    careCountryId: '18',
    name: 'Bahamas',
    countryIso: 'BS',
    countryCallingCode: '+1-242',
  },
  {
    careCountryId: '19',
    name: 'Bahrain',
    countryIso: 'BH',
    countryCallingCode: '+973',
  },
  {
    careCountryId: '20',
    name: 'Bangladesch',
    countryIso: 'BD',
    countryCallingCode: '+880',
  },
  {
    careCountryId: '21',
    name: 'Barbados',
    countryIso: 'BB',
    countryCallingCode: '+1-264',
  },

  {
    careCountryId: '23',
    name: 'Belize',
    countryIso: 'BZ',
    countryCallingCode: '+501',
  },
  {
    careCountryId: '24',
    name: 'Benin',
    countryIso: 'BJ',
    countryCallingCode: '+229',
  },
  {
    careCountryId: '25',
    name: 'Bermuda',
    countryIso: 'BM',
    countryCallingCode: '+1-441',
  },
  {
    careCountryId: '26',
    name: 'Bhutan',
    countryIso: 'BT',
    countryCallingCode: '+975',
  },
  {
    careCountryId: '27',
    name: 'Bolivien',
    countryIso: 'BO',
    countryCallingCode: '+591',
  },

  {
    careCountryId: '29',
    name: 'Botswana',
    countryIso: 'BW',
    countryCallingCode: '+267',
  },
  {
    careCountryId: '30',
    name: 'Bouvet-Insel',
    countryIso: 'BV',
    countryCallingCode: '+47',
  },
  {
    careCountryId: '31',
    name: 'Brasilien',
    countryIso: 'BR',
    countryCallingCode: '+55',
  },
  {
    careCountryId: '32',
    name: 'Britisches Territorium im Indischen Ozean',
    countryIso: 'IO',
    countryCallingCode: '+1-284',
  },
  {
    careCountryId: '33',
    name: 'Brunei Darussalam',
    countryIso: 'BN',
    countryCallingCode: '+673',
  },

  {
    careCountryId: '35',
    name: 'Burkina Faso',
    countryIso: 'BF',
    countryCallingCode: '+226',
  },
  {
    careCountryId: '36',
    name: 'Burundi',
    countryIso: 'BI',
    countryCallingCode: '+257',
  },
  {
    careCountryId: '37',
    name: 'Kaimaninseln',
    countryIso: 'KY',
    countryCallingCode: '+1-345',
  },
  {
    careCountryId: '38',
    name: 'Chile',
    countryIso: 'CL',
    countryCallingCode: '+56',
  },
  {
    careCountryId: '39',
    name: 'China',
    countryIso: 'CN',
    countryCallingCode: '+86',
  },
  {
    careCountryId: '40',
    name: 'Cookinseln',
    countryIso: 'CK',
    countryCallingCode: '+682',
  },
  {
    careCountryId: '41',
    name: 'Costa Rica',
    countryIso: 'CR',
    countryCallingCode: '+506',
  },

  {
    careCountryId: '44',
    name: 'Dominica',
    countryIso: 'DM',
    countryCallingCode: '+1-767',
  },
  {
    careCountryId: '45',
    name: 'Dominikanische Republik',
    countryIso: 'DO',
    countryCallingCode: '+1',
  },
  {
    careCountryId: '46',
    name: 'Dschibuti',
    countryIso: 'DJ',
    countryCallingCode: '+253',
  },
  {
    careCountryId: '47',
    name: 'Ecuador',
    countryIso: 'EC',
    countryCallingCode: '+593',
  },
  {
    careCountryId: '48',
    name: 'El Salvador',
    countryIso: 'SV',
    countryCallingCode: '+503',
  },
  {
    careCountryId: '50',
    name: 'Eritrea',
    countryIso: 'ER',
    countryCallingCode: '+291',
  },

  {
    careCountryId: '52',
    name: 'Falklandinseln',
    countryIso: 'FK',
    countryCallingCode: '+500',
  },
  {
    careCountryId: '53',
    name: 'Färöer Inseln',
    countryIso: 'FO',
    countryCallingCode: '+298',
  },
  {
    careCountryId: '54',
    name: 'Fidschi-Inseln',
    countryIso: 'FJ',
    countryCallingCode: '+679',
  },

  {
    careCountryId: '57',
    name: 'Französische Südpolar-Territorien',
    countryIso: 'TF',
    countryCallingCode: '+33',
  },
  {
    careCountryId: '58',
    name: 'Französisch-Guyana',
    countryIso: 'GF',
    countryCallingCode: '+594',
  },
  {
    careCountryId: '59',
    name: 'Französisch-Polynesien',
    countryIso: 'PF',
    countryCallingCode: '+689',
  },
  {
    careCountryId: '60',
    name: 'Gabun',
    countryIso: 'GA',
    countryCallingCode: '+241',
  },
  {
    careCountryId: '61',
    name: 'Gambia',
    countryIso: 'GM',
    countryCallingCode: '+220',
  },
  {
    careCountryId: '62',
    name: 'Georgien',
    countryIso: 'GE',
    countryCallingCode: '+995',
  },
  {
    careCountryId: '63',
    name: 'Ghana',
    countryIso: 'GH',
    countryCallingCode: '+233',
  },
  {
    careCountryId: '64',
    name: 'Gibraltar',
    countryIso: 'GI',
    countryCallingCode: '+350',
  },
  {
    careCountryId: '65',
    name: 'Grenada',
    countryIso: 'GD',
    countryCallingCode: '+1-473',
  },

  {
    careCountryId: '67',
    name: 'Grönland',
    countryIso: 'GL',
    countryCallingCode: '+299',
  },

  {
    careCountryId: '69',
    name: 'Guadeloupe',
    countryIso: 'GP',
    countryCallingCode: '+590',
  },
  {
    careCountryId: '70',
    name: 'Guam',
    countryIso: 'GU',
    countryCallingCode: '+1-671',
  },
  {
    careCountryId: '71',
    name: 'Guatemala',
    countryIso: 'GT',
    countryCallingCode: '+502',
  },
  {
    careCountryId: '72',
    name: 'Guinea',
    countryIso: 'GN',
    countryCallingCode: '+224',
  },
  {
    careCountryId: '73',
    name: 'Guinea-Bissau',
    countryIso: 'GW',
    countryCallingCode: '+245',
  },
  {
    careCountryId: '74',
    name: 'Guyana',
    countryIso: 'GY',
    countryCallingCode: '+592',
  },
  {
    careCountryId: '75',
    name: 'Haiti',
    countryIso: 'HT',
    countryCallingCode: '+509',
  },
  {
    careCountryId: '76',
    name: 'Heard- und McDonald-Inseln',
    countryIso: 'HM',
    countryCallingCode: '+672',
  },
  {
    careCountryId: '77',
    name: 'Honduras',
    countryIso: 'HN',
    countryCallingCode: '+504',
  },
  {
    careCountryId: '79',
    name: 'Indien',
    countryIso: 'IN',
    countryCallingCode: '+91',
  },
  {
    careCountryId: '80',
    name: 'Indonesien',
    countryIso: 'ID',
    countryCallingCode: '+62',
  },
  {
    careCountryId: '81',
    name: 'Irak',
    countryIso: 'IQ',
    countryCallingCode: '+964',
  },
  {
    careCountryId: '82',
    name: 'Iran',
    countryIso: 'IR',
    countryCallingCode: '+98',
  },

  {
    careCountryId: '85',
    name: 'Israel',
    countryIso: 'IL',
    countryCallingCode: '+972',
  },

  {
    careCountryId: '87',
    name: 'Jamaika',
    countryIso: 'JM',
    countryCallingCode: '+1-876',
  },
  {
    careCountryId: '88',
    name: 'Japan',
    countryIso: 'JP',
    countryCallingCode: '+81',
  },
  {
    careCountryId: '89',
    name: 'Jemen',
    countryIso: 'YE',
    countryCallingCode: '+967',
  },
  {
    careCountryId: '90',
    name: 'Jordanien',
    countryIso: 'JO',
    countryCallingCode: '+962',
  },
  {
    careCountryId: '91',
    name: 'Britische Jungferninseln',
    countryIso: 'VG',
    countryCallingCode: '+1-284',
  },
  {
    careCountryId: '92',
    name: 'Amerikanische Jungferninseln',
    countryIso: 'VI',
    countryCallingCode: '+1-340',
  },
  {
    careCountryId: '93',
    name: 'Kambodscha',
    countryIso: 'KH',
    countryCallingCode: '+855',
  },
  {
    careCountryId: '94',
    name: 'Kamerun',
    countryIso: 'CM',
    countryCallingCode: '+237',
  },
  {
    careCountryId: '95',
    name: 'Kanada',
    countryIso: 'CA',
    countryCallingCode: '+1',
  },
  {
    careCountryId: '96',
    name: 'Kap Verde',
    countryIso: 'CV',
    countryCallingCode: '+238',
  },
  {
    careCountryId: '97',
    name: 'Kasachstan',
    countryIso: 'KZ',
    countryCallingCode: '+7',
  },
  {
    careCountryId: '98',
    name: 'Katar',
    countryIso: 'QA',
    countryCallingCode: '+974',
  },
  {
    careCountryId: '99',
    name: 'Kenia',
    countryIso: 'KE',
    countryCallingCode: '+254',
  },
  {
    careCountryId: '100',
    name: 'Kirgistan',
    countryIso: 'KG',
    countryCallingCode: '+996',
  },
  {
    careCountryId: '101',
    name: 'Kiribati',
    countryIso: 'KI',
    countryCallingCode: '+686',
  },
  {
    careCountryId: '102',
    name: 'Kokosinseln',
    countryIso: 'CC',
    countryCallingCode: '+61',
  },
  {
    careCountryId: '103',
    name: 'Kolumbien',
    countryIso: 'CO',
    countryCallingCode: '+57',
  },
  {
    careCountryId: '104',
    name: 'Komoren',
    countryIso: 'KM',
    countryCallingCode: '+269',
  },
  {
    careCountryId: '105',
    name: 'Kongo',
    countryIso: 'CD',
    countryCallingCode: '+195',
  },
  {
    careCountryId: '106',
    name: 'Kongo, Demokratische Republik',
    countryIso: 'CG',
    countryCallingCode: '+243',
  },
  {
    careCountryId: '107',
    name: 'Korea, Demokratische Volksrepublik (Nordkorea)',
    countryIso: 'KP',
    countryCallingCode: '+850',
  },

  {
    careCountryId: '109',
    name: 'Kuba',
    countryIso: 'CU',
    countryCallingCode: '+53',
  },
  {
    careCountryId: '110',
    name: 'Kuwait',
    countryIso: 'KW',
    countryCallingCode: '+965',
  },
  {
    careCountryId: '111',
    name: 'Laos',
    countryIso: 'LA',
    countryCallingCode: '+856',
  },
  {
    careCountryId: '112',
    name: 'Lesotho',
    countryIso: 'LS',
    countryCallingCode: '+266',
  },

  {
    careCountryId: '114',
    name: 'Libanon',
    countryIso: 'LB',
    countryCallingCode: '+961',
  },
  {
    careCountryId: '115',
    name: 'Liberia',
    countryIso: 'LR',
    countryCallingCode: '+231',
  },
  {
    careCountryId: '116',
    name: 'Libyen',
    countryIso: 'LY',
    countryCallingCode: '+218',
  },

  {
    careCountryId: '120',
    name: 'Macao',
    countryIso: 'MO',
    countryCallingCode: '+853',
  },
  {
    careCountryId: '121',
    name: 'Madagaskar',
    countryIso: 'MG',
    countryCallingCode: '+261',
  },
  {
    careCountryId: '122',
    name: 'Malawi',
    countryIso: 'MW',
    countryCallingCode: '+265',
  },
  {
    careCountryId: '123',
    name: 'Malaysia',
    countryIso: 'MY',
    countryCallingCode: '+60',
  },
  {
    careCountryId: '124',
    name: 'Malediven',
    countryIso: 'MV',
    countryCallingCode: '+960',
  },
  {
    careCountryId: '125',
    name: 'Mali',
    countryIso: 'ML',
    countryCallingCode: '+223',
  },

  {
    careCountryId: '127',
    name: 'Marokko',
    countryIso: 'MA',
    countryCallingCode: '+212',
  },
  {
    careCountryId: '128',
    name: 'Marshall-Inseln',
    countryIso: 'MH',
    countryCallingCode: '+692',
  },
  {
    careCountryId: '129',
    name: 'Martinique',
    countryIso: 'MQ',
    countryCallingCode: '+596',
  },
  {
    careCountryId: '130',
    name: 'Mauretanien',
    countryIso: 'MR',
    countryCallingCode: '+222',
  },
  {
    careCountryId: '131',
    name: 'Mauritius',
    countryIso: 'MU',
    countryCallingCode: '+230',
  },
  {
    careCountryId: '132',
    name: 'Mayotte',
    countryIso: 'YT',
    countryCallingCode: '+262',
  },
  {
    careCountryId: '133',
    name: 'Mazedonien, ehemalige jugoslawische',
    countryIso: 'MK',
    countryCallingCode: '+389',
  },
  {
    careCountryId: '134',
    name: 'Mexiko',
    countryIso: 'MX',
    countryCallingCode: '+52',
  },
  {
    careCountryId: '135',
    name: 'Mikronesien',
    countryIso: 'FM',
    countryCallingCode: '+691',
  },

  {
    careCountryId: '138',
    name: 'Mongolei',
    countryIso: 'MN',
    countryCallingCode: '+976',
  },
  {
    careCountryId: '139',
    name: 'Montserrat',
    countryIso: 'MS',
    countryCallingCode: '+1-664',
  },
  {
    careCountryId: '140',
    name: 'Mosambik',
    countryIso: 'MZ',
    countryCallingCode: '+258',
  },
  {
    careCountryId: '141',
    name: 'Myanmar',
    countryIso: 'MM',
    countryCallingCode: '+95',
  },
  {
    careCountryId: '142',
    name: 'Namibia',
    countryIso: 'NA',
    countryCallingCode: '+264',
  },
  {
    careCountryId: '143',
    name: 'Nauru',
    countryIso: 'NR',
    countryCallingCode: '+674',
  },
  {
    careCountryId: '144',
    name: 'Nepal',
    countryIso: 'NP',
    countryCallingCode: '+977',
  },
  {
    careCountryId: '145',
    name: 'Neukaledonien',
    countryIso: 'NC',
    countryCallingCode: '+687',
  },
  {
    careCountryId: '146',
    name: 'Neuseeland',
    countryIso: 'NZ',
    countryCallingCode: '+64',
  },
  {
    careCountryId: '147',
    name: 'Nicaragua',
    countryIso: 'NI',
    countryCallingCode: '+505',
  },

  {
    careCountryId: '149',
    name: 'Niederländisch-Antillen',
    countryIso: 'AN',
    countryCallingCode: '+599',
  },
  {
    careCountryId: '150',
    name: 'Niger',
    countryIso: 'NE',
    countryCallingCode: '+227',
  },
  {
    careCountryId: '151',
    name: 'Nigeria',
    countryIso: 'NG',
    countryCallingCode: '+234',
  },
  {
    careCountryId: '152',
    name: 'Niue',
    countryIso: 'NU',
    countryCallingCode: '+683',
  },
  {
    careCountryId: '154',
    name: 'Nördliche Marianen',
    countryIso: 'MP',
    countryCallingCode: '+1-670',
  },
  {
    careCountryId: '155',
    name: 'Norfolk-Insel',
    countryIso: 'NF',
    countryCallingCode: '+6723',
  },

  {
    careCountryId: '157',
    name: 'Oman',
    countryIso: 'OM',
    countryCallingCode: '+968',
  },
  {
    careCountryId: '159',
    name: 'Osttimor',
    countryIso: 'TL',
    countryCallingCode: '+670',
  },
  {
    careCountryId: '160',
    name: 'Pakistan',
    countryIso: 'PK',
    countryCallingCode: '+92',
  },
  {
    careCountryId: '161',
    name: 'Palau',
    countryIso: 'PW',
    countryCallingCode: '+680',
  },
  {
    careCountryId: '162',
    name: 'Panama',
    countryIso: 'PA',
    countryCallingCode: '+507',
  },
  {
    careCountryId: '163',
    name: 'Papua-Neuguinea',
    countryIso: 'PG',
    countryCallingCode: '+675',
  },
  {
    careCountryId: '164',
    name: 'Paraguay',
    countryIso: 'PY',
    countryCallingCode: '+595',
  },
  {
    careCountryId: '165',
    name: 'Peru',
    countryIso: 'PE',
    countryCallingCode: '+51',
  },
  {
    careCountryId: '166',
    name: 'Philippinen',
    countryIso: 'PH',
    countryCallingCode: '+63',
  },
  {
    careCountryId: '167',
    name: 'Pitcairninseln',
    countryIso: 'PN',
    countryCallingCode: '+63',
  },

  {
    careCountryId: '170',
    name: 'Puerto Rico',
    countryIso: 'PR',
    countryCallingCode: '+1-787',
  },
  {
    careCountryId: '171',
    name: 'Réunion',
    countryIso: 'RE',
    countryCallingCode: '+262',
  },
  {
    careCountryId: '172',
    name: 'Ruanda',
    countryIso: 'RW',
    countryCallingCode: '+250',
  },

  {
    careCountryId: '175',
    name: 'Saint Helena',
    countryIso: 'SH',
    countryCallingCode: '+290',
  },
  {
    careCountryId: '176',
    name: 'Saint Kitts und Nevis',
    countryIso: 'KN',
    countryCallingCode: '+1-869',
  },
  {
    careCountryId: '177',
    name: 'Saint Lucia',
    countryIso: 'LC',
    countryCallingCode: '+1-758',
  },
  {
    careCountryId: '178',
    name: 'Saint Vincent und die Grenadinen',
    countryIso: 'VC',
    countryCallingCode: '+508',
  },
  {
    careCountryId: '179',
    name: 'Saint-Pierre-et-Miquelon',
    countryIso: 'PM',
    countryCallingCode: '+508',
  },
  {
    careCountryId: '180',
    name: 'Sambia',
    countryIso: 'ZM',
    countryCallingCode: '+260',
  },
  {
    careCountryId: '181',
    name: 'Samoa',
    countryIso: 'WS',
    countryCallingCode: '+685',
  },

  {
    careCountryId: '183',
    name: 'São Tomé und Príncipe',
    countryIso: 'ST',
    countryCallingCode: '+239',
  },
  {
    careCountryId: '184',
    name: 'Saudi-Arabien',
    countryIso: 'SA',
    countryCallingCode: '+966',
  },

  {
    careCountryId: '187',
    name: 'Senegal',
    countryIso: 'SN',
    countryCallingCode: '+221',
  },

  {
    careCountryId: '189',
    name: 'Seychellen',
    countryIso: 'SC',
    countryCallingCode: '+248',
  },
  {
    careCountryId: '190',
    name: 'Sierra Leone',
    countryIso: 'SL',
    countryCallingCode: '+232',
  },
  {
    careCountryId: '191',
    name: 'Singapur',
    countryIso: 'SG',
    countryCallingCode: '+65',
  },

  {
    careCountryId: '194',
    name: 'Salomonen',
    countryIso: 'SB',
    countryCallingCode: '+677',
  },
  {
    careCountryId: '195',
    name: 'Somalia',
    countryIso: 'SO',
    countryCallingCode: '+252',
  },

  {
    careCountryId: '197',
    name: 'Sri Lanka',
    countryIso: 'LK',
    countryCallingCode: '+94',
  },
  {
    careCountryId: '198',
    name: 'Südafrika',
    countryIso: 'ZA',
    countryCallingCode: '+27',
  },
  {
    careCountryId: '199',
    name: 'Sudan',
    countryIso: 'SD',
    countryCallingCode: '+249',
  },
  {
    careCountryId: '200',
    name: 'Südgeorgien und Sandwich-Inseln',
    countryIso: 'GS',
    countryCallingCode: '+44',
  },
  {
    careCountryId: '201',
    name: 'Surinam',
    countryIso: 'SR',
    countryCallingCode: '+597',
  },
  {
    careCountryId: '202',
    name: 'Svalbard und Jan Mayen',
    countryIso: 'SJ',
    countryCallingCode: '+47',
  },
  {
    careCountryId: '203',
    name: 'Swasiland',
    countryIso: 'SZ',
    countryCallingCode: '+268',
  },
  {
    careCountryId: '204',
    name: 'Syrien',
    countryIso: 'SY',
    countryCallingCode: '+963',
  },
  {
    careCountryId: '205',
    name: 'Tadschikistan',
    countryIso: 'TJ',
    countryCallingCode: '+992',
  },
  {
    careCountryId: '206',
    name: 'Taiwan',
    countryIso: 'TW',
    countryCallingCode: '+886',
  },
  {
    careCountryId: '207',
    name: 'Tansania',
    countryIso: 'TZ',
    countryCallingCode: '+255',
  },
  {
    careCountryId: '208',
    name: 'Thailand',
    countryIso: 'TH',
    countryCallingCode: '+66',
  },
  {
    careCountryId: '209',
    name: 'Togo',
    countryIso: 'TG',
    countryCallingCode: '+228',
  },
  {
    careCountryId: '210',
    name: 'Tokelau',
    countryIso: 'TK',
    countryCallingCode: '+690',
  },
  {
    careCountryId: '211',
    name: 'Tonga',
    countryIso: 'TO',
    countryCallingCode: '+676',
  },
  {
    careCountryId: '212',
    name: 'Trinidad und Tobago',
    countryIso: 'TT',
    countryCallingCode: '1',
  },
  {
    careCountryId: '213',
    name: 'Tschad',
    countryIso: 'TD',
    countryCallingCode: '+235',
  },

  {
    careCountryId: '215',
    name: 'Tunesien',
    countryIso: 'TN',
    countryCallingCode: '+216',
  },

  {
    careCountryId: '217',
    name: 'Turkmenistan',
    countryIso: 'TM',
    countryCallingCode: '+993',
  },
  {
    careCountryId: '218',
    name: 'Turks- und Caicosinseln',
    countryIso: 'TC',
    countryCallingCode: '+1-649',
  },
  {
    careCountryId: '219',
    name: 'Tuvalu',
    countryIso: 'TV',
    countryCallingCode: '+688',
  },
  {
    careCountryId: '220',
    name: 'Uganda',
    countryIso: 'UG',
    countryCallingCode: '+256',
  },

  {
    careCountryId: '223',
    name: 'Uruguay',
    countryIso: 'UY',
    countryCallingCode: '+598',
  },
  {
    careCountryId: '224',
    name: 'Usbekistan',
    countryIso: 'UZ',
    countryCallingCode: '+998',
  },
  {
    careCountryId: '225',
    name: 'Vanuatu',
    countryIso: 'VU',
    countryCallingCode: '+678',
  },
  {
    careCountryId: '226',
    name: 'Vatikanstadt',
    countryIso: 'VA',
    countryCallingCode: '+379',
  },
  {
    careCountryId: '227',
    name: 'Venezuela',
    countryIso: 'VE',
    countryCallingCode: '+58',
  },
  {
    careCountryId: '228',
    name: 'Vereinigte Arabische Emirate',
    countryIso: 'AE',
    countryCallingCode: '+971',
  },
  {
    careCountryId: '229',
    name: 'Vereinigte Staaten von Amerika',
    countryIso: 'US',
    countryCallingCode: '+1',
  },
  {
    careCountryId: '231',
    name: 'Vietnam',
    countryIso: 'VN',
    countryCallingCode: '+84',
  },
  {
    careCountryId: '232',
    name: 'Wallis und Futuna',
    countryIso: 'WF',
    countryCallingCode: '+681',
  },
  {
    careCountryId: '233',
    name: 'Weihnachtsinseln',
    countryIso: 'CX',
    countryCallingCode: '+61',
  },

  {
    careCountryId: '235',
    name: 'Zentralafrikanische Republik',
    countryIso: 'CF',
    countryCallingCode: '+236',
  },
  {
    careCountryId: '236',
    name: 'Simbabwe',
    countryIso: 'ZW',
    countryCallingCode: '+263',
  },

  {
    careCountryId: '241',
    name: 'Palästina',
    countryIso: 'PS',
    countryCallingCode: '+970',
  },
  {
    careCountryId: '243',
    name: 'Insel Man',
    countryIso: 'IM',
    countryCallingCode: '+44-1624',
  },
  {
    careCountryId: '245',
    name: 'Åland',
    countryIso: 'AX',
    countryCallingCode: '+358 18',
  },
  {
    careCountryId: '246',
    name: 'Ceuta, Melilla',
    countryIso: 'EA',
    countryCallingCode: '+331',
  },
  {
    careCountryId: '247',
    name: 'Kanarische Inseln',
    countryIso: 'IC',
    countryCallingCode: '+34',
  },
  {
    careCountryId: '248',
    name: 'Saint-Barthélemy',
    countryIso: 'BL',
    countryCallingCode: '+590',
  },
  {
    careCountryId: '249',
    name: 'Saint-Martin',
    countryIso: 'MF',
    countryCallingCode: '+1',
  },
  {
    careCountryId: '250',
    name: 'Südsudan',
    countryIso: 'SS',
    countryCallingCode: '+211',
  },
  {
    careCountryId: '251',
    name: 'Westsahara',
    countryIso: 'EH',
    countryCallingCode: '+212 28',
  },
  {
    careCountryId: '252',
    name: 'Korea, Republik (Südkorea)',
    countryIso: 'KR',
    countryCallingCode: '+82',
  },
];
