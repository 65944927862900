import { formatDate } from './format';

export const validationErrorMessages = {
  emailFormatting:
    'Diese E-Mail ist nicht richtig formatiert oder keine gültige IU-Adresse.',
  length: (val) => `Maximal ${val} Zeichen erlaubt.`,
  maxTerms: 'Maximale Semesterzahl überschritten.',
  minTerms: 'Semesterzahl muss mindestens 1 betragen.',
  nameFormatting: "Unerlaubte Sonderzeichen. Erlaubt: - _ . '",
  nameRequired:
    'Es muss mindestens eines der folgenden Felder ausgefüllt sein: Vorname, Nachname',
  numeric: 'Nur Ziffern erlaubt.',
  phoneFormatting: 'Diese Telefonnummer ist nicht richtig formatiert.',
  phonePrefixAllowedEntry: 'Ländervorwahl existiert nicht.',
  phonePrefixWithPlus: 'Ländervorwahl muss mit + beginnen.',
  postalCodeFormatting: 'Unerlaubte Sonderzeichen.',
  required: 'Dieses Feld muss ausgefüllt sein.',
  identityDocumentId: 'Länge muss exakt 9 Zeichen sein.',
  noDigitsName: 'Name darf keine Zahlen enthalten.',
  before: (date) => `Das Datum muss vor dem ${formatDate(date)} sein.`,
  after: (date) => `Das Datum muss nach dem ${formatDate(date)} sein.`,
};
