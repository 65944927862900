import { Actions, Getters, Mutations } from '../types';
import { API_ENDPOINTS } from '@/common/constants';
import { ApiServiceWrapper } from '@/utils/apiServiceWrapper';
import createDefaultState from '@/store/modules/profile/state';

export default {
  async [Actions.RESET_PROFILE]({ commit }) {
    const defaultState = createDefaultState();
    commit(Mutations.SET_PROFILE, defaultState);
  },
  async [Actions.LOAD_PROFILE]({ commit }, id) {
    const CUSTOMER_DETAIL_URL = `${API_ENDPOINTS.CUSTOMER_PROFILES}/${id}`;
    const { data } = await ApiServiceWrapper.get(CUSTOMER_DETAIL_URL);
    commit(Mutations.SET_PROFILE, data);
  },
  async [Actions.SAVE_PROFILE]({ getters, commit }) {
    const { id, matriculationNumber, academyId, campus, person } =
      getters[Getters.PROFILE];

    const payload = {
      id,
      matriculationNumber,
      academyId,
      campus,
      person,
    };

    const PUT_URL = `${API_ENDPOINTS.CUSTOMER_PROFILES}/${id}`;
    const { data } = await ApiServiceWrapper.put(PUT_URL, payload);

    commit(Mutations.SET_PROFILE, data);
  },
};
