export const graduationTypes = [
  {
    statisticKey: '101',
    label: 'Mehr-Fächer-Bachelor mit Lehramtsoption - Erststudium',
  },
  { statisticKey: '102', label: 'Magister - Erststudium' },
  { statisticKey: '103', label: 'Lizentiat - Erststudium' },
  { statisticKey: '104', label: 'Kirchliche Prüfung - Erststudium' },
  { statisticKey: '105', label: 'Akad. geprüfter Übersetzer - Erststudium' },
  {
    statisticKey: '107',
    label: 'Promotion ohne vorausgesetzte Abschlussprüfung - Erststudium',
  },
  {
    statisticKey: '108',
    label: 'Staatsexamen / 1. Staatsprüfung - Erststudium',
  },
  {
    statisticKey: '109',
    label: 'Staatsexamen (einphasige Ausbildung) - Erststudium',
  },
  { statisticKey: '110', label: 'Fakultätsprüfung - Erststudium' },
  { statisticKey: '111', label: 'Diplom (U) - Erststudium' },
  { statisticKey: '112', label: 'Diplom (U) - Dolmetscher - Erststudium' },
  { statisticKey: '113', label: 'Diplom (U) - Übersetzer - Erststudium' },
  { statisticKey: '114', label: 'Diplom I (U-GH) - Erststudium' },
  { statisticKey: '115', label: 'LA Bachelor Grundschulen - Erststudium' },
  { statisticKey: '116', label: 'LA Bachelor Hauptschulen - Erststudium' },
  {
    statisticKey: '117',
    label: 'Abschlussprüfung ohne akad. Grad - Erststudium',
  },
  { statisticKey: '118', label: 'Diplom (U) - Lehrer - Erststudium' },
  {
    statisticKey: '119',
    label: 'LA Bachelor Grundstufe / Primarstufe - Erststudium',
  },
  { statisticKey: '120', label: 'LA Grund- und Hauptschulen - Erststudium' },
  { statisticKey: '121', label: 'LA Grundschulen - Erststudium' },
  { statisticKey: '122', label: 'LA Hauptschulen - Erststudium' },
  { statisticKey: '123', label: 'LA Realschulen - Erststudium' },
  {
    statisticKey: '124',
    label:
      'LA Haupt- und Realschulen / Unterstufe und Mittelstufe Gymnasien - Erststudium',
  },
  { statisticKey: '125', label: 'LA Gymnasien - Erststudium' },
  {
    statisticKey: '126',
    label: 'LA Sonderschulen / Förderschulen - Erststudium',
  },
  { statisticKey: '127', label: 'LA Berufliche Schulen - Erststudium' },
  {
    statisticKey: '128',
    label: 'LA Sekundarstufe I / Primarstufe - Erststudium',
  },
  {
    statisticKey: '129',
    label:
      'Sonstige Staatsprüfungen nach Schularten / Schulstufen - Erststudium',
  },
  {
    statisticKey: '131',
    label: 'LA Bachelor (soweit keine Differenzierung möglich) - Erststudium',
  },
  {
    statisticKey: '132',
    label: 'LA Bachelor Sekundarstufe I / Primarstufe - Erststudium',
  },
  {
    statisticKey: '133',
    label: 'LA Bachelor Grundschulen / Sekundarstufe I - Erststudium',
  },
  { statisticKey: '134', label: 'LA Bachelor Realschulen - Erststudium' },
  {
    statisticKey: '135',
    label:
      'LA Bachelor Haupt- und Realschulen / Unterstufe und Mittelstufe Gymnasien - Erststudium',
  },
  {
    statisticKey: '136',
    label: 'LA Bachelor Mittelstufe / Sekundarstufe I - Erststudium',
  },
  {
    statisticKey: '137',
    label: 'LA Bachelor Sekundarstufe II / Sekundarstufe I - Erststudium',
  },
  { statisticKey: '138', label: 'LA Bachelor Gymnasien - Erststudium' },
  {
    statisticKey: '139',
    label:
      'LA Bachelor Oberstufe / Sekundarstufe II, allgemeinbildende Schulen - Erststudium',
  },
  {
    statisticKey: '140',
    label: 'LA Grundschulen / Sekundarstufe I - Erststudium',
  },
  {
    statisticKey: '141',
    label: 'LA Sekundarstufe II / Sekundarstufe I - Erststudium',
  },
  { statisticKey: '142', label: 'LA Grundstufe / Primarstufe - Erststudium' },
  {
    statisticKey: '143',
    label: 'LA Mittelstufe / Sekundarstufe I - Erststudium',
  },
  {
    statisticKey: '144',
    label:
      'LA Oberstufe / Sekundarstufe II, allgemeinbildende Schulen - Erststudium',
  },
  {
    statisticKey: '145',
    label: 'LA Oberstufe / Sekundarstufe II, berufliche Schulen - Erststudium',
  },
  {
    statisticKey: '146',
    label: 'LA Bachelor Sonderschulen / Förderschulen - Erststudium',
  },
  {
    statisticKey: '147',
    label: 'LA Bachelor Berufliche Schulen - Erststudium',
  },
  {
    statisticKey: '148',
    label:
      'LA Bachelor Oberstufe / Sekundarstufe II, berufliche Schulen - Erststudium',
  },
  { statisticKey: '151', label: 'Diplom (FH) - Erststudium' },
  { statisticKey: '153', label: 'Diplom (FH) - Dolmetscher - Erststudium' },
  { statisticKey: '154', label: 'Diplom (FH) - Übersetzer - Erststudium' },
  {
    statisticKey: '156',
    label: 'Staatl. Laufbahnprüfung (VerwFH) - Erststudium',
  },
  { statisticKey: '159', label: 'Sonstiger FH - Abschluss - Erststudium' },
  { statisticKey: '166', label: 'Bachelor mit Lehramtsoption - Erststudium' },
  {
    statisticKey: '168',
    label: 'Mehr-Fächer-Bachelor ohne Lehramtsoption - Erststudium',
  },
  {
    statisticKey: '170',
    label: 'Staatl. Prüfung für künstlerische Abschlüsse - Erststudium',
  },
  {
    statisticKey: '171',
    label: 'Bühnen- / Konzert- / Opernreifeprüfung - Erststudium',
  },
  { statisticKey: '172', label: 'Privatmusiklehrerprüfung - Erststudium' },
  { statisticKey: '173', label: 'Kirchenmusikprüfung A - Erststudium' },
  { statisticKey: '174', label: 'Kirchenmusikprüfung B - Erststudium' },
  { statisticKey: '175', label: 'Diplom (KH) - Erststudium' },
  { statisticKey: '177', label: 'Kunstpädagogische Prüfung - Erststudium' },
  { statisticKey: '179', label: 'Solistenprüfung  - Erststudium' },
  {
    statisticKey: '180',
    label: 'Sonstiger künstlerischer Abschluss - Erststudium',
  },
  { statisticKey: '181', label: 'Akademiebrief (KH) - Erststudium' },
  { statisticKey: '182', label: 'Bachelor an Universitäten - Erststudium' },
  { statisticKey: '183', label: 'Bachelor an Kunsthochschulen - Erststudium' },
  { statisticKey: '184', label: 'Bachelor an Fachhochschulen - Erststudium' },
  {
    statisticKey: '186',
    label: 'Bachelor o. Ä. noch nicht abgeschlossen - Erststudium',
  },
  {
    statisticKey: '187',
    label: 'Abschlüsse von Berufsakademien - Erststudium',
  },
  { statisticKey: '193', label: 'Bachelor (U) - Lehrer - Erststudium' },
  { statisticKey: '194', label: 'Abschlusszeugnis / Zertifikat - Erststudium' },
  {
    statisticKey: '195',
    label: 'Sonstiger Abschluss in Deutschland - Erststudium',
  },
  { statisticKey: '196', label: 'Abschlussprüfung im Ausland - Erststudium' },
  {
    statisticKey: '198',
    label: 'LA Bachelor Grund- und Hauptschulen - Erststudium',
  },
  {
    statisticKey: '1B5',
    label: 'LA Bachelor Grundschulen (Ba LA GS) - Erststudium',
  },
  {
    statisticKey: '1B6',
    label:
      'LA Bachelor Haupt-, Real- und Gesamtschulen (Ba LA HRGe) - Erststudium',
  },
  {
    statisticKey: '1B7',
    label:
      'LA Bachelor Gymnasien und Gesamtschulen (Ba LA GymGe) - Erststudium',
  },
  {
    statisticKey: '1B8',
    label: 'LA  Bachelor Berufskollegs (BA LA BK) - Erststudium',
  },
  {
    statisticKey: '1B9',
    label:
      'LA Bachelor für sonderpädagogische Förderung (BA LA SP) - Erststudium',
  },
  {
    statisticKey: '201',
    label: 'Mehr-Fächer-Bachelor mit Lehramtsoption - Zweitstudium',
  },
  { statisticKey: '202', label: 'Magister - Zweitstudium' },
  { statisticKey: '203', label: 'Lizentiat - Zweitstudium' },
  { statisticKey: '204', label: 'Kirchliche Prüfung - Zweitstudium' },
  { statisticKey: '205', label: 'Akad. geprüfter Übersetzer - Zweitstudium' },
  {
    statisticKey: '208',
    label: 'Staatsexamen / 1. Staatsprüfung - Zweitstudium',
  },
  {
    statisticKey: '209',
    label: 'Staatsexamen (einphasige Ausbildung) - Zweitstudium',
  },
  { statisticKey: '210', label: 'Fakultätsprüfung - Zweitstudium' },
  { statisticKey: '211', label: 'Diplom (U) - Zweitstudium' },
  { statisticKey: '212', label: 'Diplom (U) - Dolmetscher - Zweitstudium' },
  { statisticKey: '213', label: 'Diplom (U) - Übersetzer - Zweitstudium' },
  { statisticKey: '214', label: 'Diplom I (U-GH) - Zweitstudium' },
  { statisticKey: '215', label: 'LA Bachelor Grundschulen - Zweitstudium' },
  { statisticKey: '216', label: 'LA Bachelor Hauptschulen - Zweitstudium' },
  { statisticKey: '218', label: 'Diplom (U) - Lehrer - Zweitstudium' },
  {
    statisticKey: '219',
    label: 'LA Bachelor Grundstufe / Primarstufe - Zweitstudium',
  },
  { statisticKey: '220', label: 'LA Grund- und Hauptschulen - Zweitstudium' },
  { statisticKey: '221', label: 'LA Grundschulen - Zweitstudium' },
  { statisticKey: '222', label: 'LA Hauptschulen - Zweitstudium' },
  { statisticKey: '223', label: 'LA Realschulen - Zweitstudium' },
  {
    statisticKey: '224',
    label:
      'LA Haupt- und Realschulen / Unterstufe und Mittelstufe Gymnasien - Zweitstudium',
  },
  { statisticKey: '225', label: 'LA Gymnasien - Zweitstudium' },
  {
    statisticKey: '226',
    label: 'LA Sonderschulen / Förderschulen - Zweitstudium',
  },
  { statisticKey: '227', label: 'LA Berufliche Schulen - Zweitstudium' },
  {
    statisticKey: '228',
    label: 'LA Sekundarstufe I / Primarstufe - Zweitstudium',
  },
  {
    statisticKey: '229',
    label:
      'Sonstige Staatsprüfungen nach Schularten / Schulstufen - Zweitstudium',
  },
  {
    statisticKey: '230',
    label: 'LA Master (soweit keine Differenzierung möglich) - Zweitstudium',
  },
  {
    statisticKey: '231',
    label: 'LA Bachelor (soweit keine Differenzierung möglich) - Zweitstudium',
  },
  {
    statisticKey: '232',
    label: 'LA Bachelor Sekundarstufe I / Primarstufe - Zweitstudium',
  },
  {
    statisticKey: '233',
    label: 'LA Bachelor Grundschulen / Sekundarstufe I - Zweitstudium',
  },
  { statisticKey: '234', label: 'LA Bachelor Realschulen - Zweitstudium' },
  {
    statisticKey: '235',
    label:
      'LA Bachelor Haupt- und Realschulen / Unterstufe und Mittelstufe Gymnasien - Zweitstudium',
  },
  {
    statisticKey: '236',
    label: 'LA Bachelor Mittelstufe / Sekundarstufe I - Zweitstudium',
  },
  {
    statisticKey: '237',
    label: 'LA Bachelor Sekundarstufe II / Sekundarstufe I - Zweitstudium',
  },
  { statisticKey: '238', label: 'LA Bachelor Gymnasien - Zweitstudium' },
  {
    statisticKey: '239',
    label:
      'LA Bachelor Oberstufe / Sekundarstufe II, allgemeinbildende Schulen - Zweitstudium',
  },
  {
    statisticKey: '240',
    label: 'LA Grundschulen / Sekundarstufe I - Zweitstudium',
  },
  {
    statisticKey: '241',
    label: 'LA Sekundarstufe II / Sekundarstufe I - Zweitstudium',
  },
  { statisticKey: '242', label: 'LA Grundstufe / Primarstufe - Zweitstudium' },
  {
    statisticKey: '243',
    label: 'LA Mittelstufe / Sekundarstufe I - Zweitstudium',
  },
  {
    statisticKey: '244',
    label:
      'LA Oberstufe / Sekundarstufe II, allgemeinbildende Schulen - Zweitstudium',
  },
  {
    statisticKey: '245',
    label: 'LA Oberstufe / Sekundarstufe II, berufliche Schulen - Zweitstudium',
  },
  {
    statisticKey: '246',
    label: 'LA Bachelor Sonderschulen / Förderschulen - Zweitstudium',
  },
  {
    statisticKey: '247',
    label: 'LA Bachelor Berufliche Schulen - Zweitstudium',
  },
  {
    statisticKey: '248',
    label:
      'LA Bachelor Oberstufe / Sekundarstufe II, berufliche Schulen - Zweitstudium',
  },
  { statisticKey: '249', label: 'LA Master Grundschulen  - Zweitstudium' },
  { statisticKey: '250', label: 'LA Master Hauptschulen  - Zweitstudium' },
  { statisticKey: '251', label: 'Diplom (FH) - Zweitstudium' },
  {
    statisticKey: '252',
    label: 'LA Master Grundstufe / Primarstufe  - Zweitstudium',
  },
  { statisticKey: '253', label: 'Diplom (FH) - Dolmetscher - Zweitstudium' },
  { statisticKey: '254', label: 'Diplom (FH) - Übersetzer - Zweitstudium' },
  {
    statisticKey: '255',
    label: 'LA Master Sekundarstufe I / Primarstufe  - Zweitstudium',
  },
  {
    statisticKey: '256',
    label: 'Staatl. Laufbahnprüfung (VerwFH) - Zweitstudium',
  },
  {
    statisticKey: '257',
    label: 'LA Master Grundschulen / Sekundarstufe I - Zweitstudium',
  },
  { statisticKey: '258', label: 'LA Master Realschulen - Zweitstudium' },
  { statisticKey: '259', label: 'Sonstiger FH - Abschluss - Zweitstudium' },
  {
    statisticKey: '260',
    label:
      'LA Master Haupt- und Realschulen / Unterstufe und Mittelstufe Gymnasien  - Zweitstudium',
  },
  {
    statisticKey: '261',
    label: 'LA Master Mittelstufe / Sekundarstufe I  - Zweitstudium',
  },
  {
    statisticKey: '262',
    label: 'LA Master Sekundarstufe II / Sekundarstufe I  - Zweitstudium',
  },
  {
    statisticKey: '263',
    label:
      'LA Master Oberstufe / Sekundarstufe II, allgemeinbildende Schulen  - Zweitstudium',
  },
  { statisticKey: '264', label: 'LA Master Gymnasien  - Zweitstudium' },
  {
    statisticKey: '265',
    label: 'LA Master Sonderschulen / Förderschulen - Zweitstudium',
  },
  {
    statisticKey: '266',
    label: 'LA Master Berufliche Schulen  - Zweitstudium',
  },
  {
    statisticKey: '267',
    label:
      'LA Master Oberstufe / Sekundarstufe II, berufliche Schulen - Zweitstudium',
  },
  {
    statisticKey: '268',
    label: 'Mehr-Fächer-Bachelor ohne Lehramtsoption - Zweitstudium',
  },
  { statisticKey: '269', label: 'Mehr-Fächer-Master  - Zweitstudium' },
  {
    statisticKey: '270',
    label: 'Staatl. Prüfung für künstlerische Abschlüsse - Zweitstudium',
  },
  {
    statisticKey: '271',
    label: 'Bühnen- / Konzert- / Opernreifeprüfung - Zweitstudium',
  },
  { statisticKey: '272', label: 'Privatmusiklehrerprüfung - Zweitstudium' },
  { statisticKey: '273', label: 'Kirchenmusikprüfung A - Zweitstudium' },
  { statisticKey: '274', label: 'Kirchenmusikprüfung B - Zweitstudium' },
  { statisticKey: '275', label: 'Diplom (KH) - Zweitstudium' },
  { statisticKey: '276', label: 'Meisterschüler - Zweitstudium' },
  { statisticKey: '277', label: 'Kunstpädagogische Prüfung - Zweitstudium' },
  { statisticKey: '278', label: 'Master (U) - Lehrer - Zweitstudium' },
  { statisticKey: '279', label: 'Solistenprüfung  - Zweitstudium' },
  {
    statisticKey: '280',
    label: 'Sonstiger künstlerischer Abschluss - Zweitstudium',
  },
  { statisticKey: '281', label: 'Akademiebrief (KH) - Zweitstudium' },
  { statisticKey: '282', label: 'Bachelor an Universitäten - Zweitstudium' },
  { statisticKey: '283', label: 'Bachelor an Kunsthochschulen - Zweitstudium' },
  { statisticKey: '284', label: 'Bachelor an Fachhochschulen - Zweitstudium' },
  {
    statisticKey: '288',
    label:
      'Master an Universitäten (Abschlussprüfung vorausgesetzt) - Zweitstudium',
  },
  {
    statisticKey: '289',
    label:
      'Master an Kunsthochschulen (Abschlussprüfung vorausgesetzt) - Zweitstudium',
  },
  {
    statisticKey: '290',
    label:
      'Master an Fachhochschulen (Abschlussprüfung vorausgesetzt) - Zweitstudium',
  },
  { statisticKey: '293', label: 'Bachelor (U) - Lehrer - Zweitstudium' },
  {
    statisticKey: '294',
    label: 'Abschlusszeugnis / Zertifikat - Zweitstudium',
  },
  {
    statisticKey: '295',
    label: 'Sonstiger Abschluss in Deutschland - Zweitstudium',
  },
  { statisticKey: '296', label: 'Abschlussprüfung im Ausland - Zweitstudium' },
  {
    statisticKey: '298',
    label: 'LA Bachelor Grund- und Hauptschulen - Zweitstudium',
  },
  {
    statisticKey: '299',
    label: 'LA Master Grund- und Hauptschulen  - Zweitstudium',
  },
  {
    statisticKey: '2B5',
    label: 'LA Bachelor Grundschulen (Ba LA GS) - Zweitstudium',
  },
  {
    statisticKey: '2B6',
    label:
      'LA Bachelor Haupt-, Real- und Gesamtschulen (Ba LA HRGe) - Zweitstudium',
  },
  {
    statisticKey: '2B7',
    label:
      'LA Bachelor Gymnasien und Gesamtschulen (Ba LA GymGe) - Zweitstudium',
  },
  {
    statisticKey: '2B8',
    label: 'LA  Bachelor Berufskollegs (BA LA BK) - Zweitstudium',
  },
  {
    statisticKey: '2B9',
    label:
      'LA Bachelor für sonderpädagogische Förderung (BA LA SP) - Zweitstudium',
  },
  {
    statisticKey: '2M7',
    label: 'LA Master Grundschulen (Ma LA GS) - Zweitstudium',
  },
  {
    statisticKey: '2M8',
    label:
      'LA Master Haupt-, Real- und Gesamtschulen (Ma LA HRGe) - Zweitstudium',
  },
  {
    statisticKey: '301',
    label: 'Mehr-Fächer-Bachelor mit Lehramtsoption - Aufbaustudium',
  },
  { statisticKey: '302', label: 'Magister - Aufbaustudium' },
  { statisticKey: '303', label: 'Lizentiat - Aufbaustudium' },
  {
    statisticKey: '308',
    label: 'Staatsexamen / 1. Staatsprüfung - Aufbaustudium',
  },
  { statisticKey: '311', label: 'Diplom (U) - Aufbaustudium' },
  {
    statisticKey: '317',
    label: 'Abschlussprüfung ohne akad. Grad - Aufbaustudium',
  },
  { statisticKey: '323', label: 'LA Realschulen - Aufbaustudium' },
  {
    statisticKey: '324',
    label:
      'LA Haupt- und Realschulen / Unterstufe und Mittelstufe Gymnasien - Aufbaustudium',
  },
  { statisticKey: '325', label: 'LA Gymnasien - Aufbaustudium' },
  {
    statisticKey: '326',
    label: 'LA Sonderschulen / Förderschulen - Aufbaustudium',
  },
  {
    statisticKey: '329',
    label:
      'Sonstige Staatsprüfungen nach Schularten / Schulstufen - Aufbaustudium',
  },
  {
    statisticKey: '330',
    label: 'LA Master (soweit keine Differenzierung möglich) - Aufbaustudium',
  },
  {
    statisticKey: '331',
    label: 'LA Bachelor (soweit keine Differenzierung möglich) - Aufbaustudium',
  },
  { statisticKey: '349', label: 'LA Master Grundschulen  - Aufbaustudium' },
  { statisticKey: '350', label: 'LA Master Hauptschulen  - Aufbaustudium' },
  { statisticKey: '351', label: 'Diplom (FH) - Aufbaustudium' },
  {
    statisticKey: '352',
    label: 'LA Master Grundstufe / Primarstufe  - Aufbaustudium',
  },
  { statisticKey: '353', label: 'Diplom (FH) - Dolmetscher - Aufbaustudium' },
  { statisticKey: '354', label: 'Diplom (FH) - Übersetzer - Aufbaustudium' },
  {
    statisticKey: '355',
    label: 'LA Master Sekundarstufe I / Primarstufe  - Aufbaustudium',
  },
  {
    statisticKey: '357',
    label: 'LA Master Grundschulen / Sekundarstufe I - Aufbaustudium',
  },
  { statisticKey: '358', label: 'LA Master Realschulen - Aufbaustudium' },
  { statisticKey: '359', label: 'Sonstiger FH - Abschluss - Aufbaustudium' },
  {
    statisticKey: '360',
    label:
      'LA Master Haupt- und Realschulen / Unterstufe und Mittelstufe Gymnasien  - Aufbaustudium',
  },
  {
    statisticKey: '361',
    label: 'LA Master Mittelstufe / Sekundarstufe I  - Aufbaustudium',
  },
  {
    statisticKey: '362',
    label: 'LA Master Sekundarstufe II / Sekundarstufe I  - Aufbaustudium',
  },
  {
    statisticKey: '363',
    label:
      'LA Master Oberstufe / Sekundarstufe II, allgemeinbildende Schulen  - Aufbaustudium',
  },
  { statisticKey: '364', label: 'LA Master Gymnasien  - Aufbaustudium' },
  {
    statisticKey: '365',
    label: 'LA Master Sonderschulen / Förderschulen - Aufbaustudium',
  },
  {
    statisticKey: '366',
    label: 'LA Master Berufliche Schulen  - Aufbaustudium',
  },
  {
    statisticKey: '367',
    label:
      'LA Master Oberstufe / Sekundarstufe II, berufliche Schulen - Aufbaustudium',
  },
  {
    statisticKey: '368',
    label: 'Mehr-Fächer-Bachelor ohne Lehramtsoption - Aufbaustudium',
  },
  { statisticKey: '369', label: 'Mehr-Fächer-Master  - Aufbaustudium' },
  {
    statisticKey: '371',
    label: 'Bühnen- / Konzert- / Opernreifeprüfung - Aufbaustudium',
  },
  { statisticKey: '372', label: 'Privatmusiklehrerprüfung - Aufbaustudium' },
  { statisticKey: '373', label: 'Kirchenmusikprüfung A - Aufbaustudium' },
  { statisticKey: '375', label: 'Diplom (KH) - Aufbaustudium' },
  { statisticKey: '376', label: 'Meisterschüler - Aufbaustudium' },
  { statisticKey: '378', label: 'Master (U) - Lehrer - Aufbaustudium' },
  { statisticKey: '379', label: 'Solistenprüfung  - Aufbaustudium' },
  {
    statisticKey: '380',
    label: 'Sonstiger künstlerischer Abschluss - Aufbaustudium',
  },
  { statisticKey: '381', label: 'Akademiebrief (KH) - Aufbaustudium' },
  {
    statisticKey: '388',
    label:
      'Master an Universitäten (Abschlussprüfung vorausgesetzt) - Aufbaustudium',
  },
  {
    statisticKey: '389',
    label:
      'Master an Kunsthochschulen (Abschlussprüfung vorausgesetzt) - Aufbaustudium',
  },
  {
    statisticKey: '390',
    label:
      'Master an Fachhochschulen (Abschlussprüfung vorausgesetzt) - Aufbaustudium',
  },
  {
    statisticKey: '394',
    label: 'Abschlusszeugnis / Zertifikat - Aufbaustudium',
  },
  {
    statisticKey: '395',
    label: 'Sonstiger Abschluss in Deutschland - Aufbaustudium',
  },
  { statisticKey: '396', label: 'Abschlussprüfung im Ausland - Aufbaustudium' },
  {
    statisticKey: '399',
    label: 'LA Master Grund- und Hauptschulen  - Aufbaustudium',
  },
  {
    statisticKey: '401',
    label:
      'Mehr-Fächer-Bachelor mit Lehramtsoption - Ergänzungs-, Erwei terungs- und Zusatzstudium',
  },
  {
    statisticKey: '402',
    label: 'Magister - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '403',
    label: 'Lizentiat - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '405',
    label:
      'Akad. geprüfter Übersetzer - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '411',
    label: 'Diplom (U) - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '412',
    label:
      'Diplom (U) - Dolmetscher - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '413',
    label:
      'Diplom (U) - Übersetzer - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '415',
    label:
      'LA Bachelor Grundschulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '416',
    label:
      'LA Bachelor Hauptschulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '418',
    label:
      'Diplom (U) - Lehrer - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '419',
    label:
      'LA Bachelor Grundstufe / Primarstufe - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '420',
    label:
      'LA Grund- und Hauptschulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '421',
    label: 'LA Grundschulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '422',
    label: 'LA Hauptschulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '423',
    label: 'LA Realschulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '424',
    label:
      'LA Haupt- und Realschulen / Unterstufe und Mittelstufe Gymnasien - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '425',
    label: 'LA Gymnasien - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '426',
    label:
      'LA Sonderschulen / Förderschulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '427',
    label:
      'LA Berufliche Schulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '428',
    label:
      'LA Sekundarstufe I / Primarstufe - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '429',
    label:
      'Sonstige Staatsprüfungen nach Schularten / Schulstufen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '430',
    label:
      'LA Master (soweit keine Differenzierung möglich) - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '431',
    label:
      'LA Bachelor (soweit keine Differenzierung möglich) - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '432',
    label:
      'LA Bachelor Sekundarstufe I / Primarstufe - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '433',
    label:
      'LA Bachelor Grundschulen / Sekundarstufe I - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '434',
    label:
      'LA Bachelor Realschulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '435',
    label:
      'LA Bachelor Haupt- und Realschulen / Unterstufe und Mittelstufe Gymnasien - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '436',
    label:
      'LA Bachelor Mittelstufe / Sekundarstufe I - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '437',
    label:
      'LA Bachelor Sekundarstufe II / Sekundarstufe I - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '438',
    label:
      'LA Bachelor Gymnasien - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '439',
    label:
      'LA Bachelor Oberstufe / Sekundarstufe II, allgemeinbildende Schulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '440',
    label:
      'LA Grundschulen / Sekundarstufe I - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '441',
    label:
      'LA Sekundarstufe II / Sekundarstufe I - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '442',
    label:
      'LA Grundstufe / Primarstufe - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '443',
    label:
      'LA Mittelstufe / Sekundarstufe I - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '444',
    label:
      'LA Oberstufe / Sekundarstufe II, allgemeinbildende Schulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '445',
    label:
      'LA Oberstufe / Sekundarstufe II, berufliche Schulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '446',
    label:
      'LA Bachelor Sonderschulen / Förderschulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '447',
    label:
      'LA Bachelor Berufliche Schulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '448',
    label:
      'LA Bachelor Oberstufe / Sekundarstufe II, berufliche Schulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '449',
    label:
      'LA Master Grundschulen  - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '450',
    label:
      'LA Master Hauptschulen  - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '451',
    label: 'Diplom (FH) - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '452',
    label:
      'LA Master Grundstufe / Primarstufe  - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '455',
    label:
      'LA Master Sekundarstufe I / Primarstufe  - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '457',
    label:
      'LA Master Grundschulen / Sekundarstufe I - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '458',
    label:
      'LA Master Realschulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '459',
    label:
      'Sonstiger FH - Abschluss - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '460',
    label:
      'LA Master Haupt- und Realschulen / Unterstufe und Mittelstufe Gymnasien  - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '461',
    label:
      'LA Master Mittelstufe / Sekundarstufe I  - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '462',
    label:
      'LA Master Sekundarstufe II / Sekundarstufe I  - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '463',
    label:
      'LA Master Oberstufe / Sekundarstufe II, allgemeinbildende Schulen  - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '464',
    label:
      'LA Master Gymnasien  - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '465',
    label:
      'LA Master Sonderschulen / Förderschulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '466',
    label:
      'LA Master Berufliche Schulen  - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '467',
    label:
      'LA Master Oberstufe / Sekundarstufe II, berufliche Schulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '468',
    label:
      'Mehr-Fächer-Bachelor ohne Lehramtsoption - Ergänzungs-, Erweiterungs- und Zusatzstudium',
  },
  {
    statisticKey: '469',
    label:
      'Mehr-Fächer-Master  - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '472',
    label:
      'Privatmusiklehrerprüfung - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '474',
    label:
      'Kirchenmusikprüfung B - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '475',
    label: 'Diplom (KH) - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '477',
    label:
      'Kunstpädagogische Prüfung - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '478',
    label:
      'Master (U) - Lehrer - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '480',
    label:
      'Sonstiger künstlerischer Abschluss - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '482',
    label:
      'Bachelor an Universitäten - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '484',
    label:
      'Bachelor an Fachhochschulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '488',
    label:
      'Master an Universitäten (Abschlussprüfung vorausgesetzt) - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '489',
    label:
      'Master an Kunsthochschulen (Abschlussprüfung vorausgesetzt) - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '490',
    label:
      'Master an Fachhochschulen (Abschlussprüfung vorausgesetzt) - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '494',
    label:
      'Abschlusszeugnis / Zertifikat - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '495',
    label:
      'Sonstiger Abschluss in Deutschland - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '496',
    label:
      'Abschlussprüfung im Ausland - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '498',
    label:
      'LA Bachelor Grund- und Hauptschulen - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '499',
    label:
      'LA Master Grund- und Hauptschulen  - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '4M7',
    label:
      'LA Master Grundschulen (Ma LA GS) - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '4M8',
    label:
      'LA Master Haupt-, Real- und Gesamtschulen (Ma LA HRGe) - Ergänzungs-, Erweiterungs- und Zusatzstudium ',
  },
  {
    statisticKey: '506',
    label: 'Promotion (Abschlussprüfung vorausgesetzt) - Promotion',
  },
  {
    statisticKey: '507',
    label: 'Promotion ohne vorausgesetzte Abschlussprüfung - Promotionsstudium',
  },
  {
    statisticKey: '591',
    label:
      'Promotion (Abschlussprüfung vorausgesetzt) - Strukturiertes Promotionsstudium',
  },
  {
    statisticKey: '592',
    label:
      'Promotion (Abschlussprüfung vorausgesetzt) - Promotion nach FH-Abschluss bzw. wiss. Kurzstudium',
  },
  {
    statisticKey: '596',
    label: 'Abschlussprüfung im Ausland - Promotionsstudium',
  },
  {
    statisticKey: '601',
    label: 'Mehr-Fächer-Bachelor mit Lehramtsoption - Weiterbildungsstudium',
  },
  { statisticKey: '602', label: 'Magister - Weiterbildungsstudium' },
  {
    statisticKey: '608',
    label: 'Staatsexamen / 1. Staatsprüfung - Weiterbildungsstudium',
  },
  { statisticKey: '611', label: 'Diplom (U) - Weiterbildungsstudium' },
  {
    statisticKey: '629',
    label:
      'Sonstige Staatsprüfungen nach Schularten / Schulstufen - Weiterbildungsstudium',
  },
  {
    statisticKey: '630',
    label:
      'LA Master (soweit keine Differenzierung möglich) - Weiterbildungsstudium',
  },
  {
    statisticKey: '631',
    label:
      'LA Bachelor (soweit keine Differenzierung möglich) - Weiterbildungsstudium',
  },
  {
    statisticKey: '649',
    label: 'LA Master Grundschulen  - Weiterbildungsstudium',
  },
  {
    statisticKey: '650',
    label: 'LA Master Hauptschulen  - Weiterbildungsstudium',
  },
  { statisticKey: '651', label: 'Diplom (FH) - Weiterbildungsstudium' },
  {
    statisticKey: '652',
    label: 'LA Master Grundstufe / Primarstufe  - Weiterbildungsstudium',
  },
  {
    statisticKey: '655',
    label: 'LA Master Sekundarstufe I / Primarstufe  - Weiterbildungsstudium',
  },
  {
    statisticKey: '657',
    label: 'LA Master Grundschulen / Sekundarstufe I - Weiterbildungsstudium',
  },
  {
    statisticKey: '658',
    label: 'LA Master Realschulen - Weiterbildungsstudium',
  },
  {
    statisticKey: '659',
    label: 'Sonstiger FH - Abschluss - Weiterbildungsstudium',
  },
  {
    statisticKey: '660',
    label:
      'LA Master Haupt- und Realschulen / Unterstufe und Mittelstufe Gymnasien  - Weiterbildungsstudium',
  },
  {
    statisticKey: '661',
    label: 'LA Master Mittelstufe / Sekundarstufe I  - Weiterbildungsstudium',
  },
  {
    statisticKey: '662',
    label:
      'LA Master Sekundarstufe II / Sekundarstufe I  - Weiterbildungsstudium',
  },
  {
    statisticKey: '663',
    label:
      'LA Master Oberstufe / Sekundarstufe II, allgemeinbildende Schulen  - Weiterbildungsstudium',
  },
  {
    statisticKey: '664',
    label: 'LA Master Gymnasien  - Weiterbildungsstudium',
  },
  {
    statisticKey: '665',
    label: 'LA Master Sonderschulen / Förderschulen - Weiterbildungsstudium',
  },
  {
    statisticKey: '666',
    label: 'LA Master Berufliche Schulen  - Weiterbildungsstudium',
  },
  {
    statisticKey: '667',
    label:
      'LA Master Oberstufe / Sekundarstufe II, berufliche Schulen - Weiterbildungsstudium',
  },
  {
    statisticKey: '668',
    label: 'Mehr-Fächer-Bachelor ohne Lehramtsoption - Weiterbildungsstudium',
  },
  { statisticKey: '669', label: 'Mehr-Fächer-Master  - Weiterbildungsstudium' },
  { statisticKey: '678', label: 'Master (U) - Lehrer - Weiterbildungsstudium' },
  {
    statisticKey: '680',
    label: 'Sonstiger künstlerischer Abschluss - Weiterbildungsstudium',
  },
  {
    statisticKey: '682',
    label: 'Bachelor an Universitäten - Weiterbildungsstudium',
  },
  {
    statisticKey: '684',
    label: 'Bachelor an Fachhochschulen - Weiterbildungsstudium',
  },
  {
    statisticKey: '688',
    label:
      'Master an Universitäten (Abschlussprüfung vorausgesetzt) - Weiterbildungsstudium',
  },
  {
    statisticKey: '689',
    label:
      'Master an Kunsthochschulen (Abschlussprüfung vorausgesetzt) - Kontakt-/Weiterbildungsstudium',
  },
  {
    statisticKey: '690',
    label:
      'Master an Fachhochschulen (Abschlussprüfung vorausgesetzt) - Weiterbildungsstudium',
  },
  {
    statisticKey: '694',
    label: 'Abschlusszeugnis / Zertifikat - Weiterbildungsstudium',
  },
  {
    statisticKey: '695',
    label: 'Sonstiger Abschluss in Deutschland - Weiterbildungsstudium',
  },
  {
    statisticKey: '696',
    label: 'Abschlussprüfung im Ausland - Weiterbildungsstudium',
  },
  {
    statisticKey: '699',
    label: 'LA Master Grund- und Hauptschulen  - Weiterbildungsstudium',
  },
  {
    statisticKey: '730',
    label:
      'LA Master (soweit keine Differenzierung möglich) - Konsekutives Masterstudium',
  },
  {
    statisticKey: '749',
    label: 'LA Master Grundschulen  - Konsekutives Masterstudium',
  },
  {
    statisticKey: '750',
    label: 'LA Master Hauptschulen  - Konsekutives Masterstudium',
  },
  {
    statisticKey: '752',
    label: 'LA Master Grundstufe / Primarstufe  - Konsekutives Masterstudium',
  },
  {
    statisticKey: '755',
    label:
      'LA Master Sekundarstufe I / Primarstufe  - Konsekutives Masterstudium',
  },
  {
    statisticKey: '757',
    label:
      'LA Master Grundschulen / Sekundarstufe I - Konsekutives Masterstudium',
  },
  {
    statisticKey: '758',
    label: 'LA Master Realschulen - Konsekutives Masterstudium',
  },
  {
    statisticKey: '760',
    label:
      'LA Master Haupt- und Realschulen / Unterstufe und Mittelstufe Gymnasien  - Konsekutives Masterstudium',
  },
  {
    statisticKey: '761',
    label:
      'LA Master Mittelstufe / Sekundarstufe I  - Konsekutives Masterstudium',
  },
  {
    statisticKey: '762',
    label:
      'LA Master Sekundarstufe II / Sekundarstufe I  - Konsekutives Masterstudium',
  },
  {
    statisticKey: '763',
    label:
      'LA Master Oberstufe / Sekundarstufe II, allgemeinbildende Schulen  - Konsekutives Masterstudium',
  },
  {
    statisticKey: '764',
    label: 'LA Master Gymnasien  - Konsekutives Masterstudium',
  },
  {
    statisticKey: '765',
    label:
      'LA Master Sonderschulen / Förderschulen - Konsekutives Masterstudium',
  },
  {
    statisticKey: '766',
    label: 'LA Master Berufliche Schulen  - Konsekutives Masterstudium',
  },
  {
    statisticKey: '767',
    label:
      'LA Master Oberstufe / Sekundarstufe II, berufliche Schulen - Konsekutives Masterstudium',
  },
  {
    statisticKey: '769',
    label: 'Mehr-Fächer-Master  - Konsekutives Masterstudium',
  },
  {
    statisticKey: '778',
    label: 'Master (U) - Lehrer - Konsekutives Masterstudium',
  },
  {
    statisticKey: '788',
    label:
      'Master an Universitäten (Abschlussprüfung vorausgesetzt) - Konsekutives Masterstudium',
  },
  {
    statisticKey: '789',
    label:
      'Master an Kunsthochschulen (Abschlussprüfung vorausgesetzt) - Konsekutives Masterstudium',
  },
  {
    statisticKey: '790',
    label:
      'Master an Fachhochschulen (Abschlussprüfung vorausgesetzt) - Konsekutives Masterstudium',
  },
  {
    statisticKey: '796',
    label: 'Abschlussprüfung im Ausland - Konsekutives Masterstudium',
  },
  {
    statisticKey: '799',
    label: 'LA Master Grund- und Hauptschulen  - Konsekutives Masterstudium',
  },
  {
    statisticKey: '7M7',
    label: 'LA Master Grundschulen (Ma LA GS) - Masterstudium',
  },
  {
    statisticKey: '7M8',
    label:
      'LA Master Haupt-, Real- und Gesamtschulen (Ma LA HRGe) - Masterstudium',
  },
  {
    statisticKey: '801',
    label:
      'Mehr-Fächer-Bachelor mit Lehramtsoption - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '802',
    label:
      'Magister - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '803',
    label:
      'Lizentiat - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '804',
    label:
      'Kirchliche Prüfung - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '805',
    label:
      'Akad. geprüfter Übersetzer - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '807',
    label:
      'Promotion ohne vorausgesetzte Abschlussprüfung - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '808',
    label:
      'Staatsexamen / 1. Staatsprüfung - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '809',
    label:
      'Staatsexamen (einphasige Ausbildung) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '810',
    label:
      'Fakultätsprüfung - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '811',
    label:
      'Diplom (U) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '812',
    label:
      'Diplom (U) - Dolmetscher - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '813',
    label:
      'Diplom (U) - Übersetzer - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '814',
    label:
      'Diplom I (U-GH) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '815',
    label:
      'LA Bachelor Grundschulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '816',
    label:
      'LA Bachelor Hauptschulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '817',
    label:
      'Abschlussprüfung ohne akad. Grad - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '818',
    label:
      'Diplom (U) - Lehrer - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '819',
    label:
      'LA Bachelor Grundstufe / Primarstufe - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '820',
    label:
      'LA Grund- und Hauptschulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '821',
    label:
      'LA Grundschulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '822',
    label:
      'LA Hauptschulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '823',
    label:
      'LA Realschulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '824',
    label:
      'LA Haupt- und Realschulen / Unterstufe und Mittelstufe Gymnasien - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '825',
    label:
      'LA Gymnasien - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '826',
    label:
      'LA Sonderschulen / Förderschulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '827',
    label:
      'LA Berufliche Schulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '828',
    label:
      'LA Sekundarstufe I / Primarstufe - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '829',
    label:
      'Sonstige Staatsprüfungen nach Schularten / Schulstufen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '830',
    label:
      'LA Master (soweit keine Differenzierung möglich) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '831',
    label:
      'LA Bachelor (soweit keine Differenzierung möglich) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '832',
    label:
      'LA Bachelor Sekundarstufe I / Primarstufe - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '833',
    label:
      'LA Bachelor Grundschulen / Sekundarstufe I - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '834',
    label:
      'LA Bachelor Realschulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '835',
    label:
      'LA Bachelor Haupt- und Realschulen / Unterstufe und Mittelstufe Gymnasien - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '836',
    label:
      'LA Bachelor Mittelstufe / Sekundarstufe I - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '837',
    label:
      'LA Bachelor Sekundarstufe II / Sekundarstufe I - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '838',
    label:
      'LA Bachelor Gymnasien - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '839',
    label:
      'LA Bachelor Oberstufe / Sekundarstufe II, allgemeinbildende Schulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '840',
    label:
      'LA Grundschulen / Sekundarstufe I - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '841',
    label:
      'LA Sekundarstufe II / Sekundarstufe I - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '842',
    label:
      'LA Grundstufe / Primarstufe - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '843',
    label:
      'LA Mittelstufe / Sekundarstufe I - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '844',
    label:
      'LA Oberstufe / Sekundarstufe II, allgemeinbildende Schulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '845',
    label:
      'LA Oberstufe / Sekundarstufe II, berufliche Schulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '846',
    label:
      'LA Bachelor Sonderschulen / Förderschulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '847',
    label:
      'LA Bachelor Berufliche Schulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '848',
    label:
      'LA Bachelor Oberstufe / Sekundarstufe II, berufliche Schulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '849',
    label:
      'LA Master Grundschulen  - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '850',
    label:
      'LA Master Hauptschulen  - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '851',
    label:
      'Diplom (FH) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '852',
    label:
      'LA Master Grundstufe / Primarstufe  - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '853',
    label:
      'Diplom (FH) - Dolmetscher - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '854',
    label:
      'Diplom (FH) - Übersetzer - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '855',
    label:
      'LA Master Sekundarstufe I / Primarstufe  - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '856',
    label:
      'Staatl. Laufbahnprüfung (VerwFH) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '857',
    label:
      'LA Master Grundschulen / Sekundarstufe I - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '858',
    label:
      'LA Master Realschulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '859',
    label:
      'Sonstiger FH - Abschluss - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '860',
    label:
      'LA Master Haupt- und Realschulen / Unterstufe und Mittelstufe Gymnasien  - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '861',
    label:
      'LA Master Mittelstufe / Sekundarstufe I  - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '862',
    label:
      'LA Master Sekundarstufe II / Sekundarstufe I  - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '863',
    label:
      'LA Master Oberstufe / Sekundarstufe II, allgemeinbildende Schulen  - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '864',
    label:
      'LA Master Gymnasien  - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '865',
    label:
      'LA Master Sonderschulen / Förderschulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '866',
    label:
      'LA Master Berufliche Schulen  - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '867',
    label:
      'LA Master Oberstufe / Sekundarstufe II, berufliche Schulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '868',
    label:
      'Mehr-Fächer-Bachelor ohne Lehramtsoption - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '869',
    label:
      'Mehr-Fächer-Master  - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '870',
    label:
      'Staatl. Prüfung für künstlerische Abschlüsse - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '871',
    label:
      'Bühnen- / Konzert- / Opernreifeprüfung - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '872',
    label:
      'Privatmusiklehrerprüfung - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '873',
    label:
      'Kirchenmusikprüfung A - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '874',
    label:
      'Kirchenmusikprüfung B - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '875',
    label:
      'Diplom (KH) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '876',
    label:
      'Meisterschüler - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '877',
    label:
      'Kunstpädagogische Prüfung - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '878',
    label:
      'Master (U) - Lehrer - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '879',
    label:
      'Solistenprüfung  - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '880',
    label:
      'Sonstiger künstlerischer Abschluss - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '881',
    label:
      'Akademiebrief (KH) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '882',
    label:
      'Bachelor an Universitäten - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '883',
    label:
      'Bachelor an Kunsthochschulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '884',
    label:
      'Bachelor an Fachhochschulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '888',
    label:
      'Master an Universitäten (Abschlussprüfung vorausgesetzt) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '889',
    label:
      'Master an Kunsthochschulen (Abschlussprüfung vorausgesetzt) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '890',
    label:
      'Master an Fachhochschulen (Abschlussprüfung vorausgesetzt) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '893',
    label:
      'Bachelor (U) - Lehrer - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '894',
    label:
      'Abschlusszeugnis / Zertifikat - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '895',
    label:
      'Sonstiger Abschluss in Deutschland - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '898',
    label:
      'LA Bachelor Grund- und Hauptschulen - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '899',
    label:
      'LA Master Grund- und Hauptschulen  - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '8B5',
    label:
      'LA Bachelor Grundschulen (Ba LA GS) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '8B6',
    label:
      'LA Bachelor Haupt-, Real- und Gesamtschulen (Ba LA HRGe) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '8B7',
    label:
      'LA Bachelor Gymnasien und Gesamtschulen (Ba LA GymGe) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '8B8',
    label:
      'LA  Bachelor Berufskollegs (BA LA BK) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '8B9',
    label:
      'LA Bachelor für sonderpädagogische Förderung (BA LA SP) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '8M7',
    label:
      'LA Master Grundschulen (Ma LA GS) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '8M8',
    label:
      'LA Master Haupt-, Real- und Gesamtschulen (Ma LA HRGe) - Weiterstudium bzw. Prüfungswiederholung zur Verbesserung der Prüfungsnote',
  },
  {
    statisticKey: '997',
    label: 'Keine Abschlussprüfung möglich bzw. angestrebt - Kein Abschluss',
  },
];
