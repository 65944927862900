import { Actions, Mutations } from './types';
import {
  campuses,
  countries,
  districts,
  federalStates,
  graduationTypes,
  healthInsurances,
  honorificPrefixes,
  hzbCountries,
  interruptions,
  permitTypes,
  sexes,
  studyCourses,
  universities,
  universityEntranceCertificates,
} from '@/common/selectLists';
import {
  Actions as ProfileActions,
  Mutations as ProfileMutations,
} from '../profile/types';
import { Actions as AcademicHistoryActions } from '../academicHistory/types';

import { CMSListings } from '@epos/core-applets';

export default {
  [Actions.UPDATE_SNACKBAR]({ commit }, payload) {
    commit(Mutations.UPDATE_SNACKBAR, payload);
  },
  [Actions.SET_ERROR]({ commit }, error) {
    commit(Mutations.PUSH_TO_ERROR_STACK, error);
  },
  [Actions.DISMISS_ERROR]({ commit }) {
    commit(Mutations.REMOVE_ERROR_FROM_STACK);
  },
  // eslint-disable-next-line no-unused-vars
  async [Actions.INITIALIZE]({ commit, dispatch, state }, id) {
    if (!id) return;

    commit(Mutations.CLEAR_ERRORS);
    commit(Mutations.INCREMENT_LOADING);

    try {
      await dispatch(`profile/${ProfileActions.RESET_PROFILE}`, null, {
        root: true,
      });

      await Promise.allSettled([
        dispatch(`profile/${ProfileActions.LOAD_PROFILE}`, id, { root: true }),
        dispatch(`profile/${ProfileActions.LOAD_CONTACT_POINTS}`, id, {
          root: true,
        }),
        dispatch(`profile/${ProfileActions.LOAD_IDENTITY_MANAGEMENT}`, id, {
          root: true,
        }),
        dispatch(
          `profile/${ProfileActions.LOAD_UNIVERSITY_ENTRANCE_QUALIFICATION}`,
          id,
          {
            root: true,
          },
        ),
        dispatch(
          `academicHistory/${AcademicHistoryActions.LOAD_ACADEMIC_HISTORY}`,
          id,
          {
            root: true,
          },
        ),
      ]);

      // health insurance is currently too slow, so do not await for it
      dispatch(`profile/${ProfileActions.LOAD_HEALTH_INSURANCE}`, id, {
        root: true,
      });
    } catch (error) {
      dispatch(Actions.SET_ERROR, error);
    } finally {
      commit(Mutations.DECREMENT_LOADING);
      commit(`profile/${ProfileMutations.SET_HAS_FETCHED_PROFILE}`, true, {
        root: true,
      });
    }
  },
  async [Actions.LOAD_SELECT_LISTS]({ commit, dispatch }) {
    commit(Mutations.INCREMENT_LOADING);

    const listsToFetch = {
      campuses: [],
      countries: [],
      districts: [],
      federalStates: [],
      graduationTypes: [],
      healthInsurances: [],
      honorificPrefixes: [],
      hzbCountries: [],
      interruptions: [],
      permitTypes: [],
      sexes: [],
      studyCourses: [],
      universities: [],
      universityEntranceCertificates: [],
    };

    const localListData = {
      campuses,
      countries,
      districts,
      federalStates,
      graduationTypes,
      healthInsurances,
      honorificPrefixes,
      hzbCountries,
      interruptions,
      permitTypes,
      sexes,
      studyCourses,
      universities,
      universityEntranceCertificates,
    };

    const graduationTypesOrder = [
      '184', // Bachelor an Fachhochschulen - Erststudium
      '284', // Bachelor an Fachhochschulen - Zweitstudium
      '182', // Bachelor an Universitäten - Erststudium
      '282', // Bachelor an Universitäten - Zweitstudium
      '790', // Master an Fachhochschulen (Abschlussprüfung vorausgesetzt) - Konsekutives Masterstudium
      '290', // Master an Fachhochschulen (Abschlussprüfung vorausgesetzt) - Zweitstudium
      '690', // Master an Fachhochschulen (Abschlussprüfung vorausgesetzt) - Weiterbildungsstudium
      '788', // Master an Universitäten (Abschlussprüfung vorausgesetzt) - Konsekutives Masterstudium
      '288', // Master an Universitäten (Abschlussprüfung vorausgesetzt) - Zweitstudium
      '688', // Master an Universitäten (Abschlussprüfung vorausgesetzt) - Weiterbildungsstudium
    ];

    const orderFunctions = {
      graduationTypes: (item1, item2) => {
        const indexItem1 = graduationTypesOrder.indexOf(item1?.statisticKey);
        const indexItem2 = graduationTypesOrder.indexOf(item2?.statisticKey);
        if (indexItem1 < 0 && indexItem2 < 0) {
          return 0;
        }
        if (indexItem1 < 0) {
          return 1;
        }
        if (indexItem2 < 0) {
          return -1;
        }

        return indexItem1 - indexItem2;
      },
    };

    await Promise.allSettled(
      Object.keys(listsToFetch).map(async (listName) => {
        try {
          listsToFetch[listName] = CMSListings.loadData(listName);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn(
            `cant load list "${listName}" from CMS, will use fallback list from local data`,
            error
          );

          if (Object.keys(localListData).includes(listName)) {
            listsToFetch[listName] = localListData[listName];
          } else {
            dispatch(Actions.SET_ERROR, {
              message: `Die Liste "${listName}" konnte weder lokal noch vom CMS geladen werden.`,
            });
          }
        }

        // Do some sorting after the lists are loaded, when there is a sorter defined
        if (typeof orderFunctions[listName] === 'function') {
          listsToFetch[listName] = listsToFetch[listName].sort(
            orderFunctions[listName],
          );
        }
      }),
    );

    commit(Mutations.SET_SELECT_LISTS, listsToFetch);
    commit(Mutations.DECREMENT_LOADING);
  },
};
