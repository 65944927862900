import {
  EButton,
  EChip,
  EContactDetail,
  EContactGroup,
  EIcon,
  EMenu,
  EModal,
} from '@careerpartner/nitro';
import { mapGetters } from 'vuex';
import {
  Getters as ProfileGetters,
  Actions as ProfileActions,
} from '@/store/modules/profile/types';
import {
  ADDRESS_TYPES,
  CONTACT_POINTS,
  EMAIL_TYPES,
  OVERLAY_EVENTS,
  PHONE_TYPES,
  USAGE_TYPES,
} from '@/common/constants';

const NEW_EMPTY_ADDRESS = {
  contactType: 'default',
  addressAdditional: '',
  streetAddress: '',
  postalCode: '',
  addressLocality: '',
  addressCountry: null,
  usageType: [],
};

const NEW_EMPTY_PHONE = {
  contactType: 'default',
  usageType: [],
  phone: '',
  prefix: '+49',
};

const NEW_EMPTY_EMAIL = {
  contactType: 'default',
  usageType: [],
  email: '',
};

export default {
  components: {
    EButton,
    EMenu,
    EChip,
    EContactDetail,
    EContactGroup,
    EIcon,
    EModal,
  },
  data() {
    return {
      isOpen: false,
      currentContactPoint: null,
      currentModalTitle: '',
      currentIsNew: false,
      overlayVisible: false,
    };
  },
  computed: {
    ...mapGetters('profile', {
      addresses: ProfileGetters.PROFILE_ADDRESSES,
      emails: ProfileGetters.PROFILE_EMAILS,
      phones: ProfileGetters.PROFILE_PHONES,
    }),
    CONTACT_POINTS() {
      return CONTACT_POINTS;
    },
    OVERLAY_EVENTS() {
      return OVERLAY_EVENTS;
    },
    ADDRESS_TYPES() {
      return ADDRESS_TYPES;
    },
    EMAIL_TYPES() {
      return EMAIL_TYPES;
    },
    PHONE_TYPES() {
      return PHONE_TYPES;
    },
  },
  methods: {
    add(contactPointType) {
      if (contactPointType === CONTACT_POINTS.ADDRESS) {
        this.currentContactPoint = NEW_EMPTY_ADDRESS;
        this.currentModalTitle = 'Adresse hinzufügen';
      } else if (contactPointType === CONTACT_POINTS.EMAIL) {
        this.currentContactPoint = NEW_EMPTY_EMAIL;
        this.currentModalTitle = 'Email hinzufügen';
      } else if (contactPointType === CONTACT_POINTS.PHONE) {
        this.currentContactPoint = NEW_EMPTY_PHONE;
        this.currentModalTitle = 'Telefonnummer hinzufügen';
      }

      this.currentIsNew = true;
      this.toggleOverlay();
    },
    editContact(contactPointType, contactPoint) {
      this.currentContactPoint = contactPoint;

      if (contactPointType === CONTACT_POINTS.ADDRESS) {
        this.currentModalTitle = 'Adresse bearbeiten';
      } else if (contactPointType === CONTACT_POINTS.EMAIL) {
        this.currentModalTitle = 'E-Mail bearbeiten';
      } else if (contactPointType === CONTACT_POINTS.PHONE) {
        this.currentModalTitle = 'Phone bearbeiten';
      }

      this.currentIsNew = false;
      this.toggleOverlay();
    },
    deleteContact(contactPointType, contactPoint) {
      if (confirm('Eintrag löschen?')) {
        this.$store.dispatch(`profile/${ProfileActions.DELETE_CONTACT_POINT}`, {
          contactPoint,
          target: contactPointType,
        });
      }
    },
    closeOverlay() {
      if (
        this.currentContactPoint &&
        confirm('Bearbeiten ohne speichern beenden?')
      ) {
        this.toggleOverlay();
      }
    },
    toggleOverlay() {
      this.overlayVisible = !this.overlayVisible;
    },
    tags(contact) {
      return (contact.usageType || []).map((el) => USAGE_TYPES[el]);
    },
    updateContactPoint({ contactPoint, target }) {
      this.$store.dispatch(`profile/${ProfileActions.UPDATE_CONTACT_POINT}`, {
        contactPoint,
        target,
      });
      this.currentIsNew = false;
    },
    saveContactPoint({ contactPoint, target }) {
      this.$store.dispatch(`profile/${ProfileActions.SAVE_NEW_CONTACT_POINT}`, {
        contactPoint,
        target,
      });
      this.currentIsNew = false;
    },
    isPrimary(contact) {
      return !!contact.usageType?.filter((el) =>
        ['shipping', 'primary'].includes(el),
      ).length;
    },
  },
};
