export const imagePaths = {
  SEARCH_IMAGE_URL:
    'https://res.cloudinary.com/iubh/image/upload/v1633071894/IT%20-%20Epos%20/illustrations/epos-customer-profile-search.svg',
  ERROR_IMAGE_URL:
    'https://res.cloudinary.com/iubh/image/upload/v1625224956/IT%20-%20Epos%20/illustrations/epos-error.svg',
  STATISTIC_PREVIOUS_STUDIES:
    'https://res.cloudinary.com/iubh/image/upload/v1633072224/IT%20-%20Epos%20/illustrations/epos-customer-profile-statistic-gesamtanzahl.svg',
  STATISTIC_FIRST_ENROLLMENT:
    'https://res.cloudinary.com/iubh/image/upload/v1633072226/IT%20-%20Epos%20/illustrations/epos-customer-profile-statistic-ersteinschreibung.svg',
  STATISTIC_GRADUATED_STUDY:
    'https://res.cloudinary.com/iubh/image/upload/v1633072228/IT%20-%20Epos%20/illustrations/epos-customer-profile-statistic-abschluss.svg',
  STATISTIC_PARALLEL_ENROLLMENT:
    'https://res.cloudinary.com/iubh/image/upload/v1633072231/IT%20-%20Epos%20/illustrations/epos-customer-profile-statistic-parallel.svg',
  CONFIRM_CERTIFICATION_OF_HZB:
    'https://res.cloudinary.com/iubh/image/upload/v1649923867/IT%20-%20Epos%20/illustrations/epos-beglaubigung-hzb.svg',
};
