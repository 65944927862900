<template>
  <section-structure is-extendable mount-closed>
    <template #headline>
      Aufenthaltstitel (Nur erforderlich für NON-EU CS Studierende)
    </template>

    <div class="col-span-3">
      <validation-provider
        v-slot="{ errors }"
        name="ResidencePermit | Typ"
        slim
      >
        <e-multiselect
          id="permit-types"
          v-model="permitType"
          :errors="errors"
          data-test="profile__residence-type"
          :options="selectLists.permitTypes"
          label="Art des Aufenthaltstitels"
          option-label="value"
          track-by="key"
          mapped
          wide
        ></e-multiselect>
      </validation-provider>
    </div>

    <div class="col-span-3">
      <validation-provider
        v-slot="{ errors }"
        name="ResidencePermit | Ablaufdatum"
        :rules="dateValidator"
        slim
      >
        <e-text-field
          id="permit-valid"
          v-model="validUntil"
          type="date"
          :errors="errors"
          data-test="profile__residence-validUntil"
          label="Gültig bis"
        ></e-text-field>
      </validation-provider>
    </div>
  </section-structure>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import SectionStructure from '@/components/SectionStructure';
import { EMultiselect, ETextField } from '@careerpartner/nitro';
import selectLists from '@/mixins/selectLists';
import { ValidationProvider } from 'vee-validate';
import dayjs from 'dayjs';

export default {
  name: 'ResidencePermit',
  components: {
    SectionStructure,
    EMultiselect,
    ValidationProvider,
    ETextField,
  },
  mixins: [selectLists],
  computed: {
    ...mapFields('profile', [
      'residencePermit.permitType',
      'residencePermit.validUntil',
    ]),
    nextDayDate() {
      return dayjs().add(1, 'day').format('YYYY-MM-DD');
    },
  },
  methods: {
    dateValidator(value) {
      if (!value) {
        return true;
      }
      return { date_format: true, after: [new Date()] };
    },
  },
};
</script>
