<template>
  <section-structure>
    <template #headline> Persönliche Daten </template>
    <div class="col-span-3">
      <validation-provider
        v-slot="{ errors }"
        name="Personal Info | Geschlecht"
        rules="required"
        slim
        immediate
        :skip-if-empty="false"
      >
        <e-multiselect
          id="sexes"
          v-model="sex"
          data-test="profile__select-sex"
          :options="selectLists.sexes"
          :errors="errors"
          label="Geschlecht"
          option-label="label"
          track-by="referenceId"
          mapped
          required
        />
      </validation-provider>
    </div>
    <div class="col-span-3 flex space-x-2">
      <validation-provider
        name="Personal Info | Titel"
        slim
        immediate
        :skip-if-empty="false"
      >
        <e-multiselect
          id="honorific-prefix"
          v-model="honorificPrefix"
          data-test="profile__select-prefix"
          :options="selectLists.honorificPrefixes"
          label="Titel"
          wide
          mapped
          option-label="key"
          track-by="key"
        />
      </validation-provider>
      <e-tooltip
        content="Eine Bezeichnung, die man als Zusatz zu seinem Nachnamen verwendet und die einen akademischen Rang oder ein Amt bezeichnet."
        class="items-center justify-center text-cta"
      >
        <e-icon icon="mdi-information-outline" size="2xl" />
      </e-tooltip>
    </div>
    <div class="col-span-3">
      <validation-provider
        v-slot="{ errors }"
        ref="givenName"
        rules="ruleForName|required|max:50"
        name="Personal Info | Vorname"
        slim
        immediate
        :skip-if-empty="false"
      >
        <e-text-field
          id="given-name"
          v-model="givenName"
          data-test="profile__given-name"
          :errors="errors"
          label="Vorname"
        ></e-text-field>
      </validation-provider>
    </div>
    <div class="col-span-3">
      <validation-provider
        v-slot="{ errors }"
        ref="familyName"
        rules="ruleForName|required|max:50"
        name="Personal Info | Nachname"
        slim
        immediate
        :skip-if-empty="false"
      >
        <e-text-field
          id="family-name"
          v-model="familyName"
          data-test="profile__family-name"
          :errors="errors"
          label="Nachname"
        ></e-text-field>
      </validation-provider>
    </div>
    <div class="col-span-3">
      <validation-provider
        v-slot="{ errors }"
        name="Personal Info | Geburtsdatum"
        :rules="{
          required: true,
          date_format: true,
          before: { date: new Date() },
        }"
        slim
        immediate
        :skip-if-empty="false"
      >
        <e-text-field
          id="birth-date"
          v-model="birthDate"
          data-test="profile__birth-date"
          :errors="errors"
          type="date"
          label="Geburtsdatum"
          required
        />
      </validation-provider>
    </div>
    <div class="col-span-3">
      <validation-provider
        v-slot="{ errors }"
        rules="ruleForName|max:50"
        name="Personal Info | Geburtsname"
        slim
        immediate
        :skip-if-empty="true"
      >
        <e-text-field
          id="birth-name"
          v-model="birthName"
          data-test="profile__birth-name"
          :errors="errors"
          hide-details
          label="Geburtsname"
        ></e-text-field>
      </validation-provider>
    </div>
    <div class="col-span-full">
      <validation-provider
        v-slot="{ errors }"
        name="Personal Info | Staatsangehörigkeit"
        rules="required"
        slim
        immediate
        :skip-if-empty="false"
      >
        <e-multiselect
          id="nationality"
          v-model="nationality"
          data-test="profile__select-nationality"
          :errors="errors"
          :close-on-select="false"
          :options="selectLists.countries"
          searchable
          label="Staatsangehörigkeit"
          option-label="name"
          track-by="countryIso"
          multiple
          mapped
          required
          :max="3"
        />
      </validation-provider>
    </div>
  </section-structure>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mapFields } from 'vuex-map-fields';
import {
  EMultiselect,
  ETextField,
  EIcon,
  ETooltip,
} from '@careerpartner/nitro';
import SectionStructure from '@/components/SectionStructure';
import selectLists from '@/mixins/selectLists';
import '@/common/validationExtends';

export default {
  name: 'PersonalInfo',
  components: {
    SectionStructure,
    EMultiselect,
    ETextField,
    EIcon,
    ETooltip,
    ValidationProvider,
  },
  mixins: [selectLists],
  computed: {
    ...mapFields('profile', [
      'person.sex',
      'person.honorificPrefix',
      'person.givenName',
      'person.familyName',
      'person.birthName',
      'person.birthDate',
      'person.nationality',
    ]),
  },
};
</script>
