var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-observer',{ref:"email-form",attrs:{"vid":"email-form","slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('form',{staticClass:"grid grid-cols-12 gap-x-6 gap-y-7 mt-9 px-4 pb-8",attrs:{"data-test":"profile__email-modal"}},[_c('div',{staticClass:"col-span-6"},[_c('validation-provider',{attrs:{"name":"Art der E-Mail","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('e-multiselect',{attrs:{"id":"email-contacttype","data-test":"profile__email-edit-type","options":_vm.EMAIL_TYPES,"label":"Art der E-Mail-Adresse","option-label":"label","track-by":"key","required":"","errors":errors,"mapped":""},model:{value:(_vm.email.contactType),callback:function ($$v) {_vm.$set(_vm.email, "contactType", $$v)},expression:"email.contactType"}})]}}],null,true)})],1),_c('div',{staticClass:"col-span-full"},[_c('validation-provider',{attrs:{"name":"E-Mail","rules":{
          required: true,
          customEmail: {
            value: '@email',
            contactType: _vm.email.contactType,
          },
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('e-text-field',{attrs:{"id":"email","data-test":"profile__email-edit-email","label":"E-Mail","wide":"","required":"","errors":errors},model:{value:(_vm.email.email),callback:function ($$v) {_vm.$set(_vm.email, "email", $$v)},expression:"email.email"}})]}}],null,true)})],1),_c('p',{staticClass:"mt-2 text-xs text-gray-700 col-span-full"},[_vm._v("* Pflichtfelder")]),_c('last-changed-by',{attrs:{"updated-at":_vm.email.updatedAt,"updated-by":_vm.email.updatedBy}}),_c('div',{staticClass:"col-span-full flex h-9"},[_c('e-button',{staticClass:"ml-auto mt-18",attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.saveEmail}},[_c('e-icon',{staticClass:"mr-2",attrs:{"icon":"mdi-content-save-outline"}}),_vm._v(" Speichern")],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }