<template>
  <e-chip
    v-if="value"
    :class="healthInsuranceStateClass"
    class="text-white"
    data-test="profile__health-state"
  >
    {{ healthInsuranceStateContent }}
  </e-chip>
</template>

<script>
import { EChip } from '@careerpartner/nitro';

export const HealthinsuranceStatus = {
  REPORTED: 'reported',
  CANCELLED: 'cancelled',
  PENDING: 'pending',
};

export default {
  name: 'HealthinsuranceStatus',
  components: { EChip },
  props: {
    value: { type: String, default: undefined },
  },
  computed: {
    healthInsuranceStateClass() {
      return {
        'bg-success': this.value === HealthinsuranceStatus.REPORTED,
        'bg-error': this.value === HealthinsuranceStatus.CANCELLED,
        'bg-info': this.value === HealthinsuranceStatus.PENDING,
      };
    },
    healthInsuranceStateContent() {
      return {
        [HealthinsuranceStatus.REPORTED]: 'Gemeldet',
        [HealthinsuranceStatus.PENDING]: 'Ausstehend',
        [HealthinsuranceStatus.CANCELLED]: 'Abgemeldet',
      }[this.value];
    },
  },
};
</script>
